import React from 'react'
import { SwiperSlide } from 'swiper/react'
import CaregiverCard from 'components/organisms/CaregiverCard'
import CustomSwiper from 'seniors-first-commons/components/atoms/Swiper'


const MyCaregiversMobileLayout = ({
  selectedCaregiver,
  caregiverStatus,
  unlockedCaregivers,
  onViewProfile,
  onOpenVideoCVModal
}) => {
  return (
    <>
      <CustomSwiper
        autoplay={false}
        centeredSlides={false}
        data={unlockedCaregivers.items}
        freeMode={true}
        loop={false}
      >
        {unlockedCaregivers.items.map((caregiver, index) => {
          return (
            <SwiperSlide key={index}>
              {() => {
                return (
                  <CaregiverCard
                    caregiverStatus={caregiverStatus({ ...caregiver })}
                    data={caregiver}
                    onOpenVideoCVModal={onOpenVideoCVModal}
                    onViewProfile={() => onViewProfile({ ...caregiver })}
                    selectedCaregiver={selectedCaregiver}
                  />
                )
              }}
            </SwiperSlide>
          )
        })}
      </CustomSwiper>
    </>
  )
}


export default MyCaregiversMobileLayout
