// should be implemted with the filter valeus in the search screen's sidebar (find & send)
// see src/pages/Search/Sidebar/index.js
import { CAREGIVER_AGE_RANGE } from 'seniors-first-commons/utils/constants'

export default class Search {
  constructor() {
    this.gender = []
    this.age = CAREGIVER_AGE_RANGE
    this.languages = []
    this.disease_experience = []
    this.mobilizations = false
    this.pet_friendly = false
    this.driving_license = false
    this.education_level = []
    this.nationalities = []
  }
}
