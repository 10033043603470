import React from 'react'
import { ErrorMessage } from 'formik'
import Input from 'seniors-first-commons/components/atoms/Input'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import { PencilEdit } from 'seniors-first-commons/components/atoms/images/icons'


export const NameInput = (props) => {
  return (
    <div className='has-padding-lr-7 full-width'>
      <Input
        autoFocus
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        id='name'
        maxLength={50}
        placeholder=''
        {...props}
      />
      <ErrorMessage component={HandleError} name='name' />
    </div>
  )
}

export const ContractType = ({contract_type}) => {

  const contractItems = [
    {
      name: 'contract_type',
      text: 'client.createOffer.steps.contractType.autonomus',
      value: 'autonomous'
    },
    {
      name: 'contract_type',
      text: 'client.createOffer.steps.contractType.company',
      value: 'company'
    }
  ]

  return (
    <Radios content={contractItems} input={contract_type} />
  )
}


export const AddressInput = (props) => {
  return (
    <AddressAutoSuggest
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#0080F5'
          width='16'
        />
      )}
      id='address'
      inputClassName='input'
      placeholder=''
      {...props}
    />
  )
}
