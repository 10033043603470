import 'moment/locale/en-gb'
import 'moment/locale/es-us'
import 'moment/locale/es'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import moment from 'moment'
import { Loader } from '@googlemaps/js-api-loader'
import appStore from './store'
import * as serviceWorker from './serviceWorker'
import App from './root/App'
import IntlProvider from 'seniors-first-commons/intl/IntlProvider'
import { loadTranslation } from 'seniors-first-commons/shared/store/translations/actions'
import { toUrlFormat } from 'seniors-first-commons/shared/languages'
import regionService from 'seniors-first-commons/services/region'
import './store/axios-settings'
import { LANG_LOCALSTORAGE_KEY } from 'seniors-first-commons/utils/constants'


const envLanguage = (toUrlFormat(process.env.REACT_APP_LANGUAGE) || 'en-GB')
loadTranslation(envLanguage)(appStore.dispatch)

const mapsApiLoader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  language: localStorage.getItem(LANG_LOCALSTORAGE_KEY) || 'en',
  libraries: ['places']
})

regionService.getLocale()
  .then(locale => {
    const formattedLocale = toUrlFormat(locale)

    loadTranslation(formattedLocale)(appStore.dispatch)
    moment.locale(formattedLocale)
  })

mapsApiLoader
  .load()
  .finally(() => {
    ReactDOM.render(
      <Provider store={appStore}>
        <IntlProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </IntlProvider>
      </Provider>,
      document.getElementById('root')
    )
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
