import React from 'react'
import { todosIcon, morningIcon, afternoonIcon, nightIcon } from '../components/atoms/images'
import {
  HomeIcon,
  CompanionshipIcon,
  PersonalcareIcon,
  NursingIcon
} from 'seniors-first-commons/components/atoms/images/icons'


export const MAX_STEPS = 6
export const WORK_HOURS = [1, 2, 3, 4, 5, 6, 7, 8]

export const dayIds = {
  1: 'apps.general.days.monday',
  2: 'apps.general.days.tuesday',
  3: 'apps.general.days.wednesday',
  4: 'apps.general.days.thursday',
  5: 'apps.general.days.friday',
  6: 'apps.general.days.saturday',
  7: 'apps.general.days.sunday'
}

export const TIMES = [
  '00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00',
  '08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00',
  '15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00',
  '22:30','23:00','23:30'
]

export const MORNING_TIME = ['08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00']
export const AFTERNOON_TIME = ['15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00']
export const NIGHT_TIME = ['21:30','22:00','22:30','23:00','23:30','00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','05:30','06:00','06:30','07:00','07:30']

export const TIME_OPTIONS = {
  afternoon: { description: '(De 2pm a 9pm)', icon: afternoonIcon, value: 'Tarde' },
  all: { icon: todosIcon, value: 'Toda la franja horaria' },
  morning: { description: '(De 7am a 2pm)', icon: morningIcon, value: 'Mañana' },
  night: { description: '(De 9pm a 7am)', icon: nightIcon, value: 'Noche' }
}

export const MODAL_NAMES = {
  CONFIRMATION: 'ConfirmationModal',
  CONTINUE: 'ContinueModal',
  EXPERIENCE: 'ExperienceModals',
  EXPERIENCE_OBSERVATION: 'ObservationModal'
}

export const FORM_NAMES = {
  contratacion: {
    actual: 'HiringForm',
    condicionesLaborales: 'CondicionalLaboralForm',
    remuneracion: 'RemuneracionForm',
    situacionLaboral: 'SituacionLaboralForm'
  },
  'datos-de-contacto': 'ContactForm',
  'datos-personales': 'PersonalDataForm',
  disponibilidad: 'ServiceAvailabilityForm',
  education: 'EducationForm',
  experiencia: 'ExperienceForm',
  'experiencia-enfermedad': 'IllnessExperienceForm',
  servicios: 'ServiceForm',
  'servicios-como': 'ServicesHowForm',
  'video-cv': 'VideoForm'
}

export const FORM_STATE_MAP = {
  condicionesLaborales: 'hiring',
  contratacion: 'hiring',
  'datos-de-contacto': 'personalData',
  'datos-personales': 'personalData',
  disponibilidad: 'offeredServices',
  educacion: 'education',
  experiencia: 'experience',
  'experiencia-enfermedad': 'illnessExperiences',
  salario: 'hiring',
  servicios: 'offeredServices',
  'servicios-como': 'offeredServices',
  'video-cv': 'video'
}


export const HOME_FORM_MAP = {
  education: 'formation',
  experience: 'experiences',
  hiring: 'hiring_condition',
  profile: 'profile',
  services: 'offered_service',
  video: 'videos'
}

export const BOARDING_ROUTES = {
  education: 'formation',
  experienceForm: 'experience',
  experiences: 'experiences',
  hiring: 'hiring-conditions',
  illnessExperiences: 'illnesses-experience',
  offeredServices: 'services',
  personalData: 'personal-data',
  video: 'video-cv'
}

export const MAP_STATE_TO_API = {
  education: 'EDUCATION',
  experience: 'EXPERIENCES',
  hiring: 'HIRING',
  offeredServices: 'OFFERED_SERVICES',
  personalData: 'PROFILE',
  video: 'VIDEO'
}

export const LANGUAGE_OPTIONS = {
  1: { level: 1, name: 'app.boarding.constants.language.basic' },
  2: { level: 2, name: 'app.boarding.constants.language.intermediate' },
  3: { level: 3, name: 'app.boarding.constants.language.advanced' }
}


export const DAYS_OF_WEEK = [
  'apps.general.days.monday',
  'apps.general.days.tuesday',
  'apps.general.days.wednesday',
  'apps.general.days.thursday',
  'apps.general.days.friday',
  'apps.general.days.saturday',
  'apps.general.days.sunday'
]

export const STORAGE = {
  ACCESS_TOKEN: 'access_token',
  EXPIRES_AT: 'expires_at',
  REFRESH_TOKEN: 'refresh_token'
}


export const FORMATION = {
  1: 'client.search.sidebar.eduction.primary',
  2: 'client.search.sidebar.eduction.secondary',
  3: 'client.search.sidebar.eduction.professional',
  4: 'client.search.sidebar.eduction.university',
  5: 'client.search.sidebar.eduction.other'
}


export const handleServiceType = (item, height, width, disabled) => {
  switch (item) {
  case 1:
    return {
      image: (
        <HomeIcon
          fill='none'
          height={height}
          stroke={disabled ? '#A6A6A6' : '#022C72'}
          width={width}
        />
      )
    }
  case 2:
    return {
      image: (
        <CompanionshipIcon
          fill='none'
          height={height}
          stroke={disabled ? '#A6A6A6' : '#022C72'}
          width={width}
        />
      )
    }
  case 3:
    return {
      image: (
        <PersonalcareIcon
          fill='none'
          height={height}
          stroke={disabled ? '#A6A6A6' : '#022C72'}
          width={width}
        />
      )
    }
  case 4:
    return {
      image: (
        <NursingIcon
          fill='none'
          height={height}
          stroke={disabled ? '#A6A6A6' : '#022C72'}
          width={width}
        />
      )
    }
  default:
    return {
      image: '',
      text: ''
    }
  }
}

export const handleTimes = (day) => {
  switch(day){
  case 'apps.general.days.monday':
    return 1
  case 'apps.general.days.tuesday':
    return 2
  case 'apps.general.days.wednesday':
    return 3
  case 'apps.general.days.thursday':
    return 4
  case 'apps.general.days.friday':
    return 5
  case 'apps.general.days.saturday':
    return 6
  case 'apps.general.days.sunday':
    return 7
  default:
    return 1
  }
}

export const interestedModalOptions = [
  {
    label: 'apps.general.interestedReasonsModal.location',
    value: 'location'
  },
  {
    label: 'apps.general.interestedReasonsModal.time_schedule',
    value: 'time_schedule'
  },
  {
    label: 'apps.general.interestedReasonsModal.start_end_schedule',
    value: 'start_end_schedule'
  },
  {
    label: 'apps.general.interestedReasonsModal.salary',
    value: 'salary'
  },
  {
    label: 'apps.general.interestedReasonsModal.other',
    value: 'other'
  }
]

export const ITEMS_PER_PAGE = 20
export const NEW_NOTIFICATIONS = 3

export const AWS_IMAGE_STORAGE = 'https://seniors-first.s3.amazonaws.com/'

export const MAX_VIDEO_SIZE = 83886080

export const CAREGIVER_AGE_RANGE = [18, 70]

export const CREATE_JOB_OFFER_STEPS = [
  { label: 'client.createOffer.steps.address' },
  { label: 'client.createOffer.steps.serviceType' },
  { label: 'client.createOffer.steps.modality' },
  { label: 'client.createOffer.steps.duration' },
  {
    label: 'client.createOffer.steps.contract',
    label2: 'client.createOffer.steps.retribution'
  },
  { label: 'client.createOffer.steps.offerName' }
]

export const CREATE_PUBLIC_JOB_OFFER_STEPS = [
  { label: 'client.createOffer.steps.address' },
  { label: 'client.createOffer.steps.serviceType' },
  { label: 'client.createOffer.steps.modality' },
  { label: 'client.createOffer.steps.duration' },
  {
    label: 'client.createOffer.steps.contract',
    label2: 'client.createOffer.steps.retribution'
  },
  { label: 'client.createOffer.summary.text1' }
]

export const MINIMUM_WAGE_SPAIN_INFO = {
  code: 'ES',
  countryId: 64, // our API country id
  url: 'https://www.mites.gob.es/es/Guia/texto/guia_6/contenidos/guia_6_13_2.htm'
}
export const MINIMUM_WAGE_UK_INFO = {
  code: 'UK',
  countryId: 185, // our API country id
  url: 'https://www.gov.uk/national-minimum-wage-rates'
}
export const LANG_LOCALSTORAGE_KEY = 'sf_lang'
export const REACT_SELECT_PREFIXES = {
  EXTERNAL_STARTING_HOUR: 'external-starting-hour',
  INTERNAL_STARTING_HOUR: 'internal-starting-hour',
  REACT_SELECT: 'react-select'
}
export const DEFAULT_FOCUSED_START_HOUR = '07:00'
export const WEEKS_TO_EXPIRE_POST = 4
export const PHONE_VALIDATION_MIN_LENGTH = 4
