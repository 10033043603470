import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const ArchiveButton = ({ archived, onClick }) => {
  return (
    <button
      className={`filled ${archived ? '' : 'has-margin-b-7'} ${classes.button}`}
      onClick={onClick}
    >
      <FormattedMessageWithClass id='apps.general.jobOffer.actions.unarchive' />
    </button>
  )
}

export default ArchiveButton
