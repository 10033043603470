import { pathOr } from 'ramda'


export default class Billing {
  constructor(billing, countries) {
    this.company_name = pathOr('', ['company_name'], billing)
    this.company_id_number = pathOr('', ['company_id_number'], billing)
    this.person_first_last_name = pathOr('', ['person_first_last_name'], billing)
    this.person_id_number = pathOr('', ['person_id_number'], billing)
    this.email = pathOr('', ['email'], billing)
    this.country = {
      label: countries && billing ?
        countries.find(item => item.value === pathOr('', ['country_id'], billing))
          .label : '',
      value: countries && billing ?
        countries.find(item => item.value === pathOr('', ['country_id'], billing))
          .value : ''
    }
    this.address = {
      address_lat: 0,
      address_lng: 0,
      formatted_address: pathOr('', ['address'], billing)
    }
    this.zip_code = pathOr('', ['zip_code'], billing)
    this.phone = pathOr('', ['phone'], billing)
  }
}
