import {createReducer} from '@reduxjs/toolkit'
import moment from 'moment'
import {
  getCaregiverDetail,
  getCaregivers,
  getLastOffersResults,
  getUnlockedCaregivers,
  getCaregiversNearYou,
  getConnectedCandidates,
  getCandidatesFromSearch,
  getCaregiversFromPost,
  paginatePostCandidatesClient,
  connectCaregiverFromPost,
  connectCaregiverFromSearch,
  toggleSuccessStatus,
  getInterestedReasons,
  connectInterestedCaregiver,
  chatWithCaregiver,
  setInboxSelectedConversation,
  directConnectFromProfile,
  getNotReadMessages,
  getCaregiverBasicData,
  nullCaregiverBasicData,
  connectAcceptedDirectOffer,
  checkConnectionWithCaregiver,
  rateCaregiver,
  onChatRegisterCaregiverId
} from './actions'
import { AWS_IMAGE_STORAGE, ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'
import { hasSameLatLng, getPostCandidatesPage, setNewCandidatesFirst } from './helpers'

import user from 'seniors-first-commons/components/atoms/images/user.png'


const initValues = {
  caregiverBasicData: {
    index: null,
    loading: false
  },
  caregiverDetails: null,
  caregiver_rating: {
    data: null,
    loading: false
  },
  caregiversNearYou: {
    currentPage: 1,
    items: [],
    total: 0
  },
  chat: {
    areConnected: null,
    checkingConnection: false,
    index: null,
    loading: false,
    notReadMessages: 0
  },
  connectAcceptedDirectOfferLoading: false,
  connectFromPostLoading: false,
  connectFromSearchLoading: false,
  directConnectFromProfileLoading: false,
  inboxSelectedConversation: null,
  index: {
    currentPage: 1,
    items: [],
    total: 0
  },
  interestedReasons: {
    caregiver_id: null,
    items: [],
    loading: false,
    loadingReasons: false
  },
  lastResults: [],
  loading: false,
  oneSheetCandidates: {
    currentPage: 1,
    items: [],
    total: 0
  },
  postCandidates: {
    currentPage: 1,
    items: [],
    itemsOnDisplay: [], //shows the current page slice of items
    total: 0
  },
  selectedCaregiver: '',
  success: '',
  unlockedCaregiver: {
    currentPage: 1,
    items: [],
    total: 0
  }
}

const formatOfferedServices = ({ lat, lng, ...rest }, direction) => {
  const HUNDRED_METERS_TO_DEGRESS = 0.00090861677

  if (lat && lng) {
    switch(direction) {
    case 'up':
      lat += HUNDRED_METERS_TO_DEGRESS
      break
    case 'bottom':
      lat -= HUNDRED_METERS_TO_DEGRESS
      break
    case 'right':
      lng += HUNDRED_METERS_TO_DEGRESS
      break
    case 'left':
      lng -= HUNDRED_METERS_TO_DEGRESS
      break
    default: return null
    }
  }

  return { lat, lng, ...rest }
}

const baseApiToData = (payload) => {
  return payload.map(({profile, offered_service, ...rest}) => {
    return {
      age: moment().diff(profile.birthday, 'years', false),
      image: rest['bio.photo'] ? AWS_IMAGE_STORAGE + rest['bio.photo'] : user,
      ...profile,
      ...offered_service,
      ...rest
    }
  })
}

const baseApiToCaregiversNearYou = (payload) => {
  const directions = ['up', 'right', 'bottom', 'left']

  payload = payload.map(({ offered_service, ...rest }) => {
    const direction = directions[Math.floor(Math.random() * directions.length)]
    return {
      offered_service: formatOfferedServices(offered_service, direction),
      ...rest
    }
  })

  while(hasSameLatLng(payload)) {
    for (let i=0; i<payload.length; i++) {
      const direction = directions[Math.floor(Math.random() * directions.length)]
      const coordinates = payload.map(item => `${item.offered_service.lat}-${item.offered_service.lng}`)
      const lat = payload[i].offered_service.lat
      const lng = payload[i].offered_service.lng

      if (!coordinates.includes(`${lat}-${lng}`)) continue
      payload[i] = {
        ...payload[i],
        offered_service: formatOfferedServices(payload[i].offered_service, direction)
      }
    }
  }

  return payload.map(({ profile, offered_service, ...rest }) => ({
    age: moment().diff(profile.birthday, 'years', false),
    image: rest['bio.photo'] ? AWS_IMAGE_STORAGE + rest['bio.photo'] : user,
    ...offered_service,
    ...profile,
    ...rest
  }))
}

const reducer = createReducer(initValues, (builder) => {
  builder.addCase(onChatRegisterCaregiverId, (state, action) => {
    state.selectedCaregiver = action.payload
  })

  builder.addCase(getCaregiverDetail.fulfilled, (state, action) => {
    const allServices = {
      companionship: [],
      home: [],
      nursing: [],
      personalcare: []
    }
    const { offered_service, profile, ...rest } = action.payload
    const { services, ...restOfferedService } = offered_service

    services.map(service => {
      if(service.service_category_id === 1) {
        allServices.home.push({isDisabled: true, label: service.name})
      }
      if(service.service_category_id === 2){
        allServices.companionship.push({isDisabled: true, label: service.name})
      }
      if(service.service_category_id === 3){
        allServices.personalcare.push({isDisabled: true, label: service.name})
      }
      if(service.service_category_id === 4){
        allServices.nursing.push({isDisabled: true, label: service.name})
      }

      return service
    })

    state.caregiverDetails = {
      age: moment().diff(profile.birthday, 'years', false),
      image: rest.bio && rest.bio.photo ?
        rest.bio.photo :
        user,
      offered_service: { ...restOfferedService, allServices },
      service_categories: services.map(({ service_category_id }) => {
        return {
          id: service_category_id
        }
      }),
      ...profile,
      ...rest
    }
  })

  builder.addCase(getCaregivers.fulfilled, (state, action) => {
    state.index.items = baseApiToData(action.payload.items)

    state.index.total = action.payload.total
    state.index.currentPage = action.payload.page
  })

  builder.addCase(getLastOffersResults.fulfilled, (state, action) => {
    state.lastResults = baseApiToData(action.payload)
  })

  builder.addCase(getUnlockedCaregivers.fulfilled, (state, action) => {
    state.unlockedCaregiver.items = baseApiToData(action.payload.items)
    state.unlockedCaregiver.total = action.payload.total
    state.unlockedCaregiver.currentPage = action.payload.page
  })

  builder.addCase(getCaregiversNearYou.fulfilled, (state, action) => {
    state.caregiversNearYou.total = action.payload.total
    state.caregiversNearYou.currentPage = action.payload.page
    state.caregiversNearYou.items = action.payload.total ? baseApiToCaregiversNearYou(action.payload.items) : []
  })

  builder.addCase(getConnectedCandidates.fulfilled, (state, action) => {
    state.oneSheetCandidates.items = baseApiToData(action.payload.items)
    state.oneSheetCandidates.currentPage = action.payload.page
    state.oneSheetCandidates.total = action.payload.total
  })

  builder.addCase(getCandidatesFromSearch.fulfilled, (state, action) => {
    state.oneSheetCandidates.items = baseApiToData(action.payload.items)
    state.oneSheetCandidates.currentPage = action.payload.page
    state.oneSheetCandidates.total = action.payload.total
  })

  builder.addCase(getCaregiversFromPost.fulfilled, (state, action) => {
    const { items: candidates, page, total } = action.payload
    const candidatesItems = setNewCandidatesFirst(baseApiToData(candidates))
    state.postCandidates.items = candidatesItems
    state.postCandidates.itemsOnDisplay = getPostCandidatesPage(candidatesItems, page, ITEMS_PER_PAGE)
    state.postCandidates.currentPage = page
    state.postCandidates.total = total
  })

  builder.addCase(paginatePostCandidatesClient.fulfilled, (state, action) => {
    const page = action.payload
    const candidatesItems = state.postCandidates.items
    state.postCandidates.currentPage = page
    state.postCandidates.itemsOnDisplay = getPostCandidatesPage(candidatesItems, page, ITEMS_PER_PAGE)
  })

  builder.addCase(connectCaregiverFromSearch.fulfilled, (state, action) => {
    state.index.items = state.index.items.map(item => {
      if (item.id === action.payload.caregiver_id) {
        item.job_proposition_status = 'pending'
      }

      return item
    })
    state.success = 'success.offerSent'
  })

  builder.addCase(connectCaregiverFromPost.fulfilled, (state, action) => {
    state.oneSheetCandidates.items = state.oneSheetCandidates.items
      .filter(item => item.id !== action.payload.caregiver_id)
    state.success = 'success.caregiverUnblocked'
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addCase(getInterestedReasons.fulfilled, (state, action) => {
    const reasons = action.payload.interested_reasons.map(({ reason }) => reason)
    state.interestedReasons.caregiver_id = action.payload.caregiver_id
    state.interestedReasons.items = reasons
  })

  builder.addCase(connectInterestedCaregiver.fulfilled, (state) => {
    state.success = 'success.caregiverUnblocked'
  })

  builder.addCase(getInterestedReasons.pending, (state) => {
    state.interestedReasons.loadingReasons = true
  })

  builder.addCase(connectInterestedCaregiver.pending, (state) => {
    state.interestedReasons.loading = true
  })

  builder.addCase(connectCaregiverFromPost.pending, (state) => {
    state.connectFromPostLoading = true
  })

  builder.addCase(connectCaregiverFromSearch.pending, (state) => {
    state.connectFromSearchLoading = true
  })

  builder.addCase(chatWithCaregiver.fulfilled, (state, action) => {
    state.chat.index = action.payload
  })

  builder.addCase(chatWithCaregiver.pending, (state) => {
    state.chat.loading = true
  })

  builder.addCase(setInboxSelectedConversation, (state, action) => {
    state.inboxSelectedConversation = action.payload.id
  })

  builder.addCase(connectAcceptedDirectOffer.pending, (state) => {
    state.connectAcceptedDirectOfferLoading = true
    state.success = 'success.caregiverUnblocked'
  })

  builder.addCase(directConnectFromProfile.fulfilled, (state, action) => {
    state.caregiverDetails = {
      ...state.caregiverDetails,
      service_sheet: {
        ...state.caregiverDetails.service_sheet,
        job_proposition: action.payload
      }
    }
  })

  builder.addCase(directConnectFromProfile.pending, (state) => {
    state.directConnectFromProfileLoading = true
  })

  builder.addCase(getNotReadMessages, (state, action) => {
    state.chat.notReadMessages = action.payload
  })

  builder.addCase(getCaregiverBasicData.pending, (state) => {
    state.caregiverBasicData.loading = true
  })

  builder.addCase(getCaregiverBasicData.fulfilled, (state, action) => {
    const { profile, ...rest } = action.payload

    state.caregiverBasicData.index = {
      ...rest,
      ...profile
    }
  })

  builder.addCase(nullCaregiverBasicData, (state) => {
    state.caregiverBasicData.index = null
    state.caregiverBasicData.loading = false
  })

  builder.addCase(checkConnectionWithCaregiver.fulfilled, (state, action) => {
    state.chat.areConnected = action.payload
  })

  builder.addCase(checkConnectionWithCaregiver.pending, (state) => {
    state.chat.checkingConnection = true
  })

  builder.addCase(rateCaregiver.fulfilled, (state, action) => {
    state.caregiver_rating.data = action.payload
    state.success = 'success.caregiverRated'
  })

  builder.addCase(rateCaregiver.pending, (state) => {
    state.caregiver_rating.loading = true
  })

  builder.addMatcher((action) => [
    getCaregiverBasicData.fulfilled.toString(),
    getCaregiverBasicData.rejected.toString()
  ].includes(action.type), (state) => {
    state.caregiverBasicData.loading = false
  })

  builder.addMatcher((action) => [
    directConnectFromProfile.fulfilled.toString(),
    directConnectFromProfile.rejected.toString()
  ].includes(action.type), (state) => {
    state.directConnectFromProfileLoading = false
  })

  builder.addMatcher((action) => [
    connectCaregiverFromPost.fulfilled.toString(),
    connectCaregiverFromPost.rejected.toString()
  ].includes(action.type) ,(state) => {
    state.connectFromPostLoading = false
  })

  builder.addMatcher((action) => [
    connectCaregiverFromSearch.fulfilled.toString(),
    connectCaregiverFromSearch.rejected.toString()
  ].includes(action.type), (state) => {
    state.connectFromSearchLoading = false
  })

  builder.addMatcher((action) => [
    getCaregiverDetail.pending.toString(),
    getCaregivers.pending.toString(),
    getLastOffersResults.pending.toString(),
    getUnlockedCaregivers.pending.toString(),
    getCaregiversNearYou.pending.toString(),
    getConnectedCandidates.pending.toString(),
    getCandidatesFromSearch.pending.toString(),
    getCaregiversFromPost.pending.toString()
  ].includes(action.type), (state) => {
    state.loading = true
  })

  builder.addMatcher((action) => [
    getInterestedReasons.fulfilled.toString(),
    getInterestedReasons.rejected.toString(),

    connectInterestedCaregiver.fulfilled.toString(),
    connectInterestedCaregiver.rejected.toString()
  ].includes(action.type), (state) => {
    state.interestedReasons.loading = false
    state.interestedReasons.loadingReasons = false
  })

  builder.addMatcher((action) => [
    getCaregiverDetail.fulfilled.toString(),
    getCaregiverDetail.rejected.toString(),

    getCaregivers.fulfilled.toString(),
    getCaregivers.rejected.toString(),

    getLastOffersResults.fulfilled.toString(),
    getLastOffersResults.rejected.toString(),

    getUnlockedCaregivers.fulfilled.toString(),
    getUnlockedCaregivers.rejected.toString(),

    getCaregiversNearYou.fulfilled.toString(),
    getCaregiversNearYou.rejected.toString(),

    getConnectedCandidates.fulfilled.toString(),
    getConnectedCandidates.rejected.toString(),

    getCaregiversFromPost.fulfilled.toString(),
    getCaregiversFromPost.rejected.toString(),

    getCandidatesFromSearch.fulfilled.toString(),
    getCandidatesFromSearch.rejected.toString()
  ].includes(action.type), (state) => {
    state.loading = false
  })

  builder.addMatcher((action) => [
    chatWithCaregiver.fulfilled.toString(),
    chatWithCaregiver.rejected.toString()
  ].includes(action.type), (state) => {
    state.chat.loading = false
  })

  builder.addMatcher((action) => [
    connectAcceptedDirectOffer.fulfilled.toString(),
    connectAcceptedDirectOffer.rejected.toString()
  ].includes(action.type), (state) => {
    state.connectAcceptedDirectOfferLoading = false
  })

  builder.addMatcher((action) => [
    checkConnectionWithCaregiver.fulfilled.toString(),
    checkConnectionWithCaregiver.rejected.toString()
  ].includes(action.type), (state) => {
    state.chat.checkingConnection = false
  })

  builder.addMatcher((action) => [
    rateCaregiver.fulfilled.toString(),
    rateCaregiver.rejected.toString()
  ].includes(action.type), (state) => {
    state.caregiver_rating.loading = false
  })
})

export default reducer
