import React from 'react'
import CaregiverCard from 'components/organisms/CaregiverCard'


const SearchDesktopLayout = ({
  selectedCaregiver,
  caregiverStatus,
  caregivers,
  onViewProfile,
  onOpenVideoCVModal
}) => {

  return (
    <>
      <div className='columns is-marginless-l is-multiline has-margin-t-4'>
        {caregivers.items.map((caregiver, index) => {
          return (
            <div
              className='column is-one-third-desktop is-half-tablet is-one-quarter-fullhd is-paddingless-l'
              key={index}
            >
              <CaregiverCard
                caregiverStatus={caregiverStatus({ ...caregiver })}
                data={caregiver}
                onOpenVideoCVModal={onOpenVideoCVModal}
                onViewProfile={() => onViewProfile(caregiver)}
                selectedCaregiver={selectedCaregiver}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default SearchDesktopLayout
