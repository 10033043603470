import React from 'react'
import { SwiperSlide } from 'swiper/react'
import CaregiverCard from 'components/organisms/CaregiverCard'
import CustomSwiper from 'seniors-first-commons/components/atoms/Swiper'


const ViewCandidatesMobileLayout = ({
  candidates,
  selectedCaregiver,
  caregiverStatus,
  onViewProfile,
  onOpenVideoCVModal
}) => {
  return (
    <CustomSwiper
      autoplay={false}
      data={candidates.items}
      loop={false}
    >
      {candidates.items.map((candidate, index) => {
        return (
          <SwiperSlide key={index}>
            {() => {
              return (
                <div>
                  <CaregiverCard
                    caregiverStatus={caregiverStatus({ ...candidate })}
                    data={candidate}
                    onOpenVideoCVModal={onOpenVideoCVModal}
                    onViewProfile={() => onViewProfile({ ...candidate })}
                    selectedCaregiver={selectedCaregiver}
                  />
                </div>
              )
            }}
          </SwiperSlide>
        )
      })}
    </CustomSwiper>
  )
}

export default ViewCandidatesMobileLayout
