import React, { useState, useEffect } from 'react'
import {ArrowRightIcon, ArrowLeftIcon} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const Pagination = ({
  data,
  currentPage,
  itemsPerPage,
  onPageChange,
  additionalClasses=''
}) => {
  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  useEffect(() => {
    const minLimit = currentPage % pageNumberLimit ?
      Math.floor(currentPage / pageNumberLimit) * pageNumberLimit :
      currentPage - pageNumberLimit

    const maxLimit = Math.ceil(currentPage / pageNumberLimit) * pageNumberLimit

    setMaxPageNumberLimit(maxLimit)
    setMinPageNumberLimit(minLimit)

    // eslint-disable-next-line
  }, [currentPage])

  const handlePageNumberClick = (e) => {
    window.scrollTo(0, 0)
    onPageChange(Number(e.target.id))
  }


  const pages = []

  for(let i=1; i<=Math.ceil(data / itemsPerPage); i++){
    pages.push(i)
  }

  const RenderPageNumbers = pages.map(number => {
    if(number < maxPageNumberLimit + 1 && number > minPageNumberLimit){
      return (
        <li
          className={currentPage === number ? classes.activePageNumber : classes.pageNumber}
          id={number}
          key={number}
          onClick={handlePageNumberClick}
        >
          {number}
        </li>
      )
    }

    else{
      return null
    }
  })

  const handleNextClick = () => {
    window.scrollTo(0, 0)
    onPageChange(currentPage + 1)
  }

  const handlePrevClick = () => {
    window.scrollTo(0, 0)
    onPageChange(currentPage - 1)
  }

  let pageNumberIncrementBtn = null
  if(pages.length > maxPageNumberLimit){
    pageNumberIncrementBtn = <li>&hellip;</li>
  }

  const pageNumberDecrementBtn = null
  if(pages.length > maxPageNumberLimit){
    pageNumberIncrementBtn = <li className={classes.dots}>&hellip;</li>
  }


  return (
    <div className={`${classes.paginationContainer} ${additionalClasses}`}>
      <div className={classes.paginationlist}>
        <ul className='is-flex flex-wrap '>
          <li>
            <button
              className={`button ${classes.prevButton}`}
              disabled={currentPage === 1 || !data ? true : false}
              onClick={handlePrevClick}>
              <ArrowLeftIcon className='button-text-icon' height='24px' width='24px' />
            </button>
          </li>
          {pageNumberDecrementBtn}
          {RenderPageNumbers}
          {pageNumberIncrementBtn}
          <li>
            <button
              className={`button ${classes.nextButton}`}
              disabled={currentPage === pages.length || !data ? true : false}
              onClick={handleNextClick}>
              <ArrowRightIcon className='button-text-icon' height='24px' width='24px' />
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Pagination
