import authService from './auth-service'

let isRefreshing = false
let subscribers = []

const onRefreshed = ({ access_token }) => {
  subscribers.map(cb => cb(access_token))
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}
const getToken = () => `Bearer ${localStorage.getItem('refresh_token') || ''}`

const setupAxiosInterceptors = axios => {
  axios.interceptors.response.use(null, err => {
    const { config,  response: { status } = {} } = err
    const originalRequest = config

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        axios.post('/refresh', null, {
          headers: {
            Authorization: getToken(),
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            isRefreshing = false
            authService.setSession(response.data)
            onRefreshed(response.data)
            subscribers = []
          }).catch(() => {
          // Runtime never access this piece of code
            authService.logout()
            window.location.reload()
          })
      } else {
        // It is possible that the interceptor may intercept an error to it's own refresh request
        if (config.url.includes('/refresh')) {
          authService.logout()
          window.location.reload()
        }
      }
      return new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          resolve(axios(originalRequest))
        })
      })
    }


    return Promise.reject(err)
  })
  return axios
}

export default setupAxiosInterceptors
