import Talk from 'talkjs'
import { ROUTE } from 'root/constants'

export default class Chat {
  constructor(
    role,
    translations,
    chatHeaderRef,
    feedHeader,
    titleRef,
    backButtonRef,
    toggleShowModal,
    setUnreads
  ) {
    this.role = role
    this.translations = translations
    this.chatHeaderRef = chatHeaderRef
    this.feedHeaderRef = feedHeader
    this.titleRef = titleRef
    this.backButtonRef = backButtonRef
    this.toggleShowModal = toggleShowModal
    this.setUnreads = setUnreads
  }

  // start session
  startSession(userId) {
    if (!window.talkSession) {
      console.log('should create the session')
      const user = new Talk.User(userId)

      window.talkSession = new Talk.Session({
        appId: process.env.REACT_APP_TALK_ID,
        me: user
      })
    }
    this.watchUnreadMessages()
  }

  // create inbox
  createInbox() {
    if (!window.talkInbox) {
      console.log('should create the inbox')
      window.talkInbox = window.talkSession.createInbox({
        showChatHeader: false,
        showFeedHeader: false,
        showMobileBackButton: false
      })
    }
  }

  // mount (show) box
  mountInbox(ref) {
    console.log('should mount the inbox')
    window.talkInbox.mount(ref)
    this.onConversationSelected()
  }

  onConversationSelected() {
    console.log('should setup the converstaion click event')
    window.talkInbox.on('conversationSelected', ({ others }) => {

      if(others) {
        if (this.role === 'client') {
          const pathname = window.location.pathname
          const lang = pathname.split('/')[1]
          const domain = window.location.host
          const caregiver_id = others[0].custom.sf_user_id
          const href = `http://${domain}/${lang}${ROUTE.CAREGIVER_PROFILE}/${caregiver_id}?from=connected`

          this.titleRef.current.innerHTML = `
            <div style='max-width: 220px'>
              <p class='p1-bold ellipsis'>${others.map((o) => o.name).join(', ')}</p>
              <a style='display: inherit' class='p3-bold primary' href=${href}>
                ${this.translations && this.translations.viewProfile}
              </a>
            </div>
          `
        }
        else {
          this.titleRef.current.innerHTML = `
            <div style='max-width: 220px'>
              <p class='p1-bold ellipsis'>${others.map((o) => o.name).join(', ')}</p>
            </div>
          `
        }

        this.chatHeaderRef.current.firstChild.innerHTML =  '⬅︎'
        this.chatHeaderRef.current.classList.remove('hidden')
        this.feedHeaderRef.current.classList.add('hidden')
      }
      else {
        this.feedHeaderRef.current.firstChild.innerHTML = 'Inbox'
        this.chatHeaderRef.current.classList.add('hidden')
        this.feedHeaderRef.current.classList.remove('hidden')
      }

      this.backButtonRef.current.addEventListener('click', () => {
        window.talkInbox.select(null)
      })
    })
  }

  toggleNotifications () {
    console.log('should toggle the notification')
    window.talkSession.setDesktopNotificationEnabled(true, {
      alertOnFailure: true // only on development
    })
    this.onNotificationClick()
  }

  onNotificationClick () {
    console.log('should setup the notification click event')
    window.talkSession.on('desktopNotificationClicked', (event) => {
      const conversation = event.conversation.id
      window.talkInbox.select(conversation)
      this.toggleShowModal(true)
    })
  }

  watchUnreadMessages () {
    console.log('watching unread messages')
    window.talkSession.unreads.on('change', unreadConversations => {
      const amountOfUnreads = unreadConversations.length
      this.setUnreads(amountOfUnreads)

      // update browser tab title [document.title]
      const originalTitle = document.title.slice(document.title.search('S'))
      if(amountOfUnreads) document.title = `(${amountOfUnreads}) ${originalTitle}`
      else document.title = originalTitle
    })
  }
}
