import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const NavbarCircleIcon = ({
  badge,
  tooltip='',
  isActive,
  Icon,
  filled=false,
  onClick
}) => {
  return (
    <div
      className={`${classes.wrapper} ${isActive === undefined ? 'cursor-disabled' : 'custom-tooltip pointer'}`}
      onClick={isActive === undefined ? () => {} : onClick}
    >
      {badge
        ? <span className={`p3-bold white ${classes.badge}`}>{badge}</span>
        : null
      }
      <figure className='image is-24x24'>
        <Icon
          fill={`${filled ?
            isActive !== undefined
              ? isActive ? '#0040AA' : '#0080F5'
              : '#A6A6A6'
            : 'none'}`}
          height='24'
          stroke={`${isActive !== undefined ?
            isActive ? '#0040AA' : '#0080F5'
            : '#A6A6A6'}
          `}
          width='24'
        />
      </figure>

      {!!tooltip && isActive !== undefined &&
        <FormattedMessageWithClass
          className='tooltip-content p3-bold white'
          id={tooltip}
          placement='bottom'
        />
      }
    </div>
  )
}


export default NavbarCircleIcon
