import { createReducer } from '@reduxjs/toolkit'
import {
  getProfile,
  updateProfile,
  uploadProfilePhoto,
  clear,
  toggleSuccessStatus,
  askForSpecialService,
  getStatistics,
  sendAnayetRequest
} from './actions'
import {
  AWS_IMAGE_STORAGE,
  LANG_LOCALSTORAGE_KEY
} from 'seniors-first-commons/utils/constants'


const initValues = {
  anayetRequest: {
    loading: false
  },
  profile: {
    finishedWizard: true,
    index: null,
    loading: false,
    loadingPhotoUpdate: false,
    loadingProfileUpdate: false,
    submittedBasicInfo: false
  },
  specialService: {
    findSend: null,
    home: null,
    loading: false
  },
  statistics: {
    data: null,
    loading: false
  },
  success: ''
}

const profileApiToData = (payload) => {
  // If the country belongs the European Union they will have the Euro '€' as currency
  // If the country is UK it will have the Pound as currency
  // If none of above it will have the dollar as currency
  const europeanCountryIds = [3, 15, 17, 32, 42, 52, 55, 62, 63, 64, 66, 69, 71, 78, 92, 97, 122, 135, 139, 140, 149, 172, 181, 182, 187, 192, 217]
  // The country ID 185 belongs to UK
  if (payload.country_id === 185) payload.currency = '£'
  else if (europeanCountryIds.includes(payload.country_id)) payload.currency = '€'
  else payload.currency = '$'

  if(payload.photo) {
    payload.photo = AWS_IMAGE_STORAGE + payload.photo
  }

  return payload
}

const checkWizardCompletion = (payload) => {
  return !!payload.address && !!payload.address_lat && !!payload.address_lng && !!payload.country_id
}

const reducer = createReducer(initValues, (builder) => {
  builder.addCase(getStatistics.pending, (state) => {
    state.statistics.loading = true
  })

  builder.addCase(getStatistics.fulfilled, (state, action) => {
    state.statistics.data = action.payload
  })

  builder.addCase(uploadProfilePhoto.pending, (state) => {
    state.profile.loadingPhotoUpdate = true
  })

  builder.addCase(clear, () => {
    const lang = localStorage.getItem(LANG_LOCALSTORAGE_KEY)
    localStorage.clear()
    localStorage.setItem(LANG_LOCALSTORAGE_KEY, lang)
    clearTimeout(window.notificationsTimeout)
    if(window.talkSession) {
      // destory the chat session
      console.log('shoud destory all the sessions and UIs')
      window.talkSession.destroy()
      window.talkSession = null
      window.talkInbox = null
    }
  })

  builder.addCase(getProfile.fulfilled, (state, action) => {
    const hasCompletedWizard = checkWizardCompletion(action.payload)

    state.profile.index = profileApiToData(action.payload)
    state.profile.finishedWizard = hasCompletedWizard
    state.profile.submittedBasicInfo = hasCompletedWizard
  })

  builder.addCase(uploadProfilePhoto.fulfilled, (state, action) => {
    state.profile.index = profileApiToData(action.payload)
    state.success = 'success.uploadPhoto'
  })

  builder.addCase(updateProfile.fulfilled, (state, action) => {
    const hasCompletedWizard = checkWizardCompletion(action.payload)

    state.profile.index = profileApiToData(action.payload)
    state.profile.finishedWizard = hasCompletedWizard
    state.profile.submittedBasicInfo = hasCompletedWizard
    state.success = 'success.successfullChange'
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addCase(askForSpecialService.fulfilled, (state, action) => {
    if (action.payload.from === 'home') {
      state.specialService = {
        ...state.specialService,
        home: action.payload.response
      }
    }
    else {
      state.specialService = {
        ...state.specialService,
        findSend: action.payload.response
      }
    }
  })

  builder.addCase(askForSpecialService.pending, (state) => {
    state.specialService.loading = true
  })

  builder.addCase(sendAnayetRequest.pending, (state) => {
    state.anayetRequest.loading = true
  })

  builder.addMatcher((action) => [
    updateProfile.pending.toString()
  ].includes(action.type), (state) => {
    state.profile.loadingProfileUpdate = true
  })

  builder.addMatcher((action) => [
    updateProfile.fulfilled.toString(),
    updateProfile.rejected.toString()
  ].includes(action.type), (state) => {
    state.profile.loadingProfileUpdate = false
  })

  builder.addMatcher((action) => [
    getProfile.pending.toString()
  ].includes(action.type), (state) => {
    state.profile.loading = true
  })

  builder.addMatcher(action => [
    getProfile.fulfilled.toString(),
    getProfile.rejected.toString()
  ].includes(action.type), (state) => {
    state.profile.loading = false
  })

  builder.addMatcher(action => [
    uploadProfilePhoto.fulfilled.toString(),
    uploadProfilePhoto.rejected.toString()
  ].includes(action.type), (state) => {
    state.profile.loadingPhotoUpdate = false
  })

  builder.addMatcher((action) => [
    askForSpecialService.fulfilled.toString(),
    askForSpecialService.rejected.toString()
  ].includes(action.type), (state) => {
    state.specialService.loading = false
  })

  builder.addMatcher((action) => [
    getStatistics.fulfilled.toString(),
    getStatistics.rejected.toString()
  ].includes(action.type), (state) => {
    state.statistics.loading = false
  })

  builder.addMatcher((action) => [
    sendAnayetRequest.fulfilled.toString(),
    sendAnayetRequest.rejected.toString()
  ].includes(action.type), (state) => {
    state.anayetRequest.loading = false
  })
})

export default reducer
