import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { Formik, Form, Field } from 'formik'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import Select from 'seniors-first-commons/components/molecules/Select'
import classes from './index.module.css'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'


const PickJobOfferModal = ({
  formRef,
  open=false,
  availableOffers,
  loading=false,
  onConfirm,
  onClose
}) => {
  return (
    <CustomModal
      onClose={onClose}
      open={open}
      styles={{ maxWidth: '450px', width: '95%' }}
    >
      <div className={classes.modalWrapper}>
        <p className='t3-bold grey primary'>
          <FormattedMessageWithClass id='client.general.pickOfferModal.title' />
        </p>

        <p className='p1-regular grey has-margin-b-7'>
          <FormattedMessageWithClass id='client.general.pickOfferModal.text' />
        </p>

        <Formik
          enableReinitialize
          initialValues={{ offer: null }}
          innerRef={ref => formRef.current = ref}
          onSubmit={onConfirm}
        >
          {({ dirty, handleSubmit }) => {
            return (
              <Form>
                <FieldLabel
                  bottomSpace={0}
                  centered={false}
                  labelName='client.general.pickOfferModal.label'
                  labelType='p1-bold'
                  topSpace={3}
                />
                <Field
                  component={Select}
                  hasoptionicon={false}
                  id='offer'
                  name='offer'
                  options={availableOffers && availableOffers.index ? availableOffers.index : []}
                  placeholder=''
                  shouldTranslate={false}
                />
                <div className='full-width has-text-right'>
                  <button
                    className={`
                      button filled has-margin-t-5 
                      ${loading ? 'is-loading' : ''} 
                      ${classes.confirmButton}
                    `}
                    disabled={!dirty || loading}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    <FormattedMessageWithClass id='client.general.pickOfferModal.button' />
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}

export default PickJobOfferModal
