import React, { Fragment, useState } from 'react'
import moment from 'moment'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import ShiftsModal from 'seniors-first-commons/components/organisms/ShiftsModal'
import {
  ArrowRightIcon,
  ClockIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import { WEEKS_TO_EXPIRE_POST } from 'seniors-first-commons/utils/constants'
import classes from './index.module.css'


const DataLevel = ({ label, value='' }) => {
  return (
    <p className='primary' style={{ flexWrap: 'wrap', wordBreak: 'break-all' }}>
      <FormattedMessageWithClass className='p2-bold' id={label} />:
      <span className='p2-regular grey' style={{ marginLeft: '5px' }}>
        {value}
      </span>
    </p>
  )
}

const JobOfferSummary = ({
  matchesMobile,
  currency,
  offerValues,
  withName='true'
}) => {
  const [showShiftsModal, setShowShiftsModal] = useState(false)
  const postExpirationDate = moment().add(WEEKS_TO_EXPIRE_POST, 'weeks').format('L')
  const translations = {
    hour: useFormatMessage('apps.general.jobOffer.salary.hourlyRate'),
    min: useFormatMessage('apps.general.jobOffer.salary.min'),
    month: useFormatMessage('apps.general.jobOffer.salary.monthlyRate'),
    'undefined': useFormatMessage('apps.general.jobOffer.duration.undefined')
  }

  const formatTime = (time) => {
    return time.format('L')
  }

  return (
    <>
      <div className={`card ${matchesMobile ? 'bordered-card' : ''}`}>
        <div className='card-content'>
          <p className='t2-bold black has-text-centered has-margin-b-7'>
            <FormattedMessageWithClass id='client.createOffer.summary.title' />
          </p>

          <DataLevel
            label='client.createOffer.steps.address'
            value={offerValues.address.formatted_address}
          />

          <div
            className={`grey ${classes['flex-center-align']}`}
            style={{ flexWrap: 'wrap' }}
          >
            <DataLevel label='client.createOffer.steps.serviceType' />
            {
              offerValues.service_categories.map((service, index) => (
                <Fragment key={index}>
                  <FormattedMessageWithClass
                    className='p2-regular grey'
                    id={`apps.general.jobOffer.services.${service}`}
                  />
                  {offerValues.service_categories[offerValues.service_categories.length-1] !== service ? ', ' : ''}
                </Fragment>
              ))
            }

            <button
              className={`button-text ${classes['flex-center-align']}`}
              onClick={() => setShowShiftsModal(!showShiftsModal)}
              type='button'
            >
              {!matchesMobile
                ? <>
                  <FormattedMessageWithClass
                    className='p2-bold'
                    id='apps.general.jobOffer.modality.daysAndHours'
                  />
                  <ArrowRightIcon
                    className='button-text-icon'
                    height='18'
                    width='18'
                  />
                </>
                : <ClockIcon
                  fill='#0040AA'
                  height='16'
                  width='18'
                />
              }
            </button>
          </div>

          <div className={classes['flex-center-align']}>
            <DataLevel label='client.createOffer.steps.modality' />

            <FormattedMessageWithClass
              className='p2-regular grey'
              id={offerValues?.hiring_modality === 'live_in_care'
                ? 'apps.general.jobOffer.modality.live-in-care'
                : offerValues?.hiring_modality === 'visiting_care'
                  ? 'apps.general.jobOffer.modality.visitingCare'
                  : ''
              }
            />
          </div>

          <div
            className={`p2-regular ${classes['flex-center-align']}`}
            style={{ flexWrap: 'wrap' }}
          >
            <DataLevel label='client.createOffer.steps.duration' />

            <FormattedMessageWithClass
              className='p2-bold black'
              id='apps.general.jobOffer.duration.fromTo'
              values={{ end: <span className='p2-regular grey'>{offerValues.duration === 'temporal'
                ? formatTime(offerValues.temporal_duration_to)
                : translations.undefined}</span>,
              start: <span className='p2-regular grey'>{offerValues.duration === 'temporal'
                ? formatTime(offerValues.temporal_duration_from)
                : formatTime(offerValues.undefined_duration_from)}</span>
              }}
            />
          </div>

          <div className={classes['flex-center-align']}>
            <DataLevel label='client.createOffer.steps.contract' />
            <FormattedMessageWithClass
              className='p2-regular grey is-capitalized'
              id={
                  offerValues?.contract_type === 'autonomous'
                    ? 'apps.general.jobOffer.contract.autonomus'
                    : offerValues?.contract_type === 'company'
                      ? 'apps.general.jobOffer.contract.company' : ''
              }
            />
          </div>

          <div
            className={`p2-regular ${classes['flex-center-align']}`}
            style={{ flexWrap: 'wrap' }}
          >
            <DataLevel label='client.createOffer.steps.retribution' />

            <FormattedMessageWithClass
              className='p2-bold black'
              id='apps.general.jobOffer.salary.wrapper'
              values={{
                currency: <span className='p2-regular grey'>{currency}</span>,
                paytime: translations[offerValues?.salary_type],
                quantity: <span className='p2-regular grey'>{offerValues?.retribution_amount}</span>
              }}
            />
          </div>

          { withName &&
            <DataLevel
              label='client.createOffer.steps.offerName'
              value={offerValues?.name}
            />
          }

          <div className='has-text-centered'>
            <p className='t4-bold primary has-margin-t-5'>
              <FormattedMessageWithClass
                id='client.createOffer.summary.text1'
              />
            </p>
            <p className='p2-regular grey'>
              <FormattedMessageWithClass
                id='client.createOffer.summary.text2'
                values={{
                  expirationDate: <span className='black'>{postExpirationDate}</span>,
                  weeks: <span className='black'>{WEEKS_TO_EXPIRE_POST}</span>
                }}
              />
            </p>
          </div>
        </div>
      </div>

      <ShiftsModal
        active={showShiftsModal}
        offer={offerValues}
        onClose={setShowShiftsModal}
      />
    </>
  )
}

export default JobOfferSummary
