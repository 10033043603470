import React, { Component, Fragment } from 'react'
import ToogleSwitch from '../../../ToogleSwitch/ToogleSwitch'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

class Tracking extends Component {
  render() {
    const title = this.props.intl.formatMessage({
      id: 'app.privacy.options.performance_cookies'
    })
    const text = this.renderText()

    return (
      <Fragment>
        <div className='level has-margin-b-7'>
          <div className='level-left'>
            <div className='level-item'>
              <h2 className='is-size-5 has-text-weight-semibold has-text-black'>{title}</h2>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <ToogleSwitch
                toggle={this.props.toggle}
                value={this.props.value}
              />
            </div>
          </div>
        </div>
        {text}
      </Fragment>
    )
  }

  renderText() {
    let output = ''
    switch (this.props.locale) {
    // Spanish
    case 'es-es':
    case 'es-us':
      output = (<p className='has-text-justified'>Estas cookies nos permiten contar las visitas y fuentes de tráfico para poder evaluar el rendimiento de nuestro sitio y mejorarlo. Nos ayudan a saber qué páginas son las más o las menos visitadas, y cómo los visitantes navegan por el sitio. Toda la información que recogen estas cookies es agregada y, por lo tanto, es anónima. Si no permite utilizar estas cookies, no sabremos cuándo visitó nuestro sitio y no podremos evaluar si funcionó correctamente.</p>)
      break

      // All English
    default:
      output = (<p className='has-text-justified'>These cookies help us count the visits and sources of traffic to be able to evaluate our site’s performance and help improve it. They help us find out which pages are the most or least visited and how visitors find out site. All the information collected by these cookies is aggregated and therefore, is anonymous. If you do not let us use these cookies, we will not know when you visit our site, and we will not be able to evaluate if it is operating correctly.</p>)
      break
    }

    return output
  }
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale.toLowerCase()
})

export default injectIntl(connect(mapStateToProps)(Tracking))
