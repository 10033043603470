import React from 'react'
import classes from './index.module.css'


const IconTextActionLayout = ({
  matchesMobile=false,
  icon,
  text,
  action
}) => {
  return (
    <div className={`${!matchesMobile ? 'has-padding-lr-5' : ''} full-width ${classes.fullContainer}`}>
      <div className='columns is-marginless is is-vcentered full-width'>
        <div className='column is-half has-text-centered'>
          {icon}
        </div>
        <div className='column is-half has-text-centered'>
          {text}
          {action}
        </div>
      </div>
    </div>
  )
}

export default IconTextActionLayout
