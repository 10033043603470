import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Container from 'components/organisms/AppContainer'
import BillingForm from 'components/organisms/BillingForm'
import classes from './index.module.css'


const BillingLayout = ({
  logicPropsAndMethods: {
    helpers: { countries },
    billing,
    fromUK,
    loading,
    onFormSubmit,
    onChangeCountry
  }
}) => {
  return (
    <Container additionalClass='part' name='Billing'>
      <div className={`${classes.formContainer}`}>
        <h1 className='t1-bold primary has-padding-b-5'>
          <FormattedMessageWithClass id='client.billing.title' />
        </h1>
        <BillingForm
          billing={billing}
          countries={countries}
          fromUK={fromUK}
          isSubmitting={loading}
          onChangeCountry={onChangeCountry}
          onSubmit={onFormSubmit}
        />
      </div>
    </Container>
  )
}

export default BillingLayout
