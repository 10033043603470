import { createReducer } from '@reduxjs/toolkit'
import {
  getInterestedReasons,
  getCountries,
  getLanguages,
  getIllnesses,
  getServices
} from './actions'
import rawCountries from 'seniors-first-commons/utils/rawCountries'


const initState = {
  countryCode: '',
  helpers: {
    countries: null,
    illnesses: null,
    languages: null,
    loading: false,
    loadingServices: false,
    services: null
  },
  interestedReasons: {
    caregiver_id: null,
    items: [],
    loadingReasons: false
  }
}

const reducer = createReducer(initState, (builder) => {
  builder.addCase(getInterestedReasons.fulfilled, (state, action) => {
    const reasons = action.payload.interested_reasons.map(({ reason }) => reason)
    state.interestedReasons.caregiver_id = action.payload.caregiver_id
    state.interestedReasons.items = reasons
  })

  builder.addCase(getInterestedReasons.pending, (state) => {
    state.interestedReasons.loadingReasons = true
  })

  builder.addCase(getCountries.fulfilled, (state, action) => {
    const onlyRawCountries = action.payload.filter(item => rawCountries.map(element => element[2].toLowerCase())
      .includes(item.code.toLowerCase()))
    state.helpers.countries = onlyRawCountries.map(({name, id, ...rest}) =>
      ({label: name, value: id, ...rest}))
  })

  builder.addCase(getIllnesses.fulfilled, (state, action) => {
    state.helpers.illnesses = action.payload.map(({name, id, ...rest}) =>
      ({label: name, value: id, ...rest}))
  })

  builder.addCase(getLanguages.fulfilled, (state, action) => {
    state.helpers.languages = action.payload.map(({name, id, ...rest}) =>
      ({label: name, value: id, ...rest}))
  })

  builder.addCase(getServices.fulfilled, (state, action) => {
    const formatted_services = {
      1: [],
      2: [],
      3: [],
      4: []
    }

    action.payload.map(({ service_category_id, name, id }) => {
      formatted_services[service_category_id].push({ id, service_category_id, value: name  })
      return { id, name, service_category_id }
    })

    state.helpers.services = formatted_services
  })

  builder.addCase(getServices.pending, (state) => {
    state.helpers.loadingServices = true
  })

  builder.addMatcher((action) => [
    getInterestedReasons.fulfilled.toString(),
    getInterestedReasons.rejected.toString()
  ].includes(action.type), (state) => {
    state.interestedReasons.loadingReasons = false
  })

  builder.addMatcher((action) => [
    getCountries.pending.toString(),
    getIllnesses.pending.toString(),
    getLanguages.pending.toString()
  ].includes(action.type), (state) => {
    state.helpers.loading = true
  })

  builder.addMatcher((action) => [
    getCountries.fulfilled.toString(),
    getCountries.rejected.toString(),
    getIllnesses.fulfilled.toString(),
    getIllnesses.rejected.toString(),
    getLanguages.fulfilled.toString(),
    getLanguages.rejected.toString()
  ].includes(action.type), (state) => {
    state.helpers.loading = false
  })

  builder.addMatcher((action) => [
    getServices.fulfilled.toString(),
    getServices.rejected.toString()
  ].includes(action.type), (state) => {
    state.helpers.loadingServices = false
  })
})

export default reducer
