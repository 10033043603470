
import React from 'react'
import { ErrorMessage } from 'formik'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Input from 'seniors-first-commons/components/atoms/Input'
import { PencilEdit } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


export const Email = ({ ...props }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id={props.field.name}
        labelName='app.password.passwordresetform.email'
        labelType='p2-bold'
        topSpace={2}
      />
      <Input
        className={`input ${classes.input}`}
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}
