import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import routingService from 'seniors-first-commons/services/routingService'
import JobOfferCard from '../JobOfferCard'
import ActionalbleEmptyResponse from 'components/atoms/ActionalbleEmptyResopnse'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const ViewAllButton = ({ onViewAll }) => {
  return (
    <button
      className='button-text is-pulled-right'
      onClick={onViewAll}
    >
      <FormattedMessageWithClass
        className='p1-bold'
        id='client.home.lastOffers.button'
      />
    </button>
  )
}


const LastJobOffers = ({
  matchesMobile,
  jobOffers,
  profile,
  ...props
}) => {
  const history = useHistory()
  const translations = {
    noOffersButton: useFormatMessage('client.home.noOffers.button'),
    noOffersText: useFormatMessage('client.home.noOffers.text')
  }

  const onViewAll = () => routingService.push(history, ROUTE.MY_JOB_OFFERS)

  return (
    <>
      <div className='level has-margin-t-1 is-vcentered'>
        <div className='level-left'>
          <h3 className={`t2-bold primary is-marginless ${classes.title}`}>
            <FormattedMessageWithClass id={!!jobOffers && !!jobOffers.length ? 'client.home.lastOffers.title' : 'client.home.lastOffers.noOffersTitle'} />
          </h3>
        </div>

        {!matchesMobile && jobOffers && !!jobOffers.length &&
          <div className='level-right'>
            <ViewAllButton onViewAll={onViewAll} />
          </div>
        }
      </div>

      {jobOffers && !!jobOffers.length ?
        jobOffers.map((item, index) => {
          return (
            <JobOfferCard
              archived={0}
              item={item}
              key={index}
              matchesMobile={matchesMobile}
              perPage={3}
              profile={profile}
              {...props}
            />
          )
        })
        :
        <ActionalbleEmptyResponse
          buttonText={translations.noOffersButton}
          matchesMobile={matchesMobile}
          message={translations.noOffersText}
          onClickAction={() => routingService.push(history, ROUTE.CREATE_JOB_OFFER)}
        />
      }

      {matchesMobile && jobOffers && !!jobOffers.length && (
        <div className='has-margin-t-7' style={{ height: '20px', margin: 'auto', width: 'fit-content' }}>
          <ViewAllButton onViewAll={onViewAll} />
        </div>
      )}
    </>
  )
}

export default LastJobOffers
