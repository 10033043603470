import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

export default connect(state => {
  const { intl } = state
  return { ...intl }
})(IntlProvider)


// https://formatjs.io/docs/getting-started/installation/
