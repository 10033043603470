import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import OneNotification from 'seniors-first-commons/components/molecules/OneNotification'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'
import GoBackOrHome from 'seniors-first-commons/components/atoms/GoBackOrHome'
import LogoSF from 'seniors-first-commons/components/atoms/images/SF.png'
import UserDummyImage from 'seniors-first-commons/components/atoms/images/user.png'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'
import classes from './index.module.css'


const NotificationsLayout = ({
  notifications,
  matchesMobile,
  role = 'carer',
  handleChangeReadStatus,
  handleChangeArchivedStatus,
  getNotifications
}) => {
  return (
    <div className='part has-padding-b-1'>
      {!matchesMobile ?
        <GoBackOrHome
          className='has-padding-b-4 is-paddingless-l'
        />
        : null
      }
      {
        notifications.total ?
          <InfiniteScroll
            dataLength={notifications.items.length}
            hasMore={notifications.pages > notifications.page}
            loader={
              <div className={classes.loaderContainer}>
                <p className='p1-bold has-margin-t-5 white'>Loading more...</p>
              </div>
            }
            next={() => getNotifications(notifications.page + 1, ITEMS_PER_PAGE)}
            style={{ overflow: 'visible' }}
          >
            <div className={`card ${classes.cardContent} ${matchesMobile ? 'is-radiusless' : 'bordered-card'}`}>
              <div className='card-content is-paddingless'>
                <div className='media'>
                  <div className='media-content has-padding-b-3'>
                    {!matchesMobile ?
                      <p className='t2-bold primary has-padding-lr-1 has-padding-t-2 has-padding-b-5'>
                        <FormattedMessageWithClass
                          id='apps.notifications.title'
                        />
                      </p>
                      : null
                    }
                    {notifications.items
                      .map((item, index) => {
                        return (
                          <OneNotification
                            date={item.created_at}
                            details={item.feed_body}
                            image={
                              item.user_photo ? item.user_photo
                                : !item.from_user_id ? LogoSF
                                  : UserDummyImage
                            }
                            key={index}
                            matchesMobile={matchesMobile}
                            notification_id={item.id}
                            onChangeArchivedStatus={handleChangeArchivedStatus}
                            onChangeReadStatus={handleChangeReadStatus}
                            read={!item.unread}
                          />
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </InfiniteScroll>
          : (
            <EmptyBox stroke={role === 'client' ? '#525860' : '#fff'} />
          )
      }
    </div>
  )
}

export default NotificationsLayout
