import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { MailIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Email = ({ data, caregiverStatus }) => {
  const translations = {
    lockedInfo: useFormatMessage('client.general.caregiverCard.blockedInformation'),
    lockedInfoTooltip: useFormatMessage('client.general.caregiverCard.blockedInformation.tooltip')
  }


  return (
    <div
      className='has-margin-b-7 is-flex'
      style={{ alignItems: 'center' }}
    >
      <MailIcon
        fill='none'
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='16'
      />

      {data.email ?
        <a
          className='has-margin-l-7 primary-light p2-bold ellipsis'
          href={`mailto: ${data.email}`}
          style={{ maxWidth: '85%' }}
        >
          {data.email}
        </a>
        :
        <div className='custom-tooltip'>
          <span className={`has-margin-l-7 p2-bold ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey-lighter' }`}
          >
            {translations.lockedInfo}
          </span>

          {caregiverStatus.text !== 'Rejected' &&
            <span className='tooltip-content p3-bold white' placement='top'>
              {translations.lockedInfoTooltip}
            </span>
          }
        </div>
      }
    </div>
  )
}

export default Email
