import React from 'react'
import moment from 'moment'
import { ErrorMessage, Field } from 'formik'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import DatePicker from 'seniors-first-commons/components/molecules/DatePicker'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'


const TemporalDuration = ({ values }) => {
  return (
    <>
      <div className='columns is-mobile has-margin-t-1'>
        <div className='column'>
          <FieldLabel
            labelName='client.createOffer.steps.duration.startDate'
            labelType='l-bold'
          />
          <Field
            component={DatePicker}
            id='temporal_duration_from'
            isOutsideRange={(day) => day.isSameOrBefore(moment())}
            name='temporal_duration_from'
            openDirection='up'
          />

          <ErrorMessage
            component={HandleError}
            name='temporal_duration_from'
          />
        </div>

        <div className='column'>
          <FieldLabel
            labelName='client.createOffer.steps.duration.endDate'
            labelType='l-bold'
          />
          <Field
            component={DatePicker}
            id='temporal_duration_to'
            isOutsideRange={(day) => day.isSameOrBefore(values.temporal_duration_from)}
            name='temporal_duration_to'
            openDirection='up'
          />

          <ErrorMessage
            component={HandleError}
            name='temporal_duration_to'
          />
        </div>
      </div>
    </>
  )
}

export default TemporalDuration
