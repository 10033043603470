import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { LoginIcon, ArrowRightIcon } from 'seniors-first-commons/components/atoms/images/icons'


const OfferModality = ({ data, onOpenDaysAndShiftsModal }) => {
  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <LoginIcon
        fill='none'
        height='16'
        stroke='#022C72'
        width='18'
      />
      <FormattedMessageWithClass
        className='has-margin-l-7 p2-regular grey ellipsis'
        id={data?.hiring_modality === 'live_in_care'
          ? 'apps.general.jobOffer.modality.live-in-care'
          : data?.hiring_modality === 'visiting_care'
            ? 'apps.general.jobOffer.modality.visitingCare'
            : ''
        }
      />
      <button
        className='button-text is-flex'
        onClick={() => onOpenDaysAndShiftsModal(data)}
        style={{ alignItems: 'center' }}
      >
        <>
          <FormattedMessageWithClass
            className='p2-bold ellipsis'
            id='apps.general.jobOffer.modality.daysAndHours'
          />
          <ArrowRightIcon
            className='button-text-icon'
            height='20px'
            width='20px'
          />
        </>
      </button>
    </div>
  )
}

export default OfferModality
