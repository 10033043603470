import React from 'react'
import { SwiperSlide } from 'swiper/react'
import CaregiverCard from 'components/organisms/CaregiverCard'
import CustomSwiper from 'seniors-first-commons/components/atoms/Swiper'


const MobileLayout = ({
  caregivers,
  selectedCaregiver,
  caregiverStatus,
  onViewProfile,
  onOpenVideoCVModal
}) => {
  return (
    <>
      <CustomSwiper
        autoplay={false}
        data={caregivers.items}
        loop={false}
      >
        {caregivers.total ? caregivers.items.map((caregiver, index) => {
          return (
            <SwiperSlide key={index}>
              {() => {
                return (
                  <>
                    <CaregiverCard
                      caregiverStatus={caregiverStatus({ ...caregiver })}
                      data={caregiver}
                      onOpenVideoCVModal={onOpenVideoCVModal}
                      onViewProfile={() => onViewProfile({ ...caregiver })}
                      selectedCaregiver={selectedCaregiver}
                    />
                  </>
                )
              }}
            </SwiperSlide>
          )
        }) : null}
      </CustomSwiper>
    </>
  )
}

export default MobileLayout
