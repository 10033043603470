import React from 'react'
import withJobOffer from 'hoc/withJobOffer'
import AppContainer from 'components/organisms/AppContainer'
import MyJobOffersDesktopLayout from './DesktopLayout'
import MyJobOffersMobileLayout from './MobileLayout'
import MyJobOffersHeader from 'components/molecules/MyJobOffersHeader'
import Pagination from 'seniors-first-commons/components/molecules/Pagination'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const MyJobOffersLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    ...rest
  },
  actions
}) => {
  return (
    <AppContainer
      additionalClass={matchesMobile ? 'is-paddingless-lr is-marginless-lr' : 'has-padding-lr-7'}
      name='My job offers'
    >
      <div className='part has-padding-b-1'>
        <MyJobOffersHeader
          matchesMobile={matchesMobile}
          onChangeOrder={rest.onChangeOrder}
          onToggleArchivedStatus={rest.onToggleArchivedStatus}
          selectedSort={rest.selectedSort}
          showArchived={rest.showArchived}
        />

        {matchesMobile
          ? (
            <MyJobOffersMobileLayout
              {...rest}
              {...actions}
            />
          )
          : (
            <MyJobOffersDesktopLayout
              {...rest}
              {...actions}
            />
          )
        }
      </div>


      <Pagination
        currentPage={rest.jobOffers.currentPage}
        data={rest.jobOffers.total}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={rest.handlePageChange}
      />
    </AppContainer>
  )
}

export default withJobOffer(MyJobOffersLayout)
