import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  getNotifications,
  changeNotificationReadStatus,
  deleteNotification
} from 'seniors-first-commons/shared/store/notifications/actions'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import AppContainer from '../../components/organisms/AppContainer'
import NotificationsLayout from 'seniors-first-commons/components/templates/NotificationsLayout'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const Notifications = ({
  notifications,
  getNotifications,
  changeNotificationReadStatus,
  deleteNotification
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const fetchNotifications = useCallback((page, per_page) => {
    getNotifications({ page, per_page })
  }, [getNotifications])

  useEffect(() => {
    fetchNotifications(1, ITEMS_PER_PAGE)
  }, [fetchNotifications])

  const handleChangeReadStatus = (id) => {
    changeNotificationReadStatus({ notificacion_id: id })
  }

  const handleChangeArchivedStatus = (id, read) => {
    deleteNotification({ notificacion_id: id, read })
  }

  return (
    <div className={`${!matchesMobile ? 'full-content' : ''}`}>
      <AppContainer
        additionalClass={`${matchesMobile ? 'is-paddingless is-marginless' : ''}`}
        name='Notifications'
        shouldCallNotifications={false}
      >
        <NotificationsLayout
          getNotifications={fetchNotifications}
          handleChangeArchivedStatus={handleChangeArchivedStatus}
          handleChangeReadStatus={handleChangeReadStatus}
          matchesMobile={matchesMobile}
          notifications={notifications}
          role='client'
        />
      </AppContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.notifications.loading,
    notifications: state.notifications.data
  }
}

const mapDispatchToProps = {
  changeNotificationReadStatus,
  deleteNotification,
  getNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
