import React from 'react'
import { Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'

const Radios = ({
  content,
  input
}) => {
  return (
    <div className='columns is-multiline full-width'>
      {content.map(({ Icon, ...item }, i)=> {
        return (
          <div className='column' key={i}>
            <label
              className={`${classes.radio} ${input === item['value'] ? classes.active : ''}`}
              htmlFor={`${item.name}_${item.value}`}
            >
              <div className={classes.valueWrapper}>
                {Icon &&
                  <Icon
                    fill='#1DDDFA'
                    height='16'
                    stroke='none'
                    strokeWidth='5'
                    width='16'
                  />
                }
                <FormattedMessageWithClass
                  className='p2-bold has-text-centered'
                  id={item.text}
                  values={item?.textValues}
                />
              </div>

              <label className='radio'>
                <Field
                  id={`${item.name}_${item.value}`}
                  name={item.name}
                  type='radio'
                  value={item.value || ''}
                />
              </label>
            </label>
            {item.extra ? item.extra : null}
          </div>
        )
      })}
    </div>
  )
}

export default Radios
