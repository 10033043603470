import * as Yup from 'yup'

//location validation schema
export const FirstStepSchema = Yup.object({
  address: Yup.object().shape({
    formatted_address: Yup.string().required('validations.required').test('testing address exists', 'validations.clients.createOffer.address', (value) => {
      let error = true
      if (value && (value.match(/,/g) || []).length < 2) {
        error = false
      }
      return error
    })
  })
})

// services validation schema
export const SecondStepSchema = Yup.object({
  service_categories: Yup.array().min(1, 'validations.selectoneservice')
    .required('Required')
})

// modality validation schema
export const ThirdStepSchema = Yup.object({
  hiring_modality: Yup.string().required('validations.required'),

  // living_care schema
  live_in_care: Yup.object().when('hiring_modality', {
    is: 'live_in_care',
    then: Yup.object().shape({
      day_of_week_from: Yup.object().shape({
        label: Yup.string().required('validations.required')
      }),
      day_of_week_to: Yup.object().shape({
        label: Yup.string().required('validations.required')
      }),
      hour_from: Yup.object().shape({
        label: Yup.string().required('validations.required')
      }),
      hour_to: Yup.object().shape({
        label: Yup.string().required('validations.required')
      })
    }).test(
      'testing hour to value',
      'validations.client.createOffer.departureHour',
      (value) => {
        let error = true
        if(
          value.day_of_week_from.value === value.day_of_week_to.value &&
          value.hour_from.value <= value.hour_to.value
        ) {
          error = false
        }

        return error
      })
  }),

  // visiting care
  visiting_care_day_and_hours: Yup.array().when('hiring_modality', {
    is: 'visiting_care',
    then: Yup.array()
      .of(
        Yup.object().shape({
          day_of_week: Yup.object().shape({
            label: Yup.string().required('validations.required')
          }),
          hour_from: Yup.object().shape({
            label: Yup.string().required('validations.required')
          }),
          hour_to: Yup.object().shape({
            label: Yup.string()
              .required('validations.required')
          })
        })
      )
      .test({
        name: 'shiftsTesting',
        test: function (value) {
          let overlappingError = true
          let shiftsError = true
          let duplicateError = true
          const counts = {}
          const duplicateCounts = {}

          value
            .map((item) => item.day_of_week.label)
            .forEach(function (x) {
              counts[x] = (counts[x] || 0) + 1
            })

          // eslint-disable-next-line 
            for (let index in counts) { 
            if(counts[index] === 2){
              const filterDuplicatedDays = value.filter(item => item.day_of_week.label === index)
              if(filterDuplicatedDays && filterDuplicatedDays.length > 0){
                const hourFrom = filterDuplicatedDays[0].hour_from.value
                const hourTo = filterDuplicatedDays[0].hour_to.value

                const otherHourFrom = filterDuplicatedDays[1].hour_from.value
                const otherHourTo = filterDuplicatedDays[1].hour_to.value

                if (hourFrom <= otherHourFrom && otherHourFrom <= hourTo) {
                  overlappingError = false
                }
                if (hourFrom <= otherHourTo && otherHourTo <= hourTo) {
                  overlappingError = false
                }
                if(hourFrom >= otherHourFrom && hourTo <= otherHourTo){
                  overlappingError = false
                }
              }
            }
          }

          // 2
          value.map(item => {
            if(item.hour_from.value >= item.hour_to.value ) {
              shiftsError = false
            }
            return item
          })

          // 3
          value
            .map((week) => week.day_of_week.label)
            .forEach(function (day) {
              duplicateCounts[day] = (duplicateCounts[day] || 0) + 1
            })

          // eslint-disable-next-line
            for (let index in duplicateCounts) {
            if (duplicateCounts[index] > 2) {
              duplicateError = false
            }
          }

          if(!overlappingError) {
            return this.createError({
              message: 'validations.overlappingDays',
              path: 'visiting_care_day_and_hours'
            })
          }

          else if (!duplicateError) {
            return this.createError({
              message: 'validations.twoShiftsADay',
              path: 'visiting_care_day_and_hours'
            })
          }

          else if (!shiftsError) {
            return this.createError({
              message: 'validations.startBeforeEndHours',
              path: 'visiting_care_day_and_hours'
            })
          }

          else {
            return true
          }
        }
      })
  })
})

// duration schema 
export const FourthStepSchema = Yup.object({
  duration: Yup.string().required('validations.required'),

  temporal_duration_from: Yup.date()
    .nullable()
    .default(null)
    .when('duration', {
      is: 'temporal',
      then: Yup.date().required('validations.required')
    }),

  temporal_duration_to: Yup.date()
    .nullable()
    .default(null)
    .when('duration', {
      is: 'temporal',
      then: Yup.date()
        .required('validations.required')
        .min(Yup.ref('temporal_duration_from'),
          'validations.endBeforeStartHour')
    }),

  undefined_duration_from: Yup.date()
    .nullable()
    .default(null)
    .when('duration', {
      is: 'undefined',
      then: Yup.date().required('validations.required')
    })
})

// contract type and retribution schemas
export const FifthStepSchema = Yup.object({
  contract_type: Yup.string().required('validations.required'),

  retribution_amount: Yup.number()
    .min(1, 'validations.positiveNumber')
    .required('validations.required')
    .test('is-decimal', 'validations.clients.createOffer.salary', (value) => {
      const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/
      return patternTwoDigisAfterComma.test(value)
    }),
  salary_type: Yup.string().required('validations.required')
})

// job offer name schema
export const SixthStepSchema = Yup.object({
  name: Yup.string()
    .max(50, 'validations.referenceLenght')
    .required('validations.required')
})
