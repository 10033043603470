import React from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import {
  Gender,
  Age,
  Nationality,
  Illnesses,
  Education,
  Languages,
  About
} from './inputs'
import { TimesIcon} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const SearchFiltersForm = ({
  params,
  helpers: { countries, languages, illnesses },
  initialValues,
  onFilter,
  onClose,
  onRemoveFilters
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onFilter}
    >
      {({ values, isSubmitting, dirty, resetForm }) => {
        return (
          <Form>
            <div className='section has-padding-b-7'>
              <div className='columns is-vcentered has-padding-t-5'>
                <p className={`column t2-bold primary ${classes.text}`}>
                  <FormattedMessageWithClass id='client.search.sidebar.title' />
                </p>
                <TimesIcon
                  className={classes.icon}
                  onClick={onClose}
                  stroke='#0080F5'
                  strokeWidth='2'
                />
              </div>

              <Gender />

              <Age values={values} />

              <Nationality
                countries={countries || []}
                values={values}
              />

              <Illnesses
                illnesses={illnesses || []}
                values={values}
              />

              <Education values={values} />

              <Languages
                languages={languages || []}
                values={values}
              />

              <About />

              <div className={classes.sidebarbottom}>
                <button
                  className={`filled has-margin-t-6 ${classes.searchbutton}`}
                  disabled={isSubmitting || !dirty}
                  type='submit'
                >
                  <FormattedMessageWithClass id='client.search.sidebar.submit' />
                </button>

                <button
                  className={`button-text is-fullwidth has-margin-t-7 ${classes.removefilters}`}
                  onClick={() => {
                    resetForm()
                    !isEmpty(params) && onRemoveFilters()
                  }}
                  type='button'
                >
                  <FormattedMessageWithClass
                    className='p1-bold'
                    id='client.search.sidebar.removeFilters'
                  />
                </button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SearchFiltersForm
