import React from 'react'
import Input from 'seniors-first-commons/components/atoms/Input'
import { PencilEdit } from 'seniors-first-commons/components/atoms/images/icons'


export const ReasonInput = (props) => {
  return (
    <Input
      autoFocus
      className='input'
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#0080F5'
          width='16'
        />
      )}
      id={props.field.name}
      maxLength={250}
      placeholder=''
      {...props}
    />
  )
}
