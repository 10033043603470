import React from 'react'
import { Formik, Form, Field } from 'formik'
import { useFormatMessage, FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CaregiverRatingInitValues from 'models/caregiverRating'
import { caregiverRatingSchema } from 'schemas/caregiverRating'
import Container from 'components/organisms/AppContainer'
import {
  Rate,
  HiringDecision
} from './inputs'
import { UserDummyImage, SuccessIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const SuccessfullSubmit = ({
  title,
  details,
  finishButton,
  onGoHome
}) => {
  return (
    <div className={classes.submittedFormContainer}>
      <div className={classes.submittedFormWrapper}>
        <SuccessIcon
          fill='none'
          height='75'
          stroke='#0080F5'
          width='75'
        />
        <FormattedMessageWithClass
          className='t1-bold primary has-margin-b-7 has-margin-t-3'
          id={title}
        />

        <FormattedMessageWithClass
          className='t2-regular has-margin-b-2'
          id={details}
        />

        <button
          className={`button filled ${classes.goHomeButton}`}
          onClick={onGoHome}
        >
          <FormattedMessageWithClass id={finishButton} />
        </button>
      </div>
    </div>
  )
}


const CaregiverRatingLayout = ({
  logicPropsAndMethods: {
    lang,
    submitted,
    caregiver_rating,
    caregiverBasicData,
    onFormSubmit,
    onGoHome
  }
}) => {
  const translations = {
    noOption: useFormatMessage('client.ratingForm.secondquestion.option2'),
    yesOption: useFormatMessage('client.ratingForm.secondquestion.option1')
  }

  return (
    <Container name='Caregivers rating'>
      <div className={`${classes.container}`}>
        {submitted && caregiver_rating
          ? <SuccessfullSubmit
            details='client.ratingForm.successmessage.text'
            finishButton='client.ratingForm.successmessage.button'
            onGoHome={onGoHome}
            title='client.ratingForm.successmessage.title'
          />
          : !submitted && caregiver_rating
            ? <SuccessfullSubmit
              details='client.ratingForm.completedmessage.text'
              finishButton='client.ratingForm.completedmessage.button'
              onGoHome={onGoHome}
              title='client.ratingForm.completedmessage.title'
            />
            : (
              <div className={`${classes.section}`}>
                <div className={`level is-vcentered ${classes.details}`}>
                  <div className='level-left'>
                    <figure className='image'>
                      {caregiverBasicData && caregiverBasicData.bio && caregiverBasicData.bio.photo
                        ? <img
                          alt='carer_personal_photo'
                          className={`is-rounded ${classes.profileImg}`}
                          src={caregiverBasicData.bio.photo}
                        />
                        : <UserDummyImage className='is-rounded' />
                      }
                    </figure>
                  </div>
                  <div className={`level-item has-padding-l-5 ${classes.level}`}>
                    <FormattedMessageWithClass
                      className='t3-bold grey'
                      id='client.ratingForm.header.title'
                    />

                    <p className='t1-bold primary has-margin-t-7'>
                      {caregiverBasicData?.firstname
                        ? `${caregiverBasicData?.firstname} ${caregiverBasicData?.lastname}`
                        : caregiverBasicData?.username
                      }
                    </p>
                  </div>
                </div>

                <Formik
                  initialErrors={{
                    forceError: 'force error'
                  }}
                  initialValues={new CaregiverRatingInitValues()}
                  onSubmit={onFormSubmit}
                  validationSchema={caregiverRatingSchema}
                >
                  {({ isSubmitting, isValid, handleSubmit }) => {
                    return (
                      <Form className={classes.formWrapper}>
                        <label className='has-margin-b-5 t4-bold grey' htmlFor='rating'>
                          1. <FormattedMessageWithClass id='client.ratingForm.firstquestion.text1' />
                          <span> {caregiverBasicData?.firstname} </span>
                          <FormattedMessageWithClass id='client.ratingForm.firstquestion.text2' />
                          <span className='danger'> *</span>
                        </label>
                        <div className={classes.ratingResponse}>
                          <FormattedMessageWithClass
                            className={`primary p1-regular ${classes.ratingExplination}`}
                            id='client.ratingForm.firstquestion.answer.text1'
                          />

                          <Field
                            component={Rate}
                            id='rating'
                            name='rating'
                          />
                          <FormattedMessageWithClass
                            className={`primary p1-regular ${classes.ratingExplination}`}
                            id='client.ratingForm.firstquestion.answer.text2'
                          />
                        </div>

                        <label className='has-margin-t-1 has-margin-b-5 t4-bold grey' htmlFor='hired'>
                          2. <FormattedMessageWithClass id='client.ratingForm.secondquestion.text' />
                          {lang === 'en-US' || lang === 'en-GB'
                            ? caregiverBasicData?.gender === 'm'
                              ? ' him ?'
                              : caregiverBasicData?.gender === 'f'
                                ? ' her ?'
                                : null
                            : null
                          }
                          <span className='danger'> *</span>
                        </label>
                        <HiringDecision
                          options={[
                            {
                              text: translations.yesOption,
                              value: 'yes'
                            },
                            {
                              text: translations.noOption,
                              value: 'no'
                            }
                          ]}
                        />

                        <button
                          className={`filled has-margin-t-1 ${classes.submitbutton}`}
                          disabled={isSubmitting || !isValid}
                          onClick={handleSubmit}
                          type='submit'
                        >
                          <FormattedMessageWithClass id='client.ratingForm.submitButton' />
                        </button>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            )
        }
      </div>
    </Container>
  )
}

export default CaregiverRatingLayout
