import React from 'react'
import classes from './index.module.css'
import OfferCreatedConfirmationMessage from '../OfferCreatedConfirmationMessage'
import OfferDetailsBox from '../OfferDetailsBox'
import Select from 'seniors-first-commons/components/molecules/Select'
import { connect } from 'react-redux'
import { FiltersIcon, PersonIcon } from 'seniors-first-commons/components/atoms/images/icons'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { ORDER_LIST_STYLES as dropdownStyles } from 'seniors-first-commons/styles/orderListStyles'

const orderOptions = [
  { label: 'client.search.header.sortBy.options.bestRanking', value: 'ranking' },
  { label: 'client.search.header.sortBy.options.nearestFirst', value: 'nearest' }
]

const Title = ({ matchesMobile, toggleHowItWorksModal }) => {
  return (
    <div
      className='is-flex'
      style={{
        alignItems: matchesMobile ? 'flex-start' : 'center',
        flexDirection: matchesMobile ? 'column' : 'row'
      }}
    >
      <FormattedMessageWithClass
        className='t1-bold primary'
        id='client.search.header.title'
      />

      <button
        className={`button-text primary-light ${matchesMobile ? 'is-paddingless has-margin-bt-7' : 'has-margin-l-7'}`}
        onClick={() => toggleHowItWorksModal('585018494')}
      >
        <FormattedMessageWithClass
          className='p1-bold'
          id='client.search.header.howThisWorks'
        />
      </button>
    </div>
  )
}

const HeaderSecondColumn = ({
  matchesMobile,
  onViewCandidates,
  onShowRightSidebar
}) => {
  const filterButton = useFormatMessage('client.search.header.filtersButton')

  return (
    <div className='level is-mobile' >
      <button
        className={`button level-item outlined primary-light 
        ${matchesMobile ? classes.mobileHeaderButton : classes.headerButton}`}
        onClick={onViewCandidates}
      >
        {matchesMobile ?
          <PersonIcon
            fill='none'
            height='16'
            stroke='#0040AA'
            style={{ minWidth: '16px' }}
            width='16'
          />
          :
          <FormattedMessageWithClass
            className='p1-bold'
            id='client.search.header.viewCandidates'
          />
        }
      </button>
      <button
        className={`filled level-item ${matchesMobile ? classes.mobileHeaderButton : classes.headerButton}`}
        id='filter_button'
        onClick={onShowRightSidebar}
      >
        {!matchesMobile ?
          filterButton:
          <FiltersIcon
            height='16'
            stroke='#fff'
            width='16'
          />
        }
      </button>
    </div>
  )
}

const SearchHeader = ({
  matchesMobile,
  offerCreatedConfirmation,
  onChangeOrder,
  onOpenVideoCVModal,
  onShowRightSidebar,
  onViewCandidates,
  orderBy,
  profile,
  sheetData
}) => {
  const OrderByDropdown =
    <Select
      addStyles={{...dropdownStyles,
        container: (base) => ({ ...base, marginLeft: 'auto', width: '130px' }),
        dropdownIndicator: (base) => ({ ...base, height: '20px', padding: 0 })
      }}
      hasoptionicon={false}
      isSearchable={false}
      onChange={onChangeOrder}
      options={orderOptions}
      value={orderBy}
    />

  return (
    <>
      {matchesMobile ?
        <Title
          matchesMobile={matchesMobile}
          toggleHowItWorksModal={onOpenVideoCVModal}
        />
        :
        <div className='level full-width'>
          <div className='level-left'>
            <Title
              matchesMobile={matchesMobile}
              toggleHowItWorksModal={onOpenVideoCVModal}
            />
          </div>
          { offerCreatedConfirmation && <OfferCreatedConfirmationMessage /> }
          <div className='level-right'>
            <HeaderSecondColumn
              matchesMobile={matchesMobile}
              onShowRightSidebar={onShowRightSidebar}
              onViewCandidates={onViewCandidates}
            />
          </div>
        </div>
      }
      <OfferDetailsBox
        bottomSection={OrderByDropdown}
        currency={profile.index?.currency}
        data={sheetData}
        matchesMobile={matchesMobile}
        secondColumn={!matchesMobile ? (
          OrderByDropdown
        ) : (
          <HeaderSecondColumn
            matchesMobile={matchesMobile}
            onShowRightSidebar={onShowRightSidebar}
            onViewCandidates={onViewCandidates}
          />
        )}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  offerCreatedConfirmation: state.jobOffers.isOfferCreatedConfirmationModalOpened
})

export default connect(mapStateToProps, null)(SearchHeader)
