import { createReducer } from '@reduxjs/toolkit'
import { path } from 'ramda'
import authService from 'seniors-first-commons/services/auth-service'
import {
  register,
  verifyEmail,
  resendEmail,
  login,
  blackList,
  refreshToken,
  deleteAccount,
  requestPasswordReset,
  resetPassword,
  socialLogin,
  socialAuthorize,
  changeAuthErrorStatus,
  toggleSuccessStatus,
  closeWelcomeModal,
  saveDestination
} from './actions'
import { ROUTE } from 'root/constants'


const initState = {
  deleteAccount: {
    loading: false
  },
  destination: null,
  error: '',
  login: {
    error: '',
    loading: false,
    successLogin: false
  },
  register: {
    data: null,
    emailVerified: false,
    loading: false
  },
  resetPassword: {
    email: null,
    loading: false
  },
  socialAccess: {
    data: null,
    loading: false
  },
  success: '',
  token: {
    access_token: undefined,
    refresh_token: undefined
  },
  verifyEmailError: ''
}

const reducer = createReducer(initState, (builder) => {
  builder.addCase(saveDestination, (state, action) => {
    let dest = action.payload
    if (dest.pathname && dest.pathname.includes(ROUTE.WELCOME)) {
      dest = {
        ...dest,
        pathname: dest.pathname.replace(ROUTE.WELCOME, ROUTE.HOME)
      }
    }
    state.destination = dest
  })

  builder.addCase(socialAuthorize.fulfilled, (state, action) => {
    const { currentRole, ...rest } = action.payload
    if (action.payload.role === currentRole) {
      authService.setSession(rest)
    }
  })

  builder.addCase(socialAuthorize.pending, (state) => {
    state.socialAccess.loading = true
  })

  builder.addCase(socialAuthorize.rejected, (state, action) => {
    const { status } = action.payload
    if (status === 500) {
      state.error = 'errors.somethingWentWrong.tryAgain'
    }
    else if (status === 400 || status === 404) {
      state.error = 'errors.email.already-in-use'
    }
  })

  builder.addCase(socialLogin.fulfilled, (state, action) => {
    state.socialAccess.data = action.payload
    window.location.replace(action.payload.redirect_url)
  })

  builder.addCase(socialLogin.pending, (state) => {
    state.socialAccess.loading = true
  })

  builder.addCase(socialLogin.rejected, (state) => {
    state.error = 'errors.somethingWentWrong'
  })

  builder.addCase(requestPasswordReset.fulfilled, (state, action) => {
    state.resetPassword.email = action.payload.email
    state.success = 'success.requestPasswordReset'
  })

  builder.addCase(requestPasswordReset.pending, (state) => {
    state.resetPassword.loading = true
  })

  builder.addCase(requestPasswordReset.rejected, (state, action) => {
    const { status } = action.payload
    if (status === 404) {
      state.error = 'errors.non-existent-user'
    }
  })

  builder.addCase(resetPassword.fulfilled, (state) => {
    state.resetPassword.success = true
    state.success = 'success.resetPassword'
  })

  builder.addCase(resetPassword.pending, (state) => {
    state.resetPassword.loading = true
  })

  builder.addCase(resetPassword.rejected, (state, action) => {
    if (action.payload) {
      state.error = 'errors.non-existent-user'
    }
  })

  builder.addCase(deleteAccount.fulfilled, (state) => {
    state.success = 'success.deleteAccount'
  })

  builder.addCase(deleteAccount.pending, (state) => {
    state.deleteAccount.loading = true
  })

  builder.addCase(changeAuthErrorStatus, (state, action) => {
    state.error = action.payload
    state.login.error = action.payload
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addCase(register.fulfilled, (state, action) => {
    state.register.data = action.payload
    state.success = 'success.register'
  })

  builder.addCase(register.rejected, (state, action) => {
    const error = path(['data', 'message'], action.payload)
    if (typeof error === 'string' && error === 'email-already-in-use') {
      state.error = 'errors.email.already-in-use'
    } else {
      state.error = typeof error !== 'string' && !!error
        ? error[0] === 'email-already-in-use'
          ? 'errors.email.already-in-use'
          : 'errors.somethingWentWrong.tryAgain'
        : 'errors.somethingWentWrong'
    }
  })

  builder.addCase(verifyEmail.fulfilled, (state, action) => {
    state.register.emailVerified = action.payload
  })

  builder.addCase(register.pending, (state) => {
    state.register.loading = true
  })

  builder.addCase(verifyEmail.pending, (state) => {
    state.register.loading = true
  })

  builder.addCase(verifyEmail.rejected, (state, action) => {
    const error = path(['payload', 'status'], action) === 404
      ? 'errors.user-already-verified!'
      : path(['payload', 'data', 'message'], action)

    state.verifyEmailError = error
  })

  builder.addCase(resendEmail.fulfilled, (state) => {
    state.success = 'success.resendEmail'
  })

  builder.addCase(resendEmail.pending, (state) => {
    state.register.loading = true
  })

  builder.addCase(login.pending, (state) => {
    state.login.loading = true
  })

  builder.addCase(login.fulfilled, (state, action) => {
    const { currentRole, ...rest } = action.payload
    state.login.successLogin = true
    state.token = rest

    if (currentRole === rest.role) {
      authService.setSession(rest)
    }
  })

  builder.addCase(login.rejected, (state, action) => {
    const error = path(['payload', 'data', 'message'], action)
    let errorMessage
    switch(error) {
    case 'non-existent-user':
      errorMessage = 'errors.user-not-found'
      break
    case 'email-not-verified':
      errorMessage = 'errors.email-not-verified'
      break
    case 'invalid-password':
      errorMessage = 'errors.invalid-password'
      break
    case 'Not a valid email address':
      errorMessage = 'validations.invalidemail'
      break
    default:
      errorMessage = error
      break
    }

    state.login.error = errorMessage
  })

  // eslint-disable-next-line no-unused-vars
  builder.addCase(blackList.fulfilled, (state) => {
    state = initState
    authService.logout()
  })

  builder.addCase(refreshToken.fulfilled, (state, action) => {
    state.token = {
      ...state.token,
      ...action.payload
    }
  })

  builder.addCase(closeWelcomeModal, (state) => {
    state.login.successLogin = false
  })

  builder.addMatcher((action) => [
    login.fulfilled.toString(),
    login.rejected.toString()
  ].includes(action.type), (state) => {
    state.login.loading = false
  })

  builder.addMatcher((action) => [
    register.fulfilled.toString(),
    register.rejected.toString(),
    verifyEmail.fulfilled.toString(),
    verifyEmail.rejected.toString(),
    resendEmail.fulfilled.toString(),
    resendEmail.rejected.toString()
  ].includes(action.type), (state) => {
    state.register.loading = false
  })

  builder.addMatcher((action) => [
    requestPasswordReset.fulfilled.toString(),
    requestPasswordReset.rejected.toString()
  ].includes(action.type), (state) => {
    state.resetPassword.loading = false
  })

  builder.addMatcher((action) => [
    resetPassword.fulfilled.toString(),
    resetPassword.rejected.toString()
  ].includes(action.type), (state) => {
    state.resetPassword.loading = false
  })

  builder.addMatcher((action) => [
    deleteAccount.fulfilled.toString(),
    deleteAccount.rejected.toString()
  ].includes(action.type), (state) => {
    state.deleteAccount.loading = false
  })

  builder.addMatcher((action) => [
    socialLogin.fulfilled.toString(),
    socialLogin.rejected.toString(),
    socialAuthorize.fulfilled.toString(),
    socialAuthorize.rejected.toString()
  ].includes(action.type), (state) => {
    state.socialAccess.loading = false
  })
})

export default reducer
