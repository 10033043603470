import React from 'react'
import classes from './index.module.css'

/* eslint-disable no-unused-vars */
const RadioButton = ({
  field,
  form,
  labelClass='',
  ...props
}) => {
  /* eslint-enable no-unused-vars */
  return (
    <label className={`p1-regular grey ${labelClass}`} htmlFor={field.name + field.value}>
      <input
        className={classes.radio}
        style={{verticalAlign: 'middle'}}
        type='radio'
        {...field}
        {...props}
      />
      <span className='has-margin-l-7'>{props.label}</span>
    </label>
  )
}

export default RadioButton
