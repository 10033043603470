
export const hasSameLatLng = (arr=[]) => {
  return arr.map(item => `${item.offered_service.lat}-${item.offered_service.lng}`)
    .some((item, index, array) => {
      return array.indexOf(item) !== array.lastIndexOf(item)
    })
}

export const getPostCandidatesPage = (candidates, pageNumber, itemsPerPage) => {
  const startIndex = (pageNumber - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return candidates.slice(startIndex, endIndex)
}

export const setNewCandidatesFirst = (candidates) => {
  const newCandidates = candidates.filter(candidate => !candidate.viewed)
  const viewedCandidates = candidates.filter(candidate => candidate.viewed)
  return [...newCandidates, ...viewedCandidates]
}
