import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'ramda'
import qs from 'query-string'

export const useFetchData = (data = [], fetchAction) => {
  useEffect(() => {
    if (isEmpty(data)) {
      fetchAction()
    }
    // eslint-disable-next-line
    }, []);
}


export function useOuterClick(callback) {
  const callbackRef = useRef()
  const innerRef = useRef()

  useEffect(() => { callbackRef.current = callback })
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
    function handleClick(e) {
      if (innerRef.current && callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) callbackRef.current(e)
    }
  }, [])

  return innerRef
}


export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function parseQueries(options={}) {
  return qs.parse(window.location.search, { parseBooleans: true, parseNumbers: true, ...options })
}

export function stringifyQueries(data, options={}) {
  return qs.stringify(data, { arrayFormat: 'comma', ...options })
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}


export function useFecthDataOnCondition(callback, condition) {
  useEffect(() => {
    if (condition) {
      callback()
    }
    // eslint-disable-next-line
  }, [condition])
}
