import axios from 'axios'
import { pathOr } from 'ramda'
import setupAxiosInterceptors from './refresh-interceptor'
import routingService from './routingService'


const handleError = (error) => {
  return Promise.reject(pathOr(error, ['response'], error))
}

class ApiService {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_API
    setupAxiosInterceptors(axios)
  }

  getToken = () => `Bearer ${localStorage.getItem('access_token') || ''}`

  // API must only receive 'es' or 'en'.
  getLanguage = () => routingService.getLanguage()

  axiosPut = (url, body) =>
    axios
      .put(url, JSON.stringify(body), {
        headers: {
          'Accept-Language': this.getLanguage(),
          Authorization: this.getToken(),
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.data)
      .catch((error) => handleError(error))

  axiosPost = (
    url,
    body,
    opt = {
      headers: {
        'Accept-Language': this.getLanguage(),
        Authorization: this.getToken(),
        'Content-Type': 'application/json'
      }
    }
  ) =>
    axios
      .post(url, JSON.stringify(body), opt)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        return handleError(error)
      })

  axiosUpload = (
    url,
    body,
    opt = {
      headers: {
        'Accept-Language': this.getLanguage(),
        Authorization: this.getToken(),
        'Content-Type': 'application/json'
      }
    }
  ) =>
    axios
      .post(url, body, opt)
      .then((response) => response.data)
      .catch((error) => handleError(error))

  axiosGet = (url, params = null) => {
    return axios
      .get(url, {
        headers: {
          'Accept-Language': this.getLanguage(),
          Authorization: this.getToken(),
          'Content-Type': 'application/json'
        },
        params
      })
      .then((response) => response.data)
      .catch((error) => handleError(error))
  }

  axiosDelete = (url, body) =>
    axios
      .delete(url, {
        data: {
          ...body
        },
        headers: {
          'Accept-Language': this.getLanguage(),
          Authorization: this.getToken(),
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.data)
      .catch((error) => handleError(error))

  axiosPatch = (url, video, uploaded = 0, handleProgress) =>
    axios
      .patch(url, video, {
        headers: {
          Accept: 'application/vnd.vimeo.*+jsonversion=3.4',
          'Content-Type': 'application/offset+octet-stream',
          'Tus-Resumable': '1.0.0',
          'Upload-Offset': uploaded
        },
        onUploadProgress: handleProgress
      })
      .then((response) => response.data)
      .catch((error) => handleError(error))

  axiosHead = (url) =>
    axios
      .head(url, {
        headers: {
          Accept: 'application/vnd.vimeo.*+jsonversion=3.4',
          'Tus-Resumable': '1.0.0'
        }
      })
      .then((response) => response.data)
      .catch((error) => handleError(error))
}

const service = new ApiService()
export default service
