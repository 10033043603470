import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import moment from 'moment'
import services from 'seniors-first-commons/services/api-service'

const { axiosGet, axiosPost, axiosPut } = services

const baseSheetDataToApi = (payload) => {
  // by default a job offer should not be archived
  const data = { archived: false }

  const {
    name,
    address,
    country,
    duration,
    hiring_modality,
    service_categories,
    contract_type,
    client_id
  } = payload

  data['name'] = name

  data['country_id'] = country
  data['address_lat'] = address.address_lat
  data['address_lng'] = address.address_lng

  data['address'] = address.formatted_address

  // services logic
  data['service_categories'] = service_categories.map(item => {
    return {
      'service_category_id': item
    }
  })

  data['hiring_modality'] = hiring_modality

  if(hiring_modality === 'live_in_care'){
    // times
    data['live_in_care_hour_from'] = payload.live_in_care.hour_from.value
    data['live_in_care_hour_to'] = payload.live_in_care.hour_to.value
    //days
    data['live_in_care_day_of_week_from'] = payload.live_in_care.day_of_week_from.value
    data['live_in_care_day_of_week_to'] = payload.live_in_care.day_of_week_to.value
  }
  else if (hiring_modality === 'visiting_care'){
    data['visiting_care_day_and_hours'] = []

    payload.visiting_care_day_and_hours.map(shift => {
      data.visiting_care_day_and_hours.push({
        day_of_week: shift.day_of_week.value,
        hour_from: shift.hour_from.value,
        hour_to: shift.hour_to.value
      })

      return shift
    })
  }

  //duration logic
  data['duration'] = duration
  if(duration === 'temporal'){
    const formattedDurationFrom = payload.temporal_duration_from.format('YYYY-MM-DD HH:mm:ss')
    const formattedDurationTo = payload.temporal_duration_to.format('YYYY-MM-DD HH:mm:ss')

    data['duration_from'] = formattedDurationFrom
    data['duration_to'] = formattedDurationTo
  }
  else if (duration === 'undefined'){
    const formattedDurationFrom = payload.undefined_duration_from.format('YYYY-MM-DD HH:mm:ss')
    data['duration_from'] = formattedDurationFrom
  }

  data['contract_type'] = contract_type

  // retribution logic 
  data['retribution'] = 'custom'
  data['retribution_amount'] = Number(payload['retribution_amount'])
  data['salary_type'] = payload['salary_type']

  data['client_id'] = client_id

  return data
}

const baseServiceSheetDataToApi = (payload) => {
  const data = {...payload}
  /* eslint-disable no-unused-vars */
  const {
    created_at,
    posted_until,
    id,
    updated_at,
    name,
    service_categories,
    hiring_modality,
    ...rest } = data
  /* eslint-enable no-unused-vars */

  const services = []
  service_categories.map(({service_category_id}) => {
    return services.push({service_category_id: service_category_id.toString()})
  })

  if(hiring_modality === 'visiting_care'){
    const visitingCareTimes = []
    data.visiting_care_day_and_hours.map(item => {
      return visitingCareTimes.push({day_of_week: item.day_of_week, hour_from: item.hour_from, hour_to: item.hour_to})
    })

    rest['visiting_care_day_and_hours'] = visitingCareTimes
  }

  rest['duration_from'] = moment(rest['duration_from']).format('YYYY-MM-DD 12:mm:ss')
  rest['duration_to'] = rest['duration_to'] ?
    moment(rest['duration_to']).format('YYYY-MM-DD 12:mm:ss') :
    rest['duration_to']

  return { hiring_modality, name: `(COPY) ${name}`, service_categories: services, ...rest }
}

export const getJobOffers = createAsyncThunk(
  'jobOffers/getJobOffers',
  ({ page, per_page, archived, order_by='updated_at', order_type='desc' }, {rejectWithValue}) => {
    archived = archived ? 1 : 0
    return axiosGet('/service-sheets', { archived, order_by, order_type, page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getOfferDetails = createAsyncThunk(
  'jobOffers/getOfferDetails',
  ({id}, {rejectWithValue}) => {
    return axiosGet(`/service-sheets/${id}`)
      .then(response => response)
      .catch(oneSheetError => rejectWithValue(oneSheetError))
  }
)

export const createJobOffer = createAsyncThunk(
  'jobOffers/createJobOffer',
  (payload, {rejectWithValue}) => {
    const requestPayload = baseSheetDataToApi(payload)
    return axiosPost('/service-sheets', requestPayload)
      .then(response => {
        return {
          ...response,
          visiting_care_day_and_hours: requestPayload['visiting_care_day_and_hours']
        }
      })
      .catch(error => rejectWithValue(error))
  })

export const duplicateOffer = createAsyncThunk(
  'jobOffers/duplicateOffer',
  ({ serviceSheet_data }, {rejectWithValue}) => {
    const requestPayload = baseServiceSheetDataToApi(serviceSheet_data)
    return axiosPost('/service-sheets', requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const changeOfferArchivedStatus = createAsyncThunk(
  'jobOffers/changeOfferArchivedStatus',
  ({ id }, {rejectWithValue}) => {
    return axiosPut(`/service-sheets/${id}/change-archived-status`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const postJobOffer = createAsyncThunk(
  'jobOffers/postJobOffer',
  ({ service_sheet_id, weeks }, {rejectWithValue}) => {
    return axiosPut(`/service-sheets/${service_sheet_id}/post-for-weeks/${weeks}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const postJobOfferFromDetails = createAsyncThunk(
  'jobOffers/postJobOfferFromDetails',
  ({ service_sheet_id, weeks }, {rejectWithValue}) => {
    return axiosPut(`/service-sheets/${service_sheet_id}/post-for-weeks/${weeks}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getAvailableOffers = createAsyncThunk(
  'jobOffers/getAvailableOffers',
  ({ caregiver_id, page=1, per_page }, {rejectWithValue}) => {
    return axiosGet(`/service-sheets/not-offered-to/${caregiver_id}`, { page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const requestAssistanceForCreatedOffer = createAsyncThunk(
  'jobOffers/requestAssistanceForCreatedOffer',
  (payload, { rejectWithValue }) => {
    return axiosPut(`/service-sheets/${payload.id}`, {request_assistance: true})
      .then(response => response)
      .catch(error => rejectWithValue(error?.message))
  }
)

export const requestAssistanceForCreatedPublicOffer = createAsyncThunk(
  'jobOffers/requestAssistanceForCreatedPublicOffer',
  (payload, { rejectWithValue }) => {
    return axiosPost('/notifications/contact-by-phone-request', {
      language: payload.language,
      phone: payload.phone
    })
      .then(response => response)
      .catch(error => rejectWithValue(error?.message))
  }
)

export const sendNotificationForCreatedOffer = createAsyncThunk(
  'jobOffers/sendNotificationForCreatedOffer',
  ({ user_phone, service_sheet_id, service_sheet_name }, { rejectWithValue }) => {
    return axiosPost(
      '/notifications/send_support_notification',
      {
        service_sheet_id,
        service_sheet_name,
        user_phone
      })
      .then(response => response)
      .catch(error => rejectWithValue(error?.message))
  }
)

export const toggleSuccessStatus = createAction('jobOffers/toggleSuccessStatus')

export const toggleOfferCreatedConfirmationModal = createAction('jobOffers/toggleOfferCreatedConfirmationModal')

export const toggleRequestAssistanceModal = createAction('jobOffers/toggleRequestAssistanceModal')

export const toggleRegisterModal = createAction('jobOffers/toggleRegisterModal')
