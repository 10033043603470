import React from 'react'
import withChat from 'hoc/withChat'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import AppContainer from 'components/organisms/AppContainer'
import Sidebar from 'components/organisms/SearchFiltersSidebar'
import SearchHeader from 'components/organisms/SearchHeader'
import VideoModal from 'seniors-first-commons/components/molecules/VideoModal'
import Pagination from 'seniors-first-commons/components/molecules/Pagination'
import SearchDesktopLayout from './DesktopLayout'
import SearchMobileLayout from './MobileLayout'
import ActionalbleEmptyResponse from 'components/atoms/ActionalbleEmptyResopnse'
import AnimatedSuccessModal from 'components/atoms/AnimatedSuccessModal'
import RequestAssitanceModal from 'components/organisms/RequestAssistanceModal'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const SearchLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    orderBy,
    onChangeOrder,
    ...rest
  },
  ...props
}) => {
  const {
    specialService,
    showAnimatedModal,
    loading,
    onCallSpecialService,
    startAnimation,
    setStartAnimation,
    setShowAnimatedModal
  } = rest
  const translations =  {
    filtersButton: useFormatMessage('client.search.header.filtersButton'),
    noFiltersButton: useFormatMessage('client.search.noFilter.button'),
    noFiltersText: useFormatMessage('client.search.noFilters.text'),
    specialServiceNotSent: useFormatMessage('client.search.specialService.notSent.text'),
    specialServiceNotSentButton: useFormatMessage('client.general.specialService.notSent.button'),
    specialServiceSent: useFormatMessage('client.general.specialService.sent.text'),
    specialServiceSentButton: useFormatMessage('client.general.specialService.sent.button')
  }

  return (
    <AppContainer
      additionalClass={matchesMobile && rest.caregivers.total ? 'is-paddingless-lr is-marginless-lr' : 'has-padding-lr-7'}
      name='Search'
      RightSidebar={
        <Sidebar
          onClose={rest.onHideRightSidebar}
          onShow={rest.onShowRightSidebar}
          open={rest.showRightSidebar}
          {...rest}
        />
      }
    >
      <div className='has-padding-b-1'>
        <div className={matchesMobile && rest.caregivers.total ? 'has-padding-lr-7' : ''}>
          <SearchHeader
            matchesMobile={matchesMobile}
            onChangeOrder={onChangeOrder}
            orderBy={orderBy}
            {...rest}
          />
        </div>

        {!loading.loadingPageData ?
          rest.caregivers.total ?
            matchesMobile ?
              (
                <SearchMobileLayout
                  matchesMobile={matchesMobile}
                  {...rest}
                  {...props}
                />
              ) :
              (
                <SearchDesktopLayout
                  matchesMobile={matchesMobile}
                  {...rest}
                  {...props}
                />
              )
            :
            <div className='no-results'>
              <ActionalbleEmptyResponse
                buttonText={
                  !startAnimation && specialService && specialService.id
                    ? translations.specialServiceSentButton
                    : translations.specialServiceNotSentButton
                }
                disabled={!startAnimation && specialService && specialService.id}
                loading={false}
                matchesMobile={matchesMobile}
                message={!startAnimation && specialService && specialService.id
                  ? translations.specialServiceSent
                  : translations.specialServiceNotSent
                }
                onClickAction={onCallSpecialService}
              />

              <AnimatedSuccessModal
                loading={loading.loadingSpecialService}
                open={showAnimatedModal}
                setOpen={setShowAnimatedModal}
                setStartAnimation={setStartAnimation}
                startAnimation={startAnimation}
              />
            </div> : null
        }
      </div>

      <RequestAssitanceModal loading={rest.loading.loadingPageData} />

      <VideoModal
        isOpen={!!rest.showVideoCVModal}
        onClose={rest.onCloseVideoCVModal}
        title='CV Video'
        videoId={rest.showVideoCVModal}
      />

      <Pagination
        currentPage={rest.caregivers.currentPage}
        data={rest.caregivers.total}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={rest.handlePageChange}
      />
    </AppContainer>
  )
}

export default withChat(SearchLayout, 'findsend')
