import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { useFecthDataOnCondition } from 'seniors-first-commons/shared/hooks'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import { getCaregiverDetail, getCaregiversNearYou} from 'store/caregivers/actions'
import { askForSpecialService, sendAnayetRequest } from 'store/profile/actions'
import { getOfferDetails, getJobOffers } from 'store/jobOffers/actions'
import HomeLayout from 'components/templates/HomeLayout'
import { ROUTE } from 'root/constants'


const Home = ({
  user,
  profile,
  serviceSheets,
  specialService,
  credits,
  clientCarerConnection,
  submittedBasicInfo,
  sendJobOfferLoading,
  loadingNearCaregivers,
  postJobLoading,
  caregiversNearYou,
  availableOffers,
  getCaregiverDetail,
  getJobOffers,
  getCaregiversNearYou,
  askForSpecialService,
  sendAnayetRequest
}) => {
  const [showAnimatedModal, setShowAnimatedModal] = useState(false)
  const [showVideoCVModal, setShowVideoCVModal] = useState('')
  const [startAnimation, setStartAnimation] = useState(false)
  const [showAnayetRequestModal, setShowAnayetRequestModal] = useState(false)
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const currnetRoute = history.location.pathname.split('/')[2]

  const sliderRef = useRef(null)

  useFecthDataOnCondition(() => {
    if (!!currnetRoute && ROUTE.ANAYET_REQUEST.includes(currnetRoute)) {
      sendAnayetRequest()
        .unwrap()
        .then(() => setShowAnayetRequestModal(true))
        .catch(() => setShowAnayetRequestModal(false))
    }
  }, submittedBasicInfo)

  useFecthDataOnCondition(() => {
    getJobOffers({ archived: 0, page: 1, per_page: 3 })
  }, submittedBasicInfo)

  useFecthDataOnCondition(() => {
    getCaregiversNearYou()
  }, submittedBasicInfo)

  const handleNextSlide = () => {
    sliderRef.current.swiper.slideNext()
  }

  const handlePrevSlide = () => {
    sliderRef.current.swiper.slidePrev()
  }

  const onCallSpecialService = () => {
    setShowAnimatedModal(true)
    askForSpecialService({ from: 'home' })
      .unwrap()
      .then(() => setStartAnimation(true))
  }

  const onOpenVideoCVModal = (video_id) => {
    setShowVideoCVModal(video_id)
  }

  const onCloseVideoCVModal = () => {
    setShowVideoCVModal('')
  }

  const onEnterSlider = () => {
    sliderRef.current.swiper.autoplay.pause()
  }

  const onLeaveSlider = () => {
    sliderRef.current.swiper.autoplay.run()
  }

  const onFindCaregiver = () => {
    routingService.push(history, `${ROUTE.CREATE_JOB_OFFER}`)
  }

  const onCloseAnayetRequestModal = () => {
    setShowAnayetRequestModal(false)
    routingService.push(history, ROUTE.HOME)
  }

  return (
    <div className={'full-content'}>
      <HomeLayout
        logicPropsAndMethods={{
          availableOffers,
          caregiversNearYou,
          getCaregiverDetail,
          handleNextSlide,
          handlePrevSlide,
          loading: {
            checkingConnection: clientCarerConnection.checkingConnection,
            loadingCredits: credits.loadingCredits,
            loadingNearCaregivers,
            loadingSpecialService: specialService.loading,
            postJobLoading,
            sendJobOfferLoading
          },
          matchesMobile,
          onCallSpecialService,
          onCloseAnayetRequestModal,
          onCloseVideoCVModal,
          onEnterSlider,
          onFindCaregiver,
          onLeaveSlider,
          onOpenVideoCVModal,
          profile,
          serviceSheets,
          setShowAnimatedModal,
          setStartAnimation,
          showAnayetRequestModal,
          showAnimatedModal,
          showVideoCVModal,
          sliderRef,
          specialService: specialService.data,
          startAnimation,
          user
        }}
      />
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    availableOffers: state.jobOffers.availableOffers,
    caregiversNearYou: state.caregivers.caregiversNearYou,
    clientCarerConnection: state.caregivers.chat,
    credits: state.billing.credits,
    loadingNearCaregivers: state.caregivers.loading,
    oneSheetData: state.jobOffers.oneSheetData,
    postJobLoading: state.jobOffers.postJobLoading,
    profile: state.profile.profile.index,
    sendJobOfferLoading: state.caregivers.connectFromSearchLoading,
    serviceSheets: state.jobOffers.index.items,
    specialService: {
      data: state.profile.specialService.home,
      loading: state.profile.specialService.loading
    },
    submittedBasicInfo: state.profile.profile.submittedBasicInfo,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  askForSpecialService,
  getCaregiverDetail,
  getCaregiversNearYou,
  getJobOffers,
  getOfferDetails,
  sendAnayetRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
