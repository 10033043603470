import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import DeleteProfileInitValues from 'seniors-first-commons/shared/models/deleteProfile'
import deleteProfileSchemas from 'seniors-first-commons/shared/schemas/deleteProfile'
import { ReasonInput } from './inputs'


const DeleteProfileForm = ({ onFormSubmit }) => {
  return (
    <Formik
      initialValues={new DeleteProfileInitValues()}
      onSubmit={onFormSubmit}
      validationSchema={deleteProfileSchemas}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Field
              component={ReasonInput}
              name='reason'
            />
            <ErrorMessage
              component={ErrorHandler}
              name='reason'
            />

            <button
              className='button filled has-margin-t-4 nonAuthedButtons'
              onClick={handleSubmit}
              type='submit'
            >
              <FormattedMessageWithClass
                id='app.deleteaccount.form.button'
              />
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default DeleteProfileForm
