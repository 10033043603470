export default {
  'ES': [
    {
      'hourlyRate': '8.87 €/h',
      'hoursPerWeek': '1 h',
      'monthlyGrossIncome': '35.48 €/mes'
    },
    {
      'hourlyRate': '8.87 €/h',
      'hoursPerWeek': '2 h',
      'monthlyGrossIncome': '70.96 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '3 h',
      'monthlyGrossIncome': '99.24 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '4 h',
      'monthlyGrossIncome': '132.32 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '5 h',
      'monthlyGrossIncome': '165.40 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '6 h',
      'monthlyGrossIncome': '198.48 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '7 h',
      'monthlyGrossIncome': '231.56 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '8 h',
      'monthlyGrossIncome': '264.64 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '9 h',
      'monthlyGrossIncome': '297.72 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '10 h',
      'monthlyGrossIncome': '330.80 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '11 h',
      'monthlyGrossIncome': '363.88 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '12 h',
      'monthlyGrossIncome': '396.96 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '13 h',
      'monthlyGrossIncome': '430.04 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '14 h',
      'monthlyGrossIncome': '463.12 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '15 h',
      'monthlyGrossIncome': '496.20 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '16 h',
      'monthlyGrossIncome': '529.28 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '17 h',
      'monthlyGrossIncome': '562.36 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '18 h',
      'monthlyGrossIncome': '595.44 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '19 h',
      'monthlyGrossIncome': '628.52 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '20 h',
      'monthlyGrossIncome': '661.60 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '21 h',
      'monthlyGrossIncome': '694.68 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '22 h',
      'monthlyGrossIncome': '727.76 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '23 h',
      'monthlyGrossIncome': '760.84 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '24 h',
      'monthlyGrossIncome': '793.92 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '25 h',
      'monthlyGrossIncome': '827.00 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '26 h',
      'monthlyGrossIncome': '860.08 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '27 h',
      'monthlyGrossIncome': '893.16 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '28 h',
      'monthlyGrossIncome': '926.24 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '29 h',
      'monthlyGrossIncome': '959.32 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '30 h',
      'monthlyGrossIncome': '992.40 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '31 h',
      'monthlyGrossIncome': '1,025.48 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '32 h',
      'monthlyGrossIncome': '1,058.56 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '33 h',
      'monthlyGrossIncome': '1,091.64 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '34 h',
      'monthlyGrossIncome': '1,124.72 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '35 h',
      'monthlyGrossIncome': '1,157.80 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '36 h',
      'monthlyGrossIncome': '1,190.88 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '37 h',
      'monthlyGrossIncome': '1,223.96 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '38 h',
      'monthlyGrossIncome': '1,257.04 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '39 h',
      'monthlyGrossIncome': '1,290.12 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '40 h',
      'monthlyGrossIncome': '1,323.20 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '41 h',
      'monthlyGrossIncome': '1356.28 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '42 h',
      'monthlyGrossIncome': '1389.36 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '43 h',
      'monthlyGrossIncome': '1422.44 €/mes'
    },
    {
      'hourlyRate': '8.27 €/h',
      'hoursPerWeek': '44 h',
      'monthlyGrossIncome': '1455.52 €/mes'
    }
  ],
  'UK': [
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '1 h',
      'monthlyGrossIncome': '41,68 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '2 h',
      'monthlyGrossIncome': '83,36 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '3 h',
      'monthlyGrossIncome': '125,04 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '4 h',
      'monthlyGrossIncome': '166,72 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '5 h',
      'monthlyGrossIncome': '208,40 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '6 h',
      'monthlyGrossIncome': '250,08 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '7 h',
      'monthlyGrossIncome': '291,76 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '8 h',
      'monthlyGrossIncome': '333,44 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '9 h',
      'monthlyGrossIncome': '375,12 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '10 h',
      'monthlyGrossIncome': '416,80 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '11 h',
      'monthlyGrossIncome': '458,48 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '12 h',
      'monthlyGrossIncome': '500,16 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '13 h',
      'monthlyGrossIncome': '541,84 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '14 h',
      'monthlyGrossIncome': '583,52 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '15 h',
      'monthlyGrossIncome': '625,20 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '16 h',
      'monthlyGrossIncome': '666,88 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '17 h',
      'monthlyGrossIncome': '708,56 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '18 h',
      'monthlyGrossIncome': '750,24 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '19 h',
      'monthlyGrossIncome': '791,92 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '20 h',
      'monthlyGrossIncome': '833,60 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '21 h',
      'monthlyGrossIncome': '875,28 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '22 h',
      'monthlyGrossIncome': '916,96 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '23 h',
      'monthlyGrossIncome': '958,64 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '24 h',
      'monthlyGrossIncome': '1000,32 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '25 h',
      'monthlyGrossIncome': '1042,00 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '26 h',
      'monthlyGrossIncome': '1083,68 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '27 h',
      'monthlyGrossIncome': '1125,36 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '28 h',
      'monthlyGrossIncome': '1167,04 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '29 h',
      'monthlyGrossIncome': '1208,72 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '30 h',
      'monthlyGrossIncome': '1250,40 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '31 h',
      'monthlyGrossIncome': '1292,08 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '32 h',
      'monthlyGrossIncome': '1333,76 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '33 h',
      'monthlyGrossIncome': '1375,44 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '34 h',
      'monthlyGrossIncome': '1417,12 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '35 h',
      'monthlyGrossIncome': '1458,80 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '36 h',
      'monthlyGrossIncome': '1500,48 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '37 h',
      'monthlyGrossIncome': '1542,16 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '38 h',
      'monthlyGrossIncome': '1583,84 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '39 h',
      'monthlyGrossIncome': '1625,52 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '40 h',
      'monthlyGrossIncome': '1667,20 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '41 h',
      'monthlyGrossIncome': '1708,88 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '42 h',
      'monthlyGrossIncome': '1750,56 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '43 h',
      'monthlyGrossIncome': '1792,24 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '44 h',
      'monthlyGrossIncome': '1833,92 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '45 h',
      'monthlyGrossIncome': '1875,60 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '46 h',
      'monthlyGrossIncome': '1917,28 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '47 h',
      'monthlyGrossIncome': '1958,96 £/month'
    },
    {
      'hourlyRate': '10,42 £/h',
      'hoursPerWeek': '48 h',
      'monthlyGrossIncome': '2000,64 £/month'
    }
  ]
}
