export const Actions = {
  BLACKLIST_ERROR: 'app/BLACKLIST_ERROR',
  BLACKLIST_INIT: 'app/BLACKLIST_INIT',
  BLACKLIST_SUCCESS: 'app/BLACKLIST_SUCCESS',
  LOADING: '',
  LOAD_TRANSLATIONS_ERROR: 'LOAD_TRANSLATIONS_ERROR',
  LOAD_TRANSLATIONS_INIT: 'LOAD_TRANSLATIONS_INIT',
  LOAD_TRANSLATIONS_SUCCESS: 'LOAD_TRANSLATIONS_SUCCESS',
  REFRESH_TOKEN_ERROR: 'app/REFRESH_TOKEN_ERROR',
  REFRESH_TOKEN_INIT: 'app/REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'app/REFRESH_TOKEN_SUCCESS',
  SET_ERROR_MESSAGE: 'app/SET_ERROR_MESSAGE',
  SET_FROM_PERSONAL_DATA: 'flags/SET_FROM_PERSONAL_DATA',
  TOAST_CLOSE: 'app/TOAST_CLOSE',
  TOAST_SHOW: 'app/TOAST_SHOW',
  UNSET_FROM_PERSONAL_DATA: 'flags/UNSET_FROM_PERSONAL_DATA',
  USER_UNAUTHORIZED: 'app/USER_UNAUTHORIZED'
}
