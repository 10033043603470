import React from 'react'
import Rating from 'react-rating'
import { Field } from 'formik'
import Radio from 'seniors-first-commons/components/atoms/RadioButton'
import { EmptyStar, FullStar } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

export const Rate = ({
  form: { setFieldValue },
  field
}) => {
  const handleChange = (value) => {
    setFieldValue('rating', value)
  }

  return (
    <Rating
      emptySymbol={<EmptyStar className={`${classes.star}`} fill='none' height='38' stroke='#FFD500' width='40' />}
      fractions={1}
      fullSymbol={<FullStar className={`${classes.star}`} height='38' stroke='#FFD500' width='40' />}
      initialRating={field.value}
      onChange={handleChange}
    />
  )
}

export const HiringDecision = ({ options }) => {
  return (
    <div className={classes.radios}>
      {
        options.map((option, index) => {
          return (
            <Field
              component={Radio}
              id={`hired${option.value}`}
              key={index}
              label={option.text}
              labelClass={classes.radioOption}
              name='hired'
              type='radio'
              value={option.value}
            />
          )
        })
      }
    </div>
  )
}
