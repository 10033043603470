import * as Yup from 'yup'

const ChangePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .test('confirmaPassword_text', 'validations.passwordsdontmatch', function(value) {
      const { new_password } = this.parent

      if (value === new_password) return true
    }).required('validations.required'),
  new_password: Yup.string().min(6, 'validations.passwordlength')
    .required('validations.required')
})

export default ChangePasswordSchema
