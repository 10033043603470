import {
  MINIMUM_WAGE_UK_INFO,
  MINIMUM_WAGE_SPAIN_INFO
} from 'seniors-first-commons/utils/constants'

export const getMinimumWageInfo = (profileCountryId) => {
  if(profileCountryId === MINIMUM_WAGE_SPAIN_INFO.countryId) {
    return MINIMUM_WAGE_SPAIN_INFO
  } else if (profileCountryId === MINIMUM_WAGE_UK_INFO.countryId) {
    return MINIMUM_WAGE_UK_INFO
  }
  return null
}
