import React from 'react'
import { Field, ErrorMessage } from 'formik'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import MapContainer from 'seniors-first-commons/components/organisms/Map'
import OfferedServices from 'seniors-first-commons/components/molecules/OfferedServices'
import Modality from 'components/templates/CreateJobOfferLayout/Modality'
import InternalModality from 'components/templates/CreateJobOfferLayout/Modality/InternalModality'
import ExternalModality from 'components/templates/CreateJobOfferLayout/Modality/ExternalModality'
import ServiceDuration from 'components/templates/CreateJobOfferLayout/Duration'
import Salary from 'components/templates/CreateJobOfferLayout/Salary'
import CustomBudget from 'components/templates/CreateJobOfferLayout/Salary/CustomBudget'
import Temporal from 'components/templates/CreateJobOfferLayout/Duration/TemporalDuration'
import Undefined from 'components/templates/CreateJobOfferLayout/Duration/UndefinedDuration'
import { NameInput, ContractType, AddressInput } from './inputs'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


export const FirstStep = ({
  values,
  profile,
  user,
  country,
  onRedirectToProfileSettings
}) => {
  const mapContainerStyles = {
    height: '250px',
    position: 'relative',
    width: '100%'
  }
  const mapStyles = {
    borderRadius: '15px'
  }

  return (
    <>
      <FieldLabel
        id='location'
        labelName='client.createOffer.steps.address.title'
      />
      <div className='columns'>
        <div className='column'>
          <p className='l-regular grey'>
            <FormattedMessageWithClass
              id='client.createOffer.steps.address.text1'
            />
          </p>

          <FieldLabel
            labelName='client.createOffer.steps.address.label'
            labelType='l-bold'
            topSpace={2}
          />
          <Field
            component={AddressInput}
            country={country}
            name='address'
          />
          <ErrorMessage component={HandleError} name='address' />

          <div className={`has-margin-t-4 ${classes.infoText}`}>
            <InfoIcon
              fill='none'
              height='24'
              stroke='#0040AA'
              width='24'
            />
            <div className='has-margin-l-7'>
              <FormattedMessageWithClass
                className='p3-regular primary'
                id='client.createOffer.steps.address.text2'
              />
              {'  '}
              {/* eslint-disable-next-line */}
              <a
                className='underline p3-bold primary'
                onClick={onRedirectToProfileSettings}
              >
                <FormattedMessageWithClass
                  id='client.createOffer.steps.address.text3'
                />
              </a>
            </div>
          </div>
        </div>
        <div className='column' >
          {profile &&
            <MapContainer
              animateMarker={2}
              hasMarker={values.address.address_lat ? true : false}
              location={{
                lat: Number(values.address.address_lat || Number(profile.address_lat)),
                lng: Number(values.address.address_lng || Number(profile.address_lng))
              }}
              markerText={user && `${user.firstname} ${user.lastname}`}
              options={{
                containerStyle: mapContainerStyles,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                style: mapStyles
              }}
              radius={0}
              zoom={!values.address.address_lat ? 5 : 15}
            />
          }
        </div>
      </div>
    </>
  )
}

export const SecondStep = () => {
  return (
    <>
      <FieldLabel
        id='service_categories'
        labelName='client.createOffer.steps.serviceType.title'
      />
      <Field component={OfferedServices} name='service_categories' />
      <ErrorMessage component={HandleError} name='service_categories' />
    </>
  )
}

export const ThirdStep = ({
  matchesMobile,
  values
}) => {
  return (
    <>
      <FieldLabel
        id='hiring_modality'
        labelName='client.createOffer.steps.modality'
      />

      <Modality hiring_modality={values.hiring_modality} />
      <ErrorMessage component={HandleError} name='hiring_modality' />

      {values.hiring_modality === 'visiting_care'
        ? <ExternalModality matchesMobile={matchesMobile} />
        : values.hiring_modality === 'live_in_care'
          ? <InternalModality matchesMobile={matchesMobile} values={values} /> : null
      }
    </>
  )
}

export const FourthStep = ({ values }) => {
  return (
    <>
      <FieldLabel
        id='duration'
        labelName='client.createOffer.steps.duration.title'
      />
      <ServiceDuration duration={values['duration']} />
      <ErrorMessage component={HandleError} name='duration' />

      {values.duration === 'temporal' ?
        <Temporal values={values} /> :
        values.duration === 'undefined' ?
          <Undefined /> : null
      }
    </>
  )
}

export const FifthStep = ({
  values,
  profile
}) => {
  return (
    <>
      <FieldLabel
        id='contract_type'
        labelName='client.createOffer.steps.contractType.title'
      />
      <ContractType contract_type={values['contract_type']} />
      <ErrorMessage component={HandleError} name='contract_type' />

      <FieldLabel
        id='retribution'
        labelName='client.createOffer.steps.retribution.title'
        topSpace={3}
      />
      <Salary
        currency={profile?.currency}
        profileCountryId={profile?.country_id}
        salaryType={values['salary_type']}
      />
      {!values['salary_type'] && <ErrorMessage component={HandleError} name='salary_type' />}

      {!!values.salary_type && (
        <CustomBudget
          currency={profile?.currency}
        />
      )}
    </>
  )
}

export const SixthStep = () => {
  return (
    <>
      <FieldLabel
        id='name'
        labelName='client.createOffer.steps.name.title'
      />
      <Field
        component={NameInput}
        name='name'
      />
    </>
  )
}
