import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import { updateProfile, uploadProfilePhoto } from 'store/profile/actions'
import { closeWelcomeModal } from 'seniors-first-commons/shared/store/auth/actions'
import WelcomeLayout from 'components/templates/WelcomeLayout'
import { ROUTE } from 'root/constants'


const Welcome = ({
  user,
  helpers,
  profile,
  successLogin,
  destination,
  uploadProfilePhoto,
  updateProfile,
  closeWelcomeModal
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const history = useHistory()

  const onChangeStep = (value) => {
    setCurrentStep(value)
  }

  const onFormSubmit = (values) => {
    const payload = values
    updateProfile(payload)
      .unwrap()
      .then(() => {
        routingService.push(
          history, destination?.pathname
            ? `${destination.pathname}`
            : ROUTE.HOME
        )
      })
  }

  const onCloseWelcomeModal = () => {
    closeWelcomeModal()
  }

  if (profile.finishedWizard) {
    return (
      <Redirect to={{
        pathname: destination?.pathname
          ? destination.pathname
          : routingService.getFinalRoute(ROUTE.HOME),
        search: destination?.search
          ? destination.search
          : ''
      }}
      />
    )
  }

  return (
    <WelcomeLayout
      logicPropsAndMethods={{
        currentStep,
        helpers,
        matchesMobile,
        onChangeStep,
        onCloseWelcomeModal,
        onFormSubmit,
        profile,
        successLogin,
        uploadProfilePhoto,
        uploadingPhoto: profile.loadingPhotoUpdate,
        user
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    destination: state.auth.destination,
    helpers: state.helpers.helpers,
    profile: state.profile.profile,
    successLogin: state.auth.login.successLogin,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  closeWelcomeModal,
  updateProfile,
  uploadProfilePhoto
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
