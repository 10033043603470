import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const EmptyChats = ({ role }) => {
  return (
    <div className={`has-text-centered columns is-vcentered ${classes.noChatsContainer}`}>
      <div className='grey'>
        <h1 className='t2-regular'>
          <FormattedMessageWithClass id='apps.general.chatInbox.title' />
        </h1>
        <p className='has-margin-t-7 p2-regular'>
          <FormattedMessageWithClass
            id={role === 'carer' ?
              'app.general.chatInbox.text' :
              'client.general.chatInbox.text'
            }
          />
        </p>
      </div>
    </div>
  )
}

export default EmptyChats
