import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import { deleteAccount } from 'seniors-first-commons/shared/store/auth/actions'
import authService from 'seniors-first-commons/services/auth-service'
import DeleteProfileLayout from 'seniors-first-commons/components/templates/DeleteProfileLayout'
import { ROUTE } from 'root/constants'


const DeleteProfile = ({
  deletingAccount,
  deleteAccount,
  locale,
  role
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [reason, setReason] = useState('')
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const onDelete = ({ reason }) => {
    setReason(reason)
    setShowConfirmModal(true)
  }

  const onCloseConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const onConfirmAccountDelete = () => {
    deleteAccount({ reason })
      .then(() => authService.logout())
      .finally(() => routingService.push(history, `${ROUTE.LOGIN}`))
      .catch(() => {})
  }

  const onLogoClick = () => routingService.push(history, ROUTE.LOGIN)

  return (
    <DeleteProfileLayout
      isSubmitting={deletingAccount}
      locale={locale}
      matchesMobile={matchesMobile}
      onCloseConfirmModal={onCloseConfirmModal}
      onConfirmAccountDelete={onConfirmAccountDelete}
      onDelete={onDelete}
      onLogoClick={onLogoClick}
      role={role}
      showConfirmModal={showConfirmModal}
    />
  )
}

const mapStateToProps = (state) => ({
  deletingAccount: state.auth.deleteAccount.loading,
  locale: state.intl.locale
})

const mapDispatchToProps = {
  deleteAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProfile)
