import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { PersonIcon, PersonsIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const PricingTable = ({
  hasInfiniteCredits,
  planId='',
  planPrice=5,
  candidatesNumber=1,
  offer='',
  actionText='',
  currency,
  onUsePlan
}) => {

  return (
    <div className={`card shadow-card is-borderless has-padding-lr-5 has-padding-bt-6 ${classes.card}`}>
      {offer &&
        <p className={`success-background white has-padding-lr-7 ${classes.offer}`}>
          {offer}% OFF
        </p>
      }

      <p className='t4-bold' style={{ maxWidth: offer ? '60%' : '' }}>
        <FormattedMessageWithClass
          id={planId === '1' ?
            'client.general.creditsBoard.firstPlan' :
            'client.general.creditsBoard.secondPlan'
          }
        />
      </p>

      <p className='t1-bold primary has-margin-bt-7'>
        <FormattedMessageWithClass id={planPrice} />
        <span>{currency}</span>
      </p>

      <div className={`level is-mobile ${classes.bottomLevel}`}>
        <div className='level-left'>
          {candidatesNumber > 1
            ? (
              <PersonsIcon
                fill='none'
                height='18'
                stroke='#0040AA'
                width='18'
              />
            )
            : (
              <PersonIcon
                fill='none'
                height='18'
                stroke='#0040AA'
                width='18'
              />
            )
          }
          <p className='p2-regular' style={{ marginLeft: '5px' }}>
            {candidatesNumber} {' '}
            <FormattedMessageWithClass id='client.general.creditsBoard.cantidate' />
            {candidatesNumber > 1 ? 's' : ''}
          </p>
        </div>
        <div className='level-right'>
          <button
            className={`button filled ${classes.usePlanButton}`}
            disabled={hasInfiniteCredits}
            onClick={() => onUsePlan(planId)}
            type='button'
          >
            <FormattedMessageWithClass id={actionText} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default PricingTable

