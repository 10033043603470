import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  createJobOffer,
  getOfferDetails,
  getJobOffers,
  duplicateOffer,
  postJobOffer,
  changeOfferArchivedStatus,
  toggleSuccessStatus,
  postJobOfferFromDetails,
  getAvailableOffers,
  toggleOfferCreatedConfirmationModal,
  toggleRequestAssistanceModal,
  toggleRegisterModal
} from './actions'
import HandleDaysShifts from 'seniors-first-commons/services/handleDayShifts'


const initValues = {
  availableOffers: {
    index: null,
    loading: false,
    total: 0
  },
  createdSheet: null,
  creatingJobOffer: false,
  index: {
    currentPage: 0,
    items: [],
    total: 0
  },
  isOfferCreatedConfirmationModalOpened: false,
  isRegisterModalOpen: false,
  isRequestAssistanceModalOpen: false,
  loading: false,
  oneSheetData: null,
  postJobLoading: false,
  success: ''
}

const baseApiToData = (response) => {''
  const data = {...response}

  data['created_at'] = moment(data['created_at']).format('L')
  data['location'] = data.address
  data['duration_from'] = moment(data['duration_from']).format('L')
  data['duration_to'] = data['duration_to']
    ? moment(data['duration_to']).format('L')
    : null

  if (data.posted_until) {
    data.posted_until = moment(data.posted_until, 'YYYY-MM-DD')
      .diff(moment(new Date(), 'YYYY-MM-DD'), 'days')
  }
  else data.posted_until = NaN

  if(data['hiring_modality'] === 'live_in_care'){
    // handle live-in care days & times
    HandleDaysShifts.handleLiveInCareTimes(data)
  }

  else{
    // handle visiting-care days & times
    HandleDaysShifts.handleVisitingCareTimes(data)
  }

  return data
}


const baseApiToServiceSheets = (items) => {
  items.map(sheet => {
    sheet['created_at'] = moment(sheet['created_at']).format('L')
    sheet['duration_from'] = moment(sheet['duration_from']).format('L')
    sheet['duration_to'] = sheet['duration_to'] ?
      moment(sheet['duration_to']).format('L') :
      sheet['duration_to']

    if(sheet.posted_until){
      const today = moment(new Date(), 'YYYY-MM-DD') // today
      const targetDate = moment(sheet.posted_until, 'YYYY-MM-DD') // service sheet expires

      sheet.posted_until = targetDate.diff(today, 'days')
    }
    else sheet.posted_until = NaN

    if(sheet.hiring_modality === 'live_in_care'){
      // handle live-in care days & times
      HandleDaysShifts.handleLiveInCareTimes(sheet)
    }

    else{
      // handle visiting-care days & times
      HandleDaysShifts.handleVisitingCareTimes(sheet)
    }

    return sheet
  })

  return items
}

const reducer = createReducer(initValues, (builder) => {
  builder.addCase(createJobOffer.fulfilled, (state, action) => {
    state.createdSheet = action.payload
  })

  builder.addCase(createJobOffer.pending, (state) => {
    state.creatingJobOffer = true
  })

  builder.addCase(getOfferDetails.fulfilled, (state, action) => {
    const responsePayload = baseApiToData(action.payload)
    state.oneSheetData = responsePayload
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addCase(postJobOffer.fulfilled, (state, action) => {
    state.index.items = state.index.items.map(item => {
      if(item.id === action.payload.id) {
        item.posted_until = moment(action.payload.posted_until, 'YYYY-MM-DD')
          .diff(moment(new Date(), 'YYYY-MM-DD'), 'days')
      }

      return item
    })
  })

  builder.addCase(postJobOfferFromDetails.fulfilled, (state, action) => {
    state.success = 'success.jobOfferPosted'
    state.oneSheetData = {
      ...state.oneSheetData,
      posted_until: moment(action.payload.posted_until, 'YYYY-MM-DD')
        .diff(moment(new Date(), 'YYYY-MM-DD'), 'days')
    }
  })

  builder.addCase(getAvailableOffers.fulfilled, (state, action) => {
    state.availableOffers.total = action.payload.total
    state.availableOffers.index = action.payload.items.map(({name, id, ...rest}) => ({
      label: name,
      value: id,
      ...rest
    }))
  })

  builder.addCase(getAvailableOffers.pending, (state) => {
    state.availableOffers.loading = true
  })

  builder.addCase(getJobOffers.fulfilled, (state, action) => {
    state.index.total = action.payload.total
    state.index.currentPage = action.payload.page
    state.index.items = baseApiToServiceSheets(action.payload.items)
  })

  builder.addCase(toggleOfferCreatedConfirmationModal, (state, action) => {
    state.isOfferCreatedConfirmationModalOpened = action.payload
  })

  builder.addCase(toggleRequestAssistanceModal, (state, action) => {
    state.isRequestAssistanceModalOpen = action.payload
  })

  builder.addCase(toggleRegisterModal, (state, action) => {
    state.isRegisterModalOpen = action.payload
  })

  builder.addMatcher((action) => [
    getAvailableOffers.fulfilled.toString(),
    getAvailableOffers.rejected.toString()
  ].includes(action.type), (state) => {
    state.availableOffers.loading = false
  })

  builder.addMatcher((action) => [
    postJobOffer.pending.toString(),
    postJobOfferFromDetails.pending.toString()
  ].includes(action.type), (state) => {
    state.postJobLoading = true
  })

  builder.addMatcher((action) => [
    postJobOffer.fulfilled.toString(),
    postJobOffer.rejected.toString(),

    postJobOfferFromDetails.fulfilled.toString(),
    postJobOfferFromDetails.rejected.toString()
  ].includes(action.type), (state) => {
    state.postJobLoading = false
  })

  builder.addMatcher((action) => [
    duplicateOffer.fulfilled.toString(),
    changeOfferArchivedStatus.fulfilled.toString()
  ].includes(action.type), (state) => {
    state.success = 'success.successfullChange'
  })

  builder.addMatcher((action) => [
    getJobOffers.pending.toString(),
    getOfferDetails.pending.toString(),
    duplicateOffer.pending.toString(),
    changeOfferArchivedStatus.pending.toString()
  ].includes(action.type), (state) => {
    state.loading = true
  })

  builder.addMatcher((action) => [
    getJobOffers.fulfilled.toString(),
    getJobOffers.rejected.toString(),

    getOfferDetails.fulfilled.toString(),
    getOfferDetails.rejected.toString(),

    duplicateOffer.fulfilled.toString(),
    duplicateOffer.rejected.toString(),

    changeOfferArchivedStatus.fulfilled.toString(),
    changeOfferArchivedStatus.rejected.toString()
  ].includes(action.type), (state) => {
    state.loading = false
  })

  builder.addMatcher((action) => [
    createJobOffer.fulfilled.toString(),
    createJobOffer.rejected.toString()
  ].includes(action.type), (state) => {
    state.creatingJobOffer = false
  })
})

export default reducer
