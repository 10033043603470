import React from 'react'
import Text from 'react-texty'
import { PinIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Address = ({ data, caregiverStatus }) => {
  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <PinIcon
        fill='none'
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='18'
      />
      <Text
        className={`has-margin-l-7 p2-regular ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
        style={{ maxWidth: '85%' }}
      >
        {data.working_location ? data.working_location : data.city}
      </Text>
    </div>
  )
}


export default Address

