import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import services from 'seniors-first-commons/services/api-service'

const { axiosGet, axiosPut } = services

export const getNotifications = createAsyncThunk(
  'shared/notifications/getNotifications',
  ({ page, per_page }, { rejectWithValue }) => {
    return axiosGet('/notifications', { page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getNotReadNotifications = createAsyncThunk(
  'shared/notifications/getNotReadNotifications',
  ({ page, per_page }, { rejectWithValue }) => {
    return axiosGet('/notifications', { page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const changeNotificationReadStatus = createAsyncThunk(
  'shared/notifications/changeNotificationReadStatus',
  ({ notificacion_id }, { rejectWithValue }) => {
    return axiosPut(`/notifications/${notificacion_id}/change-unread-status`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteNotification = createAsyncThunk(
  'shared/notifications/deleteNotification',
  ({ notificacion_id, read }, { rejectWithValue }) => {
    return axiosPut(`/notifications/${notificacion_id}/change-archived-status`)
      .then(response => ({ ...response, wasread: read }))
      .catch(error => rejectWithValue(error))
  }
)

export const toggleSuccessStatus = createAction('shared/notifications/toggleSuccessStatus')
