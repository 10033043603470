import React from 'react'
import Select, { components } from 'react-select'
import { injectIntl } from 'react-intl'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CircleIcon,
  FilledCircleIcon,
  CheckboxIcon,
  CheckboxFilledIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const { Option } = components

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span>
          {props.selectProps.menuIsOpen ?
            <ArrowUpIcon className='button-text-icon' height='20' width='20' /> :
            <ArrowDownIcon className='button-text-icon' height='20' width='20' />
          }
        </span>
      </components.DropdownIndicator>
    )
  )
}

const styles = {
  control: (base, { isFocused }) => ({
    ...base,
    '&:hover': {
      borderColor: '#525860'
    },
    background: 'transparent',
    borderBottom: isFocused ? '1px solid #0040AA' : '1px solid #A6A6A6',
    borderLeft: 'none',
    borderRadius: '0px',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: 'none',
    cursor: 'pointer'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    minWidth: '125px',
    zIndex: '9'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '200px'
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '10px'
  }),
  option: (base, { isSelected }) => ({
    ...base,
    alignItems: 'center',
    background: isSelected ? '#F8F8F8' : '#ffffff',
    color: '#000000',
    display: 'flex',
    fontWeight: 'normal'
  }),
  placeholder: (base) => ({
    ...base,
    color: '#A6A6A6',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '24px'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#000000',
    fontSize: '16px',
    fontWeight: '300'
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '0px'
  })
}

const labelMessage = (intl, label) => {
  return intl.messages[label] ? intl.formatMessage({ id: label }) : label
}

const shapeValue = (label, value) => ({
  label,
  value
})

const formatValue = (intl, v, shouldTranslate = false) => {
  if (v && shouldTranslate) {
    if (Array.isArray(v)) {
      return v.map(element => shapeValue(labelMessage(intl, element.label), element.value))
    } else {
      return shapeValue(labelMessage(intl, v.label), v.value)
    }
  }
  return v
}

const SelectList = ({
  field,
  form,
  options=[],
  addStyles={},
  defaults=[],
  icon=true,
  hasoptionicon=true,
  shouldTranslate=true,
  intl,
  value,
  ...props
}) => {
  const v = field && field.value
    ? field.value
    : value
      ? value
      : null

  const handleChange = (e) => {
    if(form && field) {
      form.setFieldValue(field.name, e)
    }
    else {
      props.onChange(e)
    }
  }

  const handleBlur = () => {
    if(form && field) {
      form.setFieldTouched(field.name)
    }
  }

  return (
    <Select
      autocomplete='off'
      className={classes.list}
      components={{
        DropdownIndicator: icon && DropdownIndicator
        ,
        Option: (props) => {
          const handleIcon = () => {
            if(props.isMulti && !props.isSelected){
              return <CheckboxIcon />
            }

            else if (props.isMulti && props.isSelected){
              return <CheckboxFilledIcon />
            }

            else if (!props.isMulti && !props.isSelected){
              if(hasoptionicon){
                return <CircleIcon />
              }
            }

            else {
              if(hasoptionicon){
                return <FilledCircleIcon />
              }
            }
          }
          return (
            <Option {...props}>
              {handleIcon()}
              {shouldTranslate ?
                <FormattedMessageWithClass
                  className={hasoptionicon ? 'has-margin-l-7' : ''}
                  id={props.data.label}
                /> :
                <span className={hasoptionicon ? 'has-margin-l-7' : ''}>
                  {props.data.label}
                </span>
              }
            </Option>
          )
        }
      }}
      data-lpignore='true'
      defaultValue={defaults}
      onBlur={handleBlur}
      onChange={handleChange}
      options={options}
      styles={{...styles, ...addStyles}}
      value={formatValue(intl, v, shouldTranslate)}
      {...props}
    />
  )
}

export default injectIntl(SelectList)
