import * as Yup from 'yup'
import { PHONE_VALIDATION_MIN_LENGTH } from 'seniors-first-commons/utils/constants'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Custom validation methods
Yup.addMethod(Yup.string, 'phone', function () {
  return this.min(PHONE_VALIDATION_MIN_LENGTH, 'validations.phonetooshort').matches(phoneRegExp, 'validations.invalidphone').required('validations.mandatory')
})

export default Yup
