import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { requestPasswordReset } from 'seniors-first-commons/shared/store/auth/actions'
import PasswordResetLayout from 'seniors-first-commons/components/templates/PasswordResetLayout'
import { ROUTE } from 'root/constants'


const PasswordReset = ({
  locale,
  role,
  resetPasswordData,
  requestPasswordReset
}) => {
  const history = useHistory()

  useEffect(() => {
    document.title = 'Seniors First | Reset password'
  }, [])

  const onResetPassword = (values) => {
    requestPasswordReset(values)
  }

  const onBackFromSuccess = () => {
    routingService.push(history, ROUTE.LOGIN)
  }

  const onResendEmail = () => {
    requestPasswordReset({ email: resetPasswordData.email })
  }

  const onLogoClick = () => routingService.push(history, ROUTE.LOGIN)

  return (
    <PasswordResetLayout
      locale={locale}
      onBackFromSuccess={onBackFromSuccess}
      onLogoClick={onLogoClick}
      onResendEmail={onResendEmail}
      onResetPassword={onResetPassword}
      requestingPasswordReset={resetPasswordData.loading}
      resendingEmail={resetPasswordData.loading}
      role={role}
      showSuccess={resetPasswordData?.email}
    />
  )
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  resetPasswordData: state.auth.resetPassword
})

const mapDispatchToProps = {
  requestPasswordReset
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
