import React, { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import routingService from 'seniors-first-commons/services/routingService'
import { getCaregiverBasicData, rateCaregiver } from 'store/caregivers/actions'
import CaregiverRatingLayout from 'components/templates/CaregiverRatingLayout'
import { ROUTE } from 'root/constants'


const CaregiverRating = ({
  caregiverBasicData,
  caregiver_rating,
  getCaregiverBasicData,
  rateCaregiver,
  ...props
}) => {
  const [submitted, setSubmitted] = useState(false)

  const token = props.match.params.token
  const lang = props.match.params.lang
  const history = useHistory()

  const fetchCaregiverBasicData = useCallback((caregiver_id) => {
    getCaregiverBasicData({ caregiver_id })
  }, [getCaregiverBasicData])

  useEffect(() => {
    fetchCaregiverBasicData(token)
    // eslint-disable-next-line
  }, [fetchCaregiverBasicData])

  const onFormSubmit = (values) => {
    rateCaregiver({ caregiver_id: token, ...values })
      .unwrap()
      .then(() => setSubmitted(true))
      .catch(() => {})
  }

  const onGoHome = () => {
    routingService.push(history, ROUTE.HOME)
  }

  return (
    <CaregiverRatingLayout logicPropsAndMethods={{
      caregiverBasicData,
      caregiver_rating,
      lang,
      onFormSubmit,
      onGoHome,
      submitted
    }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    caregiverBasicData: state.caregivers.caregiverBasicData.index,
    caregiver_rating: state.caregivers.caregiver_rating.data
  }
}

const mapDispatchToProps = {
  getCaregiverBasicData,
  rateCaregiver
}

export default connect(mapStateToProps, mapDispatchToProps)(CaregiverRating)
