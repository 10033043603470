import React from 'react'
import Radios from 'seniors-first-commons/components/atoms/Radios'


const ServiceDuration = ({duration}) => {
  const durationItems = [
    {
      name: 'duration',
      text: 'client.createOffer.steps.duration.undefined',
      value: 'undefined'
    },
    {
      name: 'duration',
      text: 'client.createOffer.steps.duration.temporal',
      value: 'temporal'
    }
  ]

  return (
    <Radios content={durationItems} input={duration} />
  )
}

export default ServiceDuration
