import React from 'react'
import { FormattedMessage } from 'react-intl'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'


export const InternalModal = ({ isOpen, onClose }) => {
  return (
    <CustomModal
      onClose={onClose}
      open={isOpen}
      shouldCloseOnOverlayClick
      styles={{ maxWidth: '450px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <h2 className='title'>
          <FormattedMessage id='app.boarding.offeredservices.internalmodal.title' />
        </h2>
        <p className='p1-bold has-margin-b-4'>
          <FormattedMessage id='app.boarding.offeredservices.internalmodal.text' />
        </p>
        <button
          className='button filled'
          onClick={onClose}
          style={{ height: '52px', width: '160px' }}
          type='button'
        >
          <FormattedMessage id='app.boarding.offeredservices.internalmodal.button' />
        </button>
      </div>
    </CustomModal>
  )
}


export const ExternalModal = ({ isOpen, onClose }) => {
  return (
    <CustomModal
      onClose={onClose}
      open={isOpen}
      shouldCloseOnOverlayClick
      styles={{ maxWidth: '450px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <h2 className='title'>
          <FormattedMessage id='app.boarding.offeredservices.externalmodal.title' />
        </h2>
        <p className='is-size-6 has-margin-b-4'>
          <FormattedMessage id='app.boarding.offeredservices.externalmodal.text' />
        </p>
        <button
          className='button filled'
          onClick={onClose}
          style={{ height: '52px', width: '160px' }}
          type='button'
        >
          <FormattedMessage id='app.boarding.offeredservices.externalmodal.button' />
        </button>
      </div>
    </CustomModal>
  )
}
