import React, { Fragment } from 'react'
import { pathOr } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import {
  TimesIcon,
  CheckIcon,
  HelpIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'
import { ArrowDownIcon, ArrowUpIcon } from 'seniors-first-commons/components/atoms/images/icons'

const Icon = ({ status=true }) => {
  if(status === true || status === 'yes') {
    return (
      <CheckIcon
        fill='none'
        height='24'
        stroke='#0080F5'
        strokeWidth='2'
        width='24'
      />
    )
  }
  else if (status === false || status === 'no') {
    return (
      <TimesIcon
        fill='none'
        height='24'
        stroke='#ED0202'
        strokeWidth='2'
        width='24'
      />
    )
  }
  else {
    return (
      <div className='custom-tooltip' style={{ display: 'flex' }}>
        <HelpIcon
          fill='none'
          height='24'
          stroke='#0080F5'
          width='24'
        />
        <FormattedMessageWithClass
          className='tooltip-content p3-bold white'
          id='client.caregiverProfile.contractsCard.partially'
          placement='top'
        />
      </div>
    )
  }
}

const ContractsCardBody = ({ data: { ...data }, currency }) => {
  return (
    <div className='card-content has-padding-b-5 has-padding-lrt-3'>
      <div className='columns'>
        <div className='column'>
          <h3 className='t4-bold primary has-margin-b-6'>
            <FormattedMessageWithClass id='client.caregiverProfile.contractsCard.acceptWork' />
          </h3>
          <div className={`has-margin-b-7 ${classes.level}`}>
            <Icon status={data.accepts_work_with_company} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.employed'
            />
          </div>

          <div className={`has-margin-b-7 ${classes.level}`}>
            <Icon status={data.accepts_work_with_autonomous} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.autonomous'
            />
          </div>

          <div className={`has-margin-b-7 ${classes.level}`}>
            <Icon status={data.has_work_permit} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.has-work-permit'
            />
          </div>

          <div className={classes.level}>
            <Icon status={data.has_criminal_record_certificate} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.criminal-record'
            />
          </div>
        </div>
        <div className='column grey'>
          <h3 className='t4-bold primary has-margin-b-6'>
            <FormattedMessageWithClass
              id='client.caregiverProfile.contractsCard.economicConditions'
            />
          </h3>

          {data.salary || data.hourly_rate ?
            <Fragment>
              {data.salary &&
                <div>
                  <p className='p1-regular'>
                    <span>{data.salary}{currency}</span>
                    <FormattedMessageWithClass
                      id='client.caregiverProfile.contractsCard.perMonth'
                    />
                  </p>
                </div>
              }

              {data.hourly_rate &&
                <div className='has-margin-t-7'>
                  <p className='p1-regular'>
                    <span>{data.hourly_rate}{currency}</span>
                    <FormattedMessageWithClass id='client.caregiverProfile.contractsCard.perHour' />
                  </p>
                </div>
              }
            </Fragment> :
            <FormattedMessageWithClass
              className='p1-regular'
              id='client.caregiverProfile.contractsCard.min-wage'
            />
          }

        </div>
      </div>

      <hr className={classes.divider} />

      <p className='t4-bold primary has-margin-b-6'>
        <FormattedMessageWithClass
          id='client.caregiverProfile.contractsCard.otherConditions'
        />
      </p>
      <div className='columns'>
        <div className='column'>
          <div className={classes.level}>
            <Icon status={data.smoke_free} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.smoke'
            />
          </div>

          <div className={`has-margin-t-7 ${classes.level}`}>
            <Icon status={data.pet_friendly} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.pets'
            />
          </div>

          <div className={`has-margin-t-7 ${classes.level}`}>
            <Icon status={data.accept_opposite_sex} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.oppositeSex'
            />
          </div>

          <div className={`has-margin-t-7 ${classes.level}`}>
            <Icon status={data.accept_more_people} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.otherResidents'
            />
          </div>
        </div>
        <div className='column'>
          <div className={`${classes.level}`}>
            <Icon status={data.accept_driving} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.driving'
            />
          </div>

          <div className={`has-margin-t-7 ${classes.level}`}>
            <Icon status={data.personal_hygiene} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.personalOffers'
            />
          </div>

          <div className={`has-margin-t-7 ${classes.level}`}>
            <Icon status={data.movilization_bodily || data.movilization_crane} />
            <FormattedMessageWithClass
              className='p1-regular grey has-margin-l-7'
              id='client.caregiverProfile.contractsCard.mobilizations'
            />
          </div>

          {data.movilization_crane &&
            <FormattedMessageWithClass
              className='tag is-rounded p3-bold primary'
              id='app.search.caregiverdetail.contractsCard.lift'
            />
          }

          {data.movilization_bodily &&
            <span className='tag is-rounded p3-bold primary'>
              <FormattedMessageWithClass id='app.search.caregiverdetail.contractsCard.manual' />
            </span>
          }

          {data.movilization_bodily && (data.movilization_min || 5) &&
            <span className='tag is-rounded p3-bold primary'>
              <FormattedMessageWithClass id='app.components.date.from' />:
              <span style={{ marginLeft: '3px' }}>{data.movilization_min || 5} kg</span>
            </span>
          }

          {data.movilization_bodily && data.movilization_max &&
            <span className='tag is-rounded p3-bold primary'>
              <FormattedMessageWithClass id='app.components.date.to' />:
              <span style={{ marginLeft: '3px' }}>{data.movilization_max} kg</span>
            </span>
          }
        </div>
      </div>
    </div>
  )
}

const CaregiverContractsCard = ({
  matchesMobile,
  isSelected,
  data,
  currency,
  onToggleCard
}) => {
  const hiringCondition = pathOr({}, ['hiring_condition'], data)

  return (
    <div className={`card has-margin-t-4 ${classes.card}`}>
      <div
        className={`card-header primary-light-background has-padding-l-3 has-padding-bt-5 
          ${matchesMobile ? 'pointer' : ''}`}
        onClick={() => onToggleCard('contracts')}
      >
        <div className='columns is-mobile is-vcentered full-width '>
          <div className='column'>
            <p className='t2-bold white'>
              <FormattedMessageWithClass id='client.caregiverProfile.contractsCard.title' />
            </p>
          </div>

          {matchesMobile &&
              <div className='column has-text-right pointer center'>
                {isSelected ?
                  <ArrowUpIcon  height='24px' stroke='#FFF' width='24px'/> :
                  <ArrowDownIcon height='24px' stroke='#FFF' width='24px' />
                }
              </div>
          }
        </div>
      </div>
      {!matchesMobile
        ? <ContractsCardBody
          currency={currency}
          data={{...hiringCondition}}
        />
        : matchesMobile && isSelected
          ? <ContractsCardBody
            currency={currency}
            data={{ ...hiringCondition }}
          />
          : null
      }
    </div>
  )
}

export default CaregiverContractsCard
