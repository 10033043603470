import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'bulma-toast'
import { injectIntl } from 'react-intl'
import { toggleSuccessStatus as toggleOffersSuccess } from 'store/jobOffers/actions'
import { toggleSuccessStatus as toggleCaregiverSuccess } from 'store/caregivers/actions'
import { toggleSuccessStatus as toggleProfileSuccess} from 'store/profile/actions'
import { toggleSuccessStatus as toggleSpecialServiceSuccess } from 'store/profile/actions'
import { toggleSuccessStatus as toggleBillingSuccess } from 'store/billing/actions'
import { toggleSuccessStatus as toggleLayoutSuccess } from 'store/layouts/actions'
import { toggleSuccessStatus as toggleUserSuccess }
from 'seniors-first-commons/shared/store/user/actions'
import { toggleSuccessStatus as toggleAuthSuccessStatus } from 'seniors-first-commons/shared/store/auth/actions'


const Toast = ({
  successMessage,
  toggleCaregiverSuccess,
  toggleOffersSuccess,
  toggleProfileSuccess,
  toggleSpecialServiceSuccess,
  toggleBillingSuccess,
  toggleUserSuccess,
  toggleAuthSuccessStatus,
  toggleLayoutSuccess,
  ...props
}) => {
  if(!!successMessage){
    setTimeout(() => {
      toggleOffersSuccess('')
      toggleCaregiverSuccess('')
      toggleProfileSuccess('')
      toggleSpecialServiceSuccess('')
      toggleBillingSuccess('')
      toggleUserSuccess('')
      toggleAuthSuccessStatus('')
      toggleLayoutSuccess('')
    }, 3000)

    toast({
      closeOnClick: false,
      dismissible: true,
      message: `<span class='p1-bold white'>${props.intl.formatMessage({ id: successMessage })}</span>`,
      pauseOnHover: true,
      position: 'bottom-center',
      type: 'is-success'
    })

    const closeButton = document.querySelector('.delete')
    closeButton.addEventListener('click', () => {
      toggleCaregiverSuccess('')
      toggleOffersSuccess('')
      toggleProfileSuccess('')
      toggleSpecialServiceSuccess('')
      toggleBillingSuccess('')
      toggleUserSuccess('')
      toggleAuthSuccessStatus('')
      toggleLayoutSuccess('')
    })
  }
  return <></>
}

const mapStateToProps = (state) => {
  return {
    successMessage:
    state.jobOffers.success ||
    state.caregivers.success ||
    state.profile.success ||
    state.user.success ||
    state.notifications.success ||
    state.billing.billing.success ||
    state.auth.success ||
    state.layouts.success
  }
}

const mapDispatchToProps = {
  toggleAuthSuccessStatus,
  toggleBillingSuccess,
  toggleCaregiverSuccess,
  toggleLayoutSuccess,
  toggleOffersSuccess,
  toggleProfileSuccess,
  toggleSpecialServiceSuccess,
  toggleUserSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Toast))
