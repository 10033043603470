import React from 'react'
import withPayment from 'hoc/withPaymentMethod'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import PricingTable from 'components/molecules/PricingTable'
import BoardHeader from 'components/molecules/CreditsBoardHeader'
import { MessageIcon, SendIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const CreditsBoard = ({
  withHeader=true,
  withUserInfo=true,
  matchesMobile,
  user,
  onUsePlan,
  ...props
}) => {
  const { statistics } = props
  const {
    recent_direct_offers_accepted,
    recent_direct_offers_interested_waiting,
    recent_direct_offers_accepted_waiting,
    recent_direct_offers_pending
  } = statistics

  let activeDirectOffers = recent_direct_offers_accepted
  activeDirectOffers += recent_direct_offers_interested_waiting
  activeDirectOffers += recent_direct_offers_accepted_waiting
  activeDirectOffers +=recent_direct_offers_pending

  let directOfferNewCandidates = recent_direct_offers_accepted
  directOfferNewCandidates += recent_direct_offers_interested_waiting
  directOfferNewCandidates += recent_direct_offers_accepted_waiting

  return (
    <div className={`card shadow-card is-borderless ${classes.card}`}>
      {withHeader &&
        <BoardHeader
          matchesMobile={matchesMobile}
          statistics={statistics}
          user={user}
          withUserInfo={withUserInfo}
        />
      }

      <div className={`card-content has-padding-lr-${matchesMobile ? '7' : '5'} has-padding-bt-2`}>
        <div className='columns'>
          <div className={`column is-4 ${matchesMobile ? 'has-padding-lr-3' : ''}`}>
            <div className='has-margin-b-6 is-flex' style={{ alignItems: 'center' }}>
              <MessageIcon
                fill='none'
                height='20'
                stroke='#0040AA'
                width='20'
              />
              <FormattedMessageWithClass
                className='t3-bold primary has-margin-l-7'
                id='client.general.creditsBoard.postedOffers'
              />
            </div>

            <div className='level is-mobile'>
              <FormattedMessageWithClass
                className='level-left p2-regular'
                id='client.general.creditsBoard.activePosts'
              />
              <span className='level-right t3-bold black'>
                {statistics?.active_posts}
              </span>
            </div>

            <div className='level is-mobile'>
              <FormattedMessageWithClass
                className='level-left p2-regular'
                id='client.general.creditsBoard.pendingCandidates'
              />
              <span className='level-right t3-bold black'>
                {statistics.new_applicants_waiting ?? 0}
              </span>
            </div>


            <div className='has-margin-b-6 is-flex' style={{ alignItems: 'center' }}>
              <SendIcon
                fill='none'
                height='20'
                stroke='#0040AA'
                width='20'
              />
              <FormattedMessageWithClass
                className='t3-bold primary has-margin-l-7'
                id='client.general.creditsBoard.directOffers'
              />
            </div>

            <div className='level is-mobile'>
              <FormattedMessageWithClass
                className='level-left p2-regular'
                id='client.general.creditsBoard.activePosts'
              />
              <span className='level-right t3-bold black'>
                {activeDirectOffers}
              </span>
            </div>

            <div className='level is-mobile'>
              <FormattedMessageWithClass
                className='level-left p2-regular'
                id='client.general.creditsBoard.pendingCandidates'
              />
              <span className='level-right t3-bold black'>
                {directOfferNewCandidates}
              </span>
            </div>
          </div>
          <div className='column is-2 is-hidden-mobile'></div>
          <div className='column is-half'>
            <div className='has-margin-b-4'>
              <PricingTable
                actionText='client.general.creditsBoard.individualeButton'
                candidatesNumber={1}
                currency={props.profile?.currency}
                hasInfiniteCredits={statistics?.credits === -1}
                onUsePlan={onUsePlan}
                planId='1'
                planPrice='client.general.creditsBoard.firstPlan.price'
              />
            </div>
            <PricingTable
              actionText='client.general.creditsBoard.packButton'
              candidatesNumber={5}
              currency={props.profile?.currency}
              hasInfiniteCredits={statistics?.credits === -1}
              offer={20}
              onUsePlan={onUsePlan}
              planId='2'
              planPrice='client.general.creditsBoard.secondPlan.price'
            />
          </div>
        </div>
      </div>
    </div>
  )
}


export default withPayment(CreditsBoard)
