import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import InitialLayout from 'seniors-first-commons/components/templates/InitialLayout'
import DeleteProfileForm from 'seniors-first-commons/components/organisms/DeleteProfileForm'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import { CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'


const DeleteProfileLayout = ({
  locale,
  role,
  isSubmitting,
  showConfirmModal,
  onCloseConfirmModal,
  onConfirmAccountDelete,
  onDelete,
  onLogoClick
}) => {
  return (
    <InitialLayout hasFooter={false} locale={locale} onLogoClick={onLogoClick} role={role}>
      <p className='t1-bold primary has-text-centered has-margin-b-7'>
        <FormattedMessageWithClass
          id='app.deleteaccount.form.title'
        />
      </p>
      <DeleteProfileForm onFormSubmit={onDelete} />

      <ConfirmModal
        details='app.deleteaccount.form.confirmation'
        Icon={CloseCircle}
        loading={isSubmitting}
        onCancel={onCloseConfirmModal}
        onClose={onCloseConfirmModal}
        onConfirm={onConfirmAccountDelete}
        show={showConfirmModal}
        title='app.deleteaccount.form.confirmation.title'
      />
    </InitialLayout>
  )
}

export default DeleteProfileLayout
