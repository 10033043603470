import React from 'react'
import NotificationText from 'seniors-first-commons/components/atoms/NotificationParts/Text'
import NotificationPhoto from 'seniors-first-commons/components/atoms/NotificationParts/Photo'
import NotificationDropdown from 'seniors-first-commons/components/atoms/NotificationParts/Dropdown'
import classes from './index.module.css'


const OneNotification = ({
  matchesMobile,
  image,
  date,
  details,
  read,
  notification_id,
  onChangeArchivedStatus,
  onChangeReadStatus,
  lastOneRef
}) => {
  const formatDate = date.fromNow()

  const onNotificationBodyClick = (e) => {
    const notificationId = e.target.getAttribute('notification_id')

    if (notificationId && !read) {
      onChangeReadStatus(notificationId)
    }
  }

  return (
    <div
      className={`media full-width is-marginless has-padding-bt-6 ${matchesMobile ? 'is-paddingless-lr' : 'has-padding-lr-3'} ${classes.level} ${!read ? classes.readNotification : ''}`}
      ref={lastOneRef}
    >
      <div className='media-left'>
        <NotificationPhoto
          image={image}
          matchesMobile={matchesMobile}
          notificationId={notification_id}
          onChangeReadStatus={onChangeReadStatus}
          read={read}
        />
      </div>

      <div className='media-content' style={{ overflowWrap: 'break-word' }}>
        <NotificationText
          details={details}
          formatDate={formatDate}
          onNotificationBodyClick={onNotificationBodyClick}
        />
      </div>

      <div className='media-right'>
        <NotificationDropdown
          notificationId={notification_id}
          onChangeArchivedStatus={onChangeArchivedStatus}
          onChangeReadStatus={onChangeReadStatus}
          read={read}
        />
      </div>
    </div>
  )
}

export default OneNotification
