import React from 'react'

import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { Phone } from './inputs'
import { Formik, Form, Field } from 'formik'
import { jobOfferRequestAssistanceSchema } from 'schemas/jobOfferRequestAssistance'
import { getCountryByCode } from 'seniors-first-commons/utils/helpers'
import { PHONE_VALIDATION_MIN_LENGTH } from 'seniors-first-commons/utils/constants'
import PhoneModel from 'seniors-first-commons/shared/models/phone'
import classes from './index.module.css'
import { useClipBody } from 'seniors-first-commons/utils/useClipBody'

const RequestAsistancePublicOfferModal = ({
  open,
  onClose,
  onConfirm,
  countries,
  phone,
  country_id
}) => {
  useClipBody(open, true, false) // The body will remain clipped until the next modal unclips it
  const defaultCountry = countries
    ? countries.find(item => item.value == country_id)
    : ''
  const countryCode = defaultCountry
    ? getCountryByCode(defaultCountry?.code)[3]
    : ''
  const initialValues = new PhoneModel(phone, countryCode)
  const validationSchema = jobOfferRequestAssistanceSchema

  return (
    <CustomModal
      backdropClasses='has-page-width has-page-height fixed-top-left'
      hasCloseButton={false}
      modalClasses='overflow-auto'
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick={false}
      styles={{ maxWidth: '542px', minHeight: '300px', width: '95%'}}
    >
      <div className={classes.formContainer}>
        <div className={`has-text-centered ${classes.info}`}>
          <img alt='request asistance' className={classes.icon} src='/request_assistance_icon.png' />
          <p className={`t2-bold has-text-centered primary ${classes.title}`}>
            <FormattedMessageWithClass id='client.search.requestAssistanceModal.title' />
          </p>
          <p className={`p1-bold ${classes.text}`}>
            <FormattedMessageWithClass id='client.search.requestAssistanceModal.text' />
          </p>
          <p className={`p1-bold ${classes.text2}`}>
            <FormattedMessageWithClass id='client.search.requestAssistanceModal.text2' />
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onConfirm}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <>
                {(!phone || phone.length < PHONE_VALIDATION_MIN_LENGTH) &&
                <Form>
                  <div className='has-margin-b-3'>
                    <Field component={Phone} name='phone' />
                  </div>
                </Form> }

                <div className='columns'>
                  <div className='column'>
                    <button
                      className={'button outlined main-button full-width'}
                      onClick={onClose}
                    >
                      <FormattedMessageWithClass
                        id={'client.search.requestAssistanceModal.cancel'}
                      />
                    </button>
                  </div>
                  <div className='column'>
                    <button
                      className={`
                        button filled main-button full-width
                        ${isSubmitting ? 'is-loading' : ''} 
                      `}
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      type='submit'
                    >
                      <FormattedMessageWithClass id='client.search.requestAssistanceModal.confirm' />
                    </button>
                  </div>
                </div>
              </>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}


export default RequestAsistancePublicOfferModal
