import React, { useState } from 'react'
import { ErrorMessage } from 'formik'
import Input from 'seniors-first-commons/components/atoms/Input'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import PasswordStrengthMeter from 'seniors-first-commons/components/atoms/PasswordStrengthMeter'
import { PencilEdit, EyeIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


export const Firstname = ({ ...props }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id={props.field.name}
        labelName='app.register.form.firstname'
        labelType='p2-bold'
        topSpace={2}
      />
      <Input
        className={`input ${classes.input}`}
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const Lastname = ({ ...props }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id={props.field.name}
        labelName='app.register.form.surname'
        labelType='p2-bold'
        topSpace={2}
      />
      <Input
        className={`input ${classes.input}`}
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const Email = ({ ...props }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id={props.field.name}
        labelName='app.register.form.email'
        labelType='p2-bold'
        topSpace={2}
      />
      <Input
        className={`input ${classes.input}`}
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const Password = ({ ...props }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id={props.field.name}
        labelName='app.register.form.password'
        labelType='p2-bold'
        topSpace={2}
      />
      <PasswordStrengthMeter
        class={`input ${classes.input}`}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const ConfirmPassword = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState('password')

  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id={props.field.name}
        labelName='app.register.form.confirmpassword'
        labelType='p2-bold'
        topSpace={2}
      />
      <Input
        className={`input ${classes.input}`}
        extraIconClass={classes.passwordIcon}
        icon={(
          <EyeIcon
            fill={showPassword === 'password' ? '#A8A8A8' : '#0040AA'}
            height='24'
            onMouseEnter={() => setShowPassword('text')}
            onMouseLeave={() => setShowPassword('password')}
            width='24'
          />
        )}
        maxLength={255}
        type={showPassword}
        {...props}
      />
      <ErrorMessage
        bottomSpace={5}
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}
