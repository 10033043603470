import React, { useEffect, useState } from 'react'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { connect } from 'react-redux'
import { socialLogin, register, login } from 'seniors-first-commons/shared/store/auth/actions'
import { updateProfile } from 'store/profile/actions'
import { useClipBody } from 'seniors-first-commons/utils/useClipBody'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import styles from './index.module.css'
import { useIntl } from 'react-intl'

const RoleConfirmationModal = ({
  open,
  onClose,
  shouldClipBody = true,
  shouldUnclipBody = true // This prevents the body from scrolling when the modal is open
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const { locale } = useIntl()
  useClipBody(open, shouldClipBody, shouldUnclipBody)
  const caregiversUrl = `${process.env.REACT_APP_CAREGIVERS_URL}/${locale}`
  useEffect(() => {
    if (showMoreInfo) {
      setTimeout(() => {
        //open a new tab with the caregivers url
        window.open(caregiversUrl, '_blank')
      }, 3000)
    }
  }, [showMoreInfo])

  return (
    <CustomModal
      backdropClasses='has-page-width has-page-height fixed-top-left'
      hasCloseButton={false}
      modalClasses='overflow-auto'
      open={open}
      shouldCloseOnOverlayClick={false}
      styles={{ maxWidth: '600px', minHeight: '300px', width: '95%' }}
    >
      <div className={styles.messageContainer}>
        {!showMoreInfo && (
          <>
            <p className='t1-bold primary'>
              <FormattedMessageWithClass
                id={'client.createPublicOffer.roleConfirmation'}
              />
            </p>
            <div>
              <button
                className='button filled main-button full-width'
                onClick={onClose}
                type='button'
              >
                <FormattedMessageWithClass
                  id={'client.createPublicOffer.option.lookingForCare'}
                />
              </button>
            </div>
            <div>
              <button
                className={'button outlined main-button full-width'}
                onClick={() => setShowMoreInfo(true)}
                type='button'
              >
                <FormattedMessageWithClass id='client.createPublicOffer.option.lookingForAJob' />
              </button>
            </div>
          </>
        )}

        {showMoreInfo && (
          <>
            <p className='t1-bold primary'>
              <FormattedMessageWithClass
                id={'client.createPublicOffer.willRedirect'}
              />
            </p>
            <a
              href={caregiversUrl}
              target='_blank'
            >
              <FormattedMessageWithClass id='client.createPublicOffer.clickHereIfNotRedirected' />
            </a>
          </>
        )}
      </div>
    </CustomModal>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    registerData: state.auth.register
  }
}

const mapDispatchToProps = {
  login,
  register,
  socialLogin,
  updateProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleConfirmationModal)
