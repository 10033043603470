import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import services from 'seniors-first-commons/services/api-service'

const { axiosGet, axiosUpload, axiosPut, axiosPost } = services


const baseDataToApi = (payload) => {
  const data = {}
  data['country_id'] = payload.country.value
  data['address'] = payload.address.formatted_address
  data['address_lat'] = payload.address.address_lat
  data['address_lng'] = payload.address.address_lng
  data['phone'] = payload.phone

  return data
}

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  (payload, { rejectWithValue }) => {
    return axiosGet('/clients')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseDataToApi(payload)
    return axiosPut('/clients', requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const uploadProfilePhoto = createAsyncThunk(
  'profile/uploadProfilePhoto',
  ({ photo }, { rejectWithValue }) => {
    const photoBlob = new FormData()
    photoBlob.append('photo', photo)

    return axiosUpload('/clients/photo', photoBlob)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const askForSpecialService = createAsyncThunk(
  'profile/askForSpecialService',
  ({ from }, { rejectWithValue }) => {
    return axiosPost('/notifications/special-services')
      .then(response => ({ from, response }))
      .catch(error => rejectWithValue(error))
  }
)

export const getStatistics = createAsyncThunk(
  'profile/getStatstics',
  (payload, { rejectWithValue }) => {
    return axiosGet('/clients/statistics')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const sendAnayetRequest = createAsyncThunk(
  'profile/sendAnayetRequest',
  (payload, { rejectWithValue }) => {
    return axiosPost('/notifications/anayet-request')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const clear = createAction('profile/clear')

export const toggleSuccessStatus = createAction('profile/toggleSuccessStatus')
