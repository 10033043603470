import React from 'react'
import { pathOr, path } from 'ramda'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import Select from 'seniors-first-commons/components/molecules/Select'
import { AvailabilityTable } from 'seniors-first-commons/components/organisms/Availability/AvailabilityTable'
import { handleServiceType } from 'seniors-first-commons/utils/constants'
import { ArrowDownIcon, ArrowUpIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ServicesCardBody = ({
  matchesMobile,
  translations,
  ...data
}) => {

  const select_list_custom_styles = {
    option: (base) => ({
      ...base,
      background: '#ffffff',
      borderBottom: '1px solid #F1F1F1',
      color: '#000000',
      fontSize: '14px',
      fontWeight: 'normal',
      paddingLeft: '5px'
    })
  }

  return (
    <div className='card-content has-padding-b-5 has-padding-lrt-3'>
      <div className='columns'>
        <div className='column'>
          <h3 className='t4-bold primary has-margin-b-6'>
            <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.generalInfo' />
          </h3>
          <div className='grey'>
            <p className='p1-bold'>
              <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.minHiringHours' />
            </p>
            <p className='p1-regular'>
              {data.hiringMinHours}
            </p>
          </div>

          <div className='has-margin-t-7 grey'>
            <p className='p1-bold'>
              <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.maxHiringHours' />
            </p>
            <p className='p1-regular'>
              {data.radioKm} KM
            </p>
          </div>

          <div className='has-margin-t-7 grey'>
            <p className='p1-bold'>
              <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.modality' />
            </p>
            <p className='p1-regular'>
              {data.modality}
            </p>
          </div>
        </div>
        <div className='column'>
          <h3 className='t4-bold primary has-margin-b-6'>
            <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.experienceArea' />
          </h3>
          {data.offered_service.allServices['home'].length ?
            <div className={classes.level}>
              <span className={classes.iconWrapper}>
                {handleServiceType(1, '24', '24')['image']}
              </span>
              <Select
                addStyles={{...select_list_custom_styles}}
                hasoptionicon={false}
                isSearchable={false}
                options={data.offered_service.allServices.home}
                placeholder=''
                shouldTranslate={false}
                value={{label: translations.home}}
              />
            </div> : null
          }

          {data.offered_service.allServices['companionship'].length ?
            <div className={classes.level}>
              <span className={classes.iconWrapper}>
                {handleServiceType(2, '24', '24')['image']}
              </span>
              <Select
                addStyles={{...select_list_custom_styles}}
                hasoptionicon={false}
                isSearchable={false}
                options={data.offered_service.allServices.companionship}
                placeholder=''
                shouldTranslate={false}
                value={{label: translations.companionship}}
              />
            </div> : null
          }

          {data.offered_service.allServices['personalcare'].length ?
            <div className={classes.level}>
              <span className={classes.iconWrapper}>
                {handleServiceType(3, '24', '24')['image']}
              </span>
              <Select
                addStyles={{...select_list_custom_styles}}
                hasoptionicon={false}
                isSearchable={false}
                options={data.offered_service.allServices.personalcare}
                placeholder=''
                shouldTranslate={false}
                value={{label: translations.personalcare}}
              />
            </div> : null
          }

          {data.offered_service.allServices['nursing'].length ?
            <div className={classes.level}>
              <span className={classes.iconWrapper}>
                {handleServiceType(4, '24', '24')['image']}
              </span>
              <Select
                addStyles={{...select_list_custom_styles}}
                hasoptionicon={false}
                isSearchable={false}
                options={data.offered_service.allServices.nursing}
                placeholder=''
                shouldTranslate={false}
                value={{label: translations.nursing}}
              />
            </div> : null
          }
        </div>
      </div>

      <hr className={classes.divider} />

      <h3 className='t4-bold primary has-margin-b-5'>
        <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.availability' />
      </h3>
      <div className={classes.tableContainer}>
        <AvailabilityTable
          additionalClass={classes.table}
          availability={data.workingDayTimes}
          matchesMobile={matchesMobile}
          onClick={() => {}}
          preSetDay={'all'}
          preSetTime={null}
          tableHeadStyle={{
            background: '#F1F1F1',
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        />
      </div>
    </div>
  )
}


const ServicesCard = ({
  data,
  matchesMobile,
  isSelected,
  additionalClass = '',
  onToggleCard
}) => {
  const workingDayTimes = pathOr([], ['offered_service', 'day_times'], data)
  const hiringMinHours = path(['offered_service', 'hiring_min_hours'], data)
  const radioKm = path(['offered_service', 'radio_km'], data)
  const internal_mode = path(['offered_service', 'internal_mode'], data)
  const external_mode = path(['offered_service', 'external_mode'], data)

  const translations = {
    companionship: useFormatMessage('apps.general.jobOffer.services.2'),
    home: useFormatMessage('apps.general.jobOffer.services.1'),
    live_inCare: useFormatMessage('client.caregiverProfile.servicesCard.modality.live-in-care'),
    nursing: useFormatMessage('apps.general.jobOffer.services.4'),
    personalcare: useFormatMessage('apps.general.jobOffer.services.3'),
    visitingCare: useFormatMessage('client.caregiverProfile.servicesCard.modality.visiting-care')
  }

  const modality = () => {
    const modalities = []

    if (internal_mode) {
      modalities.push(translations.live_inCare)
    }
    if (external_mode) {
      modalities.push(translations.visitingCare)
    }

    return modalities.join(', ')
  }

  return (
    <div className={`card ${classes.card} ${additionalClass}`}>
      <div
        className={`card-header primary-light-background has-padding-l-3 has-padding-bt-5 
        ${matchesMobile ? 'pointer' : ''}`}
        onClick={() => onToggleCard('services')}
      >
        <div className='columns is-mobile is-vcentered full-width '>
          <div className='column'>
            <p className='t2-bold white'>
              <FormattedMessageWithClass id='client.caregiverProfile.servicesCard.title' />
            </p>
          </div>
          {matchesMobile &&
            <div className='column has-text-right'>
              {isSelected ?
                <ArrowUpIcon  height='24px' stroke='#FFF' width='24px'/> :
                <ArrowDownIcon height='24px' stroke='#FFF' width='24px' />
              }
            </div>
          }
        </div>
      </div>

      {!matchesMobile
        ? <ServicesCardBody
          hiringMinHours={hiringMinHours}
          matchesMobile={matchesMobile}
          modality={modality()}
          radioKm={radioKm}
          translations={translations}
          workingDayTimes={workingDayTimes}
          {...data}
        />
        : matchesMobile && isSelected
          ? <ServicesCardBody
            hiringMinHours={hiringMinHours}
            matchesMobile={matchesMobile}
            modality={modality()}
            radioKm={radioKm}
            translations={translations}
            workingDayTimes={workingDayTimes}
            {...data}
          />
          : null
      }
    </div>
  )
}

export default ServicesCard
