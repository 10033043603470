import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import dataURLtoBlob from 'blueimp-canvas-to-blob'

class ImageCropper extends React.Component {
  state = {
    scale: 1.2
  };

  onClickSave = () => {
    return new Promise((resolve) => {
      if (this.editor) {
        const canvas = this.editor.getImage()

        if (!HTMLCanvasElement.prototype.toBlob) {
          const blob = dataURLtoBlob(canvas)
          this.props.onSave(blob).then(() => resolve())
        }else {
          canvas.toBlob(blob => {
            this.props.onSave(blob).then(() => resolve())
          })
        }

      }
    })
  };

  onStepChange = e => this.setState({ scale: e.target.value });

  setEditorRef = editor => (this.editor = editor);

  render() {
    const { image } = this.props
    const { scale } = this.state

    return image ? (
      <>
        <AvatarEditor
          border={50}
          borderRadius={150}
          className='cropper-editor'
          height={250}
          image={image}
          ref={this.setEditorRef}
          scale={+scale}
          style={{
            height: '100%',
            maxHeight: '450px',
            maxWidth: '450px',
            width: '100%'
          }}
          width={250}
        />
        <div className='column'>
          <label style={{ margin: '0 1rem', verticalAlign: 'text-bottom'}}>
            Zoom
          </label>
          <input
            max='2'
            min='1'
            name='scale'
            onChange={this.onStepChange}
            step='0.01'
            type='range'
            value={scale}
          />
        </div>
        {this.props.render(this.onClickSave)}
      </>
    ) : null
  }
}

export default ImageCropper
