import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import InitialLayout from 'seniors-first-commons/components/templates/InitialLayout'
import classes from './index.module.css'


const VerifyEmailLayout = ({
  verificationStatus,
  role,
  locale,
  error,
  onRedirectToLogin,
  onLogoClick
}) => {
  return (
    <InitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>
      {!!error && (
        <>
          <p className='danger has-text-centered'>
            {error}
          </p>
          <button
            className='button-text'
            onClick={onRedirectToLogin}
            type='button'
          >
            <FormattedMessageWithClass
              className='p2-bold'
              id='app.verifyemail.main.link'
            />
          </button>
        </>
      )}
      {!verificationStatus && !error &&
        <FormattedMessageWithClass
          id='app.verifyemail.main.verifying'
        />
      }
      {verificationStatus && !error && (
        <div className={`full-width ${classes.horizontalLevel}`}>
          <FormattedMessageWithClass
            className='t2-bold primary has-margin-b-4'
            id='app.verifyemail.main.verified'
          />
          <FormattedMessageWithClass
            className='has-margin-b-2 has-text-grey-light'
            id='app.verifyemail.main.text'
          />

          <button
            className={'button filled nonAuthedButtons'}
            disabled={!!error}
            onClick={onRedirectToLogin}
          >
            <FormattedMessageWithClass
              id='app.verifyemail.main.button'
            />
          </button>
        </div>
      )}
    </InitialLayout>
  )
}

export default VerifyEmailLayout
