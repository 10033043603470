
/*
  Opens whatsapp chat with the number passed as argument

  Documentation: https://faq.whatsapp.com/5913398998672934/?helpref=uf_share
 */
export const openWhatsappChat = (phoneNumber) => {
  const url = `https://wa.me/${phoneNumber}`
  window.open(url,'_blank')
}
