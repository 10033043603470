import React, { Component } from 'react'
import './ToogleSwitch.css'

class ToogleSwitch extends Component {
  render() {
    return (
      <label className='custom-switch'>
        <input
          checked={this.props.value}
          onChange={this.props.toggle}
          // defaultChecked={this.props.value}
          type='checkbox'
        />
        <span className='custom-slider round'></span>
      </label>
    )
  }
}

export default ToogleSwitch
