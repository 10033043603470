import { combineReducers } from 'redux'
import intl from 'seniors-first-commons/shared/store/translations/reducer'
import helpersReducer from 'seniors-first-commons/shared/store/helpers/reducer'
import usersReducer from 'seniors-first-commons/shared/store/user/reducer'
import notificationsReducer from 'seniors-first-commons/shared/store/notifications/reducer'
import authReducer from 'seniors-first-commons/shared/store/auth/reducer'
import billingReducer from './billing/reducer'
import caregiversReducer from './caregivers/reducer'
import profileReducer from 'store/profile/reducer'
import layoutsReducer from './layouts/reducer'
import jobOffersReducer from './jobOffers/reducer'


const appReducer = combineReducers({
  auth: authReducer,
  billing: billingReducer,
  caregivers: caregiversReducer,
  helpers: helpersReducer,
  intl,
  jobOffers: jobOffersReducer,
  layouts: layoutsReducer,
  notifications: notificationsReducer,
  profile: profileReducer,
  user: usersReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'shared/auth/blackList/fulfilled') {
    return appReducer({ intl: state.intl }, action)
  }
  return appReducer(state, action)
}

export default rootReducer
