import React from 'react'
import { Field, ErrorMessage } from 'formik'
import Input from 'seniors-first-commons/components/atoms/Input'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import classes from './index.module.css'

const RetributionAmount = ({ currency, inputName }) => {
  return (
    <div className='full-width has-margin-t-4' style={{ maxWidth: '300px' }}>
      <Field
        autoFocus
        className={`input ${classes.retributionAmountInput}`}
        component={Input}
        icon={<span className='black'>{currency}</span>}
        max={1000000}
        min={1}
        name={inputName}
        step='any'
        type='number'
      />
    </div>
  )
}

const CustomBudget = ({ currency }) => {
  return (
    <>
      <RetributionAmount
        currency={currency}
        inputName='retribution_amount'
      />
      <ErrorMessage
        component={HandleError}
        name='retribution_amount'
      />
    </>
  )
}

export default CustomBudget
