import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { CreditsQuantitySchema } from 'schemas/creditsQuantity'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Select from 'seniors-first-commons/components/molecules/Select'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { SFCoinsIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const QuantityInput = (props) => {
  return (
    <div className={`${classes.selectWrapper}`}>
      <Select
        addStyles={{
          singleValue: (base) => ({
            ...base,
            left: '50%',
            position: 'absolute'
          })
        }}
        isSearchable={false}
        maxMenuHeight={200}
        placeholder='Select an options'
        shouldTranslate={false}
        {...props}
      />
      <ErrorMessage
        centered={false}
        component={ErrorHandler}
        name={props.field.name}
        spaceTop={0}
      />
    </div>
  )
}


const CreditsQuantityModal = ({
  open,
  title='',
  buttonText='',
  loading=false,
  onConfirm,
  onClose
}) => {
  return (
    <CustomModal
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick
      styles={{ maxWidth: '400px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <SFCoinsIcon />

        <p className='p1-bold grey has-margin-b-7'>
          <FormattedMessageWithClass id={title} />
        </p>

        <Formik
          initialValues={{ quantity: { label: 1, value: 1 } }}
          onSubmit={onConfirm}
          validationSchema={CreditsQuantitySchema}
        >
          {({ handleSubmit }) => {
            return (
              <Form>
                <Field
                  component={QuantityInput}
                  name='quantity'
                  options={Array.from({ length: 20 }, (x, i) => ({ label: ++i, value: i++ }))}
                />

                <button
                  className={`button filled ${loading ? 'is-loading' : ''} has-margin-t-6 ${classes.submitButton}`}
                  disabled={loading}
                  onClick={handleSubmit}
                  type='submit'
                >
                  <FormattedMessageWithClass
                    id={buttonText}
                  />
                </button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}


export default CreditsQuantityModal
