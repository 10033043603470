import * as Yup from 'yup'


export const CreditsQuantitySchema = Yup.object().shape({
  quantity: Yup.object().shape({
    value: Yup.number().required('validations.required')
  })
    .typeError('validations.required')
    .required('validations.required')
})
