import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const OfferPostButton = ({ posted_until, onClick }) => {
  return (
    <>
      {posted_until > 2 ?
        <p className={'custom-tooltip p2-bold success is-marginless has-text-centered'}>
          <span
            className='is-flex full-width'
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <FormattedMessageWithClass
              className='has-margin-r-7'
              id='apps.general.jobOffer.actions.posted'
            />
            <InfoIcon
              fill='none'
              height='24'
              stroke='#4CC700'
              width='24'
            />
          </span>

          <span className='tooltip-content p3-bold white' placement='top'>
            <FormattedMessageWithClass
              id='apps.general.jobOffer.actions.expiresIn'
            />
            {' '}{posted_until}{' '}
            <FormattedMessageWithClass id='apps.general.jobOffer.actions.days' />
          </span>
        </p>
        :
        <button
          className={`filled ${classes.button}`}
          onClick={onClick}
        >
          <FormattedMessageWithClass
            id={posted_until <= 2
              ? 'apps.general.jobOffer.actions.postAgain'
              : 'apps.general.jobOffer.actions.post'
            }
          />
        </button>
      }
    </>
  )
}

export default OfferPostButton
