import React from 'react'
import classes from './index.module.css'

const Pill = ({ className = '', children, additionalStyle, rounded=false }) => {
  return (
    <div
      className={`tag ${rounded ? 'is-rounded' : ''} is-size-7 ${classes.Pill} ${className}`}
      style={additionalStyle}
    >
      {children}
    </div>
  )
}

export default Pill
