import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'


const ErrorHandler = ({
  errorMessage,
  children,
  centered=true,
  spaceTop=7,
  spaceBottom=0
}) => {
  const error = typeof children === 'string'
    ? children
    : (children instanceof Array || typeof children[Object.keys(children)[0]] !== 'string')
      ? errorMessage
      : children[Object.keys(children)[0]]
  return (
    <div
      className={`${spaceTop ? `has-margin-t-${spaceTop}` : 'is-marginless-t'} ${spaceBottom ? `has-margin-b-${spaceBottom}` : 'is-marginless-b'} ${centered ? 'has-text-centered' : 'has-text-left'}`}
    >
      <p className='p3-regular danger'>
        <FormattedMessageWithClass id={error} />
      </p>
    </div>
  )
}

export default ErrorHandler
