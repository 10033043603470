import React from 'react'
import { PinIcon } from 'seniors-first-commons/components/atoms/images/icons'


const OfferAddress = ({ data }) => {
  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <PinIcon
        fill='none'
        height='16'
        stroke='#022C72'
        width='18'
      />
      <span className='has-margin-l-7 p2-regular grey ellipsis' style={{ maxWidth: '85%' }}>
        {data.address}
      </span>
    </div>
  )
}

export default OfferAddress
