import React, { useEffect } from 'react'
import regionService from '../services/region'
import { withRouter } from 'react-router-dom'

const withLocale = (WrappedComponent) => {
  const InnerComponent = (props) => {
    useEffect(() => {
      regionService.getPath()
        .then(({ pathname, search }) => {
          props.history.push({ pathname, search })
        })
    }, []) // eslint-disable-line

    return (
      <WrappedComponent {...props}/>
    )
  }

  const EnhacedComponent = withRouter(InnerComponent)
  return EnhacedComponent
}

export default withLocale
