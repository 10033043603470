import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { UsersIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ViewCandidatesButton = ({ onClick }) => {
  return (
    <button
      className={`button-text ${classes.button}`}
      onClick={onClick}
    >
      <UsersIcon
        className='button-text-icon'
        height='24px'
        width='24px'
      />
      <FormattedMessageWithClass
        className='has-margin-l-7 p2-bold'
        id='apps.general.jobOffer.actions.viewCandidates'
      />
    </button>
  )
}

export default ViewCandidatesButton
