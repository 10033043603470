import React from 'react'
import { useHistory } from 'react-router-dom'
import routingServices from 'seniors-first-commons/services/routingService'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { formatToMatchEnv } from 'seniors-first-commons/utils/helpers'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import AppContainer from 'components/organisms/AppContainer'
import IconTextActionLayout from 'components/templates/IconTextActionLayout'
import { ROUTE } from 'root/constants'
import ErrorImage from 'seniors-first-commons/components/atoms/images/icons/error.svg'


const Icon = () => {
  return (
    <img alt='error' src={ErrorImage} />
  )
}

const Text = ({ title, details, link }) => {
  const FAQ_URL = useFormatMessage(formatToMatchEnv('app.general.faq.link'))

  return (
    <>
      <p className='t1-bold danger'>
        {title}
      </p>
      <p className='p1-bold grey has-margin-bt-5'>
        {details}{' '}
        <a
          href={FAQ_URL}
          rel='noopener noreferrer'
          target='_blank'
        >
          {link}
        </a>
      </p>
    </>
  )
}

const Action = ({ button, onGoHome }) => {
  return (
    <button
      className='button filled'
      onClick={onGoHome}
      style={{ height: '48px', width: '140px' }}
      type='button'
    >
      {button}
    </button>
  )
}


const PaymentCancelled = () => {
  const history = useHistory()
  const mediaQuery = useMediaQuery()

  const translations = {
    button: useFormatMessage('client.paymentCanceled.button'),
    details: useFormatMessage('client.paymentCanceled.text'),
    link: useFormatMessage('client.paymentCanceled.faq'),
    title: useFormatMessage('client.paymentCanceled.title')
  }

  const onGoHome = () => {
    routingServices.push(history, `${ROUTE.HOME}`)
  }

  return (
    <AppContainer name='Payment cancelled'>
      <IconTextActionLayout
        action={(<Action {...translations} onGoHome={onGoHome} />)}
        icon={(<Icon />)}
        matchesMobile={mediaQuery[0] < 768}
        text={(<Text {...translations} />)}
      />
    </AppContainer>
  )
}


export default PaymentCancelled
