import * as Yup from 'yup'

const RegisterSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .test('confirmaPassword_text', 'validations.passwordsdontmatch', function(value) {
      const { password } = this.parent
      if (value === password) return true
    })
    .required('validations.required'),
  email: Yup.string().email('validations.invalidemail')
    .required('validations.required'),
  firstname: Yup.string().required('validations.required'),
  lastname: Yup.string().required('validations.required'),
  password: Yup.string().min(6, 'validations.passwordlength')
    .required('validations.required')
})

export default RegisterSchema
