import { Actions } from './actionTypes'
import { AppLanguages, defaultLang } from '../../languages'


const loadTranslationSuccess = payload => ({
  payload,
  type: Actions.LOAD_TRANSLATIONS_SUCCESS
})

const getTranslation = (locale, messages) => {
  return {
    locale: locale.replace(locale.substring(0, 2), locale.substring(0, 2).toLowerCase()),
    messages
  }
}
export const loadTranslation = (locale = '') => dispatch => {
  const languageKey = locale.toUpperCase().replace('-', '_')
  const language = AppLanguages[languageKey] || defaultLang
  const translations = getTranslation(locale, language.messages)
  dispatch(loadTranslationSuccess(translations))
}

export const setErrorMessage = (error) => ({
  error,
  type: Actions.SET_ERROR_MESSAGE
})
