import React, { useState } from 'react'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { connect } from 'react-redux'
import RegisterLayout from 'seniors-first-commons/components/templates/RegisterLayout'
import { socialLogin, register, login } from 'seniors-first-commons/shared/store/auth/actions'
import { updateProfile } from 'store/profile/actions'
import { useClipBody } from 'seniors-first-commons/utils/useClipBody'

const RegisterModal = ({
  open,
  registerData,
  register,
  locale,
  role,
  updateProfile,
  login,
  onUserRegisteredAndLoggedIn,
  profileValues,
  shouldClipBody = true,
  shouldUnclipBody = true, // This prevents the body from scrolling when the modal is open
  withRegisterLaterOption = false
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  useClipBody(open, shouldClipBody, shouldUnclipBody)

  const onFormSubmit = async ({ confirmPassword, ...rest }) => {
    const { email, password } = rest
    try {
      await register({
        role,
        ...rest,
        create_verified: true
      }).unwrap()

      const loginData = await login({ email, password, role }).unwrap()

      await updateProfile({
        address: {
          address_lat: profileValues.address.address_lat,
          address_lng: profileValues.address.address_lng,
          formatted_address: profileValues.address.formatted_address
        },
        country: {
          value: profileValues.country
        },
        phone: profileValues.phone
      })

      onUserRegisteredAndLoggedIn({
        user_id: loginData.user_id
      })
    } catch (error) {
      console.log('error', error.data.message)
    }

  }
  const onRedirectToLogin = () => {
    console.log('onRedirectToLogin')
  }

  const onReturnToLogin = () => {
    console.log('onReturnToLogin')
  }

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo)
  }

  return (
    <CustomModal
      backdropClasses='has-page-width has-page-height fixed-top-left'
      hasCloseButton={false}
      modalClasses='overflow-auto'
      open={open}
      shouldCloseOnOverlayClick={false}
      styles={{ maxWidth: '600px', minHeight: '300px', width: '95%'}}
    >
      <RegisterLayout
        ignoreVerificationScreen={true}
        locale={locale}
        onFormSubmit={onFormSubmit}
        onRedirectToLogin={onRedirectToLogin}
        onReturnToLogin={onReturnToLogin}
        register={register}
        registerData={registerData}
        role='client'
        showMoreInfo={showMoreInfo}
        toggleShowMoreInfo={toggleShowMoreInfo}
        withAlreadyHaveAccount={false}
        withInitialLayout={false}
        withRegisterLaterOption={withRegisterLaterOption}
        withSocialLogin={false}
      />
    </CustomModal>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    open: state.jobOffers.isRegisterModalOpen,
    registerData: state.auth.register
  }
}

const mapDispatchToProps = {
  login,
  register,
  socialLogin,
  updateProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal)
