import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { path } from 'ramda'
import AppRoutes from 'root/routes'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { useFecthDataOnCondition, useInterval } from 'seniors-first-commons/shared/hooks'
import Privacy from 'seniors-first-commons/components/organisms/Privacy/Privacy'
import withLocale from 'seniors-first-commons/hoc/withLocale'
import ScrollToTop from 'seniors-first-commons/services/scrollToTop'
import { toggleChatModal, setInbox } from 'store/layouts/actions'
import { getCountries } from 'seniors-first-commons/shared/store/helpers/actions'
import { getNotReadMessages } from 'store/caregivers/actions'
import {
  getNotifications,
  getNotReadNotifications
} from 'seniors-first-commons/shared/store/notifications/actions'
import { getUser } from 'seniors-first-commons/shared/store/user/actions'
import Loading from 'components/organisms/Loading'
import ErrorSnackbar from 'components/organisms/ErrorSnackbar'
import SuccessSnackbar from 'components/organisms/SuccessSnackbar'
import InboxModal from 'components/organisms/ChatInboxModal'
import UserActiveStatus from 'seniors-first-commons/components/organisms/UserActiveStatus'
import { ROUTE } from 'root/constants'
import { NEW_NOTIFICATIONS } from 'seniors-first-commons/utils/constants'
import '../index.css'


function App({
  profile,
  chatModal,
  messagesInbox,
  setInbox,
  toggleChatModal,
  userIsActive,
  notifications,
  refresh_token,
  submittedBasicInfo,
  getNotReadMessages,
  getUser,
  getCountries,
  getNotifications,
  getNotReadNotifications
}) {
  const history = useHistory()
  const notificationsRoute = history.location.pathname.includes(ROUTE.NOTIFICATIONS.substring(1))
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  useFecthDataOnCondition(() => {
    getUser()
  }, refresh_token)

  useFecthDataOnCondition(() => {
    getCountries()
  }, refresh_token)

  useFecthDataOnCondition(() => {
    getNotifications({ page: 1, per_page: NEW_NOTIFICATIONS })
  }, refresh_token && submittedBasicInfo && !notificationsRoute && !notifications.page)

  useInterval(() => {
    getNotReadNotifications({ page: 1, per_page: NEW_NOTIFICATIONS })
  }, userIsActive && submittedBasicInfo && refresh_token ? process.env.REACT_APP_REFRESH_NOTIFICATIONS : null)

  return (
    <>
      <Privacy />
      <Loading />
      <UserActiveStatus />
      <ErrorSnackbar />
      <SuccessSnackbar />
      <ScrollToTop setShowChatModal={toggleChatModal}>
        <AppRoutes />
      </ScrollToTop>

      {!matchesMobile && refresh_token && submittedBasicInfo &&
        <>
          <InboxModal
            caregiverChatId={path(['chat_user_id'], profile)}
            inbox={messagesInbox}
            open={chatModal}
            role='client'
            setInbox={setInbox}
            setUnreads={getNotReadMessages}
            toggleChatModal={toggleChatModal}
          />
        </>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    chatModal: state.layouts.chat.showModal,
    messagesInbox: state.layouts.inbox,
    notReadMessages: state.caregivers.chat.notReadMessages,
    notReadNotifications: state.notifications.notReads,
    notifications: state.notifications.data,
    profile: state.profile.profile.index,
    refresh_token: localStorage.getItem('refresh_token'),
    submittedBasicInfo: state.profile.profile.submittedBasicInfo,
    userIsActive: state.user.active
  }
}

const mapDispatchToProps = {
  getCountries,
  getNotReadMessages,
  getNotReadNotifications,
  getNotifications,
  getUser,
  setInbox,
  toggleChatModal
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocale(App))
