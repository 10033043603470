import { createAction } from '@reduxjs/toolkit'

export const toggleSuccessStatus = createAction('layouts/toggleSuccessStatus')

export const toggleRightSidebar = createAction('layouts/toggleRightSidebar')

export const toggleMobileSidebar = createAction('layouts/toggleMobileSidebar')

export const changeErrorMessageStatus = createAction('layouts/changeErrorMessageStatus')

export const toggleChatModal = createAction('layouts/toggleChatModal')

export const setInbox = createAction('layouts/setInbox')
