import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'


const OfferServices = ({ services }) => {
  return (
    <div className='tags'>
      {services.map((service, index) => (
        <FormattedMessageWithClass
          className='tag is-rounded has-margin-r-7 p3-bold primary-light'
          id={`apps.general.jobOffer.services.${service.service_category_id}`}
          key={index}
        />
      ))}
    </div>
  )
}

export default OfferServices
