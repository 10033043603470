import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import {
  postJobOffer,
  duplicateOffer,
  changeOfferArchivedStatus,
  getJobOffers
} from 'store/jobOffers/actions'
import ShiftsModal from 'seniors-first-commons/components/organisms/ShiftsModal'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import { ITEMS_PER_PAGE, WEEKS_TO_EXPIRE_POST } from 'seniors-first-commons/utils/constants'
import { ROUTE } from 'root/constants'
import { SendIcon } from 'seniors-first-commons/components/atoms/images/icons'


const mapStateToProps = (state) => ({
  isPosting: state.jobOffers.postJobLoading,
  jobOffers: state.jobOffers.index
})

const mapDispatchToProps = {
  changeOfferArchivedStatus,
  duplicateOffer,
  getJobOffers,
  postJobOffer
}


const withJobOffer = (WrappedComponent) => {
  /* eslint-disable no-unused-vars */
  const InnerComponent = ({
    archived,
    jobOffers,
    offer,
    isPosting,
    postJobOffer,
    duplicateOffer,
    getJobOffers,
    changeOfferArchivedStatus,
    ...props
  }) => {
  /* eslint-enable no-unused-vars */
    const [offerToPost, setOfferToPost] = useState('')
    const [offerToShowItsDaysAndTimes, setOfferToShowItsDaysAndTimes] = useState(null)
    const history = useHistory()
    const { logicPropsAndMethods: { showArchived, orderType, orderBy } } = props

    const onPost = (id) => {
      setOfferToPost(id)
    }

    const onConfirmPost = (service_sheet_id) => {
      postJobOffer({
        service_sheet_id,
        weeks: WEEKS_TO_EXPIRE_POST
      })
        .unwrap()
        .then(() => setOfferToPost(''))
    }

    const onDuplicate = (payload, perPage) => {
      duplicateOffer({ serviceSheet_data: payload })
        .unwrap()
        .then(() => {
          getJobOffers({
            archived: showArchived,
            order_by: orderBy,
            order_type: orderType,
            page: jobOffers.currentPage,
            per_page: perPage || ITEMS_PER_PAGE
          })
        })
    }

    const onToggleArchivedStatus = (id, perPage) => {
      changeOfferArchivedStatus({ id })
        .unwrap()
        .then(() => {
          getJobOffers({
            archived: showArchived || 0,
            order_by: orderBy || 'updated_at',
            order_type: orderType || 'desc',
            page: jobOffers.currentPage,
            per_page: perPage || ITEMS_PER_PAGE
          })
        })
    }

    const onViewCandidates = (id) => {
      routingService.push(history, `${ROUTE.CANDIDATES}/${id}?tab=post`)
    }

    const onFindAndSend = (job_offer_id) => {
      routingService.push(history, `${ROUTE.SEARCH}/${job_offer_id}`)
    }

    const onOpenDaysAndShiftsModal = (item) => {
      setOfferToShowItsDaysAndTimes(item)
    }

    return (
      <>
        <WrappedComponent
          {...props}
          actions={{
            onDuplicate,
            onFindAndSend,
            onOpenDaysAndShiftsModal,
            onPost,
            onToggleArchivedStatus,
            onViewCandidates
          }}
        />

        <ConfirmModal
          confirm='client.general.postOfferModal.button'
          details='client.general.postOfferModal.text'
          hascancelButton={false}
          Icon={SendIcon}
          loading={isPosting}
          onClose={() => setOfferToPost('')}
          onConfirm={() => onConfirmPost(offerToPost)}
          show={!!offerToPost}
          title='client.general.postOfferModal.title'
        />

        <ShiftsModal
          active={!!offerToShowItsDaysAndTimes}
          offer={offerToShowItsDaysAndTimes}
          onClose={() => setOfferToShowItsDaysAndTimes('')}
        />
      </>
    )
  }

  const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(InnerComponent)
  return EnhancedComponent
}

export default withJobOffer
