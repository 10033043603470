import React, { useState, useEffect } from 'react'
import { indexBy, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '../../../shared/store/translations/effects'
import { dayIds, dayTimeIds } from './constants'
import {
  CircleIcon,
  FilledCircleIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const Time = ({ isSelected, text, onClick, day, time }) => {
  const handleClick = () => onClick({ id: +`${day}${time}`, name: `${dayIds[day]} ${text}` })
  return (
    <td onClick={handleClick}  >
      {isSelected ?
        <FilledCircleIcon
          fill='none'
          height='24'
          stroke='#0080F5'
          width='24'
        /> :
        <CircleIcon
          fill='none'
          height='24'
          stroke='#0080F5'
          width='24'
        />
      }
    </td>
  )
}

export const AvailabilityTable = ({
  matchesMobile,
  preSetDay,
  preSetTime,
  availability,
  onClick,
  additionalClass = '',
  useShortName,
  tableHeadStyle
}) => {
  const [selected, setSelected] = useState(preSetDay ? dayTimeIds[preSetDay] : [])
  useEffect(() => {
    if (preSetDay) {
      setSelected(dayTimeIds[preSetDay])
    }
  }, [preSetDay, preSetTime])

  const onClickWrapper = ({ id, name }) => {
    const dayTimeSelected = availability.some((dayTime) => dayTime.id === id)
    const day = String(id).substr(0, 1)
    const otherHourSelected = availability
      .filter((dayTime) => dayTime.id !== id)
      .some((dayTime) => String(dayTime.id).startsWith(day))
    let newSelected

    // Si al hacer click la celda ya esta seleccionada
    if (dayTimeSelected) {
      // Controlar si hay otras celdas de ese mismo dia seleccionadas
      if (!otherHourSelected) {
        // Si no hay ningun otro horario seleccionado en ese dia, destildar ese dia
        newSelected = selected.filter((selectedDay) => {
          return selectedDay !== day
        })
        setSelected(newSelected)
      }
    } else {
      // Habilitar el dia de la celda si ese dia no tiene otros horarios seleccionados
      if (!otherHourSelected) {
        newSelected = [...selected, Number.parseInt(day)]
        setSelected(newSelected)
      }
    }

    onClick({ id, name })
  }

  const availabilityById = indexBy(prop('id'), availability)
  const morningText = useFormatMessage('app.boarding.availability.table.morning')
  const morningShortText = useFormatMessage('app.boarding.availability.table.morning.shortname')
  const afternoonText = useFormatMessage('app.boarding.availability.table.afternoon')
  const afternoonShortText = useFormatMessage('app.boarding.availability.table.afternoon.shortname')
  const nightText = useFormatMessage('app.boarding.availability.table.night')

  if (matchesMobile) {
    return (
      <div className={`availability-table-container ${additionalClass}`}>
        <table className='table grey is-striped full-width'>
          <thead>
            <tr>
              <th></th>
              <th>{morningText}</th>
              <th>{afternoonText}</th>
              <th>{nightText}</th>
            </tr>
            {[1, 2, 3, 4, 5, 6, 7].map((day, index) => {
              return (
                <tr key={index}>
                  <th>
                    <FormattedMessage id={`app.boarding.availability.table.${day}`} />
                  </th>
                  {[
                    { id: 1, label: morningText },
                    { id: 2, label: afternoonText },
                    { id: 3, label: nightText }
                  ].map((shift) => {
                    return (
                      <Time
                        day={day}
                        isSelected={availabilityById[`${day}${shift.id}`]}
                        key={`${day}-${shift.label}`}
                        onClick={onClickWrapper}
                        text={shift.label}
                        time={shift.id}
                      />
                    )
                  })}
                </tr>
              )
            })}
          </thead>
        </table>
      </div>
    )
  }
  return (
    <>
      <div className={`availability-table-container ${additionalClass}`}>
        <table className={`table grey is-striped table-primary is-fullwidth ${classes.table}`}>
          <thead>
            <tr className='is-borderless'>
              <th></th>
              <th className={selected.includes(1) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.1' />
              </th>
              <th className={selected.includes(2) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.2' />
              </th>
              <th className={selected.includes(3) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.3' />
              </th>
              <th className={selected.includes(4) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.4' />
              </th>
              <th className={selected.includes(5) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.5' />
              </th>
              <th className={selected.includes(6) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.6' />
              </th>
              <th className={selected.includes(7) ? 'selected' : 'p1-bold'} style={tableHeadStyle}>
                <FormattedMessage id='app.boarding.availability.table.7' />
              </th>
            </tr>
            <tr>
              <th className='p1-bold'>{!useShortName ? morningText : morningShortText}</th>
              {[1, 2, 3, 4, 5, 6, 7].map((day) => {
                return (
                  <Time
                    day={day}
                    isSelected={availabilityById[`${day}${1}`]}
                    key={`${day}-Morning`}
                    onClick={onClickWrapper}
                    text={morningText}
                    time={1}
                  />
                )
              })}
            </tr>
            <tr>
              <th className='p1-bold'>{!useShortName ? afternoonText : afternoonShortText}</th>
              {[1, 2, 3, 4, 5, 6, 7].map((day) => {
                return (
                  <Time
                    day={day}
                    isSelected={availabilityById[`${day}${2}`]}
                    key={`${day}-Afternoon`}
                    onClick={onClickWrapper}
                    text={afternoonText}
                    time={2}
                  />
                )
              })}
            </tr>
            <tr>
              <th className='p1-bold'>{nightText}</th>
              {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                <Time
                  day={day}
                  isSelected={availabilityById[`${day}${3}`]}
                  key={`${day}-Night`}
                  onClick={onClickWrapper}
                  text={nightText}
                  time={3}
                />
              ))}
            </tr>
          </thead>
        </table>
      </div>
    </>
  )
}
