import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { parseQueries, stringifyQueries, useQuery } from 'seniors-first-commons/shared/hooks'
import routingService from 'seniors-first-commons/services/routingService'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { getOfferDetails, postJobOfferFromDetails } from 'store/jobOffers/actions'
import {
  getConnectedCandidates,
  getCandidatesFromSearch,
  getCaregiversFromPost,
  markPostCandidatesAsViewed,
  paginatePostCandidatesClient
} from 'store/caregivers/actions'
import CandidatesLayout from 'components/templates/CandidatesLayout'
import { ITEMS_PER_PAGE, WEEKS_TO_EXPIRE_POST } from 'seniors-first-commons/utils/constants'
import { ROUTE } from 'root/constants'
import { isEmpty } from 'ramda'

const defaultOrderType = {
  label: 'client.search.header.sortBy.options.bestRanking',
  value: 'ranking'
}

const Candidates = ({
  profile,
  sheetData,
  oneSheetCandidates,
  credits,
  loading,
  loadingOfferDetails,
  connectFromPostLoading,
  postJobLoading,
  checkingConnection,
  getConnectedCandidates,
  getCandidatesFromSearch,
  getCaregiversFromPost,
  getOfferDetails,
  markPostCandidatesAsViewed,
  paginatePostCandidatesClient,
  postCandidates,
  postJobOfferFromDetails,
  ...props
}) => {
  const [showJobOfferDetails, setShowJobOfferDetails] = useState(false)
  const [showConfirmationModal, setShowConfirmModal] = useState(false)
  const [showRenewPostModal, setShowRenewPostModal] = useState(false)
  const [showVideoCVModal, setShowVideoCVModal] = useState('')
  const [filters, setFilters] = useState(['accepted', 'pending', 'interested', 'rejected'])
  const [orderBy, setOrderBy] = useState(defaultOrderType)

  const history = useHistory()
  const query = useQuery()
  const selectedTab = query.get('tab') || 'post'
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const matchesDesktop = mediaQuery[0] >= 1024

  const jobOfferId = props.match.params.id

  useEffect(() => {
    const parsedParams = parseQueries()
    const fromNotification = parsedParams.from
    const orderByParam = parsedParams.order_by
    if (fromNotification === 'post_renewal') {
      setShowRenewPostModal(true)
    }
    if (orderByParam === 'nearest_first') {
      setOrderBy({ label: 'client.search.header.sortBy.options.nearestFirst', value: 'nearest' })
    }
  }, [])

  const getCandidates = useCallback((page, resultsOrderValue) => {
    if (sheetData?.id) {
      switch(selectedTab) {
      case 'findsend':
        getCandidatesFromSearch({
          lat: sheetData?.address_lat,
          lng: sheetData?.address_lng,
          order_by: resultsOrderValue,
          page,
          per_page: ITEMS_PER_PAGE,
          service_sheet_id: sheetData?.id,
          status: filters.join(',')
        })
        break
      case 'post':
        getCaregiversFromPost({
          lat: sheetData?.address_lat,
          lng: sheetData?.address_lng,
          order_by: resultsOrderValue,
          page,
          per_page: ITEMS_PER_PAGE,
          service_sheet_id: sheetData.id
        })
        break
      case 'accepted':
        getCandidatesFromSearch({
          lat: sheetData?.address_lat,
          lng: sheetData?.address_lng,
          order_by: resultsOrderValue,
          page,
          per_page: ITEMS_PER_PAGE,
          service_sheet_id: sheetData.id
        })
        break
      case 'connected':
        getConnectedCandidates({
          lat: sheetData?.address_lat,
          lng: sheetData?.address_lng,
          order_by: resultsOrderValue,
          page,
          per_page: ITEMS_PER_PAGE,
          service_sheet_id: sheetData.id
        })
        break
      default:
        return
      }
    }
  }, [
    getConnectedCandidates,
    getCandidatesFromSearch,
    getCaregiversFromPost,
    selectedTab,
    sheetData,
    filters
  ])

  const getJobOfferDetails = useCallback(() => {
    getOfferDetails({ id: jobOfferId })
  }, [getOfferDetails, jobOfferId])

  useEffect(() => {
    const orderResultsValue = orderBy.value === 'nearest' ? 'nearest_first' : undefined
    getCandidates(1, orderResultsValue)
    // eslint-disable-next-line
  }, [getCandidates])

  useEffect(() => {
    getJobOfferDetails()
  }, [getJobOfferDetails])

  useEffect(() => {
    if (!isEmpty(postCandidates.itemsOnDisplay)) {
      const applicantsIds = postCandidates.itemsOnDisplay
        .filter(candidate => !candidate.viewed)
        .map(candidate => candidate.id)

      if (!isEmpty(applicantsIds)) {
        markPostCandidatesAsViewed({
          applicants: applicantsIds,
          service_sheet_id: sheetData.id
        })
      }
    }
  }, [postCandidates.itemsOnDisplay])

  const handleTabClick = (item) => {
    routingService.push(history, `${ROUTE.CANDIDATES}/${jobOfferId}?tab=${item.value}`)
  }

  const handlePostCandidatesPageChange = (e) => {
    if (e !== postCandidates.currentPage) {
      paginatePostCandidatesClient({pageNumber: e})
    }
  }

  const handleCandidatesPageChange = (e) => {
    if (e !== oneSheetCandidates.currentPage) {
      getCandidates(e, orderBy.value)
    }
  }

  const handlePageChange = (e) => {
    selectedTab === 'post'
      ? handlePostCandidatesPageChange(e)
      : handleCandidatesPageChange(e)
  }

  const handleFilterChange = (value) => {
    if(!value.target.checked){
      setFilters(filters.filter(item => value.target.id !== item))
    }
    else{
      setFilters([...filters, value.target.id])
    }
  }

  const onSearch = () => {
    routingService.push(history, `${ROUTE.SEARCH}/${jobOfferId}`)
  }

  const onPost = () => {
    setShowConfirmModal(true)
  }

  const onPostModalClose = () => {
    setShowConfirmModal(false)
  }

  const onPostConfirm = () => {
    postJobOfferFromDetails({ service_sheet_id: sheetData?.id, weeks: WEEKS_TO_EXPIRE_POST })
      .then(() => setShowConfirmModal(false))
  }

  const onRenewPostConfirm = () => {
    postJobOfferFromDetails({ service_sheet_id: sheetData?.id, weeks: WEEKS_TO_EXPIRE_POST })
      .then(() => setShowRenewPostModal(false))
  }

  const onCloseRenewPostModal = () => {
    setShowRenewPostModal(false)
  }

  const onOpenVideoCVModal = (video_id) => {
    setShowVideoCVModal(video_id)
  }

  const onCloseVideoCVModal = () => {
    setShowVideoCVModal('')
  }

  const onChangeOrder = (order) => {
    if (order.value !== orderBy.value) {
      const newOrderValue = order.value === 'nearest' ? 'nearest_first' : undefined
      const parsedParams = parseQueries()
      const pageParams = stringifyQueries({ ...parsedParams, order_by: newOrderValue, page: 1 })
      history.push({ search: pageParams })
      getCandidates(1, newOrderValue)
      setOrderBy(order)
    }
  }

  return (
    <div className='full-content'>
      <CandidatesLayout
        logicPropsAndMethods={{
          filters,
          handleFilterChange,
          handlePageChange,
          handleTabClick,
          jobOfferId,
          loading: {
            checkingConnection,
            connectFromPostLoading,
            gettingCredits: credits.loadingCredits,
            loadingCandidates: loading,
            loadingOfferDetails,
            postJobLoading
          },
          matchesDesktop,
          matchesMobile,
          onChangeOrder,
          onCloseRenewPostModal,
          onCloseVideoCVModal,
          onOpenVideoCVModal,
          onPost,
          onPostConfirm,
          onPostModalClose,
          onRenewPostConfirm,
          onSearch,
          oneSheetCandidates,
          orderBy,
          postCandidates,
          profile,
          selectedTab,
          setShowJobOfferDetails,
          sheetData,
          showConfirmationModal,
          showJobOfferDetails,
          showRenewPostModal,
          showVideoCVModal
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    checkingConnection: state.caregivers.chat.checkingConnection,
    connectFromPostLoading: state.caregivers.connectFromPostLoading,
    credits: state.billing.credits,
    interestedReasons: state.caregivers.interestedReasons,
    loading: state.caregivers.loading,
    loadingOfferDetails: state.jobOffers.loading,
    oneSheetCandidates: state.caregivers.oneSheetCandidates,
    postCandidates: state.caregivers.postCandidates,
    postJobLoading: state.jobOffers.postJobLoading,
    profile: state.profile.profile,
    sheetData: state.jobOffers.oneSheetData || {}
  }
}

const mapDispatchToProps = {
  getCandidatesFromSearch,
  getCaregiversFromPost,
  getConnectedCandidates,
  getOfferDetails,
  markPostCandidatesAsViewed,
  paginatePostCandidatesClient,
  postJobOfferFromDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidates)
