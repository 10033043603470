import * as Yup from 'yup'

export const welcomeSchema = Yup.object().shape({
  address: Yup.object().shape({
    address_lat: Yup.number().required('validations.required'),
    address_lng: Yup.number().required('validations.required'),
    formatted_address: Yup.string().required('validations.required')
  }),

  country: Yup.object().shape({
    label: Yup.string().required('validations.required'),
    value: Yup.number().required('validations.required')
  })
})
