import React from 'react'
import Text from 'react-texty'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { FlagIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Nationalities = ({ data, caregiverStatus }) => {
  const translations = {
    noNationalities: useFormatMessage('client.general.caregiverCard.noNationlities')
  }
  const loadNationalities = () => {
    if(data['nationalities'] && data['nationalities'].length > 0){
      const nationalities = data['nationalities']
        .map(nationality => nationality.name)
        .join(', ')

      return nationalities
    }
    else return translations.noNationalities
  }
  const nationalities = loadNationalities()

  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <FlagIcon
        fill='none'
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='18'
      />
      <Text
        className={`has-margin-l-7 p2-regular ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
        style={{ maxWidth: '85%' }}
      >
        {nationalities}
      </Text>
    </div>
  )
}

export default Nationalities
