import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { createJobOffer, postJobOffer, toggleRequestAssistanceModal } from 'store/jobOffers/actions'
import CreateJobOfferLayout from 'components/templates/CreateJobOfferLayout'
import routingService from 'seniors-first-commons/services/routingService'
import { ROUTE } from 'root/constants'
import { WEEKS_TO_EXPIRE_POST } from 'seniors-first-commons/utils/constants'

const CreateJobOffer = ({
  helpers,
  profile,
  user,
  creatingJobOffer,
  postJobLoading,
  createJobOffer,
  postJobOffer,
  toggleRequestAssistanceModal
}) => {
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const onFormSubmit = (args, { setSubmitting, setStatus }) => {
    const payload = {
      client_id: user.id,
      country: profile.country_id,
      ...args
    }

    createJobOffer(payload)
      .unwrap()
      .then((payload) => {
        const id = payload.id

        setSubmitting(false)
        setStatus({ success: true })
        postJobOffer({ service_sheet_id: id, weeks: WEEKS_TO_EXPIRE_POST })
          .unwrap()
          .then(() => {
            routingService.push(history, `${ROUTE.SEARCH}/${id}`)
            toggleRequestAssistanceModal(true)
          })
      })
  }

  const chosenCountry = () => {
    let country_code = ''
    if(helpers.countries && profile) {
      country_code = helpers.countries.find(country => country.value === profile.country_id).code
    }
    return country_code
  }

  return (
    <>
      <CreateJobOfferLayout logicPropsAndMethods={{
        chosenCountry,
        loading: creatingJobOffer || postJobLoading,
        matchesMobile,
        onFormSubmit,
        profile,
        user
      }}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    creatingJobOffer: state.jobOffers.creatingJobOffer,
    helpers: state.helpers.helpers,
    postJobLoading: state.jobOffers.postJobLoading,
    profile: state.profile.profile.index,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  createJobOffer,
  postJobOffer,
  toggleRequestAssistanceModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobOffer)
