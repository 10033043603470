import React from 'react'
import { connect } from 'react-redux'
import {
  toggleRequestAssistanceModal,
  toggleOfferCreatedConfirmationModal,
  requestAssistanceForCreatedOffer,
  sendNotificationForCreatedOffer
} from 'store/jobOffers/actions'
import { updateProfile } from 'store/profile/actions'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { Phone } from './inputs'
import { Formik, Form, Field } from 'formik'
import { jobOfferRequestAssistanceSchema } from 'schemas/jobOfferRequestAssistance'
import { getCountryByCode } from 'seniors-first-commons/utils/helpers'
import { PHONE_VALIDATION_MIN_LENGTH } from 'seniors-first-commons/utils/constants'
import PhoneModel from 'seniors-first-commons/shared/models/phone'
import classes from './index.module.css'

const RequestAssitanceModal = ({
  open,
  toggleRequestAssistanceModal,
  toggleOfferCreatedConfirmationModal,
  requestAssistanceForCreatedOffer,
  sendNotificationForCreatedOffer,
  updateProfile,
  loading,
  phone,
  country_id,
  countries,
  address,
  address_lng,
  address_lat,
  one_sheet_data
}) => {

  const onConfirm = (values, { setSubmitting, setStatus }) => {
    const payload = {
      address: {
        address_lat: address_lat,
        address_lng: address_lng,
        formatted_address: address
      },
      country: {
        value: country_id
      },
      ...values
    }

    updateProfile(payload)
      .then(() => {
        setStatus({ success: true })
        setSubmitting(false)
        //mark the service_sheet as a requested assistance one
        requestAssistanceForCreatedOffer({id: one_sheet_data.id})
        //sends an email notification
        sendNotificationForCreatedOffer({
          service_sheet_id: one_sheet_data.id,
          service_sheet_name: one_sheet_data.name,
          user_phone: values.phone
        })
        toggleRequestAssistanceModal(false)
        toggleOfferCreatedConfirmationModal(true)
      })
  }

  const onClose = () => {
    toggleRequestAssistanceModal(false)
    toggleOfferCreatedConfirmationModal(true)
  }

  const defaultCountry = countries
    ? countries.find(item => item.value === country_id)
    : ''
  const countryCode = defaultCountry
    ? getCountryByCode(defaultCountry?.code)[3]
    : ''
  const initialValues = new PhoneModel(phone, countryCode)
  const validationSchema = jobOfferRequestAssistanceSchema

  return (
    <CustomModal
      hasCloseButton={false}
      open={open && !loading}
      shouldCloseOnOverlayClick={false}
      styles={{ maxWidth: '400px', minHeight: '300px', width: '95%'}}
    >
      <div className={classes.formContainer}>
        <div className='has-text-centered'>
          <p className='p1-bold'>
            <FormattedMessageWithClass id='client.search.requestAssistanceModal.text' />
          </p>
          <p className='p1-bold'>
            <FormattedMessageWithClass id='client.search.requestAssistanceModal.text2' />
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onConfirm}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <>
                {(!phone || phone.length < PHONE_VALIDATION_MIN_LENGTH) &&
                <Form>
                  <div className='has-margin-b-3'>
                    <Field component={Phone} name='phone' />
                  </div>
                </Form> }

                <div className='columns has-margin-t-5'>
                  <div className='column'>
                    <button
                      className={'button outlined main-button'}
                      onClick={onClose}
                    >
                      <FormattedMessageWithClass
                        id={'client.search.requestAssistanceModal.cancel'}
                      />
                    </button>
                  </div>
                  <div className='column'>
                    <button
                      className={`
                        button filled main-button
                        ${isSubmitting ? 'is-loading' : ''} 
                      `}
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      type='submit'
                    >
                      <FormattedMessageWithClass id='client.search.requestAssistanceModal.confirm' />
                    </button>
                  </div>
                </div>
              </>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}

const mapStateToProps = (state) => ({
  address: state.profile.profile.index?.address,
  address_lat: state.profile.profile.index?.address_lat,
  address_lng: state.profile.profile.index?.address_lng,
  countries: state.helpers.helpers?.countries,
  country_id: state.profile.profile.index?.country_id,
  one_sheet_data: state.jobOffers.oneSheetData,
  open: state.jobOffers.isRequestAssistanceModalOpen,
  phone: state.profile.profile.index?.phone
})
const mapDispatchToProps = {
  requestAssistanceForCreatedOffer,
  sendNotificationForCreatedOffer,
  toggleOfferCreatedConfirmationModal,
  toggleRequestAssistanceModal,
  updateProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAssitanceModal)
