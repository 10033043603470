import React from 'react'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import { SuccessIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const PasswordResetSuccess = ({
  resendingEmail,
  onResendEmail,
  onBack
}) => {

  const translations = {
    backButton: useFormatMessage('app.password.passwordresetsuccess.back')
  }

  return (
    <div className={`has-text-centered ${classes.horizontalLevel}`}>
      <SuccessIcon
        fill='none'
        height='55'
        stroke='#26DCF8'
        width='55'
      />

      <FormattedMessageWithClass
        className='t1-bold primary has-margin-t-7'
        id='app.password.passwordresetsuccess.title'
      />

      <FormattedMessageWithClass
        className='has-margin-bt-4 p2-bold has-text-grey-dark'
        id='app.password.passwordresetsuccess.text'
      />

      <button
        className={`button filled has-margin-b-4 ${classes.backButton}`}
        onClick={onBack}
        type='button'
      >
        {translations.backButton}
      </button>

      <div>
        <FormattedMessageWithClass
          className='p2-bold has-text-grey-dark'
          id='app.password.passwordresetsuccess.resendtext'
        />

        <button
          className={`button-text ${resendingEmail ? 'is-loading' : ''}`}
          disabled={resendingEmail}
          onClick={onResendEmail}
          type='button'
        >
          <FormattedMessageWithClass
            className='p2-bold'
            id='app.password.passwordresetsuccess.resendbutton'
          />
        </button>
      </div>
    </div>
  )
}

export default PasswordResetSuccess
