import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const RoundedTabs = ({
  items,
  selected,
  matchesMobile,
  onChangeTab
}) => {
  return (
    <div className='tabs is-toggle is-toggle-rounded' >
      <ul className={classes.tabsList}>
        {items.map((item, index) => (
          <li
            className={selected === item.value ? classes.isActive : ''}
            key={index}
            onClick={() => onChangeTab(item)}
            style={{ width: 'fit-content' }}
          >
            {/* eslint-disable-next-line */ }
        <a className={classes.link}>
              <FormattedMessageWithClass
                className={matchesMobile ? 'p3-bold' : 'p2-bold'}
                id={item.label}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RoundedTabs
