import React from 'react'
import Text from 'react-texty'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { LanguageIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Languages = ({ data, caregiverStatus }) => {
  const translations = {
    noLanguages: useFormatMessage('client.general.caregiverCard.noLanguages')
  }
  const loadLanguages = () => {
    if(data['formation'] && data['formation']['languages'].length > 0){
      const languages = data['formation']['languages']
        .map(language => language['language'].name)
        .join(', ')

      return languages
    }
    else return translations.noLanguages
  }
  const languages = loadLanguages()

  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <LanguageIcon
        fill='none'
        fillPath={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='18'
      />
      <Text
        className={`has-margin-l-7 p2-regular ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
        style={{ maxWidth: '85%' }}
      >
        {languages}
      </Text>
    </div>
  )
}

export default Languages
