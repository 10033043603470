import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { ArrowRightIcon, ArrowLeftIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const NearCaregiversTitle = ({
  visibleButtons=true,
  handleNextSlide,
  handlePrevSlide
}) => {
  return (
    <div className='level is-mobile'>
      <div className='level-left'>
        <div className='level-item'>
          <h3 className='t2-bold primary is-marginless'>
            <FormattedMessageWithClass id='client.home.caregiverForYou.title' />
          </h3>
        </div>
      </div>
      {visibleButtons ?
        <div className='leve-right has-text-right is-paddingless-r'>
          <div className='level-item'>
            <button
              className={`button-text primary ${classes.prevButton}`}
              onClick={handlePrevSlide}
            >
              <ArrowLeftIcon
                className='button-text-icon'
                height='24px'
                width='24px'
              />
            </button>
            <button
              className={`button-text primary ${classes.nextButton}`}
              onClick={handleNextSlide}
            >
              <ArrowRightIcon
                className='button-text-icon'
                height='24px'
                width='24px'
              />
            </button>
          </div>
        </div>
        : null}
    </div>
  )
}

export default NearCaregiversTitle
