import React from 'react'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import { SuccessIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const RegisterSuccess = ({
  resendingEmail,
  onReturnToLogin,
  onResend
}) => {

  const translations = {
    resendButton: useFormatMessage('app.register.success.resendbutton'),
    successButton: useFormatMessage('app.register.success.button')
  }

  return (
    <div className={`has-text-centered ${classes.horizontalLevel}`}>
      <SuccessIcon
        fill='none'
        height='55'
        stroke='#26DCF8'
        width='55'
      />

      <FormattedMessageWithClass
        className='title is-3 primary has-margin-t-6'
        id='app.register.success.title'
      />

      <FormattedMessageWithClass
        className='has-margin-b-6'
        id='app.register.success.subtitle'
      />
      <p className={`has-margin-b-7 p2-bold ${classes.horizontalLevel}`}>
        <FormattedMessageWithClass
          id='app.register.success.resendtext'
        />
        <button
          className={`button button-text ${resendingEmail ? 'is-loading' : ''}`}
          disabled={resendingEmail}
          onClick={onResend}
          type='button'
        >
          <span className='p2-bold'>
            {translations.resendButton}
          </span>
        </button>
      </p>

      <button
        className={'button filled has-margin-t-7 nonAuthedButtons'}
        onClick={onReturnToLogin}
        type='button'
      >
        {translations.successButton}
      </button>
    </div>
  )
}

export default RegisterSuccess
