import es_ES from './es-ES.json'
import es_US from './es-US.json'
import en_GB from './en-GB.json'
import en_US from './en-US.json'

/**
 * Languages without "text" property will be hidden in LanguageSwitch.
 */
export const AppLanguages = {

  // English
  EN: {
    messages: en_GB
  },


  EN_GB: {
    icon: 'flag-icon-gb',
    isEU: true,
    messages: en_GB,
    text: 'locales.en-gb'
  },


  EN_US: {
    icon: 'flag-icon-us',
    isEU: false,
    messages: en_US,
    text: 'locales.en-us'
  },

  // Spanish
  ES: {
    messages: es_ES
  },
  ES_ES: {
    icon: 'flag-icon-es',
    isEU: true,
    messages: es_ES,
    text: 'locales.es-es'
  },
  ES_US: {
    icon: 'flag-icon-mx',
    isEU: false,
    messages: es_US,
    text: 'locales.es-us'
  }
}

export const toUrlFormat = (language) => {
  const prefix = language.substr(0, 2)
  const sufix = language.substr(3, 6)

  return sufix === '' ? prefix.toLowerCase() : `${prefix.toLowerCase()}-${sufix.toUpperCase()}`
}

/**
 * A dynamic list of languages, so that we dont have to hardcode
 * any kind of available languages list. With this in place
 * all we should do to add a new language is to add it inside
 * AppLanguages with following name convention for the defining
 * property:
 * <UPPERCASE LOCALE>|<UPPERCASE LOCALE PREFIX>_<UPPERCASE LOCALE SUFIX>
 *
 * i.e: EN, ES, EN_US, DE, IT, etc.
 */
export const languages = Object.keys(AppLanguages).map((langKey) => toUrlFormat(langKey))

export const specificLanguages = Object.keys(AppLanguages)
  .filter((langKey) => AppLanguages[langKey].text)
  .map((langKey) => toUrlFormat(langKey))

export const languageRegex = new RegExp(`(${languages.join('|')})`, 'i')

export const defaultLang = en_GB
