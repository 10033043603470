import React from 'react'
import PhoneInput from 'react-phone-input-2'
import routingService from 'seniors-first-commons/services/routingService'
import { toUrlFormat } from 'seniors-first-commons/shared/languages'
import es from 'react-phone-input-2/lang/es.json'


const getLanguage = (lang) => {
  switch (lang) {
  case 'es':
    return es
  default:
    return {}
  }
}

const CustomPhoneInput = ({
  form,
  field
}) => {

  const envLanguage = routingService.getLanguage() || toUrlFormat(process.env.REACT_APP_LANGUAGE)
  const language = getLanguage(envLanguage)

  const onChange = (e) => {
    form.setFieldValue(field.name, e)
  }

  const onBlur = () => {
    form.setFieldTouched(field.name)
  }

  return (
    <PhoneInput
      disableSearchIcon
      dropdownStyle={{ textAlign: 'left' }}
      enableSearch={true}
      inputProps={{ name: field.name }}
      inputStyle={{ width: '100%' }}
      localization={language}
      onBlur={onBlur}
      onChange={onChange}
      searchPlaceholder='Search'
      value={`+${field.value}`}
    />
  )
}

export default CustomPhoneInput
