import React from 'react'
import { OpenedBox } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const EmptyBox = ({
  text='',
  additionalClasses='',
  stroke='#fff'
}) => {
  return (
    <div className={`${classes.emptyContainer} ${additionalClasses}`}>
      <OpenedBox
        fill='none'
        height='240px'
        stroke={stroke}
        width='240px'
      />
      {text.length ?
        <p className='p1-regular grey'>{text}</p>
        : null}
    </div>
  )
}

export default EmptyBox
