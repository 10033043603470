import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import './RequestModal.css'
import { injectIntl } from 'react-intl'

class RequestModal extends Component {
  render() {
    const text = this.renderText()
    const acceptButtonText = this.props.intl.formatMessage({
      id: 'app.privacy.accept_all'
    })

    return (
      <CustomModal
        hasCloseButton={false}
        onClose={()=>{}}
        open={this.props.show}
        shouldCloseOnOverlayClick={false}
        showBackdrop={false}
      >
        <div className='has-background-white has-padding-7'>
          {text}
          <div className='columns has-margin-t-6 is-vcentered has-text-centered'>
            <div className='column'>
              <button
                className='button filled full-width'
                onClick={this.props.accept}
                style={{height: '42px', maxWidth: '180px'}}
              >{acceptButtonText}</button>
            </div>
          </div>
        </div>
      </CustomModal>
    )
  }

  renderText() {
    let output = ''
    const cookiePolicyUrl = this.props.intl.formatMessage({
      id: 'app.footer.cookies.url'
    })
    const locale = this.props.locale && this.props.locale.toLowerCase()

    switch (locale) {
    // Spanish
    case 'es-es':
    case 'es-us':
      output = (<p className='has-text-justified'>
          Utilizamos cookies propias y de terceros para analizar el tráfico en nuestra web mediante la obtención de los datos necesarios para estudiar su navegación. Puede obtener más información en la <a className='configure-link' href={cookiePolicyUrl} rel='noopener noreferrer' target='_blank'>Política de Cookies</a>. Puede aceptar todas las cookies pulsando el botón &quot;Aceptar&quot; o configurarlas o rechazar su uso <span className='configure-link' onClick={this.props.configure}>aquí</span>
      </p>)
      break

      // All English
    default:
      output = (<p className='has-text-justified'>
          We use our own cookies and those of third parties to analyze the traffic on our website by
          gathering the data necessary to study its navigation. You can find more information in our <a className='configure-link' href={cookiePolicyUrl} rel='noopener noreferrer' target='_blank'>Cookie Policy</a>. You can accept all the cookies by clicking the “Accept” button or configure them or reject
            their use <span className='configure-link' onClick={this.props.configure}>here</span>.
      </p>)
      break
    }

    return output
  }
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale
})

export default injectIntl(connect(mapStateToProps)(RequestModal))
