import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { PersonIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Birthday = ({ data, caregiverStatus }) => {
  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <PersonIcon
        fill='none'
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='18'
      />
      <span
        className={`
          has-margin-l-7 p2-regular
          ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}
        `}
      >
        {data['age']}{'  '}
        <FormattedMessageWithClass
          id='client.general.caregiverCard.age.years'
        />
      </span>
    </div>
  )
}

export default Birthday
