import React from 'react'
import Services from 'seniors-first-commons/components/molecules/OfferCardParts/Services'
import Modality from 'seniors-first-commons/components/molecules/OfferCardParts/Modality'
import Address from 'seniors-first-commons/components/molecules/OfferCardParts/Address'
import Duration from 'seniors-first-commons/components/molecules/OfferCardParts/Duration'
import Contract from 'seniors-first-commons/components/molecules/OfferCardParts/Contract'
import Retribution from 'seniors-first-commons/components/molecules/OfferCardParts/Retribution'
import ActionsDropdown from 'components/molecules/JobOfferActions/ActionsDropdown'
import OfferPostButton from 'components/molecules/JobOfferActions/OfferPostButton'
import OfferFindSendButton from 'components/molecules/JobOfferActions/OfferFindSendButton'
import ViewCandidatesButton from 'components/molecules/JobOfferActions/ViewCandidatesButton'
import ArchiveButton from 'components/molecules/JobOfferActions/ArchvieButton'
import classes from './index.module.css'


const DataLevel = ({ isMarginless, additionalClass='', children }) => {
  return (
    <div className={`ellipsis ${additionalClass} ${!isMarginless ? 'has-margin-b-6' : ''}`}>
      {children}
    </div>
  )
}

const JobOfferCard = ({
  item,
  profile,
  matchesMobile,
  archived,
  perPage,
  onOpenDaysAndShiftsModal,
  onFindAndSend,
  onDuplicate,
  onToggleArchivedStatus,
  onPost,
  onViewCandidates
}) => {
  return (
    <div className={'card shadow-card has-margin-b-7'}>
      <div className={`card-content has-padding-bt-${matchesMobile ? '5' : '3'} has-padding-lr-${matchesMobile ? '7' : '3'}`}>
        <div className='content'>
          <div className={`${classes.cardHeader} has-margin-b-7`}>
            <span className='t4-bold primary ellipsis'>
              {item.name}
            </span>

            {!item.archived &&
              <ActionsDropdown
                onDuplicate={() => onDuplicate(item, perPage)}
                onToggleArchivedStatus={() => onToggleArchivedStatus(item?.id, perPage)}
                perPage={perPage}
              />
            }
          </div>

          <div className='columns is-marginless-b is-vcentered'>
            <div className='column  is-two-thirds is-paddingless-b'>
              <DataLevel
                additionalClass={matchesMobile ? classes.servicesWrapper : ''}
                isMarginless={matchesMobile}
              >
                <Services services={item?.service_categories} />
              </DataLevel>

              <DataLevel>
                <Modality
                  data={item}
                  matchesMobile={matchesMobile}
                  onOpenDaysAndShiftsModal={onOpenDaysAndShiftsModal}
                />
              </DataLevel>

              <DataLevel>
                <Address data={item} />
              </DataLevel>

              <DataLevel>
                <Duration data={item} />
              </DataLevel>

              <DataLevel>
                <Contract data={item} />
              </DataLevel>

              <DataLevel isMarginless>
                <Retribution
                  currency={profile?.currency}
                  data={item}
                />
              </DataLevel>
            </div>

            <div className='column is-one-third'>
              <div className={`${classes.actions}`}>
                {item.archived ?
                  <ArchiveButton
                    archived={archived}
                    onClick={() => onToggleArchivedStatus(item?.id)}
                  />
                  :
                  <>
                    <OfferPostButton
                      onClick={() => onPost(item?.id)}
                      posted_until={item?.posted_until}
                    />

                    <OfferFindSendButton
                      onClick={() => onFindAndSend(item?.id)}
                    />

                    <ViewCandidatesButton
                      onClick={() => onViewCandidates(item?.id)}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobOfferCard
