import React from 'react'
import Sidebar from 'react-sidebar'
import qs from 'query-string'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import SearchFilterModel from '../../../models/searchFilters'
import SearchFiltersForm from 'components/organisms/SearchFiltersForm'
import { FORMATION } from 'seniors-first-commons/utils/constants'


const Content = ({
  helpers,
  onClose,
  onFilter,
  onRemoveFilters
}) => {

  const params = qs.parse(window.location.search, { parseBooleans: true })

  const initialValues = {
    ...new SearchFilterModel(),
    age: params.age ? params.age.split('-').map(item => parseInt(item)) : new SearchFilterModel().age,

    disease_experience: params.disease_experience ?
      helpers.illnesses && helpers.illnesses.filter(item => params.disease_experience.split(',').includes(`${item.value}`))
      : new SearchFilterModel().disease_experience,

    driving_license: params.driving_license ?
      params.driving_license :
      new SearchFilterModel().driving_license,

    education_level: params.education_level ?
      params.education_level.split(',').map(level => ({ label: FORMATION[parseInt(level)], value: level })) :
      new SearchFilterModel().education_level,

    gender: params.gender ? [params.gender] : new SearchFilterModel().gender,

    languages: params.languages ?
      helpers.languages && helpers.languages.filter(item => params.languages.split(',').includes(`${item.value}`))
      : new SearchFilterModel().languages,

    mobilizations: params.mobilizations ?
      params.mobilizations :
      new SearchFilterModel().mobilizations,

    nationalities: params.nationalities ?
      helpers.countries && helpers.countries.filter(item => params.nationalities.split(',').includes(`${item.value}`))
      : new SearchFilterModel().nationalities,

    pet_friendly: params.pet_friendly ?
      params.pet_friendly :
      new SearchFilterModel().pet_friendly
  }

  return (
    <SearchFiltersForm
      helpers={helpers}
      initialValues={initialValues}
      onClose={onClose}
      onFilter={onFilter}
      onRemoveFilters={onRemoveFilters}
      params={params}
    />
  )
}

const SearchFilterSidebar = ({
  open,
  helpers,
  onClose,
  onShow,
  onFilter,
  onRemoveFilters
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  return (
    <Sidebar
      onSetOpen={onShow}
      open={open}
      pullRight
      sidebar={
        <Content
          helpers={helpers}
          onClose={onClose}
          onFilter={onFilter}
          onRemoveFilters={onRemoveFilters}
        />
      }
      sidebarClassName='search_filter_sidebar'
      styles={{
        sidebar: {
          background: '#ffffff',
          height: 'auto',
          position: 'fixed',
          top: '70px',
          width: matchesMobile ? '100%' : '450px',
          zIndex: '9'
        }
      }}
    >
      <div></div>
    </Sidebar>
  )
}


export default SearchFilterSidebar
