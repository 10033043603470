import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { MailIcon } from 'seniors-first-commons/components/atoms/images/icons'


const MailChatButton = ({
  matchesMobile,
  basicData,
  onClick
}) => {
  return (
    <div className='custom-tooltip'>
      <button className='icon-button'>
        <MailIcon
          fill='none'
          height='18'
          width='18'
        />
      </button>

      <div
        className='tooltip-content is-flex white p3-bold'
        placement={matchesMobile ? 'left' : 'bottom'}
        style={{ alignItems: 'center' }}
      >
        <span onClick={onClick}>
          <FormattedMessageWithClass
            className='pointer'
            id='client.chats.conversationHeader.copy'
          />
        </span>
        <span className='has-padding-lr-7 p3-bold'>|</span>
        <a
          className='pointer white'
          href={`mailto:${basicData?.email}`}
        >
          <FormattedMessageWithClass id='client.chats.conversationHeader.email' />
        </a>
      </div>
    </div>
  )
}

export default MailChatButton
