import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import LanguageSwitch from 'seniors-first-commons/components/organisms/LanguageSwitch'
import { formatToMatchEnv } from 'seniors-first-commons/utils/helpers'

const Footer = ({ role, locale }) => {
  const messages = {
    cookiesAdviceText: useFormatMessage('app.footer.cookies.text'),
    cookiesAdviceUrl: useFormatMessage(formatToMatchEnv('app.footer.cookies.url')),
    legalAdviceText: useFormatMessage('app.footer.legal.text'),
    legalAdviceUrl: useFormatMessage(formatToMatchEnv('app.footer.legal.url')),
    privacyPolicyText: useFormatMessage('app.footer.privacy.text'),
    privacyPolicyUrl: useFormatMessage(formatToMatchEnv('app.footer.privacy.url')),
    termsConditionsText: useFormatMessage('app.footer.terms-conditions.text'),
    termsConditionsUrl: useFormatMessage(formatToMatchEnv(`app.footer.terms-conditions${role === 'client' ? '-clients' : ''}.url`))
  }

  return (
    <footer className='footer is-paddingless has-background-link'>
      <div className='content has-padding-4 has-text-centered is-size-7'>
        <div className='columns'>
          {(locale === 'gb' || locale === 'es') &&
            <div className='column'>
              <a className='has-text-white' href={messages.legalAdviceUrl} rel='noopener noreferrer' target='_blank'>
                {messages.legalAdviceText}
              </a>
            </div>
          }
          <div className='column'>
            <a className='has-text-white' href={messages.termsConditionsUrl} rel='noopener noreferrer' target='_blank'>
              {messages.termsConditionsText}
            </a>
          </div>
          <div className='column is-narrow'>
            <a className='has-text-white' href={messages.cookiesAdviceUrl} rel='noopener noreferrer' target='_blank'>
              {messages.cookiesAdviceText}
            </a>
          </div>
          <div className='column'>
            <a className='has-text-white' href={messages.privacyPolicyUrl} rel='noopener noreferrer' target='_blank'>
              {messages.privacyPolicyText}
            </a>
          </div>
          <div className='column is-paddingless is-narrow'>
            <LanguageSwitch isPublic />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
