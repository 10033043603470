import { createReducer } from '@reduxjs/toolkit'
import {
  toggleRightSidebar,
  toggleMobileSidebar,
  changeErrorMessageStatus,
  setInbox,
  toggleChatModal,
  toggleSuccessStatus
} from './actions'


const initValues = {
  chat: {
    showModal: false
  },
  error: '',
  inbox: false,
  showMobileSidebar: false,
  showRightSidebar: false,
  success: ''
}

const reducer = createReducer(initValues, (builder) => {
  builder.addCase(toggleRightSidebar, (state, action) => {
    state.showRightSidebar = action.payload
  })

  builder.addCase(toggleMobileSidebar, (state, action) => {
    state.showMobileSidebar = action.payload
  })

  builder.addCase(changeErrorMessageStatus, (state, action) => {
    state.error = action.payload
  })

  builder.addCase(toggleChatModal, (state, action) => {
    state.chat.showModal = action.payload
  })

  builder.addCase(setInbox, (state, action) => {
    state.inbox = action.payload
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })
})

export default reducer
