import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

export const useFormatMessage = (id) => {
  const intl = useIntl()
  return intl.formatMessage({ id })
}

export const FormattedMessageWithClass = ({
  className='',
  placement='',
  ...props
}) => {
  if (props.id) {
    return (
      <FormattedMessage {...props}>
        {(txt) => (
          <span className={className} placement={placement}>
            {txt}
          </span>
        )}
      </FormattedMessage>
    )
  }
  else return null
}
