import React from 'react'
import NavbarDropdown from '../NavbarDropdown'
import classes from './index.module.css'


const NavbarAvatar = ({ firstname, photo, dropdownItems }) => {
  return (
    <div className='navbar-item has-dropdown is-hoverable'>
      {/* eslint-disable-next-line */}
      <a 
        className={`navbar-link is-flex ${classes.userDataWrapper}`}
        href='#'
      >
        <figure
          className='image'
          style={{ height: '36px', width: '36px' }}
        >
          <img
            alt='user'
            className='is-rounded'
            src={photo}
          />
        </figure>

        <span
          className='primary-light p1-bold has-padding-lr-7 ellipsis'
          style={{ maxWidth: '80px' }}
        >
          {firstname}
        </span>
      </a>

      <NavbarDropdown dropdownItems={dropdownItems} />
    </div>
  )
}


export default NavbarAvatar
