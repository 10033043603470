import React from 'react'


const NotificationText = ({
  formatDate,
  details,
  onNotificationBodyClick
}) => {
  return (
    <>
      <p className='p2-regular'>{formatDate}</p>
      <p
        dangerouslySetInnerHTML={{__html: details}}
        onClick={onNotificationBodyClick}
      >
      </p>
    </>
  )
}


export default NotificationText
