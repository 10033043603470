import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'

const FieldLabel = ({
  labelName='',
  labelType='t2-bold',
  color='primary',
  id='',
  centered=true,
  bottomSpace=5,
  topSpace=7
}) => {
  return (
    <label
      className={`label ${centered ? 'has-text-centered' : ''} ${bottomSpace ? `has-margin-b-${bottomSpace}` : 'is-marginless-b'} ${topSpace ? `has-margin-t-${topSpace}` : 'is-marginless-t'} ${classes.label}`}
      htmlFor={`${id}`}
    >
      <FormattedMessageWithClass
        className={`${labelType} ${color}`}
        id={labelName}
      />
    </label>
  )
}

export default FieldLabel
