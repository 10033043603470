import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { connect } from 'react-redux'
import routingService from 'seniors-first-commons/services/routingService'
import LoginLayout from 'seniors-first-commons/components/templates/LoginLayout'
import { login, socialLogin, socialAuthorize } from 'seniors-first-commons/shared/store/auth/actions'
import { ROUTE } from 'root/constants'


const Login = ({
  role,
  locale,
  location,
  loginData,
  login,
  socialLogin,
  socialAuthorize
}) => {
  const [showWrongAppModal, setShowWrongAppModal] = useState(false)
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  useEffect(() => {
    document.title = 'Seniors First | Login'
  }, [])

  const socialAuthorizeWrapper = useCallback((social, code, role) => {
    socialAuthorize({ code, role, social })
      .then(({ payload }) => {
        if (payload.role && payload.role !== role) {
          setShowWrongAppModal(true)
        }
      })
      .catch(() => {})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (location && location.search) {
      const split = location.search.split('&')
      const social = location.search.includes('google') ? 'google' : 'facebook'

      if (social === 'google') {
        const loc = split.find((e) => e.startsWith('code'))
        const code = decodeURIComponent(loc.substr(5))
        socialAuthorizeWrapper(social, code, role)
      } else if ((split[0] || '').startsWith('?code=')) {
        const code = split[0].substr(6)
        socialAuthorizeWrapper(social, code, role)
      }
    }
    // eslint-disable-next-line
  }, [])

  const onLogin = (values) => {
    login({ ...values, role })
      .then(({ payload }) => {
        if (!payload.status && role !== payload.role) {
          setShowWrongAppModal(true)
        }
      })
  }

  const onRegister = () => {
    routingService.push(history, ROUTE.REGISTER)
  }

  const onPasswordReset = () => {
    routingService.push(history, ROUTE.PASSWORD_RESET)
  }

  const onFBLogin = () => {
    socialLogin({ role, social: 'facebook' })
  }

  const onGoogleLogin = () => {
    socialLogin({ role, social: 'google' })
  }

  const onCloseWrongAppModal = () => {
    setShowWrongAppModal(!showWrongAppModal)
  }

  const onLogoClick = () => routingService.push(history, ROUTE.LOGIN)

  return (
    <>
      <LoginLayout
        locale={locale}
        loginData={loginData}
        matchesMobile={matchesMobile}
        onCloseWrongAppModal={onCloseWrongAppModal}
        onFBLogin={onFBLogin}
        onGoogleLogin={onGoogleLogin}
        onLogin={onLogin}
        onLogoClick={onLogoClick}
        onPasswordReset={onPasswordReset}
        onRegister={onRegister}
        role={role}
        showWrongAppModal={showWrongAppModal}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    loginData: state.auth.login,
    token: state.auth.token.access_token
  }
}

const mapDispatchToProps = {
  login,
  socialAuthorize,
  socialLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
