import React, { Component, Fragment } from 'react'
import RequestModal from './RequestModal/RequestModal'
import ConfigureModal from './ConfigureModal/ConfigureModal'
import {
  configureCookies,
  enableTrackingSafely,
  getPrivacyConfigured,
  getTracking,
  disableTrackingSafely,
  enableMarketingSafely,
  disableMarketingSafely,
  getMarketing
} from 'seniors-first-commons/services/privacy'
import './Privacy.css'

class Privacy extends Component {
  constructor(props) {
    super(props)

    this.cookieConfigured = getPrivacyConfigured()
    this.tracking = getTracking()
    this.marketing = getMarketing()

    // Show the banner only if cookies haven't been configured yet
    this.state = {
      showConfigure: false,
      showRequest: !this.cookieConfigured
    }

    // If cookies were configured previously, 
    // then proceed to ensure the correct enabling/disabling
    if (this.cookieConfigured) {
      // Handle tracking cookies enabling/disabling
      if (this.tracking) {
        enableTrackingSafely()
      } else {
        disableTrackingSafely()
      }

      // Handle marketing cookies enabling/disabling
      if (this.marketing) {
        enableMarketingSafely()
      } else {
        disableMarketingSafely()
      }
    }
  }

  componentDidMount() {
    // regionService.getPath()
    //   .then(regionalizedPath => {
    //     history.push(regionalizedPath)
    //   });

    //event {type: "cookie", message: {t}}
    if(typeof window !== 'undefined')
      window.addEventListener('message',(event)=>{
        //if(event.origin !== process.env.REACT_APP_LANDING_URL) return

        const message = event.data

        if(message.type === 'COOKIE_SET') {
          configureCookies(message.value)
        }
      })
  }

  configureHandler = () => {
    this.setState({
      showConfigure: true,
      showRequest: false
    })
  }

  acceptHandler = () => {
    this.setState({
      showRequest: false
    })

    // Save configuration and enable tracking
    configureCookies({ marketing: true, tracking: true })
    this.cookieConfigured = true

    enableTrackingSafely()
    this.tracking = true

    enableMarketingSafely()
    this.marketing = true
  }

  confirmHandler = (configuration) => {
    this.setState({
      showConfigure: false
    })

    // Save configuration
    configureCookies({ ...configuration })
    this.cookieConfigured = true

    // Enable tracking if the user allowed it
    this.tracking = configuration.tracking
    if (this.tracking) {
      enableTrackingSafely()
    } else {
      disableTrackingSafely()
    }

    // Enable marketing if the user allowed it
    this.marketing = configuration.marketing
    if (this.marketing) {
      enableMarketingSafely()
    } else {
      disableMarketingSafely()
    }
  }

  rejectAllHandler = () => {
    this.setState({
      showConfigure: false
    })

    // Save configuration but don't enable tracking
    configureCookies({ marketing: false, tracking: false })
    this.cookieConfigured = true

    this.tracking = false
    disableTrackingSafely()

    this.marketing = false
    disableMarketingSafely()
  }

  render() {
    return (
      <Fragment>
        <RequestModal
          accept={this.acceptHandler}
          configure={this.configureHandler}
          show={this.state.showRequest}
        />
        <ConfigureModal
          confirm={this.confirmHandler}
          rejectAll={this.rejectAllHandler}
          show={this.state.showConfigure}
        />
      </Fragment>
    )
  }
}

export default Privacy
