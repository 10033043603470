import React from 'react'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CaregiverCardHeader from 'components/organisms/CaregiverCardHeader'
import CaregiverPhonePart from 'components/molecules/CaregiverCardParts/Phone'
import CaregiverEmailPart from 'components/molecules/CaregiverCardParts/Email'
import CaregiverAddressPart from 'components/molecules/CaregiverCardParts/Address'
import CaregiverBirthdayPart from 'components/molecules/CaregiverCardParts/Birthday'
import CaregiverGenderPart from 'components/molecules/CaregiverCardParts/Gender'
import CaregiverNationalitiesPart from 'components/molecules/CaregiverCardParts/Nationalities'
import CaregiverLanguagesPart from 'components/molecules/CaregiverCardParts/Languages'
import CaregiverServicesPart from 'components/molecules/CaregiverCardParts/Services'
import { WhatsappIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const DataLevel = ({ t=0, b=0, children }) => {
  return (
    <div style={{ marginBottom: `${b}px`, marginTop: `${t}px` }}>
      {children}
    </div>
  )
}

const CaregiverCard = ({
  selectedCaregiver,
  caregiverStatus,
  data,
  hasViewProfileButton=true,
  nameIsClickable=true,
  imageIsClickable=false,
  onViewProfile,
  onProfileImageClick=() => {},
  onOpenVideoCVModal
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  return (
    <div
      className={`card ${data?.viewed ? 'bordered-card' : 'bordered-card-strong' } ${caregiverStatus.text === 'Rejected' ? classes.rejectedText : ''}`}
      style={{ overflow: 'visible' }}
    >
      <CaregiverCardHeader
        caregiverStatus={caregiverStatus}
        clickableImg={imageIsClickable}
        clickableName={nameIsClickable}
        data={data}
        matchesMobile={matchesMobile}
        onOpenVideoCVModal={onOpenVideoCVModal}
        onProfileImageClick={onProfileImageClick}
        onViewProfile={onViewProfile}
      />

      <div className='card-content has-padding-t-7'>
        <FormattedMessageWithClass
          className={`p2-bold ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
          id='client.general.caregiverCard.offeredServices'
        />
        <DataLevel t={5}>
          <CaregiverServicesPart
            caregiverStatus={caregiverStatus}
            data={data}
          />
        </DataLevel>

        <DataLevel t={15}>
          <FormattedMessageWithClass
            className={`p2-bold ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
            id='client.general.caregiverCard.personalInformation'
          />
          <DataLevel t={5}>
            <CaregiverAddressPart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>

          <DataLevel t={5}>
            <CaregiverBirthdayPart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>

          <DataLevel t={5}>
            <CaregiverGenderPart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>

          <DataLevel t={5}>
            <CaregiverNationalitiesPart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>

          <DataLevel t={5}>
            <CaregiverLanguagesPart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>
        </DataLevel>

        <DataLevel b={25} t={15}>
          <FormattedMessageWithClass
            className={`p2-bold ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
            id='client.general.caregiverCard.contactInformation'
          />
          <DataLevel t={5}>
            <CaregiverPhonePart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>
          <DataLevel t={5}>
            <CaregiverEmailPart
              caregiverStatus={caregiverStatus}
              data={data}
            />
          </DataLevel>
        </DataLevel>

        <div className='has-text-centered full-width'>
          <button
            className={`
              button filled ${classes.chatButton}
              ${caregiverStatus.chatButtonColor}
              ${selectedCaregiver === data?.id && caregiverStatus.loading ? 'is-loading' : ''}
            `}
            disabled={caregiverStatus.disabled || (caregiverStatus.loading && selectedCaregiver === data?.id)}
            onClick={!caregiverStatus.disabled ? caregiverStatus.onChat : () => {}}
          >
            <WhatsappIcon />
            {caregiverStatus.text}
          </button>

          {hasViewProfileButton
            ? (<button
              className='button-text main-button'
              onClick={() => onViewProfile(data.id)}
            >
              <FormattedMessageWithClass
                className='p1-bold'
                id='client.general.caregiverCard.actions.viewProfile'
              />
            </button>)
            : null
          }
        </div>
      </div>
    </div>
  )
}

export default CaregiverCard
