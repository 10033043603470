import React from 'react'
import InitialLayout from 'seniors-first-commons/components/templates/InitialLayout'
import PasswordChangeForm from 'seniors-first-commons/components/organisms/PasswordChangeForm'
import PasswordChangedSuccess from 'seniors-first-commons/components/atoms/PasswordChangedSuccess'


const PasswordChangeLayout = ({
  locale,
  role,
  resetPasswordData,
  onChangePassword,
  onRegister,
  onLogoClick
}) => {
  return (
    <InitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>
      {!resetPasswordData.success
        ? <PasswordChangeForm
          goToRegister={onRegister}
          onFormSubmit={onChangePassword}
          submitting={resetPasswordData.loading}
        />
        : <PasswordChangedSuccess />
      }
    </InitialLayout>
  )
}

export default PasswordChangeLayout
