import React, {useEffect } from 'react'
import { connect } from 'react-redux'
import { path, pathOr } from 'ramda'
import BillingLayout from 'components/templates/BillingLayout'
import {
  getBillingData,
  updateBillingData,
  sendBillingData
} from 'store/billing/actions'
import rawCountries from 'seniors-first-commons/utils/rawCountries'


const Billing = ({
  helpers,
  billing,
  profile,
  loading,
  getBillingData,
  updateBillingData,
  sendBillingData
}) => {
  const countryOfResidence = pathOr('', ['country_id'], profile)
  const fromUK = countryOfResidence === 185

  useEffect(() =>{
    getBillingData()
  }, [getBillingData])

  const onChangeCountry = (e, setFieldValue) => {
    const country = rawCountries.find(item => item[2] === e.code.toLowerCase())

    if (e.value !== path(['index', 'country_id'], profile)) {
      setFieldValue('address', {
        address_lat: 0,
        address_lng: 0,
        formatted_address: ''
      })
      setFieldValue('phone', country[3])
    }
    setFieldValue('country', e)
  }

  const onFormSubmit = ({
    company_name,
    company_id_number,
    person_first_last_name,
    person_id_number,
    ...values
  }, { setStatus, setSubmitting }) => {
    let data = {}
    if (!fromUK) data = { ...values, person_first_last_name, person_id_number }
    else data = { ...values, company_id_number, company_name }

    if (billing) updateBillingData({ ...data })
    else sendBillingData({ ...data })

    setStatus({ success: true })
    setSubmitting(false)
    data = {}
  }

  return (
    <>
      <BillingLayout
        logicPropsAndMethods={{
          billing,
          fromUK,
          helpers,
          loading,
          onChangeCountry,
          onFormSubmit
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  billing: state.billing.billing.data,
  helpers: state.helpers.helpers,
  loading: state.billing.modifyBillingDataLoading,
  profile: state.profile.profile.index
})

const mapDispatchToProps = {
  getBillingData,
  sendBillingData,
  updateBillingData
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
