import React from 'react'
import LoginForm from 'seniors-first-commons/components/organisms/LoginForm'
import WrongAppModal from 'seniors-first-commons/components/molecules/WrongAppModal'
import InitialLayout from '../InitialLayout'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'


const LoginLayout = ({
  role,
  locale,
  loginData,
  showWrongAppModal,
  onLogin,
  onFBLogin,
  onGoogleLogin,
  onPasswordReset,
  onRegister,
  onCloseWrongAppModal,
  onLogoClick
}) => {
  return (
    <InitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>
      <p className='t1-bold primary'>
        <FormattedMessageWithClass
          id={`app.${role}.login.form.title`}
        />
      </p>

      <LoginForm
        isSubmitting={loginData.loading}
        onFBLogin={onFBLogin}
        onGoogleLogin={onGoogleLogin}
        onPasswordReset={onPasswordReset}
        onRegister={onRegister}
        onSubmit={onLogin}
      />

      <WrongAppModal
        onClose={onCloseWrongAppModal}
        role={role}
        show={showWrongAppModal}
      />
    </InitialLayout>
  )
}

export default LoginLayout
