import {configureStore} from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './rootReducer'


const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunkMiddleware],
  reducer: rootReducer
})

export default store
