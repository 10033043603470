import moment from 'moment'
import { loadTranslation } from 'seniors-first-commons/shared/store/translations/actions'
import { languageRegex, toUrlFormat } from 'seniors-first-commons/shared/languages'
import appStore from '../../store'

export class RoutingService {
  push(history, route) {
    const routeLanguage = this.getLanguage()
      ? toUrlFormat(this.getLanguage())
      : toUrlFormat(process.env.REACT_APP_LANGUAGE)

    const finalRoute = route.startsWith(`/${routeLanguage}`) ? route : `/${routeLanguage}${route}`

    return history.push(finalRoute)
  }

  setLanguage(language) {
    if (languageRegex.test(language) && language !== this.setLanguage) {
      loadTranslation(language.toUpperCase())(appStore.dispatch)

      moment.locale(language)
    }
  }

  formatLanguage(lang) {
    return lang && lang.toLowerCase().split('/')[1]
  }

  getLanguage() {
    return appStore.getState().intl.locale
  }

  getFinalRoute(route) {
    const routeLanguage = this.getLanguage()
      ? toUrlFormat(this.getLanguage())
      : toUrlFormat(process.env.REACT_APP_LANGUAGE)

    const finalRoute = route.startsWith(`/${routeLanguage}`) ? route : `/${routeLanguage}${route}`

    return finalRoute
  }
}

const service = new RoutingService()

export default service
