import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { handleServiceType } from 'seniors-first-commons/utils/constants'
import classes from './index.module.css'


const Services = ({ data, caregiverStatus }) => {
  const services = data.service_categories

  const ProvidedServices = ({ services }) => {
    return (
      <div className='level is-mobile'>
        {services.filter((v,i,a)=> a.findIndex(t=>(t.id === v.id))===i)
          .map(({ id }, index) => (
            <div className='level-item' key={index}>
              <figure className={`custom-tooltip ${classes.serviceIconWrapper}`}>
                {handleServiceType(id, '40', '40', caregiverStatus.text === 'Rejected')['image']}

                {caregiverStatus.text !== 'Rejected' &&
                <FormattedMessageWithClass
                  className='tooltip-content p3-bold white'
                  id={`apps.general.jobOffer.services.${id}`}
                  placement='top'
                />
                }
              </figure>
            </div>
          ))}
      </div>
    )
  }

  return (
    <div
      className={classes.servicesContainer}
      style={{ height: '72px' }}
    >
      {!!services.length ?
        <ProvidedServices services={services} />
        : <FormattedMessageWithClass
          className={`p2-regular ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
          id='client.general.caregiverCard.noServices'
        />
      }
    </div>
  )
}

export default Services
