// should be implemented with the rating form 
// see: src/pages/ExperienceFeedback/index.js


export default class ExperienceFeedback {
  constructor() {
    this.rating = 0
    this.hired = ''
  }
}
