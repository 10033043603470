import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const NavbarDropdown = ({ dropdownItems }) => {
  return (
    <div
      className='navbar-dropdown is-boxed'
      style={{ minWidth: '160px' }}
    >
      {dropdownItems.filter(item => !item.hide)
        .map(({ name, external, isActive, ...rest }, index) => (
        // esline-disalbe-next-line
          <a
            className={`
              has-padding-lr${index !== 0 ? 't' : ''}-7 
              ${isActive !== undefined ? isActive ? 'primary' : 'black' : 'grey-light'} 
              p1-${isActive ? 'bold' : 'regular'} 
              ${isActive !== undefined ? classes.navLink : 'cursor-disabled'}
            `}
            href={external ? external : null }
            key={index}
            onClick={isActive !== undefined ? rest.onClick : () => {}}
            rel='noopener noreferrer'
            style={{ display: 'block' }}
            target='_blank'
          >
            <FormattedMessageWithClass id={name} />
          </a>
        ))}
    </div>
  )
}


export default NavbarDropdown
