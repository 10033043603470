import React, { Fragment } from 'react'
import withChat from 'hoc/withChat'
import CaregiverCard from 'components/organisms/CaregiverCard'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'
import MobileLayout from '../MobileLayout'


const DesktopLayout = ({
  candidates,
  selectedCaregiver,
  caregiverStatus,
  onOpenVideoCVModal,
  onViewProfile
}) => {
  return (
    <div className='columns is-marginless-l is-paddingless-l is-multiline has-margin-t-4'>
      {candidates.items.map((candidate, index) => (
        <div className='column is-one-third-desktop is-half-tablet is-one-quarter-fullhd is-paddingless-l' key={index}>
          <CaregiverCard
            caregiverStatus={caregiverStatus({ ...candidate })}
            data={candidate}
            onOpenVideoCVModal={onOpenVideoCVModal}
            onViewProfile={() => onViewProfile(candidate)}
            selectedCaregiver={selectedCaregiver}
          />
        </div>
      ))}
    </div>
  )
}

const ConnectedCandidates = ({
  logicPropsAndMethods: {
    candidates,
    matchesMobile,
    loading,
    onOpenVideoCVModal
  },
  selectedCaregiver,
  caregiverStatus,
  onViewProfile
}) => {

  return (
    <Fragment>
      {
        !loading ?
          candidates.items.length ?
            matchesMobile
              ? <MobileLayout
                candidates={candidates}
                caregiverStatus={caregiverStatus}
                onOpenVideoCVModal={onOpenVideoCVModal}
                onViewProfile={onViewProfile}
                selectedCaregiver={selectedCaregiver}
              />
              : <DesktopLayout
                candidates={candidates}
                caregiverStatus={caregiverStatus}
                onOpenVideoCVModal={onOpenVideoCVModal}
                onViewProfile={onViewProfile}
                selectedCaregiver={selectedCaregiver}
              />
            : <EmptyBox stroke='#525860' />
          : null
      }
    </Fragment>
  )
}

export default withChat(ConnectedCandidates, 'connected')
