import React from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'ramda'
import {
  FirstStepSchema,
  SecondStepSchema,
  ThirdStepSchema,
  FourthStepSchema,
  FifthStepSchema,
  SixthStepSchema
} from 'schemas/createJobOffer'
import JobOfferInitValues from 'models/createJobOffer'
import routingService from 'seniors-first-commons/services/routingService'
import HandleDaysShifts from 'seniors-first-commons/services/handleDayShifts'
import AppContainer from 'components/organisms/AppContainer'
import CreateJobOfferWizard from 'components/organisms/CreateJobOfferWizard'
import JobOfferSummary from 'components/molecules/JobOfferSummary'
import {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  SixthStep
} from './steps'
import { CREATE_JOB_OFFER_STEPS } from 'seniors-first-commons/utils/constants'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const WizardStep = ({ children, ...props }) => children(props)

const CreateJobOfferLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    user,
    profile,
    chosenCountry,
    loading,
    onFormSubmit
  }
}) => {
  const history = useHistory()

  const onRedirectToProfileSettings = () => {
    routingService.push(history, ROUTE.PROFILE_SETTINGS)
  }

  return (
    <AppContainer
      additionalClass={matchesMobile ? 'has-padding-lr-7 is-margineless-lr' : '' }
      name='Create job offer'
    >
      <div className='part'>
        <CreateJobOfferWizard
          hasGoBackButton={true}
          initialValues={new JobOfferInitValues()}
          loadingSubmit={loading}
          matchesMobile={matchesMobile}
          onSubmit={onFormSubmit}
          stepsLineStyles={'full-width'}
          styles={classes.componentsWrapper}
          wizardSteps={CREATE_JOB_OFFER_STEPS}
        >
          <WizardStep validationSchema={FirstStepSchema}>
            {({ parentState: { ...formik }}) => (
              <FirstStep
                country={chosenCountry()}
                onRedirectToProfileSettings={onRedirectToProfileSettings}
                profile={profile}
                user={user}
                {...formik}
              />
            )}
          </WizardStep>

          <WizardStep validationSchema={SecondStepSchema}>
            {({parentState: { ...formik }}) => (
              <SecondStep {...formik} />
            )}
          </WizardStep>

          <WizardStep validationSchema={ThirdStepSchema}>
            {({parentState: { ...formik }}) => (
              <ThirdStep
                matchesMobile={matchesMobile}
                {...formik}
              />
            )}
          </WizardStep>

          <WizardStep validationSchema={FourthStepSchema}>
            {({parentState: { ...formik }}) => (
              <FourthStep {...formik} />
            )}
          </WizardStep>

          <WizardStep validationSchema={FifthStepSchema}>
            {({parentState: { ...formik }}) => (
              <FifthStep profile={profile} {...formik} />
            )}
          </WizardStep>

          <WizardStep validationSchema={SixthStepSchema}>
            {() => <SixthStep />}
          </WizardStep>

          <WizardStep>
            {({
              parentState: { values: {
                visiting_care_day_and_hours,
                live_in_care,
                ...rest
              }}}) => {
              let data = {}

              if (rest.hiring_modality === 'visiting_care'){
                const formattedVisitingCareTimes = visiting_care_day_and_hours
                  .map(({ day_of_week, hour_from, hour_to }) => ({
                    day_of_week: day_of_week.value,
                    hour_from: hour_from.value,
                    hour_to: hour_to.value
                  }))
                data = { visiting_care_day_and_hours: formattedVisitingCareTimes, ...rest }

                HandleDaysShifts.handleVisitingCareTimes(data)
              }
              else if (rest.hiring_modality === 'live_in_care') {
                data = {
                  live_in_care_day_of_week_from: live_in_care.day_of_week_from.value,
                  live_in_care_day_of_week_to: live_in_care.day_of_week_to.value,
                  live_in_care_hour_from: live_in_care.hour_from.value,
                  live_in_care_hour_to: live_in_care.hour_to.value,
                  ...rest
                }
                HandleDaysShifts.handleLiveInCareTimes(data)
              }

              return (
                <JobOfferSummary
                  currency={profile?.currency}
                  matchesMobile={matchesMobile}
                  offerValues={isEmpty(data) ? new JobOfferInitValues() : data }
                />
              )}}
          </WizardStep>
        </CreateJobOfferWizard>
      </div>
    </AppContainer>
  )
}

export default CreateJobOfferLayout
