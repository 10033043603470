import React from 'react'
import classes from './index.module.css'

/* eslint-disable no-unused-vars */
const Checkbox = ({
  field,
  form,
  additionalClass='',
  rounded = false,
  ...props
}) => {
  /* eslint-enable no-unused-vars */
  return (
    <input
      className={`${classes.checkbox} ${rounded ? classes.rounded : ''} ${additionalClass}`}
      style={{verticalAlign: 'middle'}}
      type='checkbox'
      {...field}
      {...props}
    />
  )
}

export default Checkbox
