import React from 'react'
import { Formik, Form, Field } from 'formik'
import BillingInitValues from 'models/billing'
import { billingSchema } from 'schemas/billing'
import {
  Company,
  Name,
  IdNumber,
  VitNumber,
  Email,
  Country,
  Phone,
  PostalCode,
  AddressInput
} from './inputs'
import classes from './index.module.css'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'


const DataLevel = ({ isMarginless, children }) => {
  return (
    <div className={!isMarginless ? 'has-margin-b-3' : ''}>
      {children}
    </div>
  )
}

const BillingForm = ({
  billing,
  countries,
  fromUK=false,
  isSubmitting,
  onChangeCountry,
  onSubmit
}) => {
  const initValues =  new BillingInitValues(billing, countries)
  const validationSchema = billingSchema(fromUK)

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, dirty, setFieldValue }) => {
        return (
          <Form>
            <DataLevel>
              {fromUK ?
                <Field
                  component={Company}
                  id='company_name'
                  name='company_name'
                /> :
                <Field
                  component={Name}
                  id='person_first_last_name'
                  name='person_first_last_name'
                />
              }
            </DataLevel>

            <div className='has-margin-b-3'>
              {fromUK ?
                <Field
                  component={VitNumber}
                  id='company_id_number'
                  name='company_id_number'
                />
                :
                <Field
                  component={IdNumber}
                  id='person_id_number'
                  name='person_id_number'
                />
              }
            </div>

            <div className='has-margin-b-3'>
              <Field
                component={Email}
                id='email'
                name='email'
              />
            </div>

            <div className='has-margin-b-3'>
              <Field
                component={Country}
                id='country'
                name='country'
                onChange={(e) => onChangeCountry(e, setFieldValue)}
                options={countries || []}
                value={values.country && values.country.value ? values.country : null}
              />
            </div>

            <div className='has-margin-b-3'>
              <Field
                component={AddressInput}
                country={values.country ? values.country.code : null}
                id='address'
                name='address'
              />
            </div>

            <div className='has-margin-b-3'>
              <Field
                component={PostalCode}
                id='zip_code'
                name='zip_code'
              />
            </div>

            <div className='has-margin-b-3'>
              <Field
                component={Phone}
                countryCode={values.country && values.country.code}
                id='phone'
                name='phone'
              />
            </div>

            <button
              className={`button filled main-button ${isSubmitting ? 'is-loading' : ''} ${classes.submitButton}`}
              disabled={!dirty || isSubmitting}
              onClick={handleSubmit}
              type='submit'
            >
              <FormattedMessageWithClass id='client.billing.update' />
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default BillingForm
