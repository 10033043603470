import React from 'react'
import classes from './index.module.css'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { SuccessIcon } from 'seniors-first-commons/components/atoms/images/icons'

const OfferCreatedConfirmationMessage = () => {
  return (
    <div className={`has-text-centered ${classes.messageContainer}`}>
      <SuccessIcon
        fill='none'
        height='64'
        stroke='#0080F5'
        strokeWidth='2'
        width='100'
      />
      <p className={`p1-bold ${classes.text}`}>
        <FormattedMessageWithClass id='client.search.offerCreatedConfirmationModal.text' />
      </p>
    </div>
  )
}

export default OfferCreatedConfirmationMessage
