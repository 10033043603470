import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import ApiService from '../../../services/api-service'

const { axiosGet, axiosPost, axiosDelete } = ApiService

const getToken = () => `Bearer ${localStorage.getItem('refresh_token') || ''}`

export const register = createAsyncThunk(
  'shared/auth/register',
  (payload, { rejectWithValue }) => {
    return axiosPost('/register', { ...payload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const verifyEmail = createAsyncThunk(
  'shared/auth/verifyEmail',
  ({ token }, { rejectWithValue }) => {
    return axiosPost('/verify-email', { token })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const resendEmail = createAsyncThunk(
  'shared/auth/resendEmail',
  ({ email }, { rejectWithValue }) => {
    return axiosPost('/resend-verification-email', { email })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const login = createAsyncThunk(
  'shared/auth/login',
  ({ email, password, role }, { rejectWithValue }) => {
    return axiosPost('/login', { email, password })
      .then(response => ({ ...response, currentRole: role }))
      .catch(error => rejectWithValue(error))
  }
)

export const blackList = createAsyncThunk(
  'shared/auth/blackList',
  (payload, { rejectWithValue }) => {
    return axiosPost('/blacklist')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const refreshToken = createAsyncThunk(
  'shared/auth/refreshToken',
  (payload, { rejectWithValue }) => {
    return axiosPost('/refresh', undefined, {
      headers: {
        Authorization: getToken(), 'Content-Type': 'application/json'
      }
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteAccount = createAsyncThunk(
  'shared/auth/deleteAccount',
  ({ reason }) => {
    return axiosDelete('/user', { reason })
      .then(response => response)
      .catch(error => { throw new Error(error) })
  }
)

export const changePassword = createAsyncThunk(
  'shared/auth/changePassword',
  ({ token, new_password }) => {
    return axiosPost('/change-password', { new_password, token })
      .then(response => response)
      .catch(error => { throw new Error(error) })
  }
)

export const requestPasswordReset = createAsyncThunk(
  'shared/auth/requestPasswordReset',
  ({ email }, { rejectWithValue }) => {
    return axiosPost('/request-password-reset', { email })
      .then(response => ({ ...response, email }))
      .catch(error =>  rejectWithValue(error))
  }
)

export const resetPassword = createAsyncThunk(
  'shared/auth/resetPassword',
  ({ token, new_password }, { rejectWithValue }) => {
    return axiosPost('/password-reset', { new_password, token })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const socialLogin = createAsyncThunk(
  'shared/auth/socialLogin',
  ({ social, role }, { rejectWithValue }) => {
    return axiosGet(`/login/${social}`, { role })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const socialAuthorize = createAsyncThunk(
  'shared/auth/socialAuthorize',
  ({ social, code, role }, { rejectWithValue }) => {
    return axiosPost(`/login/${social}`, { code, role })
      .then(response => ({ currentRole: role, ...response }))
      .catch(error => rejectWithValue(error))
  }
)

export const saveDestination = createAction('shared/auth/saveDestination')

export const closeWelcomeModal = createAction('shared/auth/closeWelcomeModal')

export const changeAuthErrorStatus = createAction('shared/auth/changeAuthErrorStatus')

export const toggleSuccessStatus = createAction('shared/auth/toggleSuccessStatus')
