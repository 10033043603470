import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import passwordChangeInitValues from 'seniors-first-commons/shared/models/changePassword'
import passwordChangeSchema from 'seniors-first-commons/shared/schemas/changePassword'
import { Password, ConfirmPassword } from './inputs'
import classes from './index.module.css'


const PasswordChangeForm = ({
  submitting,
  onFormSubmit,
  goToRegister
}) => {
  return (
    <Formik
      initialValues={new passwordChangeInitValues()}
      onSubmit={onFormSubmit}
      validationSchema={passwordChangeSchema}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Field
              component={Password}
              id='new_password'
              name='new_password'
            />
            <Field
              component={ConfirmPassword}
              id='confirmPassword'
              name='confirmPassword'
            />

            <button
              className={`button filled has-margin-t-6 nonAuthedButtons ${submitting ? 'is-loading' : ''} ${classes.submitbutton}`}
              disabled={submitting}
              onClick={handleSubmit}
              type='submit'
            >
              <FormattedMessageWithClass
                id='app.password.changepasswordform.submit'
              />
            </button>

            <button
              className={`button-text has-margin-t-7 ${classes.submitbutton}`}
              onClick={goToRegister}
              type='button'
            >
              <FormattedMessageWithClass
                className='p2-bold'
                id='app.password.changepasswordcontainer.button'
              />
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PasswordChangeForm
