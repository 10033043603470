import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const NotificationPhoto = ({
  matchesMobile,
  notificationId,
  read,
  image,
  onChangeReadStatus
}) => {
  return (
    <div className='level is-mobile'>
      <div className={`level-item ${matchesMobile ? 'is-marginless-r' : ''}`}>
        <button
          className={classes.pointButton}
          onClick={() => onChangeReadStatus(notificationId)}
        >
          <span className={`custom-tooltip ${!read ? classes.notRead : classes.read}`}>
            {!matchesMobile &&
              <FormattedMessageWithClass
                className={`tooltip-content p3-bold white ${classes.tooltip}`}
                id={read ? 'apps.notifications.notread' : 'apps.notifications.read' }
                placement='top'
              />
            }
          </span>
        </button>
      </div>
      <div className='level-item'>
        <figure className={`image is-48x48 ${classes.image}`}>
          <img
            alt='notificationIcon'
            className='is-rounded'
            src={image}
          />
        </figure>
      </div>
    </div>
  )
}


export default NotificationPhoto
