import React, { Fragment, useReducer } from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import { ExternalModal, InternalModal } from 'seniors-first-commons/components/molecules/HowToModals'


const reducer = (state, action) => {
  switch(action.type) {
  case 'visitingCare':
    return {
      ...state, visitingCare: !state.visitingCare
    }

  case 'liveInCare':
    return {
      ...state, liveInCare: !state.liveInCare
    }

  default:
    return state
  }
}

const Modality = ({ hiring_modality }) => {
  const [state, dispatch] = useReducer(reducer, {
    liveInCare: false,
    visitingCare: false
  })

  const handleVisitingCareModal = () => dispatch({ type: 'visitingCare' })

  const handleLiveInCareModal = () => dispatch({ type: 'liveInCare' })

  return (
    <Fragment>
      <Radios
        content={[
          {
            extra: (
              <button
                className='button-text full-width has-margin-t-7'
                onClick={handleVisitingCareModal}
                type='button'
              >
                <FormattedMessageWithClass
                  className='p2-bold'
                  id='client.createOffer.steps.modality.howItWorks'
                />
              </button>
            ),
            name: 'hiring_modality',
            text: 'client.createOffer.steps.modality.visitingCare',
            value: 'visiting_care'
          },
          {
            extra: (
              <button
                className='button-text full-width has-margin-t-7'
                onClick={handleLiveInCareModal}
                type='button'
              >
                <FormattedMessageWithClass
                  className='p2-bold'
                  id='client.createOffer.steps.modality.howItWorks'
                />
              </button>
            ),
            name: 'hiring_modality',
            text: 'client.createOffer.steps.modality.live-in-care',
            value: 'live_in_care'
          }
        ]}
        input={hiring_modality}
      />

      <InternalModal isOpen={state.liveInCare} onClose={handleLiveInCareModal} />
      <ExternalModal isOpen={state.visitingCare} onClose={handleVisitingCareModal} />
    </Fragment>
  )
}

export default Modality
