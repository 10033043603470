
// this model should be used in createJobOffer component
// see: src/pages/CreateJobOffer/index.js

export default class CreateJobOffer {
  constructor(initialValues = {
    address_lat: '',
    address_lng: '',
    name: '',
    phone: ''
  }) {
    const { address_lat, address_lng, phone, name } = initialValues
    this.name = ''

    if (name) {
      this.name = name
    }

    this.address = {
      formatted_address: ''
    }

    if (address_lat && address_lng) {
      this.address.address_lat = address_lat
      this.address.address_lng = address_lng
    }

    if (phone) {
      this.phone = phone
    }

    this.service_categories = []
    this.hiring_modality = ''
    this.visiting_care_day_and_hours = [{
      day_of_week: {},
      hour_from: {},
      hour_to: {}
    }]
    this.live_in_care = {
      day_of_week_from: {},
      day_of_week_to: {},
      hour_from: {},
      hour_to: {}
    }
    this.duration= ''
    this.temporal_duration_from = null // -> Invalid input type: `date` of type `string`
    this.temporal_duration_to = null // -> Invalid input type: `date` of type `string`
    this.undefined_duration_from = null // -> Invalid input type: `date` of type `string`
    this.contract_type = ''
    this.salary_type = ''
    this.retribution_amount = ''
  }
}
