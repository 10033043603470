import React from 'react'
import { Formik, Form } from 'formik'
import { pathOr } from 'ramda'
import WelcomeFormInitValues from 'models/welcome'
import { welcomeSchema } from 'schemas/welcome'
import WelcomeModal from 'components/molecules/WelcomeModal'
import WizardSteps from 'seniors-first-commons/components/atoms/Steps'
import WizardVideo from 'components/organisms/WelcomeSteps/WelcomeVideo'
import Address from 'components/organisms/WelcomeSteps/Address'
import UploadProfilePhoto from 'components/organisms/WelcomeSteps/UploadProfilePhoto'
import { ClientsLogo } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const StepContent = ({
  step,
  user,
  photo,
  countries,
  matchesMobile,
  uploadingPhoto,
  onChangeStep,
  uploadProfilePhoto,
  ...props
}) => {
  switch(step.toString()) {
  case '0':
    return (
      <Address
        countries={countries || []}
        matchesMobile={matchesMobile}
        onChangeStep={onChangeStep}
        user={user}
        values={props.values}
        {...props}
      />
    )
  case '1':
    return (
      <UploadProfilePhoto
        actions={{
          onChangeStep,
          uploadPhoto: uploadProfilePhoto
        }}
        loading={{ uploadingPhoto }}
        photo={photo}
      />
    )
  case '2':
    return (
      <WizardVideo
        matchesMobile={matchesMobile}
        {...props}
      />
    )
  default: return null
  }
}


const WelcomeLayout = ({
  logicPropsAndMethods: {
    currentStep,
    profile,
    matchesMobile,
    onFormSubmit,
    onChangeStep,
    onCloseWelcomeModal,
    ...rest
  }
}) => {
  const photo = pathOr(null, ['index', 'photo'], profile)
  const createWelcomeSteps = (errors) => [
    {
      label: 'client.welcome.steps.location',
      status: !!errors.country || !!errors.address ? 'error' : ''
    },
    { label: 'client.welcome.steps.uploadPhoto' },
    { label: 'client.welcome.steps.video' }
  ]

  return (
    <>
      <Formik
        initialValues={new WelcomeFormInitValues()}
        onSubmit={onFormSubmit}
        validationSchema={welcomeSchema}
      >
        {({ errors, ...formik }) => {
          return (
            <Form className={classes.wizardContainer}>
              <div className={`section ${matchesMobile ? 'has-padding-lr-7' : ''} full-width ${classes.wrapper}`}>
                <div style={{ margin: 'auto', maxWidth: '222px' }}>
                  <ClientsLogo height='50' width='222' />
                </div>

                <div className={classes.formsWrapper}>
                  <WizardSteps
                    currentStep={currentStep}
                    matchesMobile={matchesMobile}
                    onChange={onChangeStep}
                    steps={createWelcomeSteps(errors)}
                  />

                  <StepContent
                    countries={rest.helpers.countries}
                    matchesMobile={matchesMobile}
                    onChangeStep={onChangeStep}
                    photo={photo}
                    step={currentStep}
                    uploadingPhoto={rest.uploadingPhoto}
                    uploadProfilePhoto={rest.uploadProfilePhoto}
                    user={rest.user}
                    {...formik}
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      <WelcomeModal
        firstname={rest.user?.firstname}
        onClose={onCloseWelcomeModal}
        open={rest.successLogin && !profile.finishedWizard}
      />
    </>
  )
}

export default WelcomeLayout
