import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import InitialLayout from '../InitialLayout'
import PasswordResetForm from 'seniors-first-commons/components/organisms/PasswordResetForm'
import PasswordResetSuccess from 'seniors-first-commons/components/molecules/PasswordResetSuccess'


const PasswordResetLayout = ({
  locale,
  role,
  showSuccess,
  resendingEmail,
  requestingPasswordReset,
  onBackFromSuccess,
  onResetPassword,
  onResendEmail,
  onLogoClick
}) => {
  return (
    <InitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>
      {showSuccess ? (
        <PasswordResetSuccess
          onBack={onBackFromSuccess}
          onResendEmail={onResendEmail}
          resendingEmail={resendingEmail}
        />
      ) : (
        <>
          <p className='t1-bold primary'>
            <FormattedMessageWithClass
              id='app.password.passwordreset.title'
            />
          </p>
          <div className='p3-bold has-margin-t-7'>
            <p>
              <FormattedMessageWithClass
                id='app.password.passwordreset.text1'
              />
            </p>
            <p>
              <FormattedMessageWithClass
                id='app.password.passwordreset.text2'
              />
            </p>
          </div>
          <PasswordResetForm
            isSubmitting={requestingPasswordReset}
            onFormSubmit={onResetPassword}
          />
        </>
      )}
    </InitialLayout>
  )
}

export default PasswordResetLayout
