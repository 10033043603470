import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

class MoreInfoModal extends Component {
  renderRows = () => {
    const moreInfoButton = (
      <a href={this.props.intl.formatMessage({ id: 'app.footer.privacy.url' })} rel='noopener noreferrer' target='_blank'>
        <InfoIcon
          fill='none'
          height='14'
          stroke='#0040AA'
          width='14'
        />
      </a>
    )

    return (
      <Fragment>
        <div className={['columns is-vcentered', classes.Row].join(' ')}>
          <div className={['column has-margin-x-auto is-one-third has-text-centered-mobile', classes.Question].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.company.question' })}
          </div>
          <div className={['column has-margin-x-auto has-text-centered-mobile', classes.Answer].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.company.answer' })}
            “<span className='has-text-weight-semibold'>{this.props.intl.formatMessage({ id: 'app.register.more.company.answer.strong' })}</span>“).
          </div>
        </div>
        <div className={['columns is-vcentered', classes.Row].join(' ')}>
          <div className={['column has-margin-x-auto is-one-third has-text-centered-mobile', classes.Question].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.process.question' })}
          </div>
          <div className={['column has-margin-x-auto has-text-centered-mobile', classes.Answer].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.process.answer' })}
          </div>
        </div>
        <div className={['columns is-vcentered', classes.Row].join(' ')}>
          <div className={['column has-margin-x-auto is-one-third has-text-centered-mobile', classes.Question].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.lawful.question' })}
          </div>
          <div className={['column has-margin-x-auto has-text-centered-mobile', classes.Answer].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.lawful.answer' })}
            {moreInfoButton}
          </div>
        </div>
        <div className={['columns is-vcentered', classes.Row].join(' ')}>
          <div className={['column has-margin-x-auto is-one-third has-text-centered-mobile', classes.Question].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.rights.question' })}
          </div>
          <div className={['column has-margin-x-auto has-text-centered-mobile', classes.Answer].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.rights.answer' })}
            {moreInfoButton}
          </div>
        </div>
        <div className={['columns is-vcentered', classes.Row].join(' ')}>
          <div className={['column has-margin-x-auto is-one-third has-text-centered-mobile', classes.Question].join(' ')}>
            {this.props.intl.formatMessage({ id: 'app.register.more.contact.question' })}
          </div>
          <div className={['column has-margin-x-auto has-text-centered-mobile', classes.Answer].join(' ')}>
            <p>{this.props.intl.formatMessage({ id: 'app.register.more.contact.answer' })}:</p>
            <a
              className='has-text-weight-semibold light-blue'
              href={`mailto:${this.props.intl.formatMessage({ id: 'app.register.more.contact.answer.mail' })}`}
            >
              {this.props.intl.formatMessage({ id: 'app.register.more.contact.answer.mail' })}
            </a>.
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      <CustomModal
        cardBodyStyles={{ overflow: 'auto', padding: '35px' }}
        hasCloseButton={false}
        onClose={() => { }}
        open={this.props.show}
        styles={{ overflow: 'auto' }}
      >
        <section className='has-background-white'>
          <div className='has-text-primary has-text-weight-light'>
            {this.renderRows()}
          </div>
          <div className='columns is-mobile is-centered has-margin-t-7'>
            <div className='column is-narrow'>
              <button
                className={['button filled', classes.ConfirmButton].join(' ')}
                onClick={this.props.onClose}
              >Ok</button>
            </div>
          </div>
        </section>
      </CustomModal>
    )
  }
}

const mapStateToProps = (state) => ({
  lang: state.intl.locale
})

export default connect(mapStateToProps)(
  injectIntl(MoreInfoModal)
)
