import { createReducer } from '@reduxjs/toolkit'
import {
  getCreditsRecords,
  sendBillingData,
  updateBillingData,
  getBillingData,
  toggleSuccessStatus,
  buyCredits,
  getCredits,
  makePayment
} from './actions'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'

const initValues = {
  billing:{
    data: null,
    loading: false,
    success: ''
  },

  credits: {
    loading: false,
    loadingCredits: false,
    records: {
      items: [],
      page: 0,
      pages: 0,
      per_page: ITEMS_PER_PAGE,
      total: 0
    },
    total: null
  },

  modifyBillingDataLoading: false
}

const reducer = createReducer(initValues, (builder) => {
  builder.addCase(makePayment.pending, (state) => {
    state.credits.loadingCredits = true
  })

  builder.addCase(getCreditsRecords.pending, (state) => {
    state.credits.loading = true
  })

  builder.addCase(getCreditsRecords.fulfilled, (state, action) => {
    state.credits.records = action.payload
  })

  builder.addCase(sendBillingData.fulfilled, (state, action) => {
    state.billing.data = action.payload
    state.billing.success = 'success.successfullChange'
  })

  builder.addCase(updateBillingData.fulfilled, (state, action) => {
    state.billing.data = action.payload
    state.billing.success = 'success.successfullChange'
  })

  builder.addCase(getBillingData.fulfilled, (state, action) => {
    state.billing.data = action.payload
  })

  builder.addCase(getBillingData.pending, (state) => {
    state.billing.loading = true
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.billing.success = action.payload
  })

  builder.addCase(getCredits.fulfilled, (state, action) => {
    state.credits.total = action.payload.credits
  })

  builder.addCase(getCredits.pending, (state) => {
    state.credits.loadingCredits = true
  })

  builder.addCase(buyCredits.fulfilled, (state, action) => {
    state.credits.total = state.credits.total + action.payload.amount
  })

  builder.addCase(buyCredits.pending, (state) => {
    state.credits.loadingCredits = true
  })

  builder.addMatcher((action) => [
    buyCredits.fulfilled.toString(),
    buyCredits.rejected.toString(),

    getCredits.fulfilled.toString(),
    getCredits.rejected.toString()
  ].includes(action.type), (state) => {
    state.credits.loadingCredits = false
  })

  builder.addMatcher((action) => [
    sendBillingData.pending.toString(),
    updateBillingData.pending.toString()
  ].includes(action.type), (state) => {
    state.modifyBillingDataLoading = true
  })

  builder.addMatcher((action) => [
    getBillingData.fulfilled.toString(),
    getBillingData.rejected.toString()
  ].includes(action.type), (state) => {
    state.billing.loading = false
  })

  builder.addMatcher((action) => [
    updateBillingData.fulfilled.toString(),
    updateBillingData.rejected.toString(),

    sendBillingData.fulfilled.toString(),
    sendBillingData.rejected.toString()
  ].includes(action.type), (state) => {
    state.modifyBillingDataLoading = false
  })

  builder.addMatcher((action) => [
    getCreditsRecords.fulfilled.toString(),
    getCreditsRecords.rejected.toString()
  ].includes(action.type), (state) => {
    state.credits.loading = false
  })

  builder.addMatcher((action) => [
    makePayment.fulfilled.toString(),
    makePayment.rejected.toString()
  ].includes(action.type), (state) => {
    state.credits.loadingCredits = false
  })
})

export default reducer
