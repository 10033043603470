import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  getNotifications,
  getNotReadNotifications,
  changeNotificationReadStatus,
  deleteNotification
} from './actions'
import { AWS_IMAGE_STORAGE } from 'seniors-first-commons/utils/constants'


const initValues = {
  data: {
    items: [],
    page: 0,
    pages: 0,
    total: 0,
    unread_notifications: 0
  },
  loading: false,
  loadingNotRead: false,
  notReadNotifications: 0,
  success: ''
}

const baseApiToNotifications =  (items) => {
  return items.map(item => {
    const UTC = moment.utc(item.created_at)
    item.created_at = moment(UTC).local()
    if (item && item.user_photo) item.user_photo = `${AWS_IMAGE_STORAGE}${item.user_photo}`
    return item
  })
}

const reducer = createReducer(initValues, (builder) => {
  builder.addCase(getNotifications.pending, (state) => {
    state.loading = true
  })

  builder.addCase(getNotReadNotifications.pending, (state) => {
    state.loadingNotRead = true
  })

  builder.addCase(getNotifications.fulfilled, (state, action) => {
    const { items, ...rest } = action.payload // eslint-disable-line no-unused-vars
    if (action.payload.page === 1) state.data.items = []
    state.data.items = [...state.data.items, ...baseApiToNotifications(action.payload.items)]
      .filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
    state.data = {
      ...state.data,
      ...rest
    }
    state.notReadNotifications = action.payload.unread_notifications
  })

  builder.addCase(getNotReadNotifications.fulfilled, (state, action) => {
    state.notReadNotifications = action.payload.unread_notifications
    state.data.items = [...baseApiToNotifications(action.payload.items), ...state.data.items]
      .filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
    state.data.unread_notifications = action.payload.unread_notifications
  })

  builder.addCase(changeNotificationReadStatus.fulfilled, (state, action) => {
    state.data = {
      ...state.data,
      items: state.data.items.map(item => {
        if (item.id === action.payload.id) {
          item.unread = !item.unread
        }
        return item
      })
    }
    state.notReadNotifications = !action.payload.unread ? state.notReadNotifications - 1 : state.notReadNotifications + 1
  })

  builder.addCase(deleteNotification.fulfilled, (state, action) => {
    state.data = {
      ...state.data,
      items: state.data.items.filter(item => item.id !== action.payload.id)
    }
    state.notReadNotifications = !action.payload.wasread ? state.notReadNotifications - 1 : state.notReadNotifications
  })

  builder.addMatcher((action) => [
    getNotifications.fulfilled.toString(),
    getNotifications.rejected.toString()
  ].includes(action.type), (state) => {
    state.loading = false
  })

  builder.addMatcher((action) => [
    getNotReadNotifications.fulfilled.toString(),
    getNotReadNotifications.rejected.toString()
  ].includes(action.type), (state) => {
    state.loadingNotRead = false
  })
})

export default reducer
