import React from 'react'
import { ErrorMessage } from 'formik'
import Select from 'seniors-first-commons/components/molecules/Select'
import Input from 'seniors-first-commons/components/atoms/Input'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import { PencilEdit } from 'seniors-first-commons/components/atoms/images/icons'


export const Company = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.companyName'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        placeholder=''
        type='text'
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const Name = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.person-name'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        placeholder=''
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const IdNumber = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.person-id'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        placeholder=''
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const VitNumber = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.companyId'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        placeholder=''
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const Email = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.email'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        placeholder=''
        type='text'
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const Country = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.country'
        labelType='p1-bold'
      />
      <Select
        icon={true}
        placeholder=''
        shouldTranslate={false}
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const AddressInput = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.address'
        labelType='p1-bold'
      />
      <AddressAutoSuggest
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        inputClassName='input'
        placeholder=''
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const PostalCode = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.id}
        labelName='client.billing.zip-code'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        placeholder=''
        {...props}
      />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}

export const Phone = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={7}
        centered={false}
        id={props.id}
        labelName='client.billing.phone'
        labelType='p1-bold'
      />
      <PhoneInput {...props} />
      <ErrorMessage
        {...props.field}
        centered={false}
        component={HandleError}
        spaceTop={0}
      />
    </>
  )
}
