import React from 'react'
import { useHistory } from 'react-router-dom'
import routingServices from 'seniors-first-commons/services/routingService'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import AppContainer from 'components/organisms/AppContainer'
import IconTextActionLayout from 'components/templates/IconTextActionLayout'
import { ROUTE } from 'root/constants'
import SuccessImage from 'seniors-first-commons/components/atoms/images/icons/successImage.svg'


const Icon = () => {
  return (
    <img alt='success' src={SuccessImage} />
  )
}

const Text = ({ title, details }) => {
  return (
    <>
      <p className='t1-bold primary'>
        {title}
      </p>
      <p className='p1-bold grey has-margin-bt-5'>
        {details}
      </p>
    </>
  )
}

const Action = ({ button, onGoHome }) => {
  return (
    <button
      className='button filled'
      onClick={onGoHome}
      style={{ height: '48px', width: '140px' }}
      type='button'
    >
      {button}
    </button>
  )
}


const PaymentSuccess = () => {
  const history = useHistory()
  const mediaQuery = useMediaQuery()

  const translations = {
    button: useFormatMessage('client.paymentSuccess.button'),
    details: useFormatMessage('client.paymentSuccess.text'),
    title: useFormatMessage('client.paymentSuccess.title')
  }

  const onGoHome = () => {
    routingServices.push(history, `${ROUTE.HOME}`)
  }

  return (
    <AppContainer additionalClass='is-paddingless' name='Payment success'>
      <IconTextActionLayout
        action={(<Action {...translations} onGoHome={onGoHome} />)}
        icon={(<Icon />)}
        matchesMobile={mediaQuery[0] < 768}
        text={(<Text {...translations} />)}
      />
    </AppContainer>
  )
}


export default PaymentSuccess
