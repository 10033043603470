import React from 'react'
import { path } from 'ramda'
import withChat from 'hoc/withChat'
import AppContainer from 'components/organisms/AppContainer'
import GoBackOrHome from 'seniors-first-commons/components/atoms/GoBackOrHome'
import CaregiverCard from 'components/organisms/CaregiverCard'
import ServicesCard from 'components/organisms/CaregiverProfileCards/CaregiverServicesCard'
import ContractsCard from 'components/organisms/CaregiverProfileCards/CaregiverContractsCard'
import FormationCard from 'components/organisms/CaregiverProfileCards/CaregiverFormationCard'
import ExperienceCard from 'components/organisms/CaregiverProfileCards/CaregiverExperienceCard'
import VideoModal from 'seniors-first-commons/components/molecules/VideoModal'
import classes from './index.module.css'


const ProfileImageModal = ({
  open,
  toggleModal,
  image
}) => {
  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className='modal-background' onClick={toggleModal}></div>
      <div className={`modal-content ${classes.modalImageWrapper}`}>
        <figure className={`image ${classes.modalImage}`}>
          <img alt='' src={image} />
        </figure>
      </div>
      <button className='modal-close is-large' onClick={toggleModal}></button>
    </div>
  )
}

const CaregiverProfileLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    showVideoCVModal,
    comingFrom,
    caregiver,
    profile,
    selectedCard,
    showImageModal,
    toggleProfileImageModal,
    onOpenVideoCVModal,
    onCloseVideoCVModal,
    changeSelectedCard
  },
  selectedCaregiver,
  caregiverStatus
}) => {
  return (
    <AppContainer
      additionalClass={'has-padding-lr-7'}
      name='Caregiver profile'
    >
      <>
        <GoBackOrHome className='has-padding-b-4 is-paddingless-l' />
        {caregiver &&
        <div className='columns'>
          <div className='column is-one-third'>
            <CaregiverCard
              caregiverStatus={caregiverStatus({
                comingFrom,
                job_proposition_status: path(['service_sheet', 'job_proposition', 'status'], caregiver),
                no_credits_to_connect: path(['service_sheet', 'job_proposition', 'no_credits_to_connect'], caregiver),
                post_status: path(['service_sheet', 'post_application', 'status'], caregiver),
                ...caregiver
              })}
              data={caregiver}
              hasViewProfileButton={false}
              imageIsClickable={true}
              nameIsClickable={false}
              onOpenVideoCVModal={onOpenVideoCVModal}
              onProfileImageClick={toggleProfileImageModal}
              selectedCaregiver={selectedCaregiver}
            />
            <ProfileImageModal
              image={caregiver.image}
              open={showImageModal}
              toggleModal={toggleProfileImageModal}
            />
          </div>

          <div className='column is-two-thirds'>
            <ServicesCard
              data={caregiver}
              isSelected={selectedCard === 'services'}
              matchesMobile={matchesMobile}
              onToggleCard={changeSelectedCard}
            />

            <ContractsCard
              currency={profile.index?.currency}
              data={caregiver}
              isSelected={selectedCard === 'contracts'}
              matchesMobile={matchesMobile}
              onToggleCard={changeSelectedCard}
            />

            <FormationCard
              data={caregiver}
              isSelected={selectedCard === 'formation'}
              matchesMobile={matchesMobile}
              onToggleCard={changeSelectedCard}
            />

            <ExperienceCard
              data={caregiver}
              isSelected={selectedCard === 'experiences'}
              matchesMobile={matchesMobile}
              onToggleCard={changeSelectedCard}
            />
          </div>
        </div>
        }
      </>
      <VideoModal
        isOpen={!!showVideoCVModal}
        onClose={onCloseVideoCVModal}
        title='CV Video'
        videoId={showVideoCVModal}
      />
    </AppContainer>
  )
}

export default withChat(CaregiverProfileLayout, null)
