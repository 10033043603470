import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { parseQueries, stringifyQueries } from 'seniors-first-commons/shared/hooks'
import { updateUser } from 'seniors-first-commons/shared/store/user/actions'
import regionService from 'seniors-first-commons/services/region'
import Dropdown from 'react-bulma-components/lib/components/dropdown/dropdown'
import { AppLanguages, languages, toUrlFormat } from 'seniors-first-commons/shared/languages'
import { LANG_LOCALSTORAGE_KEY } from 'seniors-first-commons/utils/constants'
import classes from './index.module.css'

const getLanguageObject = (language) => {
  return AppLanguages[language.toUpperCase().replace('-', '_')]
}

class LanguageSwitch extends Component {
  constructor(props) {
    super(props)

    const { history } = this.props
    this.history = history
  }

  onChange = (selected) => {
    const match = this.props.match
    const params = parseQueries()
    const newPath = match.url.replace(match.params.lang, selected)
    const lang = selected.slice(0, 2)

    this.history.push({ pathname: newPath, search: stringifyQueries(params) })

    if (this.props.locale !== selected) {
      if (this.props.refresh_token) {
        this.props.updateUser({
          firstname: this.props.user?.firstname,
          language: selected,
          lastname: this.props.user?.lastname,
          success: ''
        })
          .unwrap()
          .then(() => localStorage.setItem(LANG_LOCALSTORAGE_KEY, lang))
          .then(() => window.location.reload())
      }
      else {
        localStorage.setItem(LANG_LOCALSTORAGE_KEY, lang)
        window.location.reload()
      }
    }
  }

  renderDropdownItems() {
    const isEU = regionService.isEU(regionService.getCountryCodeCookie())

    return languages
      .filter((language) => {
        const languageObj = getLanguageObject(language)

        return languageObj.text !== undefined && languageObj.isEU === isEU
      })
      .map((language) => {
        const languageObj = getLanguageObject(language)

        return (
          <Dropdown.Item
            key={language}
            onClick={() => {}}
            value={language}
          >
            <div className='columns is-mobile is-gapless'>
              <div className='column is-one-third'>
                <span
                  className={`
                  flag-icon
                  ${languageObj.icon}
                  ${this.props.isPublic ? 'is-size-7' : ''}
                `}
                ></span>
              </div>
              <div className='column has-text-left'>
                <span
                  className={`
                  ${classes.Item}
                  ${this.props.isPublic ? 'is-size-7' : ''}
                `}
                >
                  {this.props.intl.formatMessage({ id: languageObj.text })}
                </span>
              </div>
            </div>
          </Dropdown.Item>
        )
      })
  }

  render() {
    return (
      <Dropdown
        className={`
          ${this.props.UserSettings ? classes.UserSettings : classes.LanguageSwitch}
          ${this.props.isPublic ? 'is-up' : ''}
          ${this.props.isPublic ? classes.IsPublic : ''}
          ${this.props.additionalClasses ? this.props.additionalClasses : ''}
        `}
        onChange={this.onChange}
        onClick={() => {}}
        up={this.props.openToUp}
        value={toUrlFormat(this.props.locale)}
      >
        {this.renderDropdownItems()}
      </Dropdown>
    )
  }
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  refresh_token: localStorage.getItem('refresh_token'),
  user: state.user.user.data
})

const mapDispatchToProps = {
  updateUser
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(
  injectIntl(LanguageSwitch)
))
