import React from 'react'
import {connect} from 'react-redux'
import { path } from 'ramda'
import ProfileSettingsLayout from 'components/templates/ProfileSettingsLayout'
import { uploadProfilePhoto, updateProfile } from 'store/profile/actions'
import { updateUser } from 'seniors-first-commons/shared/store/user/actions'
import { getCountryByCode } from 'seniors-first-commons/utils/helpers'


const ProfileSettings = ({
  helpers,
  user,
  profile,
  loadingUpdates,
  uploadingPhoto,
  uploadProfilePhoto,
  updateUser,
  updateProfile
}) => {
  const onChangeCountry = (e, setFieldValue) => {
    //updates the phone input prefix based on the country selected
    const country = getCountryByCode(e.code)
    setFieldValue('phone', country[3])
    if (e.value !== path(['index', 'country_id'], profile)) {
      setFieldValue('address', {
        address_lat: 0,
        address_lng: 0,
        formatted_address: ''
      })
    }
    setFieldValue('country', e)
  }

  const countryHasChanged = (values, profile) => {
    return values.country.value !== profile.index.country_id
  }

  const locationHasChanged = (values, profile) => {
    return (values.address.address_lat !== profile.index.address_lat && values.address.address_lng !== profile.index.address_lng)
  }

  const phoneHasChanged = (values, profile) => {
    return values?.phone !== profile.index?.phone
  }

  const onFormSubmit = (values, { setStatus, setSubmitting }) => {
    if (
      values.firstname !== user.firstname ||
      values.lastname !== user.lastname
    ) {
      updateUser({
        firstname: values.firstname,
        lastname: values.lastname,
        success: 'success.successfullChange'
      })
        .then(() => {
          setStatus({ success: true })
          setSubmitting(false)
        })
    }

    if (
      countryHasChanged(values, profile) ||
      locationHasChanged(values, profile) ||
      phoneHasChanged(values, profile)
    ) {
      const payload = values
      updateProfile(payload)
        .then(() => {
          setStatus({ success: true })
          setSubmitting(false)
        })
    }
  }

  return (
    <>
      <ProfileSettingsLayout
        actions={{
          onChangeCountry,
          onFormSubmit,
          uploadPhoto: uploadProfilePhoto
        }}
        helpers={helpers}
        loading={{ loadingUpdates, uploadingPhoto }}
        profile={profile}
        user={user}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    helpers: state.helpers.helpers,
    loadingUpdates: state.user.user.loadingUpdates,
    profile: state.profile.profile,
    uploadingPhoto: state.profile.profile.loadingPhotoUpdate,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  updateProfile,
  updateUser,
  uploadProfilePhoto
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings)
