import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const OfferFindSendButton = ({ onClick }) => {
  return (
    <button
      className={`filled has-margin-bt-6 margin-b-7 ${classes.button}`}
      onClick={onClick}>
      <FormattedMessageWithClass
        id='apps.general.jobOffer.actions.find-send'
      />
    </button>
  )
}

export default OfferFindSendButton
