import React from 'react'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { ClientsLogo } from 'seniors-first-commons/components/atoms/images/icons/Logo'
import { ROUTE } from 'root/constants'

const NotFoundPage = () => {
  const history = useHistory()

  return (
    <div className='columns is-fullheight is-vcentered is-centered' style={{ minHeight: '100vh' }}>
      <div className='column has-text-centered'>
        <ClientsLogo
          height='200px'
          width='300px'
        />
      </div>
      <div className='column has-text-centered'>
        <p className='t1-regular'>
          <FormattedMessageWithClass id='apps.general.404' />
        </p>
        <button
          className='button filled has-margin-t-5'
          onClick={() => routingService.push(history, ROUTE.HOME)}
          style={{ height: '52px', width: '180px' }}
        >
          <FormattedMessageWithClass id='apps.general.goHomeButton' />
        </button>
      </div>
    </div>
  )
}

export default NotFoundPage
