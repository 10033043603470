import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'
import { ROUTE } from './constants'
import { languages } from 'seniors-first-commons/shared/languages/index'
import PrivateRoute from 'seniors-first-commons/utils/PrivateRoute'
import PublicRoute from 'seniors-first-commons/utils/PublicRoute'
import DeleteProfile from 'seniors-first-commons/screens/DeleteProfile'
import Login from 'seniors-first-commons/screens/Login/'
import Register from 'seniors-first-commons/screens/Register'
import PasswordReset from 'seniors-first-commons/screens/PasswordReset'
import PasswordChange from 'seniors-first-commons/screens/PasswordChange'
import VerifyEmail from 'seniors-first-commons/screens/VerifyEmail/'
import NotFoundPage from '../seniors-first-commons/components/templates/NotFound'
import authService from 'seniors-first-commons/services/auth-service'
import service from 'seniors-first-commons/services/routingService'
import Home from 'pages/Home'
import Search from 'pages/Search'
import ProfileSettings from 'pages/ProfileSettings'
import Billing from 'pages/Billing/index'
import CreateJobOffer from 'pages/CreateJobOffer'
import CreatePublicJobOffer from 'pages/CreatePublicJobOffer'
import Notifications from 'pages/Notifications'
import MyJobOffers from 'pages/MyJobOffers'
import Candidates from 'pages/Candidates'
import CaregiverProfile from 'pages/CaregiverProfile'
import MyCaregivers from 'pages/MyCaregivers'
import CaregiverRating from 'pages/CaregiverRating'
import Inbox from 'pages/Inbox'
import Credits from 'pages/Credits'
import PaymentSuccess from 'pages/PaymentSuccess'
import PaymentCancelled from 'pages/PaymentCancelled'
import Welcome from 'pages/Welcome'


var language = null

const AppRoutes = () => {
  return (
    <>
      <Switch>
        <Route path={`/:lang(${languages.join('|')})?`}>
          <LanguageRoutes />
        </Route>
      </Switch>
    </>
  )
}

const LanguageRoutes = () => {
  const isAuth = authService.isAuthenticated()
  const { path } = useRouteMatch()
  const { lang } = useParams()
  language = lang
  useEffect(() => {
    if (!service.language && language) {
      service.setLanguage(language)
    }
    // eslint-disable-next-line
  }, [service.language, language, service.setLanguage])

  return (
    <Switch>
      <PrivateRoute
        component={Home}
        exact
        path={path}
        token={isAuth}
      />

      <PrivateRoute
        component={Home}
        exact
        path={`${path}${ROUTE.ANAYET_REQUEST}`}
        token={isAuth}
      />

      <PrivateRoute
        component={DeleteProfile}
        path={`${path}${ROUTE.DELETE_ACCOUNT}`}
        role='client'
        token={isAuth}
      />

      <PrivateRoute
        component={Search}
        exact
        path={`${path}${ROUTE.SEARCH}/:id`}
        token={isAuth}
      />

      <PrivateRoute
        component={ProfileSettings}
        path={`${path}${ROUTE.PROFILE_SETTINGS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Billing}
        path={`${path}${ROUTE.BILLING}`}
        token={isAuth}
      />

      <PrivateRoute
        component={CreateJobOffer}
        path={`${path}${ROUTE.CREATE_JOB_OFFER}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Notifications}
        path={`${path}${ROUTE.NOTIFICATIONS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={MyJobOffers}
        exact
        path={`${path}${ROUTE.MY_JOB_OFFERS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Candidates}
        path={`${path}${ROUTE.CANDIDATES}/:id/`}
        token={isAuth}
      />

      <PrivateRoute
        component={CaregiverProfile}
        path={`${path}${ROUTE.CAREGIVER_PROFILE}/:id`}
        token={isAuth}
      />

      <PrivateRoute
        component={MyCaregivers}
        path={`${path}${ROUTE.MY_CAREGIVERS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Inbox}
        exact
        path={`${path}${ROUTE.CHAT}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Inbox}
        exact
        path={`${path}${ROUTE.CHAT}/:id`}
        token={isAuth}
      />

      <PrivateRoute
        component={Credits}
        path={`${path}${ROUTE.CREDITS}/:view`}
        token={isAuth}
      />

      <PrivateRoute
        component={Welcome}
        path={`${path}${ROUTE.WELCOME}`}
        route={ROUTE.WELCOME}
        token={isAuth}
      />

      <PrivateRoute
        component={CaregiverRating}
        path={`${path}${ROUTE.CAREGIVER_RATING}/:token`}
        token={isAuth}
      />

      <PrivateRoute
        component={PaymentSuccess}
        exact
        path={`${path}${ROUTE.PAYMENT_SUCCESS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={PaymentCancelled}
        exact
        path={`${path}${ROUTE.PAYMENT_CANCELLED}`}
        token={isAuth}
      />

      {/* PUBLIC ROUTES */}
      <PublicRoute
        component={Login}
        isAuthed={isAuth}
        path={`${path}${ROUTE.LOGIN}`}
        restricted={true}
        role='client'
      />

      <PublicRoute
        component={Register}
        isAuthed={isAuth}
        path={`${path}${ROUTE.REGISTER}`}
        restricted={true}
        role='client'
      />

      <PublicRoute
        component={PasswordReset}
        isAuthed={isAuth}
        path={`${path}${ROUTE.PASSWORD_RESET}`}
        restricted={true}
        role='client'
      />

      <PublicRoute
        component={PasswordChange}
        isAuthed={isAuth}
        path={`${path}${ROUTE.PASSWORD_CHANGE}/:token`}
        restricted={true}
        role='client'
      />

      <PublicRoute
        component={VerifyEmail}
        isAuthed={isAuth}
        path={`${path}${ROUTE.VERIFY_EMAIL}/:token`}
        restricted={true}
        role='client'
      />

      <PublicRoute
        component={CreatePublicJobOffer}
        path={`${path}${ROUTE.CREATE_PUBLIC_JOB_OFFER}`}
        role='client'
        token={isAuth}
      />

      <Route
        component={NotFoundPage}
        path='*'
      />
    </Switch>
  )
}

export default AppRoutes
