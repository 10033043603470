import Yup from 'seniors-first-commons/shared/schemas/yup'

export const billingSchema = (fromUK) => Yup.object().shape({
  address: Yup.object().shape({
    formatted_address: Yup.string().required('validations.mandatory')
  }),

  company_id_number: fromUK
    ? Yup.string().required('validations.mandatory')
    : Yup.string().notRequired(),

  company_name: fromUK
    ? Yup.string().required('validations.mandatory')
    : Yup.string().notRequired(),

  country: Yup.object().shape({
    label: Yup.string().required('validations.mandatory'),
    value: Yup.number().required('validations.mandatory')
  }),

  email: Yup.string().email('validations.invalidemail').required('validations.mandatory'),

  person_first_last_name: !fromUK
    ? Yup.string().required('validations.mandatory')
    : Yup.string().notRequired(),

  person_id_number: !fromUK
    ? Yup.string().required('validations.mandatory')
    : Yup.string().notRequired(),

  phone: Yup.string()
    .phone()
    .required('validations.mandatory'),

  zip_code: Yup.string().required('validations.mandatory')
})
