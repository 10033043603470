import React, { useRef } from 'react'
import { connect } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { userActive, userIdle } from 'seniors-first-commons/shared/store/user/actions'


const timeout = parseInt(process.env.REACT_APP_IDLE_USER_TIME)

const UserIdleTimer = ({ userActive, userIdle }) => {
  const idleTimerRef = useRef(null)

  const handleOnActive = () => {
    userActive()
  }

  const handleOnIdle = () => {
    userIdle()
  }

  return (
    <IdleTimer
      onActive={handleOnActive}
      onIdle={handleOnIdle}
      ref={idleTimerRef}
      timeout={timeout}
    />
  )
}

const mapDispatchToProps = { userActive, userIdle }

export default connect(null, mapDispatchToProps)(UserIdleTimer)
