import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import services from 'seniors-first-commons/services/api-service'

const { axiosGet, axiosPut } = services


export const getUser = createAsyncThunk(
  'shared/user/getUser',
  (payload, { rejectWithValue }) => {
    return axiosGet('/user')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateUser = createAsyncThunk(
  'shared/user/updateUser',
  ({ firstname, language, lastname, receiveEmailNotifications, receiveNotifications, success }, { rejectWithValue }) => {
    return axiosPut('/user' , { firstname, language, lastname, receive_email_notifications: receiveEmailNotifications, receive_notifications: receiveNotifications })
      .then(response => ({ ...response, success }))
      .catch(error => rejectWithValue(error))
  }
)

export const userActive = createAction('shared/user/userActive')

export const userIdle= createAction('shared/user/userIdle')

export const toggleSuccessStatus = createAction('shared/user/toggleSuccessStatus')
