import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import InitialLayout from 'seniors-first-commons/components/templates/InitialLayout'
import RegisterSuccess from 'seniors-first-commons/components/molecules/RegisterSuccess'
import MoreInfoModal from 'seniors-first-commons/components/atoms/MoreInfoModal'
import RegisterForm from 'seniors-first-commons/components/organisms/RegisterForm'

const FormWithInitialLayout = ({
  children,
  role,
  locale,
  onLogoClick
}) => <InitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>{children}</InitialLayout>

const RegisterFormView = ({
  role,
  showMoreInfo,
  registerData,
  onResendEmail,
  onFormSubmit,
  onFBLogin,
  onGoogleLogin,
  onRedirectToLogin,
  onReturnToLogin,
  toggleShowMoreInfo,
  withAlreadyHaveAccount,
  withSocialLogin,
  ignoreVerificationScreen = false,
  withRegisterLaterOption = false
}) => {
  if (registerData.data && registerData.data.id && !ignoreVerificationScreen) {
    return <RegisterSuccess
      onResend={onResendEmail}
      onReturnToLogin={onReturnToLogin}
      resendingEmail={registerData.loading}
    />
  }

  return (
    <>
      <p className='t1-bold primary'>
        <FormattedMessageWithClass
          id={`app.${role}.register.form.title`}
        />
      </p>
      <RegisterForm
        isSubmitting={registerData.loading}
        onFBLogin={onFBLogin}
        onGoogleLogin={onGoogleLogin}
        onLogin={onRedirectToLogin}
        onShowMoreInfo={toggleShowMoreInfo}
        onSubmit={onFormSubmit}
        withAlreadyHaveAccount={withAlreadyHaveAccount}
        withRegisterLaterOption={withRegisterLaterOption}
        withSocialLogin={withSocialLogin}
      />
      <MoreInfoModal
        onClose={toggleShowMoreInfo}
        show={showMoreInfo}
      />
    </>
  )
}

const RegisterLayout = ({
  role,
  locale,
  showMoreInfo,
  registerData,
  onResendEmail,
  onFormSubmit,
  onFBLogin,
  onGoogleLogin,
  onRedirectToLogin,
  onReturnToLogin,
  toggleShowMoreInfo,
  onLogoClick,
  withInitialLayout = true,
  withAlreadyHaveAccount = true,
  withSocialLogin = true,
  ignoreVerificationScreen = false,
  withRegisterLaterOption = false
}) => {
  const form = <RegisterFormView
    ignoreVerificationScreen={ignoreVerificationScreen}
    onFBLogin={onFBLogin}
    onFormSubmit={onFormSubmit}
    onGoogleLogin={onGoogleLogin}
    onRedirectToLogin={onRedirectToLogin}
    onResendEmail={onResendEmail}
    onReturnToLogin={onReturnToLogin}
    registerData={registerData}
    role={role}
    showMoreInfo={showMoreInfo}
    toggleShowMoreInfo={toggleShowMoreInfo}
    withAlreadyHaveAccount={withAlreadyHaveAccount}
    withRegisterLaterOption={withRegisterLaterOption}
    withSocialLogin={withSocialLogin}
  />
  return withInitialLayout
    ? <FormWithInitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>{form}</FormWithInitialLayout>
    : <div className='has-text-centered'>{form}</div>
}

export default RegisterLayout
