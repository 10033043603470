import React from 'react'
import { pathOr } from 'ramda'
import moment from 'moment'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { handleServiceType } from 'seniors-first-commons/utils/constants'
import { ArrowDownIcon, ArrowUpIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ProvidedServices = ({ services }) => {
  return (
    <div className='level is-mobile has-margin-t-7'>
      {services.map(({ id }, index) => (
        <div className='level-item' key={index}>
          <figure
            className={`custom-tooltip image ${classes.serviceIconWrapper}`}
          >
            {handleServiceType(id, '19', '19')['image']}
            <FormattedMessageWithClass
              className='tooltip-content p3-bold white'
              id={`apps.general.jobOffer.services.${id}`}
              placement='top'
            />
          </figure>
        </div>
      ))}
    </div>
  )
}

const ExperienceCardBody = ({ illnesses, experiences }) => {
  return (
    <div className='card-content has-padding-b-5 has-padding-lrt-3'>
      <>
        <h3 className='t4-bold primary has-margin-b-7'>
          <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.illnesses' />
        </h3>
        {illnesses.length ? illnesses.map((illness, index) => (
          <span
            className={`tag is-rounded has-margin-r-7 p3-bold primary ${classes.tag}`}
            key={index}
          >
            {illness.name}
          </span>
        )) :
          <p className='p1-bold'>
            <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.noillnesses' />
          </p>
        }
      </>

      <hr className={classes.divider} />

      {experiences.length ? experiences.map((exp, index) => (
        <div className={`has-margin-t-7 ${classes.experiencePart}`} key={index}>
          <div className='columns grey'>
            <div className='column'>
              <div className='columns'>
                <div className='column'>
                  <p className='p1-bold'>
                    <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.from' />
                  </p>
                  <p className='p1-regular'>
                    {moment(exp.from_date).format('L')}
                  </p>
                </div>

                <div className='column'>
                  <p className='p1-bold'>
                    <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.to' />
                  </p>
                  <p className='p1-regular '>
                    {moment(exp.to_date).format('L')}
                  </p>
                </div>
              </div>
            </div>

            <div className='column'>
              <p className='p1-bold'>
                <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.offeredServices' />
              </p>
              <div className={classes.servicesContainer}>
                {exp['service_categories'].length > 0 ?
                  <ProvidedServices services={exp['service_categories']} />
                  : <FormattedMessageWithClass
                    className='p2-regular'
                    id='client.caregiverProfile.experiencesCard.noofferedServices'
                  />
                }
              </div>
            </div>
          </div>
        </div>
      )) :
        <p className='p1-bold'>
          <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.noexperiences' />
        </p>
      }
    </div>
  )
}


const CaregiverExperienceCard = ({
  data,
  matchesMobile,
  isSelected,
  onToggleCard
}) => {
  const illnesses = pathOr([], ['illnesses'], data)
  const experiences = pathOr([], ['experiences'], data)

  return (
    <div className={`card has-margin-t-4 ${classes.card}`}>
      <div
        className={`card-header primary-light-background has-padding-l-3 has-padding-bt-5 
        ${matchesMobile ? 'pointer' : ''}`}
        onClick={() => onToggleCard('experiences')}
      >
        <div className='columns is-mobile is-vcentered full-width '>
          <div className='column'>
            <p className='t2-bold white'>
              <FormattedMessageWithClass id='client.caregiverProfile.experiencesCard.title' />
            </p>
          </div>
          {matchesMobile &&
            <div className='column has-text-right pointer center'>
              {isSelected ?
                <ArrowUpIcon height='24px' stroke='#FFF' width='24px' /> :
                <ArrowDownIcon height='24px' stroke='#FFF' width='24px' />
              }
            </div>
          }
        </div>
      </div>

      {!matchesMobile
        ? <ExperienceCardBody experiences={experiences} illnesses={illnesses} />
        : matchesMobile && isSelected
          ? <ExperienceCardBody experiences={experiences} illnesses={illnesses} />
          : null
      }
    </div>
  )
}

export default CaregiverExperienceCard
