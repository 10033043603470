import React, { Component, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

class Privacy extends Component {
  render() {
    const title = this.props.intl.formatMessage({
      id: 'app.privacy.options.your_privacy'
    })
    const text = this.renderText()

    return (
      <Fragment>
        <div className='level has-margin-b-7'>
          <div className='level-left'>
            <div className='level-item'>
              <h2 className='is-size-5 has-text-weight-semibold has-text-black'>{title}</h2>
            </div>
          </div>
        </div>
        {text}
      </Fragment>
    )
  }

  renderText() {
    let output = ''
    switch (this.props.locale) {
    // Spanish
    case 'es-es':
    case 'es-us':
      output = (
        <p className='has-text-justified'>
          {/* eslint-disable-next-line */}
          Como respetamos su privacidad, puede aceptar o rechazar nuestra utilización de cookies para cada categoría de cookies moviendo el selector que encontrarás al final de cada una de las líneas de abajo.Cada vez que se te ofrezca aceptar o rechazar la utilización de determinadas categorías de cookies, te proporcionaremos la información esencial que necesites saber para efectuar tu elección.Sin embargo, si bloquea algunos tipos de cookies, puede que su experiencia de uso de la web se vea afectada y también los servicios que podemos ofrecerle.Para obtener más información sobre la gestión de cookies que realiza Iberia acceda a nuestra política.<a href="#" target="_blank">Más información</a>
        </p>)
      break

      // All English
    default:
      output = (
        <p className='has-text-justified'>
          {/* eslint-disable-next-line */}
          Because we respect your privacy, you can accept or reject our use of cookies for each category of cookies by moving the selector at the end of each line below. Each time, you will be offered to accept or reject the use of certain categories of cookies, we will provide you with the essential information you need to be able to make your selection. However, if you block some types of cookies, your experience using the web may be affected, as well as the services we offer. To get more information on the management of Seniors First Care cookies, read our policy. <a href="#" target="_blank">More information</a>.
        </p>)
      break
    }

    return output
  }
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale.toLowerCase()
})

export default injectIntl(connect(mapStateToProps)(Privacy))
