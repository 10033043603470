import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import withChat from 'hoc/withChat'
import withJobOffer from 'hoc/withJobOffer'
import Container from 'components/organisms/AppContainer'
import CreditsBoard from 'components/organisms/CreditsBoard'
import NearCaregiversTitle from 'components/molecules/NearCaregiversTitle'
import LastJobOffers from 'components/organisms/LastJobOffers'
import NearCaregivers from 'components/organisms/NearCaregivers'
import ActionalbleEmptyResponse from 'components/atoms/ActionalbleEmptyResopnse'
import AnimatedSuccessModal from 'components/atoms/AnimatedSuccessModal'
import VideoModal from 'seniors-first-commons/components/molecules/VideoModal'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import CloseCaregivers from 'components/organisms/CloseCaregivers'
import { SendIcon } from 'seniors-first-commons/components/atoms/images/icons'


const HomeLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    showVideoCVModal,
    user,
    profile,
    serviceSheets,
    caregiversNearYou,
    sliderRef,
    specialService,
    showAnimatedModal,
    startAnimation,
    showAnayetRequestModal,
    loading,
    handleNextSlide,
    handlePrevSlide,
    onCallSpecialService,
    setShowAnimatedModal,
    setStartAnimation,
    onOpenVideoCVModal,
    onCloseVideoCVModal,
    onFindCaregiver,
    onCloseAnayetRequestModal
  },
  selectedCaregiver,
  caregiverStatus,
  actions,
  onViewProfile
}) => {
  const translations = {
    specialServiceNotSent: useFormatMessage('client.home.specialService.notSent.text'),
    specialServiceNotSentButton: useFormatMessage('client.general.specialService.notSent.button'),
    specialServiceSent: useFormatMessage('client.general.specialService.sent.text'),
    specialServiceSentButton: useFormatMessage('client.general.specialService.sent.button')
  }

  return (
    <>
      <div className='has-padding-b-3'>
        <Container additionalClass={matchesMobile ? 'has-padding-lr-7' : ''} name='Home'>
          <div className='part'>
            <CreditsBoard
              matchesMobile={matchesMobile}
              user={{ ...user, ...profile }}
            />

            <div className='has-margin-t-1'>
              <CloseCaregivers
                caregiversNearYou={caregiversNearYou}
                caregiverStatus={caregiverStatus}
                matchesMobile={matchesMobile}
                onFindCaregiver={onFindCaregiver}
                onOpenVideoCVModal={onOpenVideoCVModal}
                onViewProfile={onViewProfile}
                profile={profile}
                selectedCaregiver={selectedCaregiver}
              />
            </div>

            <div className='has-margin-bt-1'>
              <LastJobOffers
                jobOffers={serviceSheets}
                matchesMobile={matchesMobile}
                profile={profile}
                {...actions}
              />
            </div>

            <NearCaregiversTitle
              handleNextSlide={handleNextSlide}
              handlePrevSlide={handlePrevSlide}
              visibleButtons={caregiversNearYou.total}
            />

            {loading.loadingNearCaregivers ?
              null :
              !caregiversNearYou.total ?
                <div className='has-margin-t-6'>
                  <ActionalbleEmptyResponse
                    buttonText={
                      !startAnimation && specialService && specialService.id ?
                        translations.specialServiceSentButton:
                        translations.specialServiceNotSentButton
                    }
                    disabled={!startAnimation && specialService && specialService.id}
                    loading={false}
                    message={!startAnimation && specialService && specialService.id
                      ? translations.specialServiceSent
                      : translations.specialServiceNotSent
                    }
                    onClickAction={onCallSpecialService}
                  />

                  <AnimatedSuccessModal
                    loading={loading.loadingSpecialService}
                    open={showAnimatedModal}
                    setOpen={setShowAnimatedModal}
                    setStartAnimation={setStartAnimation}
                    startAnimation={startAnimation}
                  />
                </div>
                : null}
          </div>
        </Container>

        {loading.loadingNearCaregivers ?
          null :
          caregiversNearYou.total ?
            <>
              <NearCaregivers
                caregiverStatus={caregiverStatus}
                data={caregiversNearYou}
                onOpenVideoCVModal={onOpenVideoCVModal}
                onViewProfile={onViewProfile}
                selectedCaregiver={selectedCaregiver}
                sliderRef={sliderRef}
              />
            </>
            : null
        }

        <VideoModal
          isOpen={!!showVideoCVModal}
          onClose={onCloseVideoCVModal}
          title='CV Video'
          videoId={showVideoCVModal}
        />

        <ConfirmModal
          confirm='client.home.anayetModal.button'
          details='client.home.anayetModal.details'
          hascancelButton={false}
          hasCloseButton={false}
          Icon={SendIcon}
          onConfirm={onCloseAnayetRequestModal}
          show={showAnayetRequestModal}
          title='client.home.anayetModal.title'
        />
      </div>
    </>
  )
}

export default withChat(withJobOffer(HomeLayout), 'home')
