import { isEmpty } from 'ramda'
import { REACT_SELECT_PREFIXES } from 'seniors-first-commons/utils/constants'
import rawCountries from 'seniors-first-commons/utils/rawCountries'

const { REACT_SELECT } = REACT_SELECT_PREFIXES

export const hasValue = (value) => {
  if(isEmpty(value)) {
    return null
  }
  else {
    return value
  }
}

export const formatToMatchEnv = (url) => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV
  return currentEnv !== 'prod' ? `${url}-stage` : url
}

export const scrollToSpecificMenuOption = (prefix, options, value) => {
  setTimeout(() => {
    const optionIndex = options.findIndex(option => option.value === value)
    if (optionIndex > -1) {
      const optionToFocus = document.getElementById(`${REACT_SELECT}-${prefix}-option-${optionIndex}`)
      if (optionToFocus) {
        optionToFocus.parentNode.scrollTop = optionToFocus.offsetTop
      }
    }
  }, 1)
}

export const getCountryByCode = (code) => {
  return rawCountries.find(item => item[2].toLowerCase() === code.toLowerCase())
}
