import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import { getCreditsRecords } from 'store/billing/actions'
import CreditsLayout from 'components/templates/CreditsLayout'
import { ROUTE } from 'root/constants'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const tabsItems = [
  {
    label: 'client.credits.tabs.credits',
    value: 'my-credits'
  },
  {
    label: 'client.credits.tabs.records',
    value: 'records'
  }
]

const sortListItems = [
  {label: 'First order', value: 'firstorder'},
  {label: 'Second order', value: 'secondorder'},
  {label: 'Third order', value: 'thirdorder'}
]

const Credits = ({
  credits,
  getCreditsRecords,
  ...props
}) => {
  const history = useHistory()
  const selectedTab = props.match.params.view
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const fetchCreditsRecords = useCallback(() => {
    getCreditsRecords({ page: 1, per_page: ITEMS_PER_PAGE })
  }, [getCreditsRecords])

  useEffect(() => {
    fetchCreditsRecords()
  }, [fetchCreditsRecords])

  const onPageChange = (page) => {
    if (page !== credits.records.page) {
      getCreditsRecords({ page, per_page: ITEMS_PER_PAGE })
    }
  }

  const onChangeTab = (item) => {
    routingService.push(history, `${ROUTE.CREDITS}/${item.value}`)
  }

  const onViewJobOffer = (e) => {
    routingService.push(history, `${ROUTE.CANDIDATES}/${e}?tab=post`)
  }

  return (
    <div className='full-content'>
      <CreditsLayout logicPropsAndMethods={{
        creditRecords: credits.records,
        loading: {
          buyingCredits: credits.loadingCredits
        },
        matchesMobile,
        onChangeTab,
        onPageChange,
        onViewJobOffer,
        selectedTab,
        sortListItems,
        tabsItems,
        totalCredits: credits.total
      }}
      />

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    credits: state.billing.credits,
    helpers: state.helpers.helpers
  }
}

const mapDispatchToProps = {
  getCreditsRecords
}

export default connect(mapStateToProps, mapDispatchToProps)(Credits)
