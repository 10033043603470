import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTE } from 'root/constants'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'
import 'bulma-pageloader'

const getLoadingTextIdByRoute = (route = '') => {
  switch(`/${route}`) {
  case ROUTE.SEARCH:
    return 'client.search.loading.title'
  default:
    return 'app.loading'
  }
}

const Loading = ({
  loading
}) => {
  const history = useHistory()
  const currentRoute = history.location.pathname.split('/')[2]
  const loadingTextId = getLoadingTextIdByRoute(currentRoute)

  return (
    <div className={`pageloader ${loading ? 'is-active' : ''} has-background-dark `} style={{opacity: '0.93'}}>
      <div className={`px-5 full-width has-text-centered ${classes.loadingTextWrapper}`}>
        <FormattedMessageWithClass
          className={`is-size-5 ${classes.loadingText}`}
          id={loadingTextId}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading:
    state.helpers.helpers.loading
    || state.user.user.loading
    || state.jobOffers.loading
    || state.caregivers.loading
    || state.billing.loading
    || state.profile.profile.loading
    || state.caregivers.chat.loading
    || state.billing.credits.loading
    || state.billing.billing.loading
  }
}


export default connect(mapStateToProps)(Loading)
