import React, { useState } from 'react'
import { resizeFile } from 'seniors-first-commons/services/resizePhoto'
import UploadZoneModal from 'seniors-first-commons/components/atoms/UploadZoneModal'
import CropModal from 'seniors-first-commons/components/organisms/CropModal'


const withPhoto = (WrappedComponent) => {
  const InnerComponent = ({...props}) => {
    const [showUploadZoneModal, setShowUploadZoneModal] = useState(false)
    const [photo, setPhoto] = useState(null)
    const { loading: { uploadingPhoto }, actions: { uploadPhoto } } = props

    const onPhotoClick = () => {
      setShowUploadZoneModal(true)
    }

    const onConfirmCrop = (croppedImage) => {
      return resizeFile(croppedImage)
        .then(image => {
          uploadPhoto({ photo: image })
            .unwrap()
            .then(() => {
              onCloseUploadModal()
              onCancelCrop()
            })
        })
    }

    const onCancelCrop = () => {
      setPhoto(null)
    }

    const onUpload = (e) => {
      const file = Array.isArray(e) ? e[0] : e.target.files[0]
      if(file) setPhoto(file)
      setShowUploadZoneModal(false)
    }

    const onCloseUploadModal = () => {
      setShowUploadZoneModal(false)
    }

    return (
      <>
        <WrappedComponent
          onPhotoClick={onPhotoClick}
          {...props}
        />

        <UploadZoneModal
          accept = '.png,.jpeg,.jpg,.gif'
          isOpen={showUploadZoneModal}
          onCancel={onCloseUploadModal}
          onClose={onCloseUploadModal}
          onUploadClick={onUpload}
        />

        <CropModal
          image={photo}
          isUpdating={uploadingPhoto}
          onCancel={onCancelCrop}
          onConfirm={onConfirmCrop}
        />
      </>
    )
  }

  return InnerComponent
}


export default withPhoto
