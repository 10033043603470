import React from 'react'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import { ROUTE } from 'root/constants'


const PasswordChangedSuccess = () => {
  const history = useHistory()

  const translations = {
    backbutton: useFormatMessage('app.password.changepasswordcontainer.button')
  }

  const onBack = () => {
    routingService.push(history, ROUTE.LOGIN)
  }

  return (
    <>
      <p className='t1-bold primary has-margin-b-3'>
        <FormattedMessageWithClass id='app.password.changepasswordcontainer.title' />
      </p>

      <button
        className='button filled nonAuthedButtons'
        onClick={onBack}
      >
        {translations.backbutton}
      </button>
    </>
  )
}

export default PasswordChangedSuccess
