import React from 'react'
import { Field, ErrorMessage } from 'formik'
import moment from 'moment'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Select from 'seniors-first-commons/components/molecules/Select'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import {
  DAYS_OF_WEEK,
  handleTimes,
  TIMES,
  REACT_SELECT_PREFIXES,
  DEFAULT_FOCUSED_START_HOUR
} from 'seniors-first-commons/utils/constants'
import { scrollToSpecificMenuOption } from 'seniors-first-commons/utils/helpers'

const { INTERNAL_STARTING_HOUR } = REACT_SELECT_PREFIXES

const InternalModality = ({ values, matchesMobile }) => {
  const sort_days = (day) => {
    const exit_days = []

    if(day){
      for(let i = handleTimes(day); i <= (handleTimes(day) + DAYS_OF_WEEK.length - 1); i++){
        if(i >= DAYS_OF_WEEK.length){
          i = 0
        }
        if(i === handleTimes(day) - 1){
          break
        }
        exit_days.push(DAYS_OF_WEEK[i])
      }
      exit_days.push(day)
      return exit_days
    }
    else return DAYS_OF_WEEK
  }

  const handleEndTimes = (start_day, exit_day, start_hour) => {
    if(start_day.value === exit_day.value){
      if(start_hour.value){
        // find start_hour in TIMES
        const targetIndex = TIMES.findIndex(item => item === start_hour.value)
        return TIMES.slice(0, targetIndex)
      }
      else return TIMES
    }
    else return TIMES
  }

  const startingDays = DAYS_OF_WEEK.map((day, index) => ({ label: day, value: index + 1 }))
  const endingDays = sort_days(values.live_in_care.day_of_week_from.label)
    .map((day) => ({label: day, value: handleTimes(day)}))

  const startingHours = TIMES.map(time => ({
    label: time,
    value: moment(time, 'HH:mm').format('HH:mm')
  }))

  const endingHours = handleEndTimes(
    values.live_in_care.day_of_week_from,
    values.live_in_care.day_of_week_to,
    values.live_in_care.hour_from
  ).map(time => ({
    label: time,
    value: moment(time, 'HH:mm').format('HH:mm')
  }))

  return (
    <div
      className='has-margin-t-1 has-text-centered'
      style={{ width: matchesMobile ? '100%' : '80%' }}
    >
      <div className='is-flex has-flex-center-items'>
        <div className='is-relative has-margin-r-6 full-width'>
          <p className='l-bold primary'>
            <FormattedMessageWithClass
              id='client.createOffer.steps.modality.entryDay'
            />
          </p>
          <Field
            component={Select}
            hasoptionicon={false}
            isSearchable={false}
            menuPlacement='top'
            name='live_in_care.day_of_week_from'
            options={startingDays}
            placeholder=''
          />
        </div>

        <div className='is-relative full-width'>
          <p className='l-bold primary'>
            <FormattedMessageWithClass
              id='client.createOffer.steps.modality.entryHour'
            />
          </p>
          <Field
            component={Select}
            hasoptionicon={false}
            instanceId={INTERNAL_STARTING_HOUR}
            isSearchable={false}
            menuPlacement='top'
            name='live_in_care.hour_from'
            onMenuOpen={() => scrollToSpecificMenuOption(
              INTERNAL_STARTING_HOUR,
              startingHours,
              DEFAULT_FOCUSED_START_HOUR
            )}
            options={startingHours}
            placeholder=''
            shouldTranslate={false}
          />
        </div>
      </div>

      <div className='is-flex has-flex-center-items has-margin-t-6'>
        <div className='is-relative has-margin-r-6 full-width'>
          <p className='l-bold primary'>
            <FormattedMessageWithClass
              id='client.createOffer.steps.modality.lastDay'
            />
          </p>
          <Field
            component={Select}
            hasoptionicon={false}
            isSearchable={false}
            menuPlacement='top'
            name='live_in_care.day_of_week_to'
            options={endingDays}
            placeholder=''
          />
        </div>

        <div className='is-relative full-width'>
          <p className='l-bold primary'>
            <FormattedMessageWithClass
              id='client.createOffer.steps.modality.lastHour'
            />
          </p>
          <Field
            component={Select}
            hasoptionicon={false}
            isSearchable={false}
            menuPlacement='top'
            name='live_in_care.hour_to'
            options={endingHours}
            placeholder=''
            shouldTranslate={false}
          />
        </div>
      </div>

      <ErrorMessage
        component={HandleError}
        errorMessage='validations.clients.createOffer.modality.live-in-care'
        name='live_in_care'
      />
    </div>
  )
}

export default InternalModality
