import React, { useEffect, useCallback, useState, useRef } from 'react'
import { connect } from 'react-redux'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import { getCaregiverDetail } from 'store/caregivers/actions'
import CaregiverProfileLayout from 'components/templates/CaregiverProfileLayout'


const CaregiverProfile = ({
  caregiver,
  profile,
  availableOffers,
  getCaregiverDetail,
  ...props
}) => {
  const [selectedCard, setSelectedCard] = useState('')
  const [showImageModal, setShowImageModal] = useState(false)
  const [showVideoCVModal, setShowVideoCVModal] = useState('')
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const formRef = useRef(null)

  const caregiverId = props.match.params.id
  const query = useQuery()
  const comingFrom = query.get('from')
  const jobOfferId = query.get('job_offer_id')

  const fetchCaregiver = useCallback(() => {
    getCaregiverDetail({ id: caregiverId, service_sheet_id: jobOfferId })
  }, [getCaregiverDetail, caregiverId, jobOfferId])

  useEffect(() => {
    fetchCaregiver()
  }, [fetchCaregiver])

  const toggleProfileImageModal = () => setShowImageModal(!showImageModal)

  const onOpenVideoCVModal = (video_id) => {
    setShowVideoCVModal(video_id)
  }

  const onCloseVideoCVModal = () => {
    setShowVideoCVModal('')
  }

  const changeSelectedCard = (e) =>{
    if (e === selectedCard) return setSelectedCard('')
    return setSelectedCard(e)
  }

  return (
    <div className='full-content'>
      <CaregiverProfileLayout
        logicPropsAndMethods={{
          availableOffers,
          caregiver,
          caregiverId,
          changeSelectedCard,
          comingFrom,
          formRef,
          jobOfferId,
          matchesMobile,
          onCloseVideoCVModal,
          onOpenVideoCVModal,
          profile,
          selectedCard,
          showImageModal,
          showVideoCVModal,
          toggleProfileImageModal
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    availableOffers: state.jobOffers.availableOffers,
    caregiver: state.caregivers.caregiverDetails,
    interestedReasons: state.caregivers.interestedReasons,
    profile: state.profile.profile
  }
}

const mapDispatchToProps = {
  getCaregiverDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(CaregiverProfile)
