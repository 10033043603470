import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { interestedModalOptions } from 'seniors-first-commons/utils/constants'
import { TimesIcon, CheckIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const Icon = ({ status }) => {
  if(status) {
    return (
      <CheckIcon
        fill='none'
        height='24'
        stroke='#0080F5'
        strokeWidth='3'
        width='24'
      />
    )
  }
  else {
    return (
      <TimesIcon
        fill='none'
        height='24'
        stroke='#ED0202'
        strokeWidth='3'
        width='24'
      />
    )
  }
}

const InterestedReasonsModal = ({
  title='',
  details='',
  reasons,
  open,
  onClose,
  children
}) => {
  return (
    <CustomModal
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick
      styles={{ maxWidth: '450px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <p className='t2-bold grey'>
          <FormattedMessageWithClass id={title} />
        </p>

        <p className='p1-regular has-margin-t-7 grey'>
          <FormattedMessageWithClass id={details} />
        </p>

        <div className={`has-padding-bt-5 ${classes.optionsContainer}`}>
          {interestedModalOptions.map((option, index) => (
            <div className={`has-margin-bt-7 ${classes.optionWrapper}`} key={index}>
              <div className='is-flex'>
                <Icon status={!reasons.includes(option.value)} />
                <FormattedMessageWithClass
                  className='p1-regular grey'
                  id={option.label}
                />
              </div>
            </div>
          ))}
        </div>

        {children}
      </div>
    </CustomModal>
  )
}

export default InterestedReasonsModal
