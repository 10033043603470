import React from 'react'
import Steps, { Step } from 'rc-steps'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const WizardSteps = ({
  currentStep=0,
  matchesMobile,
  steps=[],
  additionalClasses='',
  onChange
}) => {
  return (
    <Steps
      className={`has-margin-bt-1 ${classes.stepsTrack} ${additionalClasses}`}
      current={currentStep}
      icons={{
        error: <span>&#9888;</span>,
        finish: <span>&#10003;</span>
      }}
      labelPlacement={!matchesMobile ? 'vertical' : ''}
      onChange={onChange}
    >
      {steps.map(({ label, label2, status }, index) => (
        <Step
          key={index}
          status={status}
          title={!matchesMobile ? (
            <>
              <FormattedMessageWithClass id={label} />
              {label2  ? <> / <FormattedMessageWithClass id={label2} /></> : null}
            </>
          ) : ''}
        />
      ))}
    </Steps>
  )
}

export default WizardSteps
