import React from 'react'
import moment from 'moment'
import { Field, ErrorMessage } from 'formik'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import DatePicker from 'seniors-first-commons/components/molecules/DatePicker'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'


const UndefinedDuration = () => {
  return (
    <>
      <div className='has-margin-t-1'>
        <FieldLabel
          labelName='client.createOffer.steps.duration.undefined.start'
          labelType='l-bold'
        />
        <Field
          component={DatePicker}
          id='start_date'
          isOutsideRange={(day) => day.isSameOrBefore(moment())}
          name='undefined_duration_from'
          openDirection='up'
        />
      </div>

      <ErrorMessage
        component={HandleError}
        name='undefined_duration_from' />
    </>
  )
}

export default UndefinedDuration
