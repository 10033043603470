import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { resetPassword } from 'seniors-first-commons/shared/store/auth/actions'
import PasswordChangeLayout from 'seniors-first-commons/components/templates/PassowordChangeLayout'
import { ROUTE } from 'root/constants'


const PasswordChange = ({
  resetPasswordData,
  resetPassword,
  locale,
  ...props
}) => {
  const history = useHistory()
  const token = props.match.params.token

  useEffect(() => {
    document.title = 'Seniors First | Change password'
  }, [])

  const onChangePassword = ({ new_password }) => {
    resetPassword({ new_password, token })
  }

  const onRegister = () => {
    routingService.push(history, ROUTE.REGISTER)
  }

  const onLogoClick = () => routingService.push(history, ROUTE.LOGIN)

  return (
    <PasswordChangeLayout
      locale={locale}
      onChangePassword={onChangePassword}
      onLogoClick={onLogoClick}
      onRegister={onRegister}
      resetPasswordData={resetPasswordData}
      role={props.role}
    />
  )
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  resetPasswordData: state.auth.resetPassword
})

const mapDispatchToProps = {
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange)
