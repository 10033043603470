import React from 'react'
import { GoogleIcon, FBIcon } from 'seniors-first-commons/components/atoms/images/icons/'
import classes from './index.module.css'


const SocialLogin = ({
  onGoogleLogin,
  onFBLogin
}) => {
  return (
    <div className={classes.socialIconsWrapper}>
      <div
        className={`has-margin-r-7 ${classes.socialIcon}`}
        onClick={onGoogleLogin}
      >
        <GoogleIcon
          height='18'
          width='18'
        />
      </div>
      <div
        className={classes.socialIcon}
        onClick={onFBLogin}
      >
        <FBIcon
          fill='#3A7FBE'
          height='18'
          width='18'
        />
      </div>
    </div>
  )
}

export default SocialLogin
