import React, { useState } from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { CameraIcon } from 'seniors-first-commons/components/atoms/images/icons'


const UploadImage = ({ image, additionalClass='is-160x160', onClick }) => {
  const [hover, setHover] = useState(false)

  const handleHoverOn = () => setHover(true)
  const handleHoverOff = () => setHover(false)

  return (
    <figure
      className={`image ${additionalClass}`}
      onClick={onClick}
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
    >
      <div
        className={` ${hover || !image ? 'overlay photo' : ''} ${
          !image ? 'overlay-photo--empty' : ''
        }`}
      >
        {!!(!hover && !image) && (
          <div className='overlay-content'>
            <CameraIcon
              fill='#0040AA'
              height='28'
              width='28'
            />
            <label className='pointer p2-bold'>
              <FormattedMessageWithClass
                className='primary'
                id='app.home.profileimage.upload'
              />
            </label>
          </div>
        )}
        {hover && (
          <div className='overlay-content'>
            <CameraIcon
              fill='#0040AA'
              height='28'
              width='28'
            />
            <label className='pointer p2-bold'>
              <FormattedMessageWithClass
                className='primary'
                id={image ? 'app.home.profileimage.update' : 'app.home.profileimage.upload'}
              />
            </label>
          </div>
        )}
      </div>
      <img alt='' className='is-rounded is-160x160' height='160px' src={image} width='160px' />
      <label className='label is-hidden-tablet has-text-centered'>
        <FormattedMessageWithClass
          className='primary-light'
          id='app.home.profileimage.update'
        />
      </label>
    </figure>
  )
}

export default UploadImage
