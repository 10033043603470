import React from 'react'
import {FormattedMessageWithClass, useFormatMessage} from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { SFCoinsIcon } from 'seniors-first-commons/components/atoms/images/icons'


const WelcomeModal = ({ open, firstname, onClose }) => {
  const videoURL = useFormatMessage('client.welcome.welcomeModal.video.url')

  return (
    <CustomModal
      hasCloseButton={false}
      onClose={onClose}
      open={open}
      styles={{ maxWidth: '500px', width: '95%' }}
    >
      <div
        className='has-margin-bt-5 is-flex'
        style={{ justifyContent: 'center' }}
      >
        <SFCoinsIcon />
      </div>
      <div className='has-text-centered'>
        <p className='t1-bold black is-uppercase ellipsis'>
          <FormattedMessageWithClass id='client.welcome.welcomeModal.title' />
          {' '}
          <span>{firstname}</span>!
        </p>

        <ul className='has-padding-t-7'>
          {[...Array(5).keys()].map((item) => (
            <li className='p2-bold' key={item + 1}>
              <FormattedMessageWithClass id={`client.welcome.welcomeModal.text${item + 1}`} />
            </li>
          ))}
        </ul>

        <div className='divider'></div>

        <div className='p2-bold'>
          <p>
            <FormattedMessageWithClass id='client.welcome.welcomeModal.howitworks' />
          </p>
          <a
            href={videoURL}
            rel='noopener noreferrer'
            target='_blank'
          >
            <FormattedMessageWithClass id='client.welcome.welcomeModal.video' />
          </a>
        </div>

        <button
          className='button filled has-margin-t-5'
          onClick={onClose}
          style={{ height: '48px', width: '140px' }}
        >
          <FormattedMessageWithClass id='client.welcome.welcomeModal.button' />
        </button>
      </div>
    </CustomModal>
  )
}


export default WelcomeModal
