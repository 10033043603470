// should be implemented with the wizard that shows up on first login 
// see: src/pages/Welcome/index.js


export default class Welcome {
  constructor() {
    this.country = {
      label: '',
      value: ''
    }
    this.address = {
      address_lat: 0,
      address_lng: 0,
      formatted_address: ''
    }
    this.phone = ''
  }
}
