import { createAsyncThunk } from '@reduxjs/toolkit'
import services from 'seniors-first-commons/services/api-service'
import * as R from 'ramda'
const { axiosGet } = services


export const getInterestedReasons = createAsyncThunk(
  'shared/helpers/getInterestedReasons',
  ({ job_offer_id, caregiver_id }, { rejectWithValue }) => {
    return axiosGet(`/service-sheets/${job_offer_id}/carer/${caregiver_id}/job-proposition`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCountries = createAsyncThunk(
  'shared/helpers/getCountries',
  (payload, { rejectWithValue }) => {
    return axiosGet('/countries')
      .then(countries => R.sort(R.ascend(R.prop('name')))(countries))
      .catch(error => rejectWithValue(error))
  }
)

export const getIllnesses = createAsyncThunk(
  'shared/helpers/getIllnesses',
  (payload, { rejectWithValue }) => {
    return axiosGet('/illnesses')
      .then(illnesses => illnesses)
      .catch(error => rejectWithValue(error))
  }
)

export const getLanguages = createAsyncThunk(
  'shared/helpers/getLanguages',
  (payload, { rejectWithValue }) => {
    return axiosGet('/languages')
      .then(languages => R.sort(R.ascend(R.prop('name')))(languages))
      .catch(error => rejectWithValue(error))
  }
)

export const getServices = createAsyncThunk(
  'shared/helpers/getServices',
  (payload, { rejectWithValue }) => {
    return axiosGet('/services')
      .then(languages => languages)
      .catch(error => rejectWithValue(error))
  }
)
