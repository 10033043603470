import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'


export const VideoModal = ({
  isOpen,
  videoId='',
  onClose
}) => {
  const [isReady, setIsReady] = useState(false)
  const [error, setError] = useState(false)

  const BASE_VIMEO_URL = 'https://vimeo.com/'

  return (
    <CustomModal
      onClose={() => {
        setIsReady(false)
        setError(false)
        onClose()
      }}
      open={isOpen}
      shouldCloseOnOverlayClick={true}
    >
      <div
        className='has-text-centered'
        style={{ padding: '20px' }}
      >
        {videoId
          ? (
            <>
              <ReactPlayer
                config={{
                  options: {
                    allowfullscreen: true
                  }
                }}
                controls={true}
                height={isReady ? '300px' : '0px'}
                onError={() => setError(true)}
                onReady={() => setIsReady(true)}
                pip={false}
                style={{ objectFit: 'cover' }}
                url={BASE_VIMEO_URL + videoId}
                width={isReady ? '100%' : '0px'}
              />
            </>
          )
          : null
        }

        {!isReady && !error ?
          <div className='spinner-custom-loader'></div> :
          null
        }
        {!isReady && error ? (
          <div>
            <CloseCircle
              fill='none'
              height='64'
              stroke='red'
              width='64'
            />

            <p className='p1-bold has-margin-t-7'>
              Uups, something went wrong please try again later
            </p>
          </div>
        ) : null}
      </div>
    </CustomModal>
  )
}

export default VideoModal
