import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Select from 'seniors-first-commons/components/molecules/Select'
import { ArchivedIcon } from 'seniors-first-commons/components/atoms/images/icons'
import { ORDER_LIST_STYLES as listStyles } from 'seniors-first-commons/styles/orderListStyles'


const MyJobOffersHeader = ({
  showArchived,
  matchesMobile,
  selectedSort,
  onChangeOrder,
  onToggleArchivedStatus
}) => {
  const filterDropDown = [
    {
      label: 'client.myOffers.sortby.oldest',
      value: 'updated_at/asc'
    },
    {
      label: 'client.myOffers.sortby.mostRecent',
      value: 'updated_at/desc'
    }
  ]

  return (
    <div className={`columns is-vcentered ${matchesMobile ? 'has-padding-lr-7 is-marginless-lr' : ''}`}>
      <div className={`column ${matchesMobile ? 'is-paddingless-l' : ''}`}>
        <h1 className='t1-bold primary'>
          <FormattedMessageWithClass id='client.myOffers.title' />
        </h1>
      </div>
      <div className={`column ${matchesMobile ? 'is-paddingless-l' : ''}`}>
        <div
          className='is-flex'
          style={{ alignItems: 'center', justifyContent: !matchesMobile ? 'flex-end' : 'space-between'  }}
        >
          <button
            className={`button-text has-margin-r-7 is-flex ${matchesMobile ? 'is-paddingless' : 'has-padding-l-6'}`}
            onClick={onToggleArchivedStatus}
            style={{ alignItems: 'center' }}
          >
            <ArchivedIcon
              className='button-text-icon has-margin-r-7'
              fill='none'
              height='24px'
              width='24px'
            />
            <FormattedMessageWithClass
              className='p1-bold'
              id={showArchived ? 'client.myOffers.unarchive.text' : 'client.myOffers.archived.text'}
            />
          </button>

          <Select
            addStyles={listStyles}
            hasoptionicon={false}
            isSearchable={false}
            onChange={onChangeOrder}
            options={filterDropDown}
            value={selectedSort}
          />
        </div>
      </div>
    </div>
  )
}


export default MyJobOffersHeader
