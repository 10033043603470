import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import classes from './index.module.css'

const ConfirmModal = ({
  title='',
  details='',
  confirm='',
  cancel='',
  children=undefined,
  show=false,
  hascancelButton=true,
  hasCloseButton=true,
  loading=false,
  Icon = false,
  vars={},
  onClose=()=>{},
  onCancel=()=>{},
  onConfirm=()=>{}
}) => {
  return (
    <CustomModal
      hasCloseButton={hasCloseButton}
      onClose={onClose}
      open={show}
      styles={{ maxWidth: '400px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <>
          {Icon && <Icon
            className='button-text-icon has-margin-t-7'
            fill='none'
            height='56'
            width='56'
          />}
          <p className='t2-bold grey has-margin-t-7' style={{ wordBreak: 'break-all' }}>
            <FormattedMessageWithClass id={title} values={vars} />
          </p>
        </>
        {!!details &&
          <p className='p1-regular grey'>
            <FormattedMessageWithClass id={details} />
          </p>
        }
        {children}
        <div className='columns has-margin-t-5'>
          {hascancelButton &&
            <div className='column'>
              <button
                className={`outlined ${classes.cancelButton}`}
                onClick={onCancel}
              >
                <FormattedMessageWithClass
                  id={cancel ? cancel : 'apps.general.confirmModal.cancel'}
                />
              </button>
            </div>
          }

          <div className='column'>
            <button
              className={`button filled ${loading ? 'is-loading' : ''} ${classes.acceptButton}`}
              disabled={loading ? true : false}
              onClick={onConfirm}
            >
              <FormattedMessageWithClass
                id={confirm ? confirm : 'apps.general.confirmModal.confirm'}
              />
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default ConfirmModal
