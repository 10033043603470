import React from 'react'
import { FormattedMessage } from 'react-intl'
import CustomModal from '../CustomModal'
import { UploadZone } from '../UploadZone'


const UploadZoneModal = ({
  isOpen,
  accept,
  onUploadClick,
  onClose
}) => {
  return (
    <CustomModal
      onClose={onClose}
      open={isOpen}
    >
      <div className='has-text-centered' style={{ padding: '20px' }}>
        <UploadZone accept={accept} onUploadClick={onUploadClick} />
        <button
          className='filled'
          onClick={onClose}
          style={{ height: '48px', width: '200px' }}
          type='button'
        >
          <FormattedMessage id='app.components.uploadzone.modal.cancel' />
        </button>
      </div>
    </CustomModal>
  )
}

UploadZoneModal.displayName = 'UploadZoneModal'

export default UploadZoneModal
