import React from 'react'
import { Route, Redirect, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { languageRegex } from 'seniors-first-commons/shared/languages'


const PublicRoute = ({
  isAuthed,
  restricted,
  component: Component,
  destination,
  ...props
}) => {
  const { lang } = useParams()

  return (
    <Route
      {...props}
      render={(renderProps) => {
        if(props.role) renderProps['role'] = props.role
        return (
          isAuthed && restricted ?
            <Redirect
              to={{
                pathname: lang
                  ? destination ?
                    !languageRegex.test(destination.pathname) ?
                      `/${lang}${destination.pathname}`
                      : `${destination.pathname}`
                    : `/${lang}/`
                  : '/',
                search: destination && destination.search
                  ? destination.search
                  : ''
              }}/> :
            <Component {...renderProps} />
        )
      }}
    />
  )
}

const mapStateToProps = (state) => ({
  destination: state.auth.destination
})

export default connect(mapStateToProps, null)(PublicRoute)
