import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const FeedHeader = ({ notificationsEnabled, onToggleNotification }) => {
  return (
    <div id='feed-header'>
      <div className={`full-width ${classes.toggleContainer}`}>
        <p className='p2-bold black'>
          <FormattedMessageWithClass id='client.chat.feedHeader.enableNotifications' />
        </p>
        <div className={classes.toggle}>
          <input
            checked={notificationsEnabled}
            className={classes.toggleCheckbox}
            id='toggle'
            name='notificationToggle'
            onChange={onToggleNotification}
            type='checkbox'
          />
          <label className={classes.toggleLabel} htmlFor='toggle'></label>
        </div>
      </div>
    </div>
  )
}


export default FeedHeader
