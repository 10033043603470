import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const CredtisTable = ({ items, onViewJobOffer }) => {
  return (
    <table
      className={`table has-margin-t-2 is-fullwidth has-text-centered ${classes.table}`}
    >
      <thead>
        <tr className='has-text-left'>
          <th className={`t4-bold grey ${classes.headerElement}`}>
            <FormattedMessageWithClass
              id='client.credits.records.table.firstColumn'
            />
          </th>
          <th className={`t4-bold grey has-text-right ${classes.headerElement}`}>
            <FormattedMessageWithClass
              id='client.credits.records.table.secondColumn'
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr className={`has-text-left ${classes.row}`} key={index}>
            <td className={`t4-bold primary ${classes.rowElement}`}>
              <span
                className='pointer'
                onClick={() => onViewJobOffer(item.service_sheet_id)}
              >
                {item.name}
              </span>
            </td>

            <td className={`t4-bold primary has-text-right ${classes.rowElement}`}>
              {item.amount}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CredtisTable
