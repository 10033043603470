import { useEffect } from 'react'

export const useClipBody = (open, shouldClipBody, shouldUnclipBody) => {
  useEffect(() => {
    if (open && shouldClipBody) {
      document.body.classList.add('is-clipped')
      document.documentElement.classList.add('is-clipped')
    } else {
      if (shouldUnclipBody) {
        document.body.classList.remove('is-clipped')
        document.documentElement.classList.remove('is-clipped')
      }
    }
  }, [open, shouldClipBody])
}
