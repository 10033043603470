import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { equals } from 'ramda'
import { Redirect, useHistory } from 'react-router-dom'
import { useFecthDataOnCondition } from 'seniors-first-commons/shared/hooks'
import routingService from 'seniors-first-commons/services/routingService'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { blackList } from 'seniors-first-commons/shared/store/auth/actions'
import { toggleMobileSidebar } from 'store/layouts/actions'
import { clear, getProfile } from 'store/profile/actions'
import Navbar from 'components/molecules/PublicNavbar'
import MobileSidebar from 'components/organisms/PublicMobileSidebar'
import { ROUTE } from 'root/constants'


const AppContainer = ({
  children,
  name='',
  RightSidebar,
  shouldRequestProfile=true,
  showMobileSidebar,
  additionalClass='',
  user,
  profile,
  notReadNotifications,
  notReadMessages,
  getProfile,
  blackList,
  clear,
  toggleMobileSidebar
}) => {
  const history = useHistory()
  const currentRoute = `/${history.location.pathname.split('/')[2]}`
  const query = useMediaQuery()

  useFecthDataOnCondition(() => {
    getProfile()
  }, !profile.index && shouldRequestProfile)

  useEffect(() => {
    const title = notReadMessages ?
      `(${notReadMessages}) Seniors First | ${name}` :
      `Seniors First | ${name}`
    document.title = title
  }, [name, notReadMessages])

  if (!profile.finishedWizard) {
    return <Redirect to={routingService.getFinalRoute(ROUTE.WELCOME)} />
  }

  const changeRoute = (route) => {
    toggleMobileSidebar(false)
    window.open(`${process.env.REACT_APP_LANDING_URL}#${route}`, '_blank')
  }

  const activeRoute = (route) => {
    return equals(route, currentRoute)
  }

  const onLogout = () => {
    toggleMobileSidebar(false)
    blackList()
      .then(() => clear())
      .finally(() => routingService.push(history, ROUTE.LOGIN))
  }

  return (
    <div className='has-navbar-fixed-top full-height full-width'>
      <section className='has-padding-b-5'>
        <Navbar
          activeRoute={activeRoute}
          changeRoute={changeRoute}
          notReadMessages={notReadMessages}
          notReadNotifications={notReadNotifications}
          onLogout={onLogout}
          showMobileSidebar={showMobileSidebar}
          toggleMobileSidebar={toggleMobileSidebar}
          user={{ ...user, ...profile.index }}
        />
      </section>

      {RightSidebar}

      <MobileSidebar
        activeRoute={activeRoute}
        changeRoute={changeRoute}
        onLogout={onLogout}
        open={query[0] < 1024 && showMobileSidebar}
        toggleMobileSidebar={toggleMobileSidebar}
      />

      <div className={`container section full-height ${additionalClass}`}>
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    helpers: state.helpers.helpers,
    notReadMessages: state.caregivers.chat.notReadMessages,
    notReadNotifications: state.notifications.notReadNotifications,
    profile: state.profile.profile,
    showMobileSidebar: state.layouts.showMobileSidebar,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  blackList,
  clear,
  getProfile,
  toggleMobileSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
