import React from 'react'
import Lottie from 'react-lottie'
import animationData from './success.json'
import classes from './index.module.css'


const AnimatedSuccessModal = ({
  open,
  startAnimation,
  setOpen,
  setStartAnimation,
  loading
}) => {

  const defaultOptions = {
    animationData,
    autoplay: false,
    loop: false,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const onComplete = () => {
    setStartAnimation(false)
    setOpen(false)
  }

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className='modal-background'></div>
      <div className={`modal-content ${classes.modalbox}`}>
        {!loading ?
          <Lottie
            eventListeners={[
              { callback: onComplete, eventName: 'complete' }
            ]}
            height={200}
            isPaused={!startAnimation}
            isStopped={!startAnimation}
            options={defaultOptions}
            width={200}
          />
          : null
        }

        {loading ?
          <div className='spinner-custom-loader'></div>
          : null}
      </div>
    </div>
  )
}

export default AnimatedSuccessModal
