import { createReducer } from '@reduxjs/toolkit'
import {
  getUser,
  updateUser,
  userActive,
  userIdle,
  toggleSuccessStatus
} from './actions'


const initState = {
  active: true,
  success: '',
  user: {
    data: null,
    loading: false,
    updatingUserData: false
  }
}


const reducer = createReducer(initState, (builder) => {
  builder.addCase(getUser.fulfilled, (state, action) => {
    state.user.data = action.payload
  })

  builder.addCase(getUser.pending, (state) => {
    state.user.loading = true
  })

  builder.addCase(updateUser.fulfilled, (state, action) => {
    state.user.data = action.payload
    state.success = action.payload.success || ''
  })

  builder.addCase(updateUser.pending, (state) => {
    state.user.updatingUserData = true
  })

  builder.addCase(userActive, (state) => {
    state.active = true
  })

  builder.addCase(userIdle, (state) => {
    state.active = false
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addMatcher((action) => [
    getUser.fulfilled.toString(),
    getUser.rejected.toString()
  ].includes(action.type), (state) => {
    state.user.loading = false
  })

  builder.addMatcher((action) => [
    updateUser.fulfilled.toString(),
    updateUser.rejected.toString()
  ].includes(action.type), (state) => {
    state.user.updatingUserData = false
  })
})

export default reducer
