import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { DotsIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ActionsDropdown = ({ item, onToggleArchivedStatus }) => {
  return (
    <div className='dropdown is-right is-hoverable'>
      <div className='dropdown-trigger'>
        <button className='button-text'>
          <DotsIcon
            className='button-text-icon'
            fill='none'
            height='27'
            stroke='#0080F5'
            width='24'
          />
        </button>
      </div>
      <div
        className={`dropdown-menu is-paddingless ${classes.dropdownMenu}`}
        id='dropdown-actions-mysearches'
        role='menu'
      >
        <div className='dropdown-content is-paddingless'>
          <div
            className={`dropdown-item has-padding-b-7 ${classes.menuItem}`}
            onClick={onToggleArchivedStatus}
          >
            <FormattedMessageWithClass
              className='p1-regular primary'
              id={item?.archived
                ? 'apps.general.jobOffer.actions.unarchive'
                : 'apps.general.jobOffer.actions.archive'
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionsDropdown
