import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import {
  CuidadoIcon,
  CompaniaIcon,
  EnfermeriaIcon,
  HogarIcon
} from 'seniors-first-commons/components/atoms/images'
import classes from './index.module.css'


const BoxIconMap = {
  '1': HogarIcon,
  '2': CompaniaIcon,
  '3': CuidadoIcon,
  '4': EnfermeriaIcon
}

// eslint-disable-next-line no-unused-vars
const OneServiceBox = ({ label, selected, value, ...props }) => {
  return (
    <div className='column is-one-quarter-widescreen'>
      <label
        className={` has-text-centered ${classes.item} ${
          selected.includes(`${label}`) ? 'primary-light-background white' : 'grey'
        }`}
        htmlFor={label}
      >
        <input
          className={classes.box}
          id={label}
          type='checkbox'
          value={label}
          {...props}
        />
        <img
          alt={label}
          className={classes.serviceIcon}
          src={BoxIconMap[label]}
          title={useFormatMessage(`apps.general.jobOffer.services.${label}`)}
        />
        <span className='p2-bold'>
          {useFormatMessage(`apps.general.jobOffer.services.${label}`)}
        </span>
      </label>
    </div>
  )
}

const OfferedServices = ({ field }) => {
  return (
    <div className='columns is-vcentered is-multiline full-width'>
      <OneServiceBox label='1' selected={field.value} {...field} />

      <OneServiceBox label='2' selected={field.value} {...field} />

      <OneServiceBox label='3' selected={field.value} {...field} />

      <OneServiceBox label='4' selected={field.value} {...field} />
    </div>
  )
}

export default OfferedServices
