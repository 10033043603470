import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { SuccessIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ChatOrSendOfferModal = ({
  title,
  details,
  open,
  onClose,
  loading,
  onChat,
  onSendOffer
}) => {
  return (
    <CustomModal
      onClose={onClose}
      open={open}
      styles={{ maxWidth: '400px', width: '95%' }}
    >
      <div className={classes.chatOrSendOfferModal_wrapper}>
        <SuccessIcon
          fill='none'
          height='72'
          stroke='#0080F5'
          widht='24'
        />
        {title ?
          <p className='has-text-centered has-margin-bt-6 t2-bold grey'>
            <FormattedMessageWithClass id={title} />
          </p> :
          null
        }

        {details ?
          <p className='has-text-centered has-margin-b-5 p1-regular'>
            <FormattedMessageWithClass id={details} />
          </p> :
          null
        }

        <div className={`full-width ${classes.buttonsWrapper}`}>
          <button
            className={`
              button outlined
              ${classes.chatOrSendOfferModal_button}
            `}
            disabled={loading}
            onClick={onSendOffer}
          >
            <FormattedMessageWithClass
              id='client.general.chatOrSend.sendNewOffer'
            />
          </button>

          <button
            className={`button filled ${classes.chatOrSendOfferModal_button}`}
            onClick={onChat}
          >
            Chat
          </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default ChatOrSendOfferModal
