import { getCookie, setCookie } from './cookies'
import { toUrlFormat, specificLanguages } from 'seniors-first-commons/shared/languages'
import { LANG_LOCALSTORAGE_KEY } from 'seniors-first-commons/utils/constants'
import ApiService from './api-service'


const IP_COUNTRY_CODE = 'cfg_ip_country_code'

const EUROPEAN_CODES = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'GB'
]

class RegionService {
  isEU = (countryCode) => EUROPEAN_CODES.includes(countryCode)

  setCountryCodeCookie = (countryCode) => {
    setCookie(IP_COUNTRY_CODE, countryCode)
  }

  getCountryCodeCookie = () => getCookie(IP_COUNTRY_CODE)

  inferLocale = (countryCode, urlLocale) => {
    const storedLang = localStorage.getItem(LANG_LOCALSTORAGE_KEY)
    let locale = 'en-gb'

    // Si ingreso una URL especifica, que mantenga esa URL
    if (specificLanguages.includes(toUrlFormat(urlLocale))) {
      locale = urlLocale.toLowerCase()
    } else if (['es', 'en'].includes(urlLocale.toLowerCase())) {
      // Si ingreso 'es' o 'en' tratarlo segun el pais de origen
      if (urlLocale.toLowerCase() === 'es' && this.isEU(countryCode)) {
        locale = 'es-es'
      } else if (urlLocale.toLowerCase() === 'es' && !this.isEU(countryCode)) {
        locale = 'es-us'
      } else if (urlLocale.toLowerCase() === 'en' && this.isEU(countryCode)) {
        locale = 'en-gb'
      } else if (urlLocale.toLowerCase() === 'en' && !this.isEU(countryCode)) {
        locale = 'en-us'
      }
    } else {
      // first check localstorage if use manually updated the lang
      if (storedLang) {
        if (storedLang === 'en') {
          if (this.isEU(countryCode)) locale = 'en-gb'
          else locale = 'en-us'
        }
        else if (storedLang === 'es') {
          if (this.isEU(countryCode)) locale = 'es-es'
          else locale = 'es-us'
        }
      }
      else {
        // Sino ver si se conecta desde la UE, y poner en-us sino.
        if (this.isEU(countryCode)) {
          if (countryCode === 'ES') locale = 'es-es'
          else locale = 'en-gb'
        }
        else locale = 'en-us'
      }
    }

    return locale
  }

  getCountryCode = () => {
    const countryCode = this.getCountryCodeCookie()

    if (countryCode === '') {
      return ApiService.axiosGet('/ipinfo')
        .then((data) => {
          this.setCountryCodeCookie(data.country)
          return Promise.resolve(data.country)
        })
    } else {
      return Promise.resolve(countryCode)
    }
  }

  getLocale = () => {
    const pathArray = window.location.pathname.split('/')

    return this.getCountryCode().then((countryCode) => {
      return this.inferLocale(countryCode, pathArray[1])
    })
  }

  getPath = () => {
    const pathArray = window.location.pathname.split('/')
    const queries = window.location.search

    return this.getCountryCode().then((countryCode) => {
      const locale = this.inferLocale(countryCode, pathArray[1])
      if (specificLanguages.includes(toUrlFormat(pathArray[1]))) {
        pathArray[1] = toUrlFormat(locale)
      }
      else pathArray.splice(1, 0, toUrlFormat(locale))
      return Promise.resolve({ pathname: `${pathArray.join('/')}`, search: queries })
    })
  }
}

const service = new RegionService()

export default service
