import React from 'react'
import { ROUTE } from 'root/constants'
import {
  PersonIcon,
  PaperIcon,
  DollarIcon,
  HelpIcon,
  LockIcon,
  LogoutIcon,
  SearchIcon,
  PersonsIcon,
  SuitCase,
  HouseIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import { formatToMatchEnv } from 'seniors-first-commons/utils/helpers'
import classes from './index.module.css'


const MobileSidebarItems = ({
  spaceBetweenItems=7,
  changeRoute,
  activeRoute,
  onLogout
}) => {
  const navbarItems = [
    {
      Icon: HouseIcon,
      filled: true,
      isActive: activeRoute(ROUTE.HOME),
      onClick: () => changeRoute(ROUTE.HOME),
      text: 'client.general.sidebar.home'
    },
    {
      Icon: SuitCase,
      isActive: activeRoute(ROUTE.MY_JOB_OFFERS),
      onClick: () => changeRoute(ROUTE.MY_JOB_OFFERS),
      text: 'client.general.sidebar.myOffers'
    },
    {
      Icon: PersonsIcon,
      isActive: activeRoute(ROUTE.MY_CAREGIVERS),
      onClick: () => changeRoute(ROUTE.MY_CAREGIVERS),
      text: 'client.general.sidebar.myCaregivers'
    },
    {
      Icon: SearchIcon,
      isActive: activeRoute(ROUTE.CREATE_JOB_OFFER),
      onClick: () => changeRoute(ROUTE.CREATE_JOB_OFFER),
      text: 'client.general.sidebar.findCaregiver'
    },
    {
      Icon: PersonIcon,
      isActive: activeRoute(ROUTE.PROFILE_SETTINGS),
      onClick: () => changeRoute(ROUTE.PROFILE_SETTINGS),
      text: 'client.general.sidebar.profileSettings'
    },
    {
      Icon: PaperIcon,
      isActive: activeRoute(ROUTE.BILLING),
      onClick: () => changeRoute(ROUTE.BILLING),
      text: 'client.general.sidebar.billing'
    },
    {
      Icon: DollarIcon,
      isActive: activeRoute(ROUTE.CREDITS),
      onClick: () => changeRoute(`${ROUTE.CREDITS}/my-credits`),
      text: 'client.general.sidebar.credits'
    },
    {
      Icon: HelpIcon,
      external: useFormatMessage(formatToMatchEnv('app.general.faq.link')),
      onClick: ()=>{},
      text: 'client.general.sidebar.faq'
    },
    {
      Icon: LockIcon,
      external: useFormatMessage(formatToMatchEnv('app.footer.privacy.url')),
      filled: true,
      onClick: ()=>{},
      text: 'client.general.sidebar.privacyPolicy'
    },
    {
      Icon: LogoutIcon,
      onClick: onLogout,
      text: 'client.general.sidebar.logout'
    }
  ]

  return (
    <>
      {navbarItems.map(({ text, Icon, external, onClick, ...rest}, index) => (
        <a
          className={`
            navbar-item ${spaceBetweenItems ? `has-margin-bt-${spaceBetweenItems}` : ''}
            ${rest.isActive ? 'primary' : ''} 
            ${classes.navbarItem}
          `}
          href={!!external ? external : null}
          key={index}
          onClick={onClick}
          rel={!!external ? 'noopener noreferrer' : ''}
          target={!!external ? '_blank' : ''}
        >
          <Icon
            fill={rest.filled ? rest.isActive ? '#0040AA' : '#525860' : 'none'}
            height='22'
            stroke={rest.isActive ? '#0040AA' : '#525860'}
            width='22'
          />
          <FormattedMessageWithClass
            className='p1-regular has-margin-l-6'
            id={text}
          />
        </a>
      ))}
    </>
  )
}

export default MobileSidebarItems
