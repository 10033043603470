import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'

const DaysAndShiftsTable = ({ offer, className = '' }) => {
  let daysAndShiftsList
  if (offer?.hiring_modality === 'visiting_care') {
    daysAndShiftsList = offer?.visitingCareTimes
  } else if (offer?.hiring_modality === 'live_in_care') {
    daysAndShiftsList = offer?.livingCareTimes
  }

  return (
    <table className={`table is-striped is-fullwidth ${className}`}>
      <tbody>
        {daysAndShiftsList?.map(({ day, shift }, index) => (
          <tr key={index}>
            <td className='p1-bold grey has-padding-l-7'>
              <FormattedMessageWithClass id={day} />
            </td>
            <td className='p1-regular grey'>
              {shift}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default DaysAndShiftsTable
