import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { PhoneIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Phone = ({ data, caregiverStatus }) => {
  const phone = data.phone
  const translations = {
    lockedInfo: useFormatMessage('client.general.caregiverCard.blockedInformation'),
    lockedInfoTooltip: useFormatMessage('client.general.caregiverCard.blockedInformation.tooltip')
  }

  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <PhoneIcon
        fill='none'
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='16'
      />
      {phone ?
        <a
          className='has-margin-l-7 primary-light p2-bold ellipsis'
          href={`tel:${phone}`}
          style={{ maxWidth: '85%' }}
        >
          {phone}
        </a>
        :
        <div className='custom-tooltip'>
          <span
            className={`has-margin-l-7 p2-bold ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey-lighter'}`}
          >
            {translations.lockedInfo}
          </span>

          {caregiverStatus.text !== 'Rejected' &&
            <span
              className='tooltip-content p3-bold white'
              placement='top'
            >
              {translations.lockedInfoTooltip}
            </span>
          }
        </div>
      }
    </div>
  )
}

export default Phone
