import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { DotsIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const NotificationDropdown = ({
  notificationId,
  read,
  onChangeReadStatus,
  onChangeArchivedStatus
}) => {
  return (
    <div className='dropdown is-right is-hoverable has-margin-r-5'>
      <div className='dropdown-trigger'>
        <DotsIcon
          fill='none'
          height='27'
          stroke='#0080F5'
          width='24'
        />
      </div>
      <div
        className={`dropdown-menu is-paddingless ${classes.dropdownMenu}`}
        id='dropdown-notifications'
        role='menu'
      >
        <div className='dropdown-content is-paddingless'>
          <div
            className={`dropdown-item p1-regular primary ${classes.menuItem}`}
            onClick={() => onChangeReadStatus(notificationId)}
          >
            <FormattedMessageWithClass
              id={read ? 'apps.notifications.notread' : 'apps.notifications.read' }
            />
          </div>

          <div
            className={`dropdown-item p1-regular primary ${classes.menuItem}`}
            onClick={() => onChangeArchivedStatus(notificationId, read)}
          >
            <FormattedMessageWithClass id='apps.notifications.delete' />
          </div>
        </div>
      </div>
    </div>
  )
}


export default NotificationDropdown
