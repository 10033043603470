import React, { useMemo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'

const baseStyle = {
  alignItems: 'center',
  backgroundColor: '#fafafa',
  borderColor: '#eeeeee',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  color: '#bdbdbd',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '8rem',
  justifyContent: 'center',
  marginBottom: '1rem',
  outline: 'none',
  padding: '20px',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

export const UploadZone = ({ accept = 'image/*', onUploadClick }) => {
  const onDrop = useCallback(acceptedFiles => {
    onUploadClick(acceptedFiles)
  }, [onUploadClick])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    // eslint-disable-next-line
    [isDragActive, isDragReject]
  )

  return (
    <div className='container'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className='is-size-6'>
          <FormattedMessage id='app.components.uploadzone.text' /><br/>
          <FormattedMessage id='app.components.uploadzone.or' />
          <button
            onClick={open}
            style={{ display: 'block', margin: '5px auto' }}
            type='button'
          >
            <FormattedMessage id='app.components.uploadzone.button' />
          </button>
        </p>
      </div>
    </div>
  )
}
