import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { profileSettingsSchema } from 'schemas/profileSettings'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import LanguageSwitch from 'seniors-first-commons/components/organisms/LanguageSwitch'
import {
  Username,
  Firstname,
  Lastname,
  Email,
  AddressInput,
  CountryInput,
  Phone
} from './inputs'
import classes from './index.module.css'


const ProfileSettingsForm = ({
  initialValues,
  countries,
  onChangeCountry,
  onSubmit
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={profileSettingsSchema}
    >
      {({ values, dirty, isSubmitting, setFieldValue, handleSubmit }) => {
        return (
          <>
            <Form>
              <div className='has-margin-b-3'>
                <Field
                  component={Username}
                  id='username'
                  name='username'
                  placeholder=''
                />
              </div>
              <div className='has-margin-b-3'>
                <Field
                  component={Email}
                  id='email'
                  name='email'
                  placeholder=''
                />
              </div>

              <div className='has-margin-b-3'>
                <Field
                  component={Firstname}
                  id='firstname'
                  name='firstname'
                  placeholder=''
                />
              </div>

              <div className='has-margin-b-3'>
                <Field
                  component={Lastname}
                  id='lastname'
                  name='lastname'
                  placeholder=''
                />
              </div>

              <div className='has-margin-b-3'>
                <Field
                  component={CountryInput}
                  name='country'
                  onChange={(e) => onChangeCountry(e, setFieldValue)}
                  options={countries || []}
                  value={values.country}
                />
              </div>

              <div className='has-margin-b-3'>
                <Field
                  component={AddressInput}
                  country={values.country ? values.country.code : null}
                  id='address'
                  name='address'
                  placeholder=''
                />
              </div>

              <div className='has-margin-b-3'>
                <Field component={Phone} name='phone' />
                <div className={`has-margin-t-6 d-flex ${classes.phoneCommentCentered}`}>
                  <InfoIcon
                    fill='none'
                    height='24'
                    stroke='#0040AA'
                    width='24'
                  />
                  <FormattedMessageWithClass
                    className='p3-regular primary has-margin-l-7 has-text-left'
                    id='client.profileSettings.phoneComment'
                  />
                </div>
              </div>

            </Form>

            <div className='has-margin-b-3'>
              <FieldLabel
                bottomSpace={7}
                centered={false}
                id='language'
                labelName='client.profileSettings.languagePreference'
                labelType='p1-bold'
              />
              <LanguageSwitch
                additionalClasses={classes.languageSwitch}
                openToUp={true}
              />
            </div>

            <button
              className={`
                button filled main-button
                ${isSubmitting ? 'is-loading' : ''} 
                ${classes.button}
              `}
              disabled={isSubmitting || !dirty}
              onClick={handleSubmit}
              type='submit'
            >
              <FormattedMessageWithClass id='client.profileSettings.updateButton' />
            </button>
          </>
        )
      }}
    </Formik>
  )
}

export default ProfileSettingsForm
