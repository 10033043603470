import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import resetPasswordInitValues from 'seniors-first-commons/shared/models/resetPassword'
import resetPasswordSchema from 'seniors-first-commons/shared/schemas/resetPassword'
import { Email } from './inputs'


const PasswordResetForm = ({
  isSubmitting,
  onFormSubmit
}) => {
  return (
    <Formik
      initialValues={new resetPasswordInitValues()}
      onSubmit={onFormSubmit}
      validationSchema={resetPasswordSchema}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Field
              component={Email}
              name='email'
            />
            <button
              className={`button filled has-margin-t-7 ${isSubmitting ? 'is-loading' : ''} nonAuthedButtons`}
              disabled={isSubmitting}
              onClick={handleSubmit}
              type='submit'
            >
              <FormattedMessageWithClass id='app.password.passwordresetform.button' />
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PasswordResetForm
