import React from 'react'
import withChat from 'hoc/withChat'
import MobileLayout from '../MobileLayout'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'
import CaregiverCard from 'components/organisms/CaregiverCard'


const DesktopLayout = ({
  candidates,
  selectedCaregiver,
  caregiverStatus,
  onOpenVideoCVModal,
  onViewProfile
}) => {
  return (
    <div className='columns is-marginless-l is-multiline has-margin-t-4'>
      {candidates.items.map((candidate, index) => {
        return (
          <div
            className='
              column is-one-third-desktop is-half-tablet is-one-quarter-fullhd is-paddingless-l'
            key={index}>
            <CaregiverCard
              caregiverStatus={caregiverStatus({
                ...candidate,
                no_credits_to_connect: candidate.job_proposition_status === 'accepted' && candidate.phone
                  ? false : true
              })}
              data={candidate}
              onOpenVideoCVModal={onOpenVideoCVModal}
              onViewProfile={() => onViewProfile(candidate)}
              rejected={candidate?.job_proposition_status === 'rejected'}
              selectedCaregiver={selectedCaregiver}
            />
          </div>
        )
      })}
    </div>
  )
}


const CandidatesFromSearch = ({
  logicPropsAndMethods: {
    matchesMobile,
    candidates,
    loading,
    onOpenVideoCVModal
  },
  selectedCaregiver,
  caregiverStatus,
  onViewProfile
}) => {
  return (
    <>
      {!loading ?
        candidates.total ?
          matchesMobile ? (
            <MobileLayout
              candidates={candidates}
              caregiverStatus={caregiverStatus}
              onOpenVideoCVModal={onOpenVideoCVModal}
              onViewProfile={onViewProfile}
              selectedCaregiver={selectedCaregiver}
            />
          ): (
            <DesktopLayout
              candidates={candidates}
              caregiverStatus={caregiverStatus}
              onOpenVideoCVModal={onOpenVideoCVModal}
              onViewProfile={onViewProfile}
              selectedCaregiver={selectedCaregiver}
            />
          )
          : <EmptyBox stroke='#525860' />
        : null
      }
    </>
  )
}

export default withChat(CandidatesFromSearch, 'findsend')
