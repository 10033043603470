import React from 'react'
import CaregiverCard from 'components/organisms/CaregiverCard'


const MyCaregiversDesktopLayout = ({
  selectedCaregiver,
  caregiverStatus,
  unlockedCaregivers,
  onViewProfile,
  onOpenVideoCVModal
}) => {
  return (
    <div className='columns is-marginless-l is-multiline'>
      {unlockedCaregivers.items.map((caregiver, index) => {
        return (
          <div
            className='column is-one-third-desktop is-half-tablet is-one-quarter-fullhd is-paddingless-l'
            key={index}
          >
            <CaregiverCard
              caregiverStatus={caregiverStatus({ ...caregiver })}
              data={caregiver}
              onOpenVideoCVModal={onOpenVideoCVModal}
              onViewProfile={() => onViewProfile(caregiver)}
              selectedCaregiver={selectedCaregiver}
            />
          </div>
        )
      })}
    </div>
  )
}

export default MyCaregiversDesktopLayout
