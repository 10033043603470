import React from 'react'
import ReactPlayer from 'react-player'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import {useIntl} from 'react-intl'


const WelcomeVideo = ({ matchesMobile, ...props }) => {
  const {locale} = useIntl()
  const video =  locale.toLowerCase().includes('es')
    ? 'https://player.vimeo.com/video/814657155' : 'https://player.vimeo.com/video/814657258'


  return (
    <>
      <h3 className='t2-bold primary has-margin-t-7 is-capitalized'>
        <FormattedMessageWithClass id='client.welcome.video.title' />
      </h3>

      <p className='l-regular grey has-margin-b-3 has-margin-t-7 has-text-centered'>
        <FormattedMessageWithClass id='client.welcome.video.text' />
      </p>

      <div style={{ width: '400px' }}>
        <ReactPlayer
          config={{
            options: {
              allowfullscreen: true
            }
          }}
          controls={true}
          height={matchesMobile ? '100%' : '300px'}
          pip={false}
          style={{ objectFit: 'cover' }}
          url={video}
          width={'100%'}
        />
      </div>

      <button
        className={`button filled full-width ${props.isSubmitting ? 'is-loading' : ''}`}
        disabled={props.isSubmitting}
        onClick={props.handleSubmit}
        style={{ height: '45px', maxWidth: '200px' }}
        type='submit'
      >
        <FormattedMessageWithClass id='client.welcome.video.submit' />
      </button>
    </>
  )
}

export default WelcomeVideo
