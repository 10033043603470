import React from 'react'
import Navbar from 'react-bulma-components/lib/components/navbar/navbar'
import Logo from 'seniors-first-commons/components/atoms/images/logo.svg'


const InitialNavbar = ({ onLogoClick }) => (
  <Navbar active={true} transparent={true}>
    <Navbar.Brand className='has-padding-t-5'>
      <Navbar.Item onClick={onLogoClick} renderAs='a'>
        <img
          alt='Seniors First'
          height='40'
          src={Logo}
          title='Seniors First'
          width='196'
        />
      </Navbar.Item>
    </Navbar.Brand>
  </Navbar>
)

export default InitialNavbar
