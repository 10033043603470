import store from '.'
import axios from 'axios'
import { changeErrorMessageStatus } from './layouts/actions'


const SPECIAL_ROUTES = [
  '/login/google',
  '/login',
  '/register',
  '/clients/billing-info',
  '/request-password-reset',
  '/password-reset'
]

const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 500) {
      if (!SPECIAL_ROUTES.includes(error.config.url)) {
        store.dispatch(changeErrorMessageStatus('errors.somethingWentWrong'))
      }
    }
  }

  if (error.request) {
    if (!SPECIAL_ROUTES.includes(error.config.url)) {
      store.dispatch(changeErrorMessageStatus('errors.somethingWentWrong'))
    }
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(null, errorHandler)
