
export default class ProfileSettings {
  constructor() {
    this.username = ''
    this.email = ''
    this.firstname = ''
    this.lastname = ''
    this.country = {
      label: '',
      value: ''
    }
    this.address = {
      address_lat: 0,
      address_lng: 0,
      formatted_address: ''
    }
    this.phone = ''
  }
}
