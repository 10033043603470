import React from 'react'

import {
  FormattedMessageWithClass
} from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'

const MobileSidebarItems = ({
  spaceBetweenItems=7,
  changeRoute
}) => {
  const navbarItems = [
    {
      icon: null,
      onClick: () => changeRoute('howItWorks'),
      route: 'howItWorks',
      text: 'app.publicNavbar.howItWorks'
    },
    {
      icon: null,
      onClick: () => changeRoute('caregiversInYourArea'),
      route: 'caregiversInYourArea',
      text: 'app.publicNavbar.caregiversInYourArea'
    },
    {
      icon: null,
      onClick: () => changeRoute('help'),
      route: 'help',
      text: 'app.publicNavbar.help'
    }
  ]

  return (
    <>
      {navbarItems.map(({ text, external, onClick, ...rest}, index) => (
        <a
          className={`
            navbar-item ${spaceBetweenItems ? `has-margin-bt-${spaceBetweenItems}` : ''}
            ${rest.isActive ? 'primary' : ''} 
            ${classes.navbarItem}
          `}
          href={!!external ? external : null}
          key={index}
          onClick={onClick}
          rel={!!external ? 'noopener noreferrer' : ''}
          target={!!external ? '_blank' : ''}
        >
          <FormattedMessageWithClass
            className='p1-regular has-margin-l-6'
            id={text}
          />
        </a>
      ))}
    </>
  )
}

export default MobileSidebarItems
