import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import { PencilEdit } from 'seniors-first-commons/components/atoms/images/icons'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import Select from 'seniors-first-commons/components/molecules/Select'
import Input from 'seniors-first-commons/components/atoms/Input'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'

export const Username = (props) => {
  return (
    <>
      <FieldLabel
        active={false}
        bottomSpace={0}
        centered={false}
        id={props.field.name}
        labelName='client.profileSettings.username'
        labelType='p1-bold'
      />
      <Input
        className='input'
        disabled
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#A6A6A6'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
    </>
  )
}

export const Email = (props) => {
  return (
    <>
      <FieldLabel
        active={false}
        bottomSpace={0}
        centered={false}
        id={props.field.name}
        labelName='client.profileSettings.email'
        labelType='p1-bold'
      />
      <Input
        className='input'
        disabled
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#A6A6A6'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
    </>
  )
}

export const Firstname = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.field.name}
        labelName='client.profileSettings.firstname'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
      <ErrorMessage
        centered={false}
        component={ErrorHandler}
        name={props.field.name}
        spaceTop={0}
      />
    </>
  )
}

export const Lastname = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.field.name}
        labelName='client.profileSettings.lastname'
        labelType='p1-bold'
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        maxLength={255}
        {...props}
      />
      <ErrorMessage
        centered={false}
        component={ErrorHandler}
        name={props.field.name}
        spaceTop={0}
      />
    </>
  )
}

export const CountryInput = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.field.name}
        labelName='client.profileSettings.country'
        labelType='p1-bold'
      />
      <Select
        id={props.field.name}
        placeholder=''
        shouldTranslate={false}
        {...props}
      />
      <ErrorMessage
        centered={false}
        component={ErrorHandler}
        name={props.field.name}
        spaceTop={0}
      />
    </>
  )
}

export const AddressInput = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id={props.field.name}
        labelName='client.profileSettings.address'
        labelType='p1-bold'
      />
      <AddressAutoSuggest
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        id={props.field.name}
        inputClassName='input'
        {...props}
      />
      <ErrorMessage
        centered={false}
        component={ErrorHandler}
        name={props.field.name}
        spaceTop={0}
      />
    </>
  )
}

export const Phone = () => {
  return (
    <>
      <FieldLabel
        centered={false}
        id='phone'
        labelName='client.profileSettings.phone'
        labelType='p1-bold'
        topSpace={1}
      />
      <Field
        component={PhoneInput}
        name='phone'
      />
      <ErrorMessage
        component={ErrorHandler}
        name='phone'
      />
    </>
  )
}
