import React from 'react'
import Rating from 'react-rating'
import {
  EmptyStar,
  FullStar
} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const RatingContainer = ({
  rating=0,
  ...props
}) => {

  const EmtpyIcon = () => (
    <EmptyStar
      fill='none'
      height='18'
      stroke={`${props.disabled ? props.disabled : '#FFD500'}`}
      width='18'
    />
  )
  const FullIcon = () => (
    <FullStar
      fill='none'
      height='18'
      stroke={`${props.disabled ? props.disabled : '#FFD500'}`}
      width='18'
    />
  )

  const formatRating = (rating) => {
    if(rating) {
      const formatRating = parseFloat(rating.toString().slice(0, 4)) // -> gets the integer part with only the first two decimals
      const integerPart = Math.trunc(formatRating) // -> get the number's integer part
      const decimalPart = Number((formatRating - integerPart).toFixed(2)) // -> gets the number's decimal part

      if(decimalPart >= 0 && decimalPart < 0.25) {
        rating = integerPart + 0
      }

      else if (decimalPart >= 0.25 && decimalPart < 0.75) {
        rating = integerPart + 0.5
      }

      else if (decimalPart >= 0.75) {
        rating = integerPart + 1
      }

      return rating
    }
    else return 0
  }

  return (
    <div className={classes.ratingContainer}>
      <Rating
        emptySymbol={<EmtpyIcon />}
        fractions={2}
        fullSymbol={<FullIcon />}
        initialRating={formatRating(rating)}
        readonly
      />
    </div>
  )
}

export default RatingContainer
