import React from 'react'
import AppContainer from 'components/organisms/AppContainer'
import ChatHeader from 'components/molecules/InboxParts/ChatHeader'
import FeedHeader from 'components/molecules/InboxParts/FeedHeader'
import ContactDetailsCard from 'seniors-first-commons/components/molecules/ContactDetailsCard'
import EmptyChats from 'seniors-first-commons/components/atoms/EmptyChats'


const InboxLayout = ({
  chatId,
  basicData=null,
  inboxRef,
  conversationDetails,
  matchesMobile,
  matchesDesktop,
  conversationSelected,
  notificationsEnabled,
  chatInboxBackButtonRef,
  onToggleNotification,
  onRedirectToCaregiverProfile,
  onCopy
}) => {
  return (
    <AppContainer
      additionalClass={matchesMobile ? 'has-padding-lr-7 is-marginless' : ''}
      name='Chat'
      shouldRequestProfile={false}
    >
      {chatId ?
        <div
          className={`full-width is-paddingless-t ${conversationSelected ? 'columns is-mobile' : ''}`}
          style={{ margin: 'auto', maxWidth: matchesMobile ? '640px' : '100%' }}
        >
          <div className={`${conversationSelected ? `column is-paddingless-t is-two-thirds-widescreen is-full-touch ${matchesMobile ? 'is-paddingless' : ''}` : ''}`}>
            <div id='container'>
              <div id='header-row'>
                {matchesMobile ? conversationSelected ?
                  <ChatHeader
                    {...conversationDetails}
                    basicData={basicData}
                    chatInboxBackButtonRef={chatInboxBackButtonRef}
                    matchesDesktop={matchesDesktop}
                    matchesMobile={matchesMobile}
                    onCopy={onCopy}
                    onRedirectToCaregiverProfile={onRedirectToCaregiverProfile}
                  />
                  :
                  (
                    <FeedHeader
                      notificationsEnabled={notificationsEnabled}
                      onToggleNotification={onToggleNotification}
                    />
                  )
                  : conversationSelected ? (
                    <>
                      <FeedHeader
                        notificationsEnabled={notificationsEnabled}
                        onToggleNotification={onToggleNotification}
                      />

                      <ChatHeader
                        {...conversationDetails}
                        basicData={basicData}
                        chatInboxBackButtonRef={chatInboxBackButtonRef}
                        matchesDesktop={matchesDesktop}
                        matchesMobile={matchesMobile}
                        onCopy={onCopy}
                        onRedirectToCaregiverProfile={onRedirectToCaregiverProfile}
                      />
                    </>
                  ) : null
                }
              </div>
              <div id='talkjs-container' ref={inboxRef} />
            </div>
          </div>

          {basicData && !matchesDesktop &&
            <div className={`${conversationSelected ? 'column is-paddingless-lt is-one-third-widescreen is-hidden-touch is-hidden-desktop-only' : ''}`}
            >
              <ContactDetailsCard basicData={basicData} />
            </div>
          }
        </div> :
        <EmptyChats role={'client'} />
      }
    </AppContainer>
  )
}

export default InboxLayout
