import React from 'react'
import JobOfferCard from 'components/organisms/JobOfferCard'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'


const MyJobOffersDesktopLayout = ({
  showArchived,
  jobOffers,
  loading,
  ...props
}) => {
  return (
    <>
      {loading.loadingJobOffers ? null :
        jobOffers.total ?
          jobOffers.items.map((serviceSheet, index) => (
            <JobOfferCard
              archived={showArchived}
              item={serviceSheet}
              key={index}
              matchesMobile={false}
              profile={props.profile}
              {...props}
            />
          )) :
          showArchived ?
            <div className='no-results'>
              <EmptyBox stroke='#525860' />
            </div> :
            <div className='no-results'>
              <EmptyBox stroke='#525860' />
            </div>
      }
    </>
  )
}

export default MyJobOffersDesktopLayout
