import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { parseQueries, stringifyQueries } from 'seniors-first-commons/shared/hooks'
import { getCaregivers, getCaregiverDetail } from 'store/caregivers/actions'
import { toggleRightSidebar } from 'store/layouts/actions'
import { getIllnesses, getLanguages } from 'seniors-first-commons/shared/store/helpers/actions'
import { askForSpecialService } from 'store/profile/actions'
import { getOfferDetails } from 'store/jobOffers/actions'
import routingService from 'seniors-first-commons/services/routingService'
import SearchLayout from 'components/templates/SearchLayout'
import { ROUTE } from 'root/constants'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'

const defaultOrderType = {
  label: 'client.search.header.sortBy.options.bestRanking',
  value: 'ranking'
}

const Search = ({
  profile,
  caregivers,
  loadingPageData,
  sheetData,
  interestedReasons,
  showRightSidebar,
  helpers,
  specialService,
  getCaregivers,
  getOfferDetails,
  askForSpecialService,
  toggleRightSidebar,
  getIllnesses,
  getLanguages,
  ...props
}) => {
  const [showSearchDetails, setShowSearchDetails] = useState(false)
  const [showAnimatedModal, setShowAnimatedModal] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const [showVideoCVModal, setShowVideoCVModal] = useState('')
  const [orderBy, setOrderBy] = useState(defaultOrderType)
  const history = useHistory()
  const jobOfferId = props.match.params.id
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const fetchCaregivers = useCallback(() => {
    if (!helpers?.illnesses) getIllnesses()
    if (!helpers?.languages) getLanguages()
    getOfferDetails({ id: jobOfferId })
      .then(({ payload }) => {
        const parsedParams = parseQueries()
        getCaregivers({
          ...payload,
          ...parsedParams,
          per_page: ITEMS_PER_PAGE
        })
      })
    // eslint-disable-next-line
  }, [getCaregivers, getOfferDetails, getIllnesses, getLanguages, jobOfferId])

  useEffect(() => {
    fetchCaregivers()
  }, [fetchCaregivers])

  useEffect(() => {
    const parsedParams = parseQueries()
    const orderByParam = parsedParams.order_by
    if (orderByParam === 'nearest_first') {
      setOrderBy({ label: 'client.search.header.sortBy.options.nearestFirst', value: 'nearest' })
    }
  }, [])

  const handlePageChange = (e) => {
    if (caregivers.currentPage === e) return
    else {
      const orderValue = orderBy.value === 'nearest' ? 'nearest_first' : undefined
      const parsedParams = parseQueries()
      const pageParam = stringifyQueries({ ...parsedParams, order_by: orderValue, page: e })
      history.push({ search: pageParam })
      getCaregivers({ ...sheetData, ...parsedParams, order_by: orderValue, page: e, per_page: ITEMS_PER_PAGE })
    }
  }

  const onViewCandidates = () => {
    routingService.push(history, `${ROUTE.CANDIDATES}/${sheetData.id}?tab=post`)
  }

  const onCallSpecialService = () => {
    setShowAnimatedModal(true)
    askForSpecialService({ from : 'search' })
      .then(() => setStartAnimation(true))
  }

  // FILTERS START
  const handleDocumentClick = event => {
    let isSidebarClassFound = false
    const path = event.path || (event.composedPath && event.composedPath())

    path.forEach(elem => {
      if (elem && elem.classList) {
        const data = elem.classList.value
        if (data.includes('search_filter_sidebar')) {
          isSidebarClassFound = true
        }
      }
    })
    if (isSidebarClassFound === false && event.target.id !== 'filter_button' && !showRightSidebar) {
      toggleRightSidebar(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false)
    // eslint-disable-next-line
  }, [])

  const onShowRightSidebar = () => toggleRightSidebar(true)
  const onHideRightSidebar = () => toggleRightSidebar(false)

  const onFilter = ({
    gender,
    age,
    nationalities,
    disease_experience,
    education_level,
    languages,
    mobilizations,
    pet_friendly,
    driving_license
  }, { setSubmitting, setStatus }) => {
    const list = {
      age: age ? `${age[0]}-${age[1]}` : undefined,
      disease_experience: disease_experience.length ? disease_experience.map(item => item.value) : undefined,
      driving_license: driving_license ? driving_license : undefined,
      education_level: education_level?.length ? education_level.map(item => item.value) : undefined,
      gender: gender.length && gender.length === 1 ? gender : undefined,
      languages: languages.length ? languages.map(item => item.value) : undefined,
      mobilizations: mobilizations ? mobilizations : undefined,
      nationalities: nationalities.length ? nationalities.map(item => item.value) : undefined,
      pet_friendly: pet_friendly ? pet_friendly : undefined
    }

    const updatedQuries = stringifyQueries(list)
    history.push({ search: updatedQuries })
    const parsedUpdatedQuries = parseQueries()

    getCaregivers({ ...parsedUpdatedQuries, ...sheetData, page: 1, per_page: ITEMS_PER_PAGE })
      .unwrap()
      .then(() => {
        setSubmitting(false)
        setStatus({ success: true })
        setOrderBy(defaultOrderType)
        onHideRightSidebar()
      })
  }

  const onRemoveFilters = () => {
    history.push({
      search: ''
    })
    getCaregivers({ ...sheetData, page: 1, per_page: ITEMS_PER_PAGE })
      .unwrap()
      .then(() => onHideRightSidebar())
  }

  const onCloseVideoCVModal = () => {
    setShowVideoCVModal('')
  }

  const onOpenVideoCVModal = (video_id) => {
    setShowVideoCVModal(video_id)
  }

  const onChangeOrder = (order) => {
    if (order.value !== orderBy.value) {
      const newOrderValue = order.value === 'nearest' ? 'nearest_first' : undefined
      const parsedParams = parseQueries()
      const pageParams = stringifyQueries({ ...parsedParams, order_by: newOrderValue, page: 1 })
      history.push({ search: pageParams })
      getCaregivers({ ...sheetData, ...parsedParams, order_by: newOrderValue, page: 1 })
        .unwrap()
        .then(() => setOrderBy(order))
    }
  }

  return (
    <div className='full-content'>
      <SearchLayout
        logicPropsAndMethods={{
          caregivers,
          handlePageChange,
          helpers,
          interestedReasons,
          jobOfferId,
          loading: {
            loadingPageData,
            loadingSpecialService: specialService.loading
          },
          matchesMobile,
          onCallSpecialService,
          onChangeOrder,
          onCloseVideoCVModal,
          onFilter,
          onHideRightSidebar,
          onOpenVideoCVModal,
          onRemoveFilters,
          onShowRightSidebar,
          onViewCandidates,
          orderBy,
          profile,
          setShowAnimatedModal,
          setShowSearchDetails,
          setStartAnimation,
          sheetData,
          showAnimatedModal,
          showRightSidebar,
          showSearchDetails,
          showVideoCVModal,
          specialService: specialService.data,
          startAnimation
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caregivers: state.caregivers.index,
    helpers: state.helpers.helpers,
    interestedReasons: state.caregivers.interestedReasons,
    loadingPageData: state.caregivers.loading || state.jobOffers.loading,
    profile: state.profile.profile,
    sheetData: state.jobOffers.oneSheetData,
    showRightSidebar: state.layouts.showRightSidebar,
    specialService: {
      data: state.profile.specialService.findSend,
      loading: state.profile.specialService.loading
    }
  }
}

const mapDispatchToProps = {
  askForSpecialService,
  getCaregiverDetail,
  getCaregivers,
  getIllnesses,
  getLanguages,
  getOfferDetails,
  toggleRightSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
