export const timeIds = {
  afternoon: [2],
  all: [1, 2, 3],
  morning: [1],
  night: [3]
}

export const timeSchedule = {
  1: 'Morning',
  2: 'Afternoon',
  3: 'Night'
}

export const dayIds = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
}

export const dayTimeIds = {
  all: [1, 2, 3, 4, 5, 6, 7],
  week: [1, 2, 3, 4, 5],
  weekend: [6, 7]
}
