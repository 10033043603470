import React from 'react'
import { pathOr } from 'ramda'
import Text from 'react-texty'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { MailIcon, PhoneIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const DataRow = ({ Icon, children }) => {
  return (
    <div className='columns'>
      <div className='column is-narrow is-paddingless-r'>
        <Icon
          fill='none'
          height='24'
          stroke='#0040AA'
          width='24'
        />
      </div>

      <div className='column' style={{ maxWidth: 'calc(100% - 35px)' }}>
        {children}
      </div>
    </div>
  )
}

const ContactDetailsCard = ({ basicData }) => {
  const firstname = pathOr('', ['firstname'], basicData)
  const lastname = pathOr('', ['lastname'], basicData)
  const email = pathOr('', ['email'], basicData)
  const phone = pathOr('', ['phone'], basicData)

  return (
    <div className={`card ${classes.container}`}>
      <div className='card-header has-padding-5 has-background-primary'>
        <Text className='p1-bold white'>
          <FormattedMessageWithClass id='client.chat.contactCard.title' />
          {' '}{firstname} {lastname}
        </Text>
      </div>

      <div className='card-content has-padding-5'>
        <DataRow Icon={MailIcon}>
          <>
            <p className='t3-bold primary'>Email</p>
            <a
              className='t4-bold full-width'
              href={`mailto:${email}`}
              style={{ display: 'inline-block' }}
            >
              <Text>
                {email}
              </Text>
            </a>
          </>
        </DataRow>

        <DataRow Icon={PhoneIcon}>
          <>
            <p className='t3-bold primary'>
              <FormattedMessageWithClass id='client.chat.contactCard.phone' />
            </p>
            <a
              className='t4-bold full-width'
              href={`tel:${phone}`}
              style={{ display: 'inline-block' }}
            >
              <Text>
                {phone}
              </Text>
            </a>
          </>
        </DataRow>
      </div>
    </div>
  )
}

export default ContactDetailsCard
