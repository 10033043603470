import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useFecthDataOnCondition } from 'seniors-first-commons/shared/hooks'
import { makePayment, getCredits } from 'store/billing/actions'
import { getStatistics } from 'store/profile/actions'
import CreditsQuantityModal from 'components/organisms/CreditsQuantityModal'


const mapStateToProps = (state) => {
  return {
    credits: state.billing.credits,
    locale: state.intl.locale,
    profile: state.profile.profile.index,
    statistics: state.profile.statistics.data,
    submittedBasicInfo: state.profile.profile.submittedBasicInfo
  }
}

const mapDispatchToProps = {
  getCredits,
  getStatistics,
  makePayment
}

const withPayment = (WrappedComponent) => {
  const InnerComponent = ({
    profile,
    credits,
    locale,
    statistics,
    submittedBasicInfo,
    getStatistics,
    makePayment,
    getCredits,
    ...props
  }) => {
    const [showCreditsQuantityModal, setShowCreditsQuantityModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState('')
    const isProduction = process.env.REACT_APP_CURRENT_ENV === 'prod'

    useFecthDataOnCondition(() => {
      getStatistics()
    }, submittedBasicInfo)

    useFecthDataOnCondition(() => {
      getCredits()
    }, submittedBasicInfo)

    const translations = {
      individualButton: 'client.general.creditsQuantity.individuale.button',
      individualDetails: 'client.general.creditsQuantity.individuale.text',
      packButton: 'client.general.creditsQuantity.pack.button',
      packDetails: 'client.general.creditsQuantity.pack.text'
    }

    const onCloseCreditsQuantityModal = () => {
      setShowCreditsQuantityModal(false)
    }

    const onMakePayment = ({ quantity: { value } }, { resetForm, setStatus, setSubmitting }) => {
      makePayment({
        cancel_url: `http://cl${isProduction ? '' : '.test'}.seniorsfirst.care/${locale}/payment-cancelled`,
        currency : {'$' : 'USD','£' : 'GBP', '€':'EUR'}[profile.currency]||'USD',
        locale,
        product_id: selectedPlan,
        quantity: value,
        success_url: `http://cl${isProduction ? '' : '.test'}.seniorsfirst.care/${locale}/payment-success`
      })
        .unwrap()
        .then(({ public_key, session_id }) => {
          setSubmitting(false)
          setStatus({ success: true })
          resetForm()
          onCloseCreditsQuantityModal()
          const stripe = window.Stripe(public_key)
          return stripe.redirectToCheckout({ sessionId: session_id })
        })
    }

    const onUsePlan = (e) => {
      setSelectedPlan(e)
      setShowCreditsQuantityModal(true)
    }

    return (
      <>
        <WrappedComponent
          onUsePlan={onUsePlan}
          profile={profile}
          statistics={{ ...statistics, credits: credits.total }}
          {...props}
        />

        <CreditsQuantityModal
          buttonText={
            selectedPlan === '1' ?
              translations.individualButton :
              translations.packButton
          }
          loading={credits.loadingCredits}
          onClose={onCloseCreditsQuantityModal}
          onConfirm={onMakePayment}
          open={showCreditsQuantityModal}
          title={
            selectedPlan === '1' ?
              translations.individualDetails :
              translations.packDetails
          }
        />
      </>
    )
  }

  const EnhacedComponent = connect(mapStateToProps, mapDispatchToProps)(InnerComponent)
  return EnhacedComponent
}


export default withPayment
