import React, { useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { CalendarIcon } from 'seniors-first-commons/components/atoms/images/icons'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './react_dates_overrides.css'


const DatePicker = ({
  form,
  field,
  openDirection='down',
  shouldRenderMonthElement=false,
  isOutsideRange=() => false,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false)
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const currentYear = (new Date()).getFullYear()
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))

  const handleDateChange = (value) => {
    form.setFieldValue(field.name, moment(value))
  }

  const handleDateFocus = ({ focused }) => {
    setFocused(focused)
  }

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          onChange={(e) => onMonthSelect(month, e.target.value)}
          value={month.month()}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select onChange={(e) => onYearSelect(month, e.target.value)} value={month.year()}>
          {range(currentYear, currentYear - 150, -1).map((year, index) => (
            <option key={index} value={year}>{year}</option>
          ))}
        </select>
      </div>
    </div>

  return (
    <SingleDatePicker
      customInputIcon={(
        <CalendarIcon
          fill='none'
          height='24'
          stroke='#0080F5'
          width='24'
        />
      )}
      date={field.value}
      focused={isFocused}
      hideKeyboardShortcutsPanel
      id={props.id}
      inputIconPosition='after'
      isOutsideRange={isOutsideRange}
      noBorder
      numberOfMonths={1}
      onDateChange={handleDateChange}
      onFocusChange={handleDateFocus}
      openDirection={openDirection}
      placeholder=''
      readOnly
      renderMonthElement={shouldRenderMonthElement ? renderMonthElement : null}
      withPortal={matchesMobile}
    />
  )
}

export default DatePicker
