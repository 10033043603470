import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import routingService from 'seniors-first-commons/services/routingService'
import {
  checkConnectionWithCaregiver,
  connectAcceptedDirectOffer,
  connectCaregiverFromPost,
  connectCaregiverFromSearch,
  connectInterestedCaregiver,
  directConnectFromProfile,
  chatWithCaregiverOnWhatsapp,
  getInterestedReasons
} from 'store/caregivers/actions'
import { getAvailableOffers } from 'store/jobOffers/actions'
import { getCredits } from 'store/billing/actions'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import ChatOrSendOfferModal from 'components/molecules/ChatOrSendModal'
import InterestedReasonsModal from 'seniors-first-commons/components/atoms/InterestedReasonsModal'
import PickJobOfferModal from 'components/organisms/PickJobOfferModal'
import { ROUTE } from 'root/constants'
import { SuccessIcon, SFCoinsIcon } from 'seniors-first-commons/components/atoms/images/icons'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'

const mapStateToProps = (state) => ({
  checkingConnection: state.caregivers.chat.checkingConnection,
  checkingCredits: state.billing.credits.loadingCredits,
  interestedReasons: state.caregivers.interestedReasons,
  loadingAvailableOffers: state.jobOffers.availableOffers.loading,
  loadingInterestedReasons: state.caregivers.interestedReasons.loadingReasons,
  respondingOffer: state.caregivers.connectFromSearchLoading
  || state.caregivers.connectFromPostLoading
  || state.caregivers.connectAcceptedDirectOfferLoading
  || state.caregivers.directConnectFromProfileLoading
  || state.caregivers.interestedReasons.loading
})

const mapDispatchToProps = {
  chatWithCaregiverOnWhatsapp,
  checkConnectionWithCaregiver,
  connectAcceptedDirectOffer,
  connectCaregiverFromPost,
  connectCaregiverFromSearch,
  connectInterestedCaregiver,
  directConnectFromProfile,
  getAvailableOffers,
  getCredits,
  getInterestedReasons
}


const withChat = (WrappedComponent, from) => {
  const InnerComponent = ({
    interestedReasons,
    checkingConnection,
    checkingCredits,
    loadingInterestedReasons,
    loadingAvailableOffers,
    respondingOffer,
    checkConnectionWithCaregiver,
    connectCaregiverFromSearch,
    connectAcceptedDirectOffer,
    connectInterestedCaregiver,
    connectCaregiverFromPost,
    getInterestedReasons,
    getAvailableOffers,
    directConnectFromProfile,
    getCredits,
    chatWithCaregiverOnWhatsapp,
    ...props
  }) => {
    const [selectedCaregiver, setSelectedCaregiver] = useState(null)
    const [selectedCarerName, setSelectedCarerName] = useState('')
    const [showConnectionConfirmModal, setShowConnectionConfirmModal] = useState('')
    const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false)
    const [showChatOrSendOfferModal, setShowChatOrSendOfferModal] = useState(false)
    const [showAvailableOffersModal, setShowAvailableOffersModal] = useState(false)
    const [showInterestedReasonsModal, setShowInterestedReasonsModal] = useState(false)

    const translations = {
      chatOrSendDetails: 'client.general.chatOrSend.text',
      chatOrSendTitle: 'client.general.chatOrSend.title',
      confirmBeforeAcceptTitle: 'client.general.confirm-before-accept.title',
      confirmBeforeAccpetDetails: 'client.general.confirm-before-accept.text',
      confirmBeforeAccpetPostFromConnectedCarerDetails: 'confirm-before-accept-connected-post-application-details',
      confirmBeforeAccpetPostFromConnectedCarerTitle: 'client.general.confirm-before-accept-post-application.title',
      confirmBeforePostAppliationAcceptTitle: 'client.general.confirm-before-accept-post-application.title',
      confirmBeforePostApplicationAcceptDetails: 'client.general.confirm-before-accept-post-application.text',
      confirmBeforeSendDetails: 'client.general.confirm-before-send.text',
      confirmBeforeSendTitle: 'client.general.confirm-before-send.title',
      interestedReasonsDetails: 'client.general.interestedReasons.text',
      interestedReasonsTitle: 'client.general.interestedReasons.title',
      noCredits: 'client.general.noCredits.title',
      noCreditsButton: 'client.general.noCredits.button',
      noCreditsText: 'client.general.noCredits.text'
    }

    const formattedTexts = {
      chatOnWhatsapp: useFormatMessage('caregiverCard.chatButtonText.chatOnWhatsapp'),
      pendingStatus: useFormatMessage('client.search.caregiverCard.pendingstatus'),
      rejectedStatus: useFormatMessage('client.search.caregiverCard.rejectedStatus')
    }

    const formRef = useRef(null)

    const { logicPropsAndMethods } = props
    const { jobOfferId, ...rest } = logicPropsAndMethods

    const history = useHistory()

    const onViewInterestedReasons = (job_offer_id, caregiver_id) => {
      getInterestedReasons({ caregiver_id, job_offer_id })
        .then(() => setShowInterestedReasonsModal(true))
        .catch(() => {})
    }

    const onConnectWithUnknownCaregiver = (caregiver_id, location) => {
      setSelectedCaregiver(caregiver_id)

      checkConnectionWithCaregiver({ caregiver_id })
        .then(({ payload }) => {
          if (payload.are_connected) {
            setShowChatOrSendOfferModal(`confirm-before-send${location ? `-${location}` : ''}`)
          }
          else {
            getCredits()
              .then(({ payload }) => {
                if (payload?.credits) {
                  setShowConnectionConfirmModal(`confirm-before-send${location ? `-${location}` : ''}`)
                }
                else {
                  setShowBuyCreditsModal(true)
                }
              })
              .catch(() => {})
          }
        })
        .catch(() => {})
    }

    const onConnectWithAcceptedCaregiver = (caregiver_id, no_credits_to_connect) => {
      setSelectedCaregiver(caregiver_id)

      if (no_credits_to_connect) {
        getCredits()
          .then(({ payload }) => {
            if (payload.credits) {
              setShowConnectionConfirmModal('confirm-before-accept')
            }
            else {
              setShowBuyCreditsModal(true)
            }
          })
      }
      else {
        chatWithCaregiverOnWhatsapp({caregiver_id})
      }
    }

    const onConnectWithInterestedCaregiver = (job_offer_id, caregiver_id) => {
      setSelectedCaregiver(caregiver_id)

      checkConnectionWithCaregiver({ caregiver_id })
        .then(({ payload }) => {
          if (payload.are_connected) {
            onViewInterestedReasons(job_offer_id, caregiver_id)
          }
          else {
            getCredits()
              .then(({ payload }) => {
                if (payload.credits) {
                  onViewInterestedReasons(job_offer_id, caregiver_id)
                }
                else {
                  setShowBuyCreditsModal(true)
                }
              })
              .catch(() => {})
          }
        })
        .catch(() => {})
    }

    const onConfirmConnectionConfirmModal = (job_offer_id, caregiver_id) => {
      if (showConnectionConfirmModal === 'confirm-before-accept') {
        connectAcceptedDirectOffer({ caregiver_id, job_offer_id })
          .then(() => {
            setShowConnectionConfirmModal('')
            chatWithCaregiverOnWhatsapp({caregiver_id})
          })
          .catch(() => {})
      }

      else if (showConnectionConfirmModal === 'confirm-before-accept-post-application' || showConnectionConfirmModal === 'confirm-before-accept-connected-post-application') {
        connectCaregiverFromPost({ caregiver_id, service_sheet_id: job_offer_id })
          .then(() => {
            chatWithCaregiverOnWhatsapp({caregiver_id})
            //once connected it will redirect to the connected tab in addition to opening the whatsapp chat
            const connectedUrl = `${routingService.getFinalRoute(ROUTE.CANDIDATES)}/${job_offer_id}?tab=connected`
            routingService.push(history, connectedUrl)
            setShowConnectionConfirmModal('')
          })
          .catch(() => {})
      }

      else if (showConnectionConfirmModal === 'confirm-before-send-profile') {
        directConnectFromProfile({ caregiver_id, service_sheet_id: job_offer_id })
          .then(() => {
            setShowConnectionConfirmModal('')
            setShowChatOrSendOfferModal('')
          })
          .catch(() => {})
      }

      else {
        connectCaregiverFromSearch({ caregiver_id, service_sheet_id: job_offer_id })
          .then(() => {
            setShowConnectionConfirmModal('')
            setShowChatOrSendOfferModal('')
          })
          .catch(() => {})
      }
    }

    const onSendNewOfferToAlreadyConnectedCaregiver = (job_offer_id, caregiver_id) => {

      if (showChatOrSendOfferModal === 'home') {
        getAvailableOffers({ caregiver_id, per_page: 20 })
          .then(() => {
            setShowChatOrSendOfferModal('')
            setShowAvailableOffersModal(true)
          })
      }

      else if (showChatOrSendOfferModal === 'confirm-before-send-profile') {
        directConnectFromProfile({ caregiver_id, service_sheet_id: job_offer_id })
          .then(() => setShowChatOrSendOfferModal(''))
          .catch(() => {})
      }

      else {
        connectCaregiverFromSearch({ caregiver_id, service_sheet_id: job_offer_id })
          .then(() => setShowChatOrSendOfferModal(''))
          .catch(() => {})
      }
    }

    const onChatFromInterestedReasonsModal = (job_offer_id, caregiver_id) => {
      connectInterestedCaregiver({
        caregiver_id,
        job_offer_id
      })
        .then(() => {
          setShowInterestedReasonsModal(false)
          chatWithCaregiverOnWhatsapp({caregiver_id})
        })
    }

    const onConnectWithAppliedCaregiverOnAPost = (job_offer_id, caregiver_id) => {
      setSelectedCaregiver(caregiver_id)

      checkConnectionWithCaregiver({ caregiver_id })
        .then(({ payload }) => {
          if (payload.are_connected) {
            setShowConnectionConfirmModal('confirm-before-accept-connected-post-application')
          }
          else {
            getCredits()
              .then(({ payload }) => {
                if (payload.credits) {
                  setShowConnectionConfirmModal('confirm-before-accept-post-application')
                }
                else {
                  setShowBuyCreditsModal(true)
                }
              })
              .catch(() => {})
          }
        })
        .catch(() => {})
    }

    const onConnectCaregiverFromHome = (caregiver_id) => {
      setSelectedCaregiver(caregiver_id)

      checkConnectionWithCaregiver({ caregiver_id })
        .then(({ payload }) => {
          if (payload.are_connected) {
            setShowChatOrSendOfferModal('home')
          }
          else {
            getCredits()
              .then(({ payload }) => {
                if (payload.credits) {
                  getAvailableOffers({ caregiver_id, per_page: 20 })
                    .then(() => setShowAvailableOffersModal(true))
                }
                else {
                  setShowBuyCreditsModal(true)
                }
              })
              .catch(() => {})
          }
        })
        .catch(() => {})
    }

    const onConfirmSendAvailableOfferFromHomeModal = ({ offer }, { setSubmitting, resetForm, setStatus }) =>  {
      connectCaregiverFromSearch({
        caregiver_id: selectedCaregiver,
        service_sheet_id: offer?.value
      })
        .then(() => {
          setSubmitting(false)
          setStatus({ success: true })
          resetForm()
          setSelectedCaregiver(null)
          setShowAvailableOffersModal(false)
        })
    }


    const onClosePickJobOfferModal = () => {
      setShowAvailableOffersModal(false)
      formRef.current.resetForm()
    }

    const onViewProfile = (caregiver) => {
      switch(from) {
      case 'home':
        routingService.push(history, `${ROUTE.CAREGIVER_PROFILE}/${caregiver?.id}?from=home`)
        break
      case 'findsend':
        routingService.push(
          history,
          `${ROUTE.CAREGIVER_PROFILE}/${caregiver?.id}?from=findsend&job_offer_id=${jobOfferId}`
        )
        break
      case 'post':
        routingService.push(
          history,
          `${ROUTE.CAREGIVER_PROFILE}/${caregiver?.id}?from=post&job_offer_id=${jobOfferId}`
        )
        break
      case 'connected':
        routingService.push(
          history,
          `${ROUTE.CAREGIVER_PROFILE}/${caregiver?.id}?from=connected${jobOfferId ? `&job_offer_id=${jobOfferId}` : ''}`
        )
        break
      default:
        break
      }
    }

    const handleChatButton = ({
      id,
      job_proposition_status,
      post_status,
      no_credits_to_connect,
      comingFrom,
      ...rest
    }) => {
      let caregiverStatus = {
        chatButtonColor: '',
        disabled: false,
        loading: false,
        onChat: () => {},
        text: formattedTexts.chatOnWhatsapp
      }

      switch(from ? from : comingFrom) {
      case 'findsend':
        switch (job_proposition_status) {
        case 'not-sent':
          if (rest.phone) {
            caregiverStatus = {
              ...caregiverStatus,
              chatButtonColor: 'success-background-whatsapp',
              disabled: false,
              loading: checkingConnection,
              onChat: () => onConnectWithUnknownCaregiver(id)
            }
          }
          else {
            caregiverStatus = {
              ...caregiverStatus,
              chatButtonColor: 'primary-background',
              disabled: false,
              loading: checkingConnection || checkingCredits,
              onChat: () => onConnectWithUnknownCaregiver(id)
            }
          }
          break

        case 'pending':
          caregiverStatus = {
            ...caregiverStatus,
            disabled: true,
            text: formattedTexts.pendingStatus
          }
          break

        case 'accepted':
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'success-background-whatsapp',
            disabled: false,
            loading: checkingCredits,
            onChat: () => onConnectWithAcceptedCaregiver(id, no_credits_to_connect)
          }
          break

        case 'rejected':
          caregiverStatus = {
            ...caregiverStatus,
            disabled: true,
            text: formattedTexts.rejectedStatus
          }
          break

        case 'interested':
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'success-background-whatsapp',
            loading: checkingConnection || checkingCredits || loadingInterestedReasons,
            onChat: () => onConnectWithInterestedCaregiver(jobOfferId, id),
            text: formattedTexts.chatOnWhatsapp
          }
          break

        default:
          if (rest.phone) {
            caregiverStatus = {
              ...caregiverStatus,
              chatButtonColor: 'success-background-whatsapp',
              loading: checkingConnection,
              onChat: () => onConnectWithUnknownCaregiver(id, 'profile')
            }
          }
          else {
            caregiverStatus = {
              ...caregiverStatus,
              chatButtonColor: 'primary-background',
              loading: checkingConnection || checkingCredits,
              onChat: () => onConnectWithUnknownCaregiver(id, 'profile')
            }
          }
          break
        }
        break

      case 'connected':
        caregiverStatus = {
          ...caregiverStatus,
          chatButtonColor: 'success-background-whatsapp',
          disabled: false,
          loading: false,
          // onChat: () => routingService.push(history, `${ROUTE.CHAT}/${id}`),
          onChat: () => chatWithCaregiverOnWhatsapp({caregiver_id: id}),
          text: formattedTexts.chatOnWhatsapp
        }
        break

      case 'post':
        switch(post_status) {
        case 'accepted':
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'success-background-whatsapp',
            disabled: false,
            loading: false,
            onChat: () => chatWithCaregiverOnWhatsapp({caregiver_id: id}),
            text: formattedTexts.chatOnWhatsapp
          }
          break

        case 'pending':
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'primary-background',
            loading: checkingConnection || checkingCredits,
            onChat: () => {
              setSelectedCarerName(rest.username)
              onConnectWithAppliedCaregiverOnAPost(jobOfferId, id)
            }
          }
          break

        default:
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'primary-background',
            loading: checkingConnection || checkingCredits || respondingOffer,
            onChat: () => {
              setSelectedCarerName(rest.username)
              onConnectWithAppliedCaregiverOnAPost(jobOfferId, id)
            }
          }
          break
        }
        break

      case 'home':
        if (rest.phone) {
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'success-background-whatsapp',
            disabled: false,
            loading: checkingConnection || checkingCredits,
            onChat: () => onConnectCaregiverFromHome(id),
            text: formattedTexts.chatOnWhatsapp
          }
        }
        else {
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'primary-background',
            disabled: false,
            loading: checkingConnection || checkingCredits || loadingAvailableOffers,
            onChat: () => onConnectCaregiverFromHome(id),
            text: formattedTexts.chatOnWhatsapp
          }
        }
        break

      case 'findsendnocredits':
        caregiverStatus = {
          ...caregiverStatus,
          chatButtonColor: 'success-background-whatsapp',
          disabled: false,
          loading: checkingCredits,
          onChat: () => onConnectWithAcceptedCaregiver(id, no_credits_to_connect)
        }
        break

      case 'ratecarer':
        caregiverStatus = {
          ...caregiverStatus,
          chatButtonColor: 'success-background-whatsapp',
          disabled: false,
          loading: false,
          onChat: () => chatWithCaregiverOnWhatsapp({caregiver_id: id}),
          text: formattedTexts.chatOnWhatsapp
        }
        break

      default:
        if (rest.phone) {
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'success-background-whatsapp',
            disabled: false,
            loading: respondingOffer,
            onChat: () => onConnectCaregiverFromHome(id),
            text: formattedTexts.chatOnWhatsapp
          }
        }
        else {
          caregiverStatus = {
            ...caregiverStatus,
            chatButtonColor: 'primary-background',
            disabled: false,
            loading: respondingOffer,
            onChat: () => onConnectCaregiverFromHome(id),
            text: formattedTexts.chatOnWhatsapp
          }
        }
        break
      }

      return caregiverStatus
    }


    return (
      <>
        <WrappedComponent
          caregiverStatus={handleChatButton}
          onViewProfile={onViewProfile}
          selectedCaregiver={selectedCaregiver}
          {...props}
        />

        <ConfirmModal
          details={
            showConnectionConfirmModal === 'confirm-before-send-profile' ||
            showConnectionConfirmModal === 'confirm-before-send'
              ? translations.confirmBeforeSendDetails
              : showConnectionConfirmModal === 'confirm-before-accept' ||
              showConnectionConfirmModal === 'confirm-before-accept-profile'
                ? translations.confirmBeforePostApplicationAcceptDetails
                : showConnectionConfirmModal === 'confirm-before-accept-post-application'
                  ? translations.confirmBeforePostApplicationAcceptDetails
                  : showConnectionConfirmModal === 'confirm-before-accept-connected-post-application'
                    ? translations.confirmBeforeAccpetPostFromConnectedCarerDetails
                    : ''
          }
          hascancelButton={false}
          Icon={SuccessIcon}
          loading={respondingOffer}
          onClose={() => setShowConnectionConfirmModal('')}
          onConfirm={() => onConfirmConnectionConfirmModal(jobOfferId, selectedCaregiver)}
          show={!!showConnectionConfirmModal}
          title={
            showConnectionConfirmModal === 'confirm-before-send-profile' ||
            showConnectionConfirmModal === 'confirm-before-send'
              ? translations.confirmBeforeSendTitle
              : showConnectionConfirmModal === 'confirm-before-accept' ||
              showConnectionConfirmModal === 'confirm-before-accept-profile'
                ? translations.confirmBeforeAcceptTitle
                : showConnectionConfirmModal === 'confirm-before-accept-post-application'
                  ? translations.confirmBeforePostAppliationAcceptTitle
                  : showConnectionConfirmModal === 'confirm-before-accept-connected-post-application'
                    ? translations.confirmBeforeAccpetPostFromConnectedCarerTitle
                    : ''
          }
          vars={{ carer: selectedCarerName }}
        />

        <ConfirmModal
          confirm={translations.noCreditsButton}
          details={translations.noCreditsText}
          hascancelButton={false}
          Icon={SFCoinsIcon}
          onClose={() => setShowBuyCreditsModal(false)}
          onConfirm={() => routingService.push(history, `${ROUTE.CREDITS}/my-credits`)}
          show={!!showBuyCreditsModal}
          title={translations.noCredits}
        />

        <ChatOrSendOfferModal
          details={translations.chatOrSendDetails}
          loading={respondingOffer || loadingAvailableOffers}
          onChat={() => chatWithCaregiverOnWhatsapp({caregiver_id: selectedCaregiver})}
          onClose={() => setShowChatOrSendOfferModal(false)}
          onSendOffer={() => onSendNewOfferToAlreadyConnectedCaregiver(jobOfferId, selectedCaregiver)}
          open={!!showChatOrSendOfferModal}
          title={translations.chatOrSendTitle}
        />

        <InterestedReasonsModal
          details={translations.interestedReasonsDetails}
          onClose={() => setShowInterestedReasonsModal(false)}
          open={showInterestedReasonsModal}
          reasons={interestedReasons.items}
          title={translations.interestedReasonsTitle}
        >
          <div className='columns is-centered is-vcentered is-mobile'>
            <div className='column'>
              <button
                className={`button filled ${respondingOffer ? 'is-loading' : ''}`}
                disabled={respondingOffer}
                onClick={() => onChatFromInterestedReasonsModal(jobOfferId, interestedReasons.caregiver_id)}
                style={{ height: '48px', width: '180px' }}
              >
                Chat
              </button>
            </div>
          </div>
        </InterestedReasonsModal>

        <PickJobOfferModal
          availableOffers={rest?.availableOffers}
          formRef={formRef}
          loading={respondingOffer}
          onClose={onClosePickJobOfferModal}
          onConfirm={onConfirmSendAvailableOfferFromHomeModal}
          open={showAvailableOffersModal}
        />
      </>

    )
  }

  const EnhacedComponent = connect(mapStateToProps, mapDispatchToProps)(InnerComponent)

  return EnhacedComponent
}


export default withChat
