import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import classes from './index.module.css'


const WrongAppModal = ({
  show,
  role,
  intl,
  onClose
}) => {
  const env = process.env.REACT_APP_CURRENT_ENV

  const hisAppURL = role === 'client' && (env === 'stage' || env === 'dev')
    ? intl.formatMessage({ id: 'wrong-app-modal.caregiver.test.app-url' })
    : role === 'client' && env === 'prod'
      ? intl.formatMessage({ id: 'wrong-app-modal.caregiver.app-url' })
      : role === 'carer' && (env === 'stage' || env === 'dev')
        ? intl.formatMessage({ id: 'wrong-app-modal.client.test.app-url' })
        : role === 'carer' && env === 'prod'
          ? intl.formatMessage({ id: 'wrong-app-modal.client.app-url' })
          : ''

  const description = role === 'carer'
    ? 'wrong-app-modal.client.description'
    : role === 'client'
      ? 'wrong-app-modal.caregiver.description' : ''

  return (
    <CustomModal
      additionalClasses={classes.modalWrapper}
      onClose={onClose}
      open={show}
    >
      <div className={classes.wrapper}>
        <FormattedMessageWithClass
          className='t1-bold primary'
          id='wrong-app-modal.title'
        />

        <FormattedMessageWithClass
          className='has-margin-t-7'
          id={description}
        />
        <a
          className='has-margin-t-4 button nonAuthedButtons is-primary'
          href={hisAppURL}
        >
          <FormattedMessage id='wrong-app-modal.button-text' />
        </a>
      </div>
    </CustomModal>
  )
}

export default injectIntl(WrongAppModal)
