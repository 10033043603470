import React from 'react'
import { SwiperSlide } from 'swiper/react'
import JobOfferCard from 'components/organisms/JobOfferCard'
import CustomSwiper from 'seniors-first-commons/components/atoms/Swiper'


const MyJobOffersMobileLayout = ({
  showArchived,
  jobOffers,
  ...props
}) => {
  return (
    <CustomSwiper
      autoplay={false}
      centeredSlides={false}
      data={jobOffers.items}
      freeMode={true}
      loop={false}
    >
      {jobOffers.total ? jobOffers.items.map((offer, index) => {
        return (
          <SwiperSlide key={index}>
            {() => {
              return (
                <div className={`${index === jobOffers.items.length - 1 ? 'has-margin-r-7' : ''} ${index === 0 ? 'has-margin-l-7' : ''}`}>
                  <JobOfferCard
                    archived={showArchived}
                    item={offer}
                    key={index}
                    matchesMobile={true}
                    profile={props.profile}
                    {...props}
                  />
                </div>
              )
            }}
          </SwiperSlide>
        )
      }) : null}
    </CustomSwiper>
  )
}

export default MyJobOffersMobileLayout
