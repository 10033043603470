import React from 'react'

const Input = ({
  form,
  field,
  icon,
  extraIconClass='',
  wrapperClass='',
  ...props
}) => {
  const handleInputChange = (e) => {
    form.setFieldValue(field.name, e.target.value)
  }

  const handleInputBlur = () => {
    form.setFieldTouched(field.name)
  }

  return (
    <div className={`full-width ${wrapperClass}`}>
      <p className={`control  ${icon ? 'has-icons-right ': ''}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          {...field}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          {...props}
        />
        {icon ?
          <span className={`icon is-small is-right ${extraIconClass}`}>
            {icon}
          </span>
          : null
        }
      </p>
    </div>
  )
}

export default Input
