import { Actions } from './actionTypes'

const initialState = {
  error: undefined,
  locale: undefined,
  messages: undefined,
  supportedLocales: undefined
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
  case Actions.LOAD_TRANSLATIONS_INIT:
    return {
      ...initialState,
      supportedLocales: action.payload
    }
  case Actions.LOAD_TRANSLATIONS_SUCCESS:
    return {
      ...state,
      locale: action.payload.locale,
      messages: action.payload.messages
    }
  case Actions.LOAD_TRANSLATIONS_ERROR:
    return {
      ...state,
      error: action.payload
    }
  case Actions.SET_ERROR_MESSAGE:
    return {
      ...state,
      error: action.payload
    }
  default:
    return state
  }
}
