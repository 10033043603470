import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import DaysAndShiftsTable from 'seniors-first-commons/components/molecules/DaysAndShiftsTable'

const ShiftsModal = ({
  active,
  offer,
  onClose
}) => {
  return (
    <CustomModal
      onClose={onClose}
      open={active}
      shouldCloseOnOverlayClick
      styles={{ maxWidth: '400px', width: '95%' }}
    >
      <h1 className='t2-bold grey has-text-centered has-margin-b-4'>
        <FormattedMessageWithClass id='apps.general.days.title' />
      </h1>
      <DaysAndShiftsTable offer={offer} />
    </CustomModal>
  )
}


export default ShiftsModal
