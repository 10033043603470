import { Component } from 'react'
import { withRouter } from 'react-router-dom'


class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const path = location.pathname
    const prevPath = prevProps.location.pathname


    if (path !== prevPath) {
      window.scrollTo(0, 0)
      this.props.setShowChatModal(false)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default withRouter(ScrollToTop)
