import React from 'react'
import { Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import withPhoto from 'seniors-first-commons/hoc/withPhoto'
import { AnonymousUser } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const UploadProfilePhoto = ({
  photo,
  actions,
  ...props
}) => {
  const handleMainButton = () => {
    if (!!photo) actions.onChangeStep(2)
    else props.onPhotoClick()
  }

  const handleSecondaryButton = () => {
    if (!!photo) props.onPhotoClick()
    else actions.onChangeStep(2)
  }

  return (
    <>
      <h3 className='t2-bold primary has-margin-t-7 is-capitalized'>
        <FormattedMessageWithClass id='client.welcome.photo.uploadPhoto' />
      </h3>

      <p className='l-regular grey has-margin-t-7 has-margin-b-3 has-text-centered'>
        <FormattedMessageWithClass id='client.welcome.photo.uploadPhoto.text' />
      </p>

      <>
        <Field
          component={() => {
            return (
              <figure className={`image ${classes.userImage}`}>
                {!photo
                  ? <AnonymousUser fill='none' height='90' stroke='#A6A6A6' widht='90' />
                  : <img
                    alt='profile_photo'
                    className='is-rounded'
                    src={photo}
                  />
                }
              </figure>
            )
          }}
          name='photo'
        />

        <button
          className='filled has-margin-t-2 full-width'
          onClick={handleMainButton}
          style={{ height: '45px', maxWidth: '200px' }}
          type='button'
        >
          <FormattedMessageWithClass
            id={`client.welcome.photo.uploadPhoto.${photo ? 'continue' : 'button'}`}
          />
        </button>
        <button
          className='button-text has-margin-t-6'
          onClick={handleSecondaryButton}
          type='button'
        >
          <FormattedMessageWithClass
            className='p2-bold'
            id={`client.welcome.photo.uploadPhoto.${photo ? 'changePhoto' : 'skip'}`}
          />
        </button>
      </>
    </>
  )
}

export default withPhoto(UploadProfilePhoto)
