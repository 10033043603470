export const ORDER_LIST_STYLES = {
  container: (base) => ({
    ...base,
    minWidth: '150px'
  }),
  control: (base) => ({
    ...base,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    paddingLeft: '0px'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '10px',
    minWidth: '180px',
    overflow: 'hidden',
    right: '0%',
    width: '100%',
    zIndex: '99'
  }),
  option: (base, { isSelected }) => ({
    ...base,
    '&:hover': {
      cursor: 'pointer'
    },
    backgroundColor: isSelected ? '#0080F5' : 'transparent',
    color: isSelected ? '#fff' : '#0040AA',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '26px'}),
  placeholder: (base, { hasValue }) => ({
    ...base,
    color: hasValue ? '#0040AA' : '#0040AA',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#0080F5'
  })
}
