import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import LoginSchema from 'seniors-first-commons/shared/schemas/login'
import LoginInitValues from 'seniors-first-commons/shared/models/login'
import { Email, Password } from './inputs'
import SocialLogin from 'seniors-first-commons/components/molecules/SocialLogin'
import classes from './index.module.css'


const LoginForm = ({
  isSubmitting,
  onSubmit,
  onGoogleLogin,
  onFBLogin,
  onRegister,
  onPasswordReset
}) => {
  return (
    <>
      <Formik
        initialValues={new LoginInitValues()}
        onSubmit={onSubmit}
        validationSchema={LoginSchema}
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <Field
                component={Email}
                id='email'
                name='email'
              />
              <Field
                component={Password}
                id='password'
                name='password'
              />

              <button
                className='button-text'
                onClick={onPasswordReset}
                type='button'
              >
                <FormattedMessageWithClass
                  className='p3-bold'
                  id='app.login.forgotPassword'
                />
              </button>

              <br />

              <button
                className={`
                  button filled has-margin-bt-5 nonAuthedButtons
                  ${isSubmitting ? 'is-loading' : ''}
                `}
                disabled={isSubmitting}
                onClick={handleSubmit}
                type='submit'
              >
                <FormattedMessageWithClass id='app.login.message' />
              </button>

              <div className='columns is-mobile'>
                <div className='column'>
                  <FormattedMessageWithClass
                    className='p2-bold primary'
                    id='app.login.noAccountQuestion'
                  />

                  <div className='has-margin-t-7'>
                    <button
                      className={`outlined ${classes.registerbutton}`}
                      onClick={onRegister}
                      type='button'
                    >
                      <FormattedMessageWithClass id='app.register.form.title' />
                    </button>
                  </div>
                </div>

                <div className='column'>
                  <FormattedMessageWithClass
                    className='p2-bold primary has-margin-b-7'
                    id='app.login.social'
                  />

                  <div className='has-margin-t-7'>
                    <SocialLogin
                      onFBLogin={onFBLogin}
                      onGoogleLogin={onGoogleLogin}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default LoginForm
