import React from 'react'
import Slider, { Handle, Range } from 'rc-slider'
import classes from './index.module.css'

// eslint-disable-next-line no-unused-vars
const MyHandle = ({ value, dragging, index, ...rest }) => {
  return (
    <Handle key={index} value={value} {...rest}>
      <span className={`p2-bold ${classes.value}`}>{value}</span>
    </Handle>
  )
}

const CustomSlider = ({
  description='',
  sliderClass='',
  min=1,
  max=50,
  form,
  field,
  sliderText='km',
  range=false,
  step=10,
  showInfo=true
}) => {
  const handleChange = (e) => form.setFieldValue(field.name, e)

  return (
    <>
      {!!description && (
        <p className='is-size-7 has-text-grey-light has-text-centered has-margin-b-5 is-italic'>
          {description}
        </p>
      )}

      {showInfo ? (
        <div className={'slider--info'}>
          <small>
            {min}
            {sliderText}
          </small>
          <small>
            {max}
            {sliderText}
          </small>
        </div>
      ) : null}

      {!range ?
        <Slider
          className={sliderClass}
          handle={MyHandle}
          max={max}
          min={min}
          onChange={handleChange}
          value={field.value}
        /> : (
          <Range
            className={sliderClass}
            handle={MyHandle}
            max={max}
            min={min}
            onChange={handleChange}
            step={step}
            value={field.value}
          />
        )
      }
    </>
  )

}

export default CustomSlider
