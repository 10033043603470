import React, { Fragment, useState } from 'react'
import ReactPasswordStrength from 'react-password-strength'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { EyeIcon } from 'seniors-first-commons/components/atoms/images/icons'


const PasswordStrengthMeter = ({ form, field, ...props }) => {
  const [showPassword, setShowPassword] = useState('password')
  const [showStrength, setStrength] = useState(false)

  const messages = {
    score1: useFormatMessage('app.components.password.score1'),
    score2: useFormatMessage('app.components.password.score2'),
    score3: useFormatMessage('app.components.password.score3'),
    score4: useFormatMessage('app.components.password.score4'),
    score5: useFormatMessage('app.components.password.score5')
  }

  const changeCallback = props => {
    form.setFieldValue(field.name, props.password)
    if(showStrength && !props.password) setStrength(false)
    else if(!showStrength && props.password) setStrength(true)
  }
  const handleHoverOn = () => setShowPassword('text')
  const handleHoverOff = () => setShowPassword('password')

  const { score1, score2, score3, score4, score5 } = messages

  return (
    <Fragment>
      <div className='password field full-width'>
        <div className='control has-icons-right'>
          <ReactPasswordStrength
            changeCallback={changeCallback}
            inputProps={{
              ...field,
              autoComplete: 'off',
              className: `full-width ${props.class}`,
              'data-lpignore': 'true',
              maxLength: 30,
              type: showPassword
            }}
            minLength={6}
            minScore={1}
            scoreWords={[score1, score2, score3, score4, score5]}
            tooShortWord={score1}
          />
          <span
            className='icon is-large'
            onMouseEnter={handleHoverOn}
            onMouseLeave={handleHoverOff}
            style={{ cursor: 'default', pointerEvents: 'initial' }}
          >
            <EyeIcon
              fill={showPassword === 'password' ? '#A8A8A8' : '#0040AA'}
              height='24'
              width='24'
            />
          </span>
        </div>
      </div>
    </Fragment>
  )
}


export default PasswordStrengthMeter
