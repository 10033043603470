import React from 'react'
import { path } from 'ramda'
import withPhoto from 'seniors-first-commons/hoc/withPhoto'
import ProfileSettingsInitValue from 'models/profileSettings'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Container from 'components/organisms/AppContainer'
import ProfileSettingsForm from 'components/organisms/ProfileSettingsForm'
import UploadImage from 'seniors-first-commons/components/atoms/UploadImage'
import { getCountryByCode } from 'seniors-first-commons/utils/helpers'


const ProfileSettingsDesktopLayout = ({
  profile,
  user,
  helpers: { countries },
  actions: {
    onFormSubmit,
    onChangeCountry
  },
  onPhotoClick
}) => {

  const handleInitValues = () => {

    const defaultCountry = countries
      ? countries.find(item => item.value === path(['index', 'country_id'], profile))
      : ''

    //this means user exists but has deleted his phone number
    //So we keep the prefix based on the country
    let userProfilePhone = profile.index?.phone
    if (user && defaultCountry && profile.index.phone?.length < 4) {
      //country phone prefix is in the 3rd position of the result
      userProfilePhone = getCountryByCode(defaultCountry.code)[3]
    }

    if (user) {
      return {
        address: {
          address_lat: path(['index', 'address_lat'], profile),
          address_lng: path(['index', 'address_lng'], profile),
          formatted_address: path(['index', 'address'], profile)
        },
        country: defaultCountry,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: userProfilePhone,
        username: user.username
      }
    }
    else return new ProfileSettingsInitValue()
  }
  return (
    <Container additionalClass='part' name='Profile settings'>
      <div className='columns is-centered'>
        <div className='column is-one-quarter'>
          <div>
            <UploadImage
              image={path(['index', 'photo'], profile)}
              onClick={onPhotoClick}
            />
          </div>
        </div>

        <div className='column is-three-quarters'>
          <div style={{ maxWidth: '600px' }}>
            <FormattedMessageWithClass
              className='t1-bold primary has-padding-b-5'
              id='client.profileSettings.title'
            />

            <ProfileSettingsForm
              countries={countries}
              initialValues={handleInitValues()}
              onChangeCountry={onChangeCountry}
              onSubmit={onFormSubmit}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default withPhoto(ProfileSettingsDesktopLayout)
