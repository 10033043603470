import React from 'react'
import { Field, ErrorMessage, FieldArray } from 'formik'
import { isEmpty } from 'ramda'
import moment from 'moment'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import Select from 'seniors-first-commons/components/molecules/Select'
import {
  DuplicateIcon,
  TrashIcon,
  PlusIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import {
  DAYS_OF_WEEK,
  TIMES,
  REACT_SELECT_PREFIXES,
  DEFAULT_FOCUSED_START_HOUR
} from 'seniors-first-commons/utils/constants'
import { scrollToSpecificMenuOption } from 'seniors-first-commons/utils/helpers'
import classes from './index.module.css'

const { EXTERNAL_STARTING_HOUR } = REACT_SELECT_PREFIXES

const SecondaryActions = ({
  matchesMobile,
  onDuplicate,
  onRemove
}) => (
  <>
    <button
      className='button-text has-margin-l-6'
      onClick={onDuplicate}
      type='button'
    >
      <DuplicateIcon
        className='button-text-icon'
        fill='none'
        height={matchesMobile ? '20' : '24'}
        storke='#0080F5'
        width={matchesMobile ? '20' : '24'}
      />
    </button>

    <button
      className='button-text'
      onClick={onRemove}
      type='button'
    >
      <TrashIcon
        className='button-text-icon'
        fill='none'
        height={matchesMobile ? '20' : '24'}
        storke='#0080F5'
        width={matchesMobile ? '20' : '24'}
      />
    </button>
  </>
)

const FirstRowActions = ({
  matchesMobile,
  onAdd,
  onDuplicate
}) => {

  return (
    <>
      <button
        className='button-text'
        onClick={onDuplicate}
        type='button'
      >
        <DuplicateIcon
          className='button-text-icon'
          fill='none'
          height={matchesMobile ? '20' : '24'}
          stroke='#0080F5'
          width={matchesMobile ? '20' : '24'}
        />
      </button>

      <button
        className='button-text'
        onClick={onAdd}
        type='button'
      >
        <PlusIcon
          className='button-text-icon'
          fill='none'
          height={matchesMobile ? '20' : '24'}
          stroke='#0080F5'
          width={matchesMobile ? '20' : '24'}
        />
      </button>
    </>
  )
}

const ExternalModality = ({ matchesMobile }) => {
  const handleRemoveButton = (remove, index, validateForm) => {
    remove(index)
    // Found a bug here in formik, when you remove one item it some how transfroms the error's 
    // string into an array of letters so we used this method validateForm as a workaround
    // checkout here: https://github.com/formium/formik/issues/784
    setTimeout(() => {
      return validateForm()
    }, 100)
  }
  const handleAddButton = (push) => {
    push({
      day_of_week: {},
      hour_from: {},
      hour_to: {}
    })
  }

  const handleDuplicateButton = (push, values) => {
    push({
      day_of_week: {},
      hour_from: values.hour_from,
      hour_to: values.hour_to
    })
  }

  const listDays = DAYS_OF_WEEK.map((day, index) => ({label: day, value: index + 1}))
  const startingHours = TIMES.map((time) => ({
    label: time,
    value: moment(time, 'HH:mm').format('HH:mm')
  }))

  const endingHours = (starting_hour) => {
    let endingHoursList = []
    if(!isEmpty(starting_hour)) {
      const starting_hour_index = TIMES.findIndex(time => time === starting_hour.value)
      endingHoursList = TIMES.slice(starting_hour_index + 1, TIMES.length)
        .map(item => ({
          label: item,
          value: moment(item, 'HH:mm').format('HH:mm')
        }))
    }
    return endingHoursList
  }

  return (
    <div
      className='has-margin-t-1 has-text-centered'
      style={{ width: matchesMobile ? '100%' : '80%' }}
    >
      <label className='has-margin-t-1' htmlFor='visiting_care_day_and_hours'>
        <FieldArray name='visiting_care_day_and_hours'>
          {({ push, remove, form }) => {
            const { values, errors, validateForm } = form
            const { visiting_care_day_and_hours } = values

            return (
              <div>
                {visiting_care_day_and_hours.map((item, index) => (
                  <div className={`${classes.row}`} key={index}>
                    <div style={{ maxWidth: '200px', width: '100%' }}>
                      {index === 0 &&
                        <p className='l-bold primary'>
                          <FormattedMessageWithClass
                            id='client.createOffer.steps.modality.day'
                          />
                        </p>
                      }
                      <Field
                        component={Select}
                        hasoptionicon={false}
                        isSearchable={false}
                        menuPlacement='top'
                        name={`visiting_care_day_and_hours[${index}].day_of_week`}
                        options={listDays}
                        placeholder=''
                      />
                    </div>

                    <div
                      className='has-margin-lr-7'
                      style={{ maxWidth: '200px', width: '100%' }}
                    >
                      {index === 0 &&
                        <p className='l-bold primary'>
                          <FormattedMessageWithClass
                            id='client.createOffer.steps.modality.startingHour'
                          />
                        </p>
                      }
                      <Field
                        component={Select}
                        hasoptionicon={false}
                        instanceId={`${EXTERNAL_STARTING_HOUR}-${index}`}
                        isSearchable={false}
                        menuPlacement='top'
                        name={`visiting_care_day_and_hours[${index}].hour_from`}
                        onMenuOpen={() => scrollToSpecificMenuOption(
                          `${EXTERNAL_STARTING_HOUR}-${index}`,
                          startingHours,
                          DEFAULT_FOCUSED_START_HOUR
                        )}
                        options={startingHours}
                        placeholder=''
                        shouldTranslate={false}
                      />
                    </div>

                    <div style={{ maxWidth: '200px', width: '100%' }}>
                      {index === 0 &&
                        <p className='l-bold primary'>
                          <FormattedMessageWithClass
                            id='client.createOffer.steps.modality.endingHour'
                          />
                        </p>
                      }
                      <Field
                        component={Select}
                        hasoptionicon={false}
                        isSearchable={false}
                        menuPlacement='top'
                        name={`visiting_care_day_and_hours[${index}].hour_to`}
                        noOptionsMessage={() => (
                          <FormattedMessageWithClass
                            className='p3-bold grey' id='client.createOffer.steps.modality.visitingCare.endingHour.noOptions'
                          />
                        )}
                        options={endingHours(visiting_care_day_and_hours[index].hour_from)}
                        placeholder=''
                        shouldTranslate={false}
                      />
                    </div>

                    {index < 13 && index === 0 ? (
                      <FirstRowActions
                        hasErrors={!isEmpty(errors)}
                        matchesMobile={matchesMobile}
                        onAdd={() => handleAddButton(push)}
                        onDuplicate={() => handleDuplicateButton(push, visiting_care_day_and_hours[index])}
                      />
                    ) : (
                      ''
                    )}

                    {index > 0 ? (
                      <SecondaryActions
                        hasErrors={!isEmpty(errors)}
                        matchesMobile={matchesMobile}
                        onDuplicate={() => handleDuplicateButton(push, visiting_care_day_and_hours[index])}
                        onRemove={() => handleRemoveButton(remove, index, validateForm)}
                        validateForm={validateForm}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            )
          }}
        </FieldArray>
      </label>

      <ErrorMessage
        component={HandleError}
        errorMessage='validations.clients.createOffer.modality.visiting-care'
        name='visiting_care_day_and_hours'
      />
    </div>
  )
}

export default ExternalModality
