import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { register, resendEmail, socialLogin } from 'seniors-first-commons/shared/store/auth/actions'
import RegisterLayout from 'seniors-first-commons/components/templates/RegisterLayout'
import { ROUTE } from 'root/constants'


const Register = ({
  role,
  registerData,
  register,
  locale,
  socialLogin,
  resendEmail
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const history = useHistory()

  useEffect(() => {
    document.title = 'Seniors First | Register'
  }, [])

  // eslint-disable-next-line no-unused-vars
  const onFormSubmit = ({ confirmPassword, ...rest }) => {
    register({
      role,
      ...rest
    })
      .unwrap()
  }

  const onResendEmail = () => {
    const email = registerData.data.email
    resendEmail({ email })
  }

  const onFBLogin = () => {
    socialLogin({ role, social: 'facebook' })
  }

  const onGoogleLogin = () => {
    socialLogin({ role, social: 'google' })
  }

  const onRedirectToLogin = () => {
    routingService.push(history, ROUTE.LOGIN)
  }

  const onReturnToLogin = () => {
    routingService.push(history, `${ROUTE.LOGIN}`)
  }

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo)
  }

  const onLogoClick = () => routingService.push(history, ROUTE.LOGIN)

  return (
    <RegisterLayout
      locale={locale}
      onFBLogin={onFBLogin}
      onFormSubmit={onFormSubmit}
      onGoogleLogin={onGoogleLogin}
      onLogoClick={onLogoClick}
      onRedirectToLogin={onRedirectToLogin}
      onResendEmail={onResendEmail}
      onReturnToLogin={onReturnToLogin}
      register={register}
      registerData={registerData}
      role={role}
      showMoreInfo={showMoreInfo}
      toggleShowMoreInfo={toggleShowMoreInfo}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    registerData: state.auth.register
  }
}

const mapDispatchToProps = {
  register,
  resendEmail,
  socialLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
