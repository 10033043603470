import React from 'react'
import { SwiperSlide } from 'swiper/react'
import CaregiverCard from 'components/organisms/CaregiverCard'
import CustomSwiper from 'seniors-first-commons/components/atoms/Swiper'
import classes from './index.module.css'


const NearCaregivers = ({
  selectedCaregiver,
  caregiverStatus,
  data,
  sliderRef,
  onViewProfile,
  onOpenVideoCVModal
}) => {
  return (
    <CustomSwiper data={data.items} sliderRef={sliderRef}>
      {data.items.map((caregiver, index) => {
        return (
          <SwiperSlide key={index}>
            {({ isActive }) => {
              return (
                <div className={`
                  ${classes.wrapper}
                  ${!isActive ? classes.notCentered : ''}
                `}>
                  <CaregiverCard
                    caregiverStatus={caregiverStatus({ ...caregiver })}
                    data={caregiver}
                    onOpenVideoCVModal={onOpenVideoCVModal}
                    onViewProfile={() => onViewProfile({ ...caregiver })}
                    selectedCaregiver={selectedCaregiver}
                  />
                </div>
              )
            }}
          </SwiperSlide>
        )
      })}
    </CustomSwiper>
  )
}

export default NearCaregivers
