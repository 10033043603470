
import moment from 'moment'
import { dayIds, handleTimes } from 'seniors-first-commons/utils/constants'

// this file is created to organize the days and shfits logic 
// Both applications caregiver and clients use the same logic

class HandleDaysShifts {

  static handleLiveInCareTimes(data) {
    const formatHourFrom = moment(data['live_in_care_hour_from'], 'HH:mm').format('HH:mm')
    const formatHourTo = moment(data['live_in_care_hour_to'], 'HH:mm').format('HH:mm')
    const firstDay = parseInt(data['live_in_care_day_of_week_from'])
    const lastDay = parseInt(data['live_in_care_day_of_week_to'])

    const times = []

    // eslint-disable-next-line
    for(let index in dayIds){
      times.push({day: dayIds[index]})
    }

    // eslint-disable-next-line
    for(let index in dayIds){
      if(parseInt(index) === firstDay){
        times[index - 1]['shift'] = `${formatHourFrom} - 23:59`
      }

      else if (parseInt(index) === lastDay){
        times[index - 1]['shift'] = `00:00 - ${formatHourTo}`
      }

      else if (lastDay < firstDay){
        if(parseInt(index) < lastDay || parseInt(index) > firstDay){
          times[index - 1 ]['shift'] = '00:00 - 23:59'
        }

        else{
          times[index - 1]['shift'] = '-'
        }
      }

      else if (firstDay === lastDay){
        times[firstDay - 1]['shift'] = `${formatHourFrom} - 23:59`

        if (parseInt(index) < lastDay || parseInt(index) > firstDay){
          times[index - 1 ]['shift'] = '00:00 - 23:59'
        }

        else{
          times[index - 1]['shift'] = '-'
        }
      }

      else if (lastDay > firstDay){
        if(parseInt(index) < lastDay && parseInt(index) > firstDay){
          times[index - 1 ]['shift'] = '00:00 - 23:59'
        }
        else{
          times[index - 1]['shift'] = '-'
        }
      }
    }

    data['livingCareTimes'] = this.sortDays({
      day: dayIds[firstDay],
      firstDay,
      lastDay,
      lastDayHour: `00:00 - ${formatHourTo}`,
      times
    })
  }


  static handleVisitingCareTimes(data) {
    const counts = {}
    let formatVisitingCareTimes = []

    data.visiting_care_day_and_hours.forEach(day => {
      const formatDay = {}
      formatDay.day_of_week = day.day_of_week
      formatDay.shift = `${day.hour_from}-${day.hour_to}`
      formatVisitingCareTimes.push(formatDay)
    })

    // to get how many shifts at each day
    formatVisitingCareTimes.map((day) => day.day_of_week)
      .forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1
      })

    // eslint-disable-next-line
    for (let index in counts) { 
      if(parseInt(counts[index]) === 2){
        // =>>> more than one shift at the same day
        const filterDuplicatedDays = formatVisitingCareTimes.filter(day => parseInt(day.day_of_week) === parseInt(index))
        if(filterDuplicatedDays && filterDuplicatedDays.length){
          filterDuplicatedDays[0].shift = `${filterDuplicatedDays[0].shift} / ${filterDuplicatedDays[1].shift}`
        }
      }
    }

    formatVisitingCareTimes = formatVisitingCareTimes.filter((v,i,a)=>a.findIndex(t=>(t.day_of_week === v.day_of_week))===i)

    const finalResult = []
    // eslint-disable-next-line
    for(let index in dayIds){
      finalResult.push({day: index, shift: '-'})
    }

    finalResult.map(item => (
      formatVisitingCareTimes.map(day => {
        if(parseInt(item.day) === parseInt(day.day_of_week)){
          return item.shift = day.shift
        }
        return day
      })
    ))

    // eslint-disable-next-line
    for(let index in dayIds){
      finalResult.map(day => {
        if(parseInt(day.day) === parseInt(index)){
          day.day = dayIds[day.day]
        }
        return day
      })
    }
    data['visitingCareTimes'] = finalResult
  }


  static sortDays ({
    day,
    firstDay,
    times,
    lastDay,
    lastDayHour
  }) {
    const days = []
    for(let i = handleTimes(day); i <= (handleTimes(day) + times.length - 1); i++){
      if(i >= times.length){
        i = 0
      }
      if(i === handleTimes(day) - 1){
        break
      }
      days.push(times[i])
    }
    days.unshift(times.filter(item => item.day === day)[0])

    if(firstDay === lastDay){
      // push the last day's last hour, because, if the first day is the same as the last day, we would like
      // to show the first day's first hour and the at the end last day's last hour
      days.push({
        day: dayIds[lastDay],
        shift: lastDayHour
      })
    }
    return days
  }

}


export default HandleDaysShifts
