import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'

export const Phone = () => {
  return (
    <>
      <FieldLabel
        centered={false}
        id='phone'
        labelName='client.profileSettings.phone'
        labelType='p1-bold'
        topSpace={1}
      />
      <Field
        component={PhoneInput}
        id='phone'
        name='phone'
      />
      <ErrorMessage
        component={ErrorHandler}
        name='phone'
      />
    </>
  )
}
