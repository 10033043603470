import React, { useEffect, useRef } from 'react'
import Talk from 'talkjs'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import Chat from 'seniors-first-commons/services/chat'
import classes from './index.module.css'

const InboxModal = ({
  open=false,
  role,
  inbox,
  caregiverChatId='',
  setUnreads,
  setInbox,
  toggleChatModal
}) => {
  const inboxRef = useRef(null)
  const chatHeaderRef = useRef(null)
  const feedHeader = useRef(null)
  const backButtonRef = useRef(null)
  const titleRef = useRef(null)
  const translations = {
    viewProfile: useFormatMessage('client.chats.conversationHeader.viewProfile')
  }

  useEffect(() => {
    if (caregiverChatId && !inbox) {
      Talk.ready
        .then(() => {
          const chat = new Chat(
            role,
            translations,
            chatHeaderRef,
            feedHeader,
            titleRef,
            backButtonRef,
            toggleChatModal,
            setUnreads
          )

          chat.startSession(caregiverChatId)
          chat.createInbox()
          setInbox(true)
          if (!inboxRef.current.firstChild) chat.mountInbox(inboxRef.current)
        })
        .catch(error => console.log(error))
    }
    // eslint-disable-next-line
  }, [setUnreads, toggleChatModal, setInbox, caregiverChatId, inbox])


  return (
    <div className={`modal is-clipped ${open ? 'is-active' : ''} ${classes.modalContainer}`} >
      <div className={`modal-content ${classes.modal}`}>
        {caregiverChatId ?
          <div id='chat-container-sm'>
            <div id='header-row-sm'>
              <div id='feed-header-sm' ref={feedHeader}>
                <span className='p1-bold black'></span>
              </div>
              <div id='chatbox-header-sm' ref={chatHeaderRef}>
                <span className='pointer' id='back-btn-sm' ref={backButtonRef}></span>
                <div className='p1-bold black' id='chat-title-sm' ref={titleRef}></div>
              </div>
            </div>
            <div className={classes.inboxContainer} id='talkjs-container-sm' ref={inboxRef}></div>
          </div>
          : <div className={classes.noMessagesWrapper}>
            <div
              className='grey has-padding-lr-7 is-flex'
              style={{ alignItems: 'center', flexDirection: 'column' }}
            >
              <FormattedMessageWithClass
                className=' h1 t2-regular'
                id='apps.general.chatInbox.title'
              />
              <FormattedMessageWithClass
                className='has-margin-t-7 p2-regular'
                id='client.general.chatInbox.text'
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default InboxModal
