import React, { useState, useMemo } from 'react'
import { Marker } from 'google-maps-react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import MapContainer from 'seniors-first-commons/components/organisms/Map'
import CaregiverCard from 'components/organisms/CaregiverCard'
import { ArrowLeftIcon } from 'seniors-first-commons/components/atoms/images/icons'
import CarerPin from 'seniors-first-commons/components/atoms/images/icons/normalPin.svg'
import classes from './index.module.css'


const FindCaregiverButton = ({ onFindCaregiver }) => {
  return (
    <button
      className={`button filled ${classes.findCaregiverButton}`}
      onClick={onFindCaregiver}
      type='button'
    >
      <FormattedMessageWithClass
        id='client.home.closeCaregivers.button'
      />
    </button>
  )
}

const CloseCaregivers = ({
  matchesMobile,
  profile,
  caregiversNearYou,
  selectedCaregiver,
  caregiverStatus,
  onFindCaregiver,
  onViewProfile,
  onOpenVideoCVModal
}) => {
  const [activeMarker, setActiveMarker] = useState(null)
  const addressLat = profile?.address_lat
  const addressLng = profile?.address_lng
  const { items } = caregiversNearYou

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker)
  }

  const onMapClick = () => {
    setActiveMarker(null)
  }

  const mapContainerStyles = {
    height: matchesMobile ? '340px' : '100%',
    maxHeight: '700px',
    position: 'relative',
    width: '100%'
  }

  const mapStyles = {
    borderRadius: '16px'
  }

  const map = useMemo(() => {
    return (
      <MapContainer
        hasMarker={true}
        location={{
          lat: addressLat ? Number(addressLat) : 50,
          lng: addressLng ? Number(addressLng) : 50
        }}
        markerText={'You are here'}
        options={{
          containerStyle: mapContainerStyles,
          disableDoubleClickZoom: true,
          onClick: onMapClick,
          style: mapStyles
        }}
        radius={0}
        zoom={14}
      >
        {items.length && items.map((carer, index) => {
          return (
            <Marker
              carer={{ ...carer }}
              icon={{
                url: CarerPin
              }}
              key={index}
              onClick={onMarkerClick}
              position={{
                lat: carer.lat ? carer.lat: 0,
                lng: carer.lng ? carer.lng : 0
              }}
              title={carer.firstname ? carer.firstname : carer.username}
            />
          )
        })}
      </MapContainer>
    )
    // eslint-disable-next-line
  }, [addressLat, addressLng, items])

  const onRemoveSelectedCaregiver = () => {
    setActiveMarker(null)
  }

  const onHideCardAndShowMap = () => {
    setActiveMarker(false)
  }

  return (
    <>
      <div className='level is-mobile'>
        <div className='level-left'>
          <p className='is-flex' style={{ flexDirection: 'column' }}>
            <FormattedMessageWithClass
              className='t2-bold primary'
              id='client.home.closeCaregivers.title'
            />
            {matchesMobile && activeMarker &&
              <button
                className='button-text is-paddingless-l is-flex'
                onClick={onHideCardAndShowMap}
                style={{ alignItems: 'center' }}
              >
                <ArrowLeftIcon className='button-text-icon ' height='24' width='24' />
                <FormattedMessageWithClass
                  className='p1-bold'
                  id='client.home.closeCaregivers.goBackToMap'
                />
              </button>
            }
          </p>
        </div>
        {!matchesMobile &&
          <div className='level-right'>
            <FindCaregiverButton onFindCaregiver={onFindCaregiver} />
          </div>
        }
      </div>

      <div className='columns'>
        {!!activeMarker && activeMarker?.carer &&
          <div className={`column ${!!activeMarker ? !matchesMobile ? 'is-one-third' : 'is-full' : 'is-hidden'}`}>
            <CaregiverCard
              caregiverStatus={caregiverStatus({ ...activeMarker?.carer })}
              data={activeMarker?.carer}
              hasCloseButton
              onOpenVideoCVModal={onOpenVideoCVModal}
              onViewProfile={() => onViewProfile({ ...activeMarker?.carer })}
              selectedCaregiver={selectedCaregiver}
            />
          </div>
        }

        <div
          className={`column ${!!activeMarker ? !matchesMobile ? 'is-two-thirds' : 'is-hidden' : 'is-full'}`}
          style={{ minHeight: !matchesMobile ? !!activeMarker ? '640px' : '415px' : '100%', position: 'relative' }}
        >
          {!!activeMarker && !matchesMobile &&
            <button
              className={`button ${classes.removeSelectedCarer}`}
              onClick={onRemoveSelectedCaregiver}
            >
              <ArrowLeftIcon
                height='30px'
                stroke='#fff'
                width='25px'
              />
            </button>
          }
          {map}
        </div>
      </div>

      {matchesMobile && (
        <div style={{ margin: 'auto', width: 'fit-content' }}>
          <FindCaregiverButton onFindCaregiver={onFindCaregiver} />
        </div>
      )}
    </>
  )
}

export default CloseCaregivers
