import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import routingService from 'seniors-first-commons/services/routingService'
import { useHistory } from 'react-router-dom'
import { ArrowLeftIcon } from 'seniors-first-commons/components/atoms/images/icons'
import { ROUTE } from 'root/constants'

const GoBackOrHome = ({ className = '' }) => {

  const history = useHistory()

  const onClick = () => {
    if (history.length <= 2) {
      routingService.push(history, `${ROUTE.HOME}`)
    }
    else {
      history.goBack()
    }
  }

  return (
    <button
      className={`button-text is-flex is-align-items-center ${className}`}
      onClick={onClick}
      style={{zIndex: '9'}}
    >
      <ArrowLeftIcon className='button-text-icon' height='24px' width='24px' />
      <FormattedMessageWithClass
        className='has-text-weight-light'
        id={history.length <=2
          ? 'apps.general.goBack.home'
          : 'apps.general.goBack.back'
        }
      />
    </button>
  )
}

export default GoBackOrHome
