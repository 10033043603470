import React, { Component, Fragment } from 'react'
import ToogleSwitch from '../../../ToogleSwitch/ToogleSwitch'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

class Marketing extends Component {
  render() {
    const title = this.props.intl.formatMessage({
      id: 'app.privacy.options.marketing_cookies'
    })
    const text = this.renderText()

    return (
      <Fragment>
        <div className='level has-margin-b-7'>
          <div className='level-left'>
            <div className='level-item'>
              <h2 className='is-size-5 has-text-weight-semibold has-text-black'>{title}</h2>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <ToogleSwitch
                toggle={this.props.toggle}
                value={this.props.value}
              />
            </div>
          </div>
        </div>
        {text}
      </Fragment>
    )
  }

  renderText() {
    let output = ''
    switch (this.props.locale) {
    // Spanish
    case 'es-es':
    case 'es-us':
      output = (<p className='has-text-justified'>Estas cookies pueden ser establecidas a través de nuestro sitio por nuestros socios publicitarios. Pueden ser utilizadas por esas empresas para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios. No almacenan directamente información personal, sino que se basan en la identificación única de su navegador y dispositivo de Internet. Si no permite utilizar estas cookies, verá menos publicidad dirigida.</p>)
      break

      // All English
    default:
      output = (<p className='has-text-justified'>These cookies may be set through our site by our advertising partners. They may be used by those companies to create a profile of your interests and show you relevant ads on other sites. They do not directly store personal information, but they are based on the unique identification of your browser and Internet device. If you don&apos;t allow these cookies you will see less targeted advertising.</p>)
      break
    }

    return output
  }
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale.toLowerCase()
})

export default injectIntl(connect(mapStateToProps)(Marketing))
