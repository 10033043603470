import React from 'react'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import InitialNavbar from 'seniors-first-commons/components/organisms/InitialNavbar'
import Footer from 'seniors-first-commons/components/organisms/Footer'
import classes from './index.module.css'


const InitialLayout = ({
  children,
  role,
  locale,
  onLogoClick,
  hasFooter=true
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const getLocale = locale && locale.slice(-2).toLowerCase()

  return (
    <section
      className={`hero is-medium is-fullheight ${classes.notAuthedBackground}`}
    >
      <div className='hero-head is-hidden-mobile'>
        <InitialNavbar onLogoClick={onLogoClick} />
      </div>
      <div className='hero-body has-padding-t-3 has-padding-b-2'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='is-one-quarter-widescreen is-one-fifth-tablet is-tablet'></div>
            <div className={`card shadow-card has-text-centered column is-half-widescreen is-three-fifths-tablet is-full-mobile has-padding-${matchesMobile ? '3' : '1'}`}>
              {children}
            </div>
            <div className='is-one-quarter-widescreen is-one-fifth-tablet is-tablet'></div>
          </div>
        </div>
      </div>

      {hasFooter && <Footer locale={getLocale} role={role} />}
    </section>
  )
}


export default InitialLayout
