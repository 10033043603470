import React from 'react'
import Sidebar from 'react-sidebar'
import MobileSidebarItems from 'components/molecules/PublicMobileSidebarItems'
import { ClientsLogo } from 'seniors-first-commons/components/atoms/images/icons/Logo'
import classes from './index.module.css'


const MobileSidebar = ({
  open,
  toggleMobileSidebar,
  onLogout,
  activeRoute,
  changeRoute
}) => {
  return (
    <Sidebar
      onSetOpen={() => toggleMobileSidebar(!open)}
      open={open}
      pullRight
      sidebar={
        <>
          <div className={`has-padding-lr-3 ${classes.sidebarHeader}`}>
            <ClientsLogo
              height='32'
              width='143'
            />
          </div>
          <div className='has-padding-lr-6'>
            <MobileSidebarItems
              activeRoute={activeRoute}
              changeRoute={changeRoute}
              onLogout={onLogout}
            />
          </div>
        </>
      }
      styles={{
        overlay: {
          zIndex: '8'
        },
        sidebar: {
          background: '#ffffff',
          position: 'fixed',
          top: '80px',
          width: '280px',
          zIndex: '9'
        }
      }}
    >
      <div></div>
    </Sidebar>
  )
}

export default MobileSidebar
