import React from 'react'
import { Map, Marker, Circle } from 'google-maps-react'
import Pin from 'seniors-first-commons/components/atoms/images/icons/map-pinfull.svg'


const MapContainer = ({
  radius = 1000,
  location,
  zoom=9,
  options={},
  onMapClick=()=>{},
  markerText='Your current location',
  hasMarker=true,
  children
}) => {

  const style = {
    height: '200px',
    marginTop: '1rem',
    position: 'relative',
    width: '100%'
  }

  return (
    <>
      { location ?
        <Map
          center={location}
          containerStyle={style}
          fullscreenControl={false}
          google={window.google}
          id='mapDiv'
          initialCenter={location}
          keyboardShortcuts={false}
          mapTypeControl={false}
          onClick={onMapClick}
          streetViewControl={false}
          zoom={zoom}
          {...options}
        >
          {location && hasMarker &&
          <Marker
            icon={{
              url: Pin
            }}
            position={location}
            title={markerText}
          />
          }
          <Circle
            center={location}
            fillColor='#FF0000'
            fillOpacity={0.2}
            radius={radius}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
          />
          {children}
        </Map>
        : null}
    </>
  )
}

export default MapContainer
