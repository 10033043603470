import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { UserDummyImage } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const CreditsBoardHeader = ({
  matchesMobile,
  withUserInfo,
  user,
  statistics
}) => {
  return (
    <div className={`card-header has-padding-lr-${matchesMobile ? '7' : '5'} ${classes.header}`}>
      <div className={`columns is-vcentered full-width ${!withUserInfo ? 'is-mobile' : ''}`}>
        {withUserInfo &&
          <div className='column is-7'>
            <div className='columns is-mobile is-vcentered is-marginless'>
              <div className='column is-narrow is-paddingless-lr'>
                <figure className='image is-48x48'>
                  {user?.photo
                    ? <img
                      alt='User profile'
                      className={`is-rounded ${classes.userPhoto}`}
                      src={`${user.photo}`}
                    />
                    : <UserDummyImage className='is-rounded' />
                  }
                </figure>
              </div>
              <div className='column is-11'>
                <p className='t2-bold white ellipsis'>
                  <FormattedMessageWithClass id='client.general.creditsBoard.welcome' />, {user.firstname}!
                </p>
              </div>
            </div>
          </div>
        }

        <div className={`column ${withUserInfo ? 'is-5 is-paddingless-lr has-text-right' : ''}`}>
          <p className='t2-regular white ellipsis'>
            <FormattedMessageWithClass id='client.general.creditsBoard.availableCredits' />: {statistics?.credits !== -1 ? statistics?.credits : '∞'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CreditsBoardHeader
