import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import PhoneChatButton from '../PhoneChatButton'
import MailChatButton from '../MailChatButton'
import UserDummyImage from 'seniors-first-commons/components/atoms/images/user.png'


const ChatHeader = ({
  image_url,
  carer_id,
  carer_name,
  basicData,
  chatInboxBackButtonRef,
  matchesMobile,
  matchesDesktop,
  onRedirectToCaregiverProfile,
  onCopy
}) => {
  return (
    <div
      className='is-flex'
      id='chatbox-header'
      style={{ alignItems: 'center' }}
    >
      <div className='columns is-mobile is-vcentered full-width'>
        <div className='column is-narrow is-hidden-tablet is-paddingless-r'>
          <span
            className='t3-bold grey-light pointer'
            ref={el => chatInboxBackButtonRef.current = el}
          >
            ⬅︎
          </span>
        </div>

        <div className='column is-narrow is-paddingless-r'>
          <figure className={`image ${matchesMobile ? 'is-32x32' : 'is-48x48'}`}>
            <img
              alt='otherSideConv'
              className='is-rounded'
              src={image_url ? image_url : UserDummyImage}
            />
          </figure>
        </div>

        <div className={`column ${matchesMobile ? 'is-6' : !matchesDesktop ? 'is-10' : 'is-6'}`}>
          <p className='p1-bold black ellipsis' style={{ maxWidth: matchesDesktop ? '100px' : 'auto' }}>
            {carer_name}
          </p>
          <p
            className='p3-bold primary underline pointer ellipsis'
            onClick={() => onRedirectToCaregiverProfile(carer_id)}
          >
            <FormattedMessageWithClass id='client.inbox.chatHeader.viewProfile' />
          </p>
        </div>

        <div className={`column ${matchesMobile ? 'is-4' : ''} is-hidden-widescreen`}>
          <div className='is-flex is-pulled-right' style={{ alignItems: 'center' }}>
            <div style={{ marginRight: '5px' }}>
              <PhoneChatButton
                basicData={basicData}
                matchesMobile={matchesMobile}
                onClick={() => onCopy(basicData?.phone)}
              />
            </div>
            <MailChatButton
              basicData={basicData}
              matchesMobile={matchesMobile}
              onClick={() => onCopy(basicData?.email)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatHeader
