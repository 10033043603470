import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, useParams } from 'react-router-dom'
import { saveDestination } from 'seniors-first-commons/shared/store/auth/actions'
import { ROUTE } from 'seniors-first-commons/constants'

const PrivateRoute = ({
  component: Component,
  token,
  location,
  saveDestination,
  ...props
}) => {
  const { lang } = useParams()

  useEffect(() => {
    if (!token || !location.pathname.includes(props.route)) {
      saveDestination(location)
    }
  }, []) // eslint-disable-line

  return (
    <Route
      {...props}
      render={renderProps => {
        if (props.role) renderProps['role'] = props.role
        if (token) {
          return (
            <Component {...renderProps} />
          )
        }

        localStorage.setItem('redirectToDelete', props.path === `${lang}${ROUTE.DELETE_ACCOUNT}`)

        return <Redirect
          to={lang
            ? `/${lang}${ROUTE.LOGIN}`
            : `${ROUTE.LOGIN}`
          }
        />
      }}
    />
  )
}

const mapDispatchToProps = {
  saveDestination
}

export default connect(null, mapDispatchToProps)(PrivateRoute)
