import React from 'react'
import { Swiper } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'


SwiperCore.use([Autoplay])

const breakpoints =  {
  0: {
    centeredSlides: true,
    slidesPerView: 1.09,
    spaceBetween: 5
  },
  1300: {
    slidesPerView: 4,
    spaceBetween: 10
  },
  1800: {
    slidesPerView: 5,
    spaceBetween: 10
  },
  2100: {
    slidesPerView: 6,
    spaceBetween: 10
  },
  320: {
    centeredSlides: true,
    slidesPerView: 1.2,
    spaceBetween: 10
  },
  550: {
    slidesPerView: 1.8,
    spaceBetween: 10
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 10
  },
  899: {
    slidesPerView: 3,
    spaceBetween: 15
  }
}

const CustomSwiper = ({
  sliderRef,
  data,
  children,
  ...props
}) => {
  return (
    <Swiper
      autoplay={{
        delay: 1000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true
      }}
      breakpoints={{...breakpoints}}
      centeredSlides={true}
      initialSlide={data.length > 3 ? 2 : 0}
      loop={data.length === 1 ? false : true}
      loopAdditionalSlides={2}
      ref={sliderRef}
      {...props}
    >
      {children}
    </Swiper>
  )
}

export default CustomSwiper
