import React from 'react'
import NavbarTabs from 'components/atoms/PublicNavbarTabs'
import NavbarCircleIcon from 'seniors-first-commons/components/atoms/NavbarCircleIcon'
import { ClientsLogo } from 'seniors-first-commons/components/atoms/images/icons/Logo'
import {
  BurgerIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const navTabsItems = [
  {
    icon: null,
    route: 'howItWorks',
    text: 'app.publicNavbar.howItWorks'
  },
  {
    icon: null,
    route: 'caregiversInYourArea',
    text: 'app.publicNavbar.caregiversInYourArea'
  },
  {
    icon: null,
    route: 'help',
    text: 'app.publicNavbar.help'
  }
]

const AppNavbar = ({
  showMobileSidebar,
  changeRoute,
  activeRoute,
  toggleMobileSidebar
}) => {

  return (
    <nav className={`navbar is-fixed-top ${classes.navbar}`} role='navigation'>
      <div className={`level is-mobile full-width ${classes.navWrapper}`}>
        <div className='level-left has-text-centered pointer'>
          <ClientsLogo
            height='45'
            onClick={() => changeRoute(ROUTE.HOME)}
            width='193'
          />
        </div>

        <div className='level-item has-text-centered'>
          <div className='is-hidden-touch'>
            <NavbarTabs
              activeRoute={activeRoute}
              changeRoute={changeRoute}
              items={navTabsItems}
            />
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item is-hidden-desktop'>
            <NavbarCircleIcon
              badge={null}
              filled
              Icon={BurgerIcon}
              isActive={showMobileSidebar}
              onClick={() => toggleMobileSidebar(!showMobileSidebar)}
            />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default AppNavbar
