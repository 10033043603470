import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import ImageCropper from 'seniors-first-commons/components/atoms/ImageCropper'


const CropModal = ({
  image,
  isUpdating,
  onCancel,
  onConfirm
}) => {

  return (
    <CustomModal
      cardBodyStyles={{ overflow: 'auto' }}
      hasCloseButton={false}
      onClose={onCancel}
      open={!!image}
      styles={{ maxWidth: '500px', overflow: 'auto', width: '95%' }}
    >
      <div className='has-text-centered'>
        <ImageCropper
          image={image}
          onSave={onConfirm}
          render={(onClickSave) => {
            return (
              <div className='column'>
                <div className='columns is-mobile'>
                  <div className='column is-paddingless'>
                    <button
                      className='outlined'
                      disabled={isUpdating}
                      onClick={onCancel}
                      style={{ height: '40px', width: '120px' }}
                      type='button'
                    >
                      <FormattedMessageWithClass id='app.home.cropmodal.cancel' />
                    </button>
                  </div>

                  <div className='column is-paddingless'>
                    <button
                      className={`button filled ${isUpdating ? 'is-loading' : ''}`}
                      disabled={isUpdating}
                      onClick={onClickSave}
                      style={{ height: '40px', width: '120px' }}
                      type='button'
                    >
                      <FormattedMessageWithClass
                        id={isUpdating ? 'app.home.cropmodal.saving' : 'app.home.cropmodal.save'}
                      />
                    </button>
                  </div>
                </div>
              </div>
            )
          }}
        ></ImageCropper>
      </div>
    </CustomModal>
  )
}

CropModal.displayName = 'CropModal'

export default CropModal
