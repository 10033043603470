import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {Menu } from 'react-bulma-components'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { logoTipo } from 'seniors-first-commons/components/atoms/images'
import './ConfigureModal.css'
import Privacy from './Content/Privacy/Privacy'
import Technical from './Content/Technical/Technical'
import Tracking from './Content/Tracking/Tracking'
import Marketing from './Content/Marketing/Marketing'

class ConfigureModal extends Component {
  state = {
    configuration: {
      marketing: false,
      tracking: false
    },
    menu: {
      selectedOption: 'privacy'
    }
  }

  selectOptionHandler = (key) => {
    this.setState({
      ...this.state,
      menu: {
        ...this.state.menu,
        selectedOption: key
      }
    })
  }

  toggleHandler = (prop) => {
    const newConfiguration = {
      ...this.state.configuration
    }
    newConfiguration[prop] = !newConfiguration[prop]

    this.setState({
      ...this.state,
      configuration: newConfiguration
    })
  }

  render() {
    const menuItems = this.renderMenuItems()
    const content = this.renderContent()

    return (
      <CustomModal
        cardBodyStyles={{ overflow: 'auto' }}
        hasCloseButton={false}
        onClose={()=>{}}
        open={this.props.show}
        shouldCloseOnOverlayClick={false}
        showBackdrop={false}
        styles={{ overflow: 'auto' }}
      >
        <div className='has-background-white' style={{
          paddingBottom: '15px',
          paddingTop: '20px'
        }}>
          <div className='columns'>
            <div className='column is-narrow has-text-centered'>
              <img alt='' src={logoTipo} />
            </div>
            <div className='column is-hidden-tablet has-text-centered'>
              <h1 className='is-size-4 has-text-black'>
                {this.props.intl.formatMessage({ id: 'app.privacy.title' })}
              </h1>
            </div>
            <div className='column is-hidden-mobile'>
              <h1 className='is-size-4 has-text-black'>
                {this.props.intl.formatMessage({ id: 'app.privacy.title' })}
              </h1>
            </div>
          </div>
          <div className='columns'>
            <div className='column is-narrow'>
              <Menu>
                <Menu.List>{menuItems}</Menu.List>
              </Menu>
            </div>
            <div className='column'>
              <div className='is-hidden-desktop' style={{ minHeight: '310px' }}>
                {content}
              </div>
              <div className='is-hidden-touch' style={{ minHeight: '310px' }}>
                {content}
              </div>
            </div>
          </div>
          <div className='columns is-vcentered has-text-centered'>
            <div className='column'>
              <button
                className='button filled'
                onClick={() => this.props.confirm(this.state.configuration)}
                style={{height: '52px'}}
              >
                {this.props.intl.formatMessage({ id: 'app.privacy.button.confirm.text' })}</button>
            </div>
            <div className='column'>
              <button
                className='button outlined'
                onClick={this.props.rejectAll}
                style={{height: '52px'}}
              >{this.props.intl.formatMessage({ id: 'app.privacy.button.reject.text' })}</button>
            </div>
          </div>
        </div>

      </CustomModal>
    )
  }

  renderMenuItems() {
    const options = {
      marketing: this.props.intl.formatMessage({
        id: 'app.privacy.options.marketing_cookies'
      }),
      privacy: this.props.intl.formatMessage({
        id: 'app.privacy.options.your_privacy'
      }),
      technical: this.props.intl.formatMessage({
        id: 'app.privacy.options.technical_cookies'
      }),
      tracking: this.props.intl.formatMessage({
        id: 'app.privacy.options.performance_cookies'
      })
    }

    return Object.keys(options).map(key => (
      <Menu.List.Item
        active={key === this.state.menu.selectedOption}
        className='custom-menu-item'
        key={key}
        onClick={() => { this.selectOptionHandler(key) }}
      >
        {options[key]}
      </Menu.List.Item>
    ))
  }

  renderContent() {
    switch (this.state.menu.selectedOption) {
    case 'privacy':
      return (<Privacy />)

    case 'technical':
      return (<Technical />)

    case 'tracking':
      return (<Tracking
        toggle={() => { this.toggleHandler('tracking') }}
        value={this.state.configuration.tracking}
      />)

    case 'marketing':
      return (<Marketing
        toggle={() => { this.toggleHandler('marketing') }}
        value={this.state.configuration.marketing}
      />)
    default:
      return <Privacy />
    }
  }
}

export default injectIntl(ConfigureModal)
