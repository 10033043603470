import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { verifyEmail } from 'seniors-first-commons/shared/store/auth/actions'
import VerifyEmailLayout from 'seniors-first-commons/components/templates/VerifyEmailLayout'
import { ROUTE } from 'root/constants'


const VerifyEmail = ({
  emailVerified,
  role,
  locale,
  error,
  match,
  verifyEmail
}) => {
  const [verificationStatus, setVerificationStatus] = useState(false)
  const [verifyRequested, setVerifyRequested] = useState(false)
  const history = useHistory()

  const token = match.params.token

  useEffect(() => {
    if (match && match.params && match.params.token && !verifyRequested) {
      setVerifyRequested(true)
      verifyEmail({ token })
    }
    // eslint-disable-next-line
  }, [verifyRequested])

  useEffect(() => {
    setVerificationStatus(emailVerified)
  }, [setVerificationStatus, emailVerified])

  const onRedirectToLogin = () => {
    routingService.push(history, ROUTE.LOGIN)
  }

  const onLogoClick = () => routingService.push(history, ROUTE.LOGIN)


  return (
    <VerifyEmailLayout
      error={error}
      locale={locale}
      onLogoClick={onLogoClick}
      onRedirectToLogin={onRedirectToLogin}
      role={role}
      verificationStatus={verificationStatus}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    emailVerified: state.auth.register.emailVerified,
    error: state.auth.verifyEmailError,
    locale: state.intl.locale
  }
}

const mapDispatchToProps = {
  verifyEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
