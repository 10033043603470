import React from 'react'
import { Field } from 'formik'
import Range from 'seniors-first-commons/components/atoms/Slider'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import { FORMATION } from 'seniors-first-commons/utils/constants'
import Checkbox from 'seniors-first-commons/components/atoms/Checkbox'
import Select from 'seniors-first-commons/components/molecules/Select'
import classes from './index.module.css'

export const Gender = () => {

  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        labelName='client.search.sidebar.gender'
        labelType='p1-bold'
        topSpace={2}
      />

      <div className={`field has-margin-t-6 ${classes.label}`}>
        <Field
          component={Checkbox}
          id='female'
          name='gender'
          type='checkbox'
          value='f'
        />
        <label
          className='has-margin-l-7 has-text-grey-darker has-text-weight-normal'
          htmlFor='female'>
          <FormattedMessageWithClass id='client.search.sidebar.gender.female' />
        </label>
      </div>

      <div className={`field ${classes.label}`}>
        <Field
          component={Checkbox}
          id='male'
          name='gender'
          type='checkbox'
          value='m'
        />
        <label
          className='has-margin-l-7 has-text-grey-darker has-text-weight-normal'
          htmlFor='male'
        >
          <FormattedMessageWithClass id='client.search.sidebar.gender.male' />
        </label>
      </div>
    </>
  )
}

export const Age = ({ values }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={8}
        centered={false}
        labelName='client.search.sidebar.age'
        labelType='p1-bold'
        topSpace={2}
      />
      <Field
        component={Range}
        defaultValue={values.age}
        id='age'
        max={70}
        min={18}
        name='age'
        range
        showInfo={false}
        sliderText=''
        step={1}
      />
    </>
  )
}

export const Nationality = ({ values, countries }) => {

  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id='nationalities'
        labelName='client.search.sidebar.nationalities'
        labelType='p1-bold'
        topSpace={2}
      />
      <Field
        closeMenuOnSelect={false}
        component={Select}
        defaults={values.nationalities}
        id='nationalities'
        isMulti
        name='nationalities'
        options={countries}
        placeholder=''
        shouldTranslate={false}
      />
    </>
  )
}


export const Languages = ({ values, languages }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id='languages'
        labelName='client.search.sidebar.languages'
        labelType='p1-bold'
        topSpace={2}
      />
      <Field
        closeMenuOnSelect={false}
        component={Select}
        defaults={values.languages}
        id='languages'
        isMulti
        name='languages'
        options={languages}
        placeholder=''
        shouldTranslate={false}
      />
    </>
  )
}


export const Illnesses = ({ values, illnesses }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id='disease_experience'
        labelName='client.search.sidebar.illnesses'
        labelType='p1-bold'
        topSpace={2}
      />
      <Field
        closeMenuOnSelect={false}
        component={Select}
        defaults={values.disease_experience}
        id='disease_experience'
        isMulti
        name='disease_experience'
        options={illnesses}
        placeholder=''
        shouldTranslate={false}
      />
    </>
  )
}

export const Education = ({ values }) => (
  <>
    <FieldLabel
      bottomSpace={0}
      centered={false}
      id='education_level'
      labelName='client.search.sidebar.education'
      labelType='p1-bold'
      topSpace={2}
    />
    <Field
      closeMenuOnSelect={false}
      component={Select}
      defaults={values.education_level}
      id='education_level'
      isMulti
      name='education_level'
      options={Object.keys(FORMATION).map((key) => ({ label: FORMATION[key], value: key }))}
      placeholder=''
      shouldTranslate
    />
  </>
)

export const About = () => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        labelName='client.search.sidebar.about'
        labelType='p1-bold'
        topSpace={2}
      />
      <div className={`field has-margin-t-6 ${classes.label}`}>
        <Field
          component={Checkbox}
          id='mobilizations'
          name='mobilizations'
          type='checkbox'
        />
        <label
          className='has-margin-l-7 has-text-grey-darker has-text-weight-normal'
          htmlFor='mobilizations'
        >
          <FormattedMessageWithClass id='client.search.sidebar.about.movilizations' />
        </label>
      </div>

      <div className={`field ${classes.label}`}>
        <Field
          component={Checkbox}
          id='pet_friendly'
          name='pet_friendly'
          type='checkbox'
        />
        <label
          className='has-margin-l-7 has-text-grey-darker has-text-weight-normal'
          htmlFor='pet_friendly'
        >
          <FormattedMessageWithClass id='client.search.sidebar.about.pets' />
        </label>
      </div>

      <div className={`field ${classes.label}`}>
        <Field
          component={Checkbox}
          id='driving_license'
          name='driving_license'
          type='checkbox'
        />
        <label
          className='has-margin-l-7 has-text-grey-darker has-text-weight-normal'
          htmlFor='driving_license'
        >
          <FormattedMessageWithClass id='client.search.sidebar.about.drivingLicense' />
        </label>
      </div>
    </>
  )
}
