import React from 'react'
import classes from './index.module.css'


const ActionalbleEmptyResponse = ({
  matchesMobile=false,
  message='',
  buttonText='',
  loading=false,
  hideButton=false,
  onClickAction,
  disabled=false,
  id=''
}) => {

  return (
    <div className={`has-padding-${matchesMobile ? '5' : '1'}
      ${classes.container}`}
    >
      <p className={`primary t${matchesMobile ? '3' : '2'}-bold`}>
        {message}
      </p>
      {!hideButton ?
        <div className='has-text-centered'>
          <button
            className={`button main-button filled has-margin-t-2 ${loading ? 'is-loading' : ''} ${classes.actionButton}`}
            disabled={loading || disabled}
            id={id}
            onClick={onClickAction}
          >
            {buttonText}
          </button>
        </div> : null
      }
    </div>
  )
}

export default ActionalbleEmptyResponse
