import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import services from 'seniors-first-commons/services/api-service'
import { dayIds } from 'seniors-first-commons/utils/constants'
import { openWhatsappChat } from 'seniors-first-commons/utils/openWhatsappChat'

const { axiosGet, axiosPost, axiosPut } = services

// create the right params to get caregivers
const baseServiceSheetToApi = (payload) => {

  if(payload === undefined || payload === null || payload === false){
    return null
  }
  else{
    const data = payload
    const params = {}

    params['service_categories'] = data['service_categories']
      .map(service => service.service_category_id).join(',')
    params['lat'] = data['address_lat']
    params['lng'] = data['address_lng']
    params['contract'] = data['contract_type']
    params['page'] = data['page'] || 1
    params['per_page'] = data['per_page'] || 20
    params['service_sheet_id'] = data['id']

    // retribution logic
    if(data['retribution'] === 'custom' && data['retribution_amount']){
      params['salary'] = data['retribution_amount']
    }

    params['modality'] = data['hiring_modality']

    if(data['hiring_modality'] === 'live_in_care'){

      // eslint-disable-next-line
      for(let index in dayIds){
        if(dayIds[index] === data['live_in_care_day_of_week_from']){
          data['live_in_care_day_of_week_from'] = index
        }

        if(dayIds[index] === data['live_in_care_day_of_week_to']){
          data['live_in_care_day_of_week_to'] = index
        }
      }

      // replace the colon : with nothing
      data['live_in_care_hour_from'] = data.live_in_care_hour_from.replace(':', '')

      data['live_in_care_hour_to'] = data.live_in_care_hour_to.replace(':', '')

      params['day_times'] = `${data['live_in_care_day_of_week_from']},${data['live_in_care_hour_from']},${data['live_in_care_day_of_week_to']},${data['live_in_care_hour_to']}`
    }

    else if (data['hiring_modality'] === 'visiting_care'){
      const dayTimes = []
      data.visiting_care_day_and_hours.map(({ hour_from, hour_to, day_of_week }) => {
        hour_from = hour_from.replace(':', '')
        hour_to = hour_to.replace(':', '')
        return dayTimes.push(`${day_of_week}~${hour_from}~${hour_to}`)
      })

      params['day_times'] = dayTimes.join(',')
    }

    //age logic
    if(data.age){
      const age_start = data.age.split('-')[0]
      const age_end = data.age.split('-')[1]

      params['age_start'] = age_start
      params['age_end'] = age_end
    }

    if (data.order_by) {
      params['order_by'] = 'nearest_first'
    }

    params.gender = payload.gender
    params.nationalities = payload.nationalities
    params.disease_experience = payload.disease_experience
    params.education_level = payload.education_level
    params.languages = payload.languages
    params.mobilizations = payload.mobilizations
    params.pet_friendly = payload.pet_friendly
    params.driving_license = payload.driving_license

    return params
  }
}


export const getCaregivers = createAsyncThunk(
  'caregivers/getCaregivers',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseServiceSheetToApi(payload)
    return axiosGet('/carers/search', requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCaregiverDetail = createAsyncThunk(
  'caregivers/getCaregiverDetail',
  ({ id, service_sheet_id }, {rejectWithValue}) => {
    return axiosGet(`/carers/${id}`, { service_sheet_id })
      .then(response => response)
      .catch(err => rejectWithValue(err))
  }
)

// TODO: goes in v1.1
export const getLastOffersResults = createAsyncThunk(
  'caregivers/getLastOffersResults',
  (payload, {rejectWithValue}) => {
    return axiosGet('/carers/search_by_service_sheets', payload)
      .then(response => response)
      .catch(err => rejectWithValue(err))
  }
)

export const getUnlockedCaregivers = createAsyncThunk(
  'caregivers/getUnlockedCaregivers',
  ({ page, per_page }, { rejectWithValue }) => {
    return axiosGet('/clients/connected-caregivers', { page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCaregiversNearYou = createAsyncThunk(
  'caregivers/getCaregiversNearYou',
  (payload, { rejectWithValue }) => {
    return axiosGet('/carers/find_near_logged_client')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getConnectedCandidates = createAsyncThunk(
  'caregivers/getConnectedCandidates',
  ({ service_sheet_id, page, per_page, order_by, lat, lng }, {rejectWithValue}) => {
    return axiosGet(`/service-sheets/${service_sheet_id}/unblocked-caregivers`, { lat, lng, order_by, page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCandidatesFromSearch = createAsyncThunk(
  'caregivers/getCandidatesFromSearch',
  ({ service_sheet_id, page, per_page, status, order_by, lat, lng }, {rejectWithValue}) => {
    return axiosGet(`/service-sheets/${service_sheet_id}/job-propositions`,
      { lat, lng, order_by, page, per_page, status })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCaregiversFromPost = createAsyncThunk(
  'caregivers/getCaregiversFromPost',
  ({ service_sheet_id, page, per_page, order_by, lat, lng }, {rejectWithValue}) => {
    return axiosGet(`/service-sheets/${service_sheet_id}/post-applicants`,
      { lat, lng, order_by, page, per_page, show_all: true })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const paginatePostCandidatesClient = createAsyncThunk(
  'caregivers/paginatePostCandidatesClient',
  ({ pageNumber }) => {
    return Promise.resolve(pageNumber)
  }
)

export const markPostCandidatesAsViewed = createAsyncThunk(
  'caregivers/markPostCandidatesAsViewed',
  ({ applicants, service_sheet_id }, { rejectWithValue }) => {
    return axiosPut(`/service-sheets/${service_sheet_id}/post-applicants/mark_as_viewed`, { applicants })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const connectCaregiverFromPost = createAsyncThunk(
  'caregivers/connectCaregiverFromPost',
  ({ service_sheet_id, caregiver_id }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${service_sheet_id}/connect-post-applicant/${caregiver_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const connectCaregiverFromSearch = createAsyncThunk(
  'caregivers/connectCaregiverFromSearch',
  ({ service_sheet_id, caregiver_id }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${service_sheet_id}/send-job-proposition/${caregiver_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const directConnectFromProfile = createAsyncThunk(
  'caregivers/directConnectFromProfile',
  ({ service_sheet_id, caregiver_id }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${service_sheet_id}/send-job-proposition/${caregiver_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const connectInterestedCaregiver = createAsyncThunk(
  'caregivers/connectInterestedCaregiver',
  ({ job_offer_id, caregiver_id }, { rejectWithValue }) => {
    return axiosPost(`service-sheets/${job_offer_id}/connect-interested/${caregiver_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const connectAcceptedDirectOffer = createAsyncThunk(
  'caregivers/connectAcceptedDirectOffers',
  ({ job_offer_id, caregiver_id }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${job_offer_id}/connect-accepted-without-credits/${caregiver_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getInterestedReasons = createAsyncThunk(
  'caregivers/getInterestedReasons',
  ({ job_offer_id, caregiver_id }, { rejectWithValue }) => {
    return axiosGet(`/service-sheets/${job_offer_id}/carer/${caregiver_id}/job-proposition`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const chatWithCaregiver = createAsyncThunk(
  'caregivers/chatWithCaregiver',
  ({ caregiver_id }, { rejectWithValue }) => {
    return axiosGet(`clients/connected-caregivers/${caregiver_id}/chat`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const checkConnectionWithCaregiver = createAsyncThunk(
  'caregivers/checkConnectionWithCaregiver',
  ({ caregiver_id }, { rejectWithValue }) => {
    return axiosGet(`/clients/is-connected-with/${caregiver_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCaregiverBasicData = createAsyncThunk(
  'caregiver/getCaregiverBasicData',
  ({ caregiver_id }, { rejectWithValue }) => {
    return axiosGet(`/carers/${caregiver_id}/basic-profile`)
      .then((response) => response)
      .catch(error => rejectWithValue(error))
  }
)

export const rateCaregiver = createAsyncThunk(
  'caregiver/rateCaregiver',
  ({ caregiver_id, hired, rating }, { rejectWithValue }) => {
    const formatted_hired = hired === 'yes' ? true : false
    return axiosPut(`/clients/rate-caregiver/${caregiver_id}`, { hired: formatted_hired, rating })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const chatWithCaregiverOnWhatsapp = createAsyncThunk(
  'caregiver/chatWithCaregiverOnWhatsapp',
  async ({ caregiver_id }) => {
    const caregiverBasicData = await axiosGet(`/carers/${caregiver_id}/basic-profile`)
    openWhatsappChat(caregiverBasicData.profile.phone)
  }
)

export const nullCaregiverBasicData = createAction('caregivers/nullBasicData')

export const getNotReadMessages = createAction('caregivers/getNotReadMessages')

export const toggleSuccessStatus = createAction('caregivers/toggleSuccessStatus')

export const setInboxSelectedConversation = createAction('caregivers/setInboxSelectedConversation')

// new ones
export const onChatRegisterCaregiverId = createAction('caregivers/onChatRegisterCaregiverId')

export const onToggleConnectionConfirmModal = createAction('caregivers/onToggleConnectionConfirmModal')

export const onToggleChatOrSendOfferModal = createAction('caregivers/onToggleChatOrSendOfferModal')

export const onToggleInterestedReasonsModal = createAction('caregivers/onToggleInterestedReasonsModal')
