import * as Yup from 'yup'

export const profileSettingsSchema = Yup.object().shape({
  address: Yup.object().shape({
    address_lat: Yup.number().required('validations.mandatory'),
    address_lng: Yup.number().required('validations.mandatory'),
    formatted_address: Yup.string().required('validations.mandatory')
  }),
  country: Yup.object().shape({
    label: Yup.string().required('validations.mandatory'),
    value: Yup.number().required('validations.mandatory')
  }),
  email: Yup.string().email('validations.invalidemail')
    .required('validations.mandatory'),
  firstname: Yup.string().required('validations.mandatory'),
  lastname: Yup.string().required('validations.mandatory'),

  username: Yup.string()
})
