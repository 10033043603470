import React from 'react'
import { pathOr } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Rating from 'seniors-first-commons/components/atoms/Rating'
import { ShieldIcon, VideoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'
import Pill from 'seniors-first-commons/components/atoms/Pill'


const CaregiverCardHeader = ({
  data,
  clickableImg,
  clickableName,
  caregiverStatus,
  matchesMobile,
  onViewProfile,
  onOpenVideoCVModal,
  onProfileImageClick
}) => {
  const photo = data.image
  const experienceRatio = data.experience_ratio
  const username = data.username
  const firstname = data.firstname
  const lastname = data.lastname
  const isCandidateNew = data?.viewed === false ?? false
  const video = pathOr('', ['videos', 0, 'video_url'], data)
  const videoId = video ? video.split('/')[2] : ''


  return (
    <div className={`card-header is-shadowless has-padding-4 ${classes.header}`}>
      <div className='columns is-mobile full-width is-flex-grow-1'>
        <div className='column is-narrow is-relative is-paddingless-r'>
          <figure
            className={`image ${classes.avatar} ${clickableImg ? 'pointer' : ''}`}
            onClick={onProfileImageClick}
          >
            <img
              alt='userPhoto'
              className={`is-rounded ${classes.userImg}`}
              src={photo}
            />
          </figure>

          {experienceRatio >= 0.5 &&
            <div
              className='valid_exp_wrapper custom-tooltip is-flex'
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <ShieldIcon
                height='18'
                stroke='#26DCF8'
                width='18'
              />
              <FormattedMessageWithClass
                className='tooltip-content p3-bold white'
                id='client.general.caregiverCard.validExperience'
                placement='right'
              />
            </div>
          }
        </div>
        <div className='column'>
          <div className={classes.usernameContainer}>
            <p
              className={`
                has-margin-none 
                t2-bold 
                ellipsis 
                ${clickableName ? 'pointer' : ''} 
                ${isCandidateNew ? classes.newCandidateUsername : classes.candidateUsername}
              `}
              onClick={onViewProfile}
            >
              {firstname ? `${firstname} ${lastname}` : username}
            </p>
            {isCandidateNew &&
              <Pill className={`${classes.new} has-margin-none`}>
                <FormattedMessageWithClass
                  id='client.general.caregiverCard.new'
                />
              </Pill>
            }
          </div>

          <div className='has-margin-top-5'>
            <Rating
              disabled={caregiverStatus.text === 'Rejected' ? '#A6A6A6' : ''}
              rating={data.rating}
            />
          </div>

          <div style={{ height: '26px' }}>
            {video
              ? (
                <button
                  className={`is-flex is-paddingless-l ${classes.viewVideoCV}`}
                  disabled={caregiverStatus.text === 'Rejected'}
                  onClick={() => onOpenVideoCVModal(videoId)}
                  style={{ alignItems: 'center' }}
                >
                  <VideoIcon
                    fill='none'
                    height='24'
                    stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#FFFFFF'}`}
                    width='24'
                  />
                  <FormattedMessageWithClass
                    className={`p${matchesMobile ? '3' : '2'}-bold has-margin-l-7`}
                    id='client.general.caregiverCard.viewVideo'
                  />
                </button>
              )
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaregiverCardHeader
