import React from 'react'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import { CalendarIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Duration = ({ data }) => {
  const translations = {
    'undefined': useFormatMessage('apps.general.jobOffer.duration.undefined')
  }
  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <CalendarIcon
        fill='none'
        height='16'
        stroke='#022C72'
        width='18'
      />
      <p className='p2-regular grey is-capitalized has-margin-l-7 ellipsis'>
        <FormattedMessageWithClass
          className='p2-bold'
          id='apps.general.jobOffer.duration.fromTo'
          values={{ end: <span className='p2-regular grey'>{data.duration === 'temporal'
            ? data.duration_to
            : translations.undefined}</span>,
          start: <span className='p2-regular grey'>{data.duration === 'temporal'
            ? data.duration_from
            : data.duration_from}</span>
          }}
        />
      </p>
    </div>
  )
}

export default Duration
