import React from 'react'
import { Field, ErrorMessage } from 'formik'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import MapContainer from 'seniors-first-commons/components/organisms/Map'
import { AddressInput, NameInput } from '../CreateJobOfferLayout/inputs'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'

export const FirstStep = ({
  values,
  user,
  country
}) => {
  const mapContainerStyles = {
    height: '250px',
    position: 'relative',
    width: '100%'
  }
  const mapStyles = {
    borderRadius: '15px'
  }

  return (
    <>
      <FieldLabel
        id='location'
        labelName='client.createOffer.steps.address.title'
      />
      <div className='columns'>
        <div className='column'>
          <p className='l-regular grey'>
            <FormattedMessageWithClass
              id='client.createOffer.steps.address.text1'
            />
          </p>

          <FieldLabel
            labelName='client.createOffer.steps.address.label'
            labelType='l-bold'
            topSpace={2}
          />
          <Field
            component={AddressInput}
            country={country}
            name='address'
          />
          <ErrorMessage component={HandleError} name='address' />
        </div>
        <div className='column' >
          <MapContainer
            animateMarker={2}
            hasMarker={values.address.address_lat ? true : false}
            location={{
              lat: Number(values?.address?.address_lat),
              lng: Number(values?.address?.address_lng)
            }}
            markerText={user && `${user.firstname} ${user.lastname}`}
            options={{
              containerStyle: mapContainerStyles,
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              style: mapStyles
            }}
            radius={0}
            zoom={!values.address.address_lat ? 5 : 15}
          />
        </div>
      </div>
    </>
  )
}

export const SixthStep = () => {
  return (
    <>
      <FieldLabel
        id='name'
        labelName='client.createOffer.steps.name.title'
      />
      <Field
        component={NameInput}
        name='name'
      />

      <FieldLabel
        centered={false}
        id='phone'
        labelName='client.profileSettings.phone'
        labelType='p1-bold'
        topSpace={1}
      />
      <Field
        component={PhoneInput}
        id='phone'
        name='phone'
      />
      <ErrorMessage
        component={HandleError}
        name='phone'
      />
    </>
  )
}
