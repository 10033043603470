import React from 'react'
import withChat from 'hoc/withChat'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import AppContainer from  'components/organisms/AppContainer'
import Pagination from 'seniors-first-commons/components/molecules/Pagination'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'
import VideoModal from 'seniors-first-commons/components/molecules/VideoModal'
import MyCaregiversDesktopLayout from './DesktopLayout'
import MyCaregiversMobileLayout from './MobileLayout'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const MyCaregiversLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    ...rest
  },
  ...props
}) => {
  return (
    <AppContainer
      additionalClass={matchesMobile && rest.unlockedCaregivers.total ? 'is-paddingless-lr is-marginless-lr' : 'has-padding-lr-7'}
      name='My caregivers'
    >
      <p className={`t${matchesMobile ? '2' : '1'}-bold primary has-margin-b-4 ${matchesMobile ? 'has-padding-lr-7' : ''}`}>
        <FormattedMessageWithClass id='client.mycaregivers.title' />
      </p>

      <div className='has-padding-b-1'>
        {!rest.loading.unlockedCaregiversLoading
          ? rest.unlockedCaregivers.total
            ? matchesMobile
              ? (
                <MyCaregiversMobileLayout
                  {...rest}
                  {...props}
                />
              )
              : (
                <MyCaregiversDesktopLayout
                  {...rest}
                  {...props}
                />
              )
            : (
              <div className='no-results'>
                <EmptyBox stroke='#525860' />
              </div>
            )
          : null
        }
      </div>

      <Pagination
        currentPage={rest.unlockedCaregivers.currentPage}
        data={rest.unlockedCaregivers.total}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={rest.handlePageChange}
      />

      <VideoModal
        isOpen={!!rest.showVideoCVModal}
        onClose={rest.onCloseVideoCVModal}
        title='CV Video'
        videoId={rest.showVideoCVModal}
      />
    </AppContainer>
  )
}

export default withChat(MyCaregiversLayout, 'connected')
