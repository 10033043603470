import React from 'react'
import { pathOr } from 'ramda'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { formatToMatchEnv } from 'seniors-first-commons/utils/helpers'
import NavbarTabs from 'components/atoms/NavbarTabs'
import NavbarAvatar from 'seniors-first-commons/components/atoms/NavbarAvatar'
import NavbarCircleIcon from 'seniors-first-commons/components/atoms/NavbarCircleIcon'
import { ClientsLogo } from 'seniors-first-commons/components/atoms/images/icons/Logo'
import {
  SearchIcon,
  BellIcon,
  BurgerIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import dummyImage from 'seniors-first-commons/components/atoms/images/user.png'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const navTabsItems = [
  {
    icon: null,
    route: ROUTE.HOME,
    text: 'client.general.navbar.home'
  },
  {
    icon: null,
    route: ROUTE.MY_JOB_OFFERS,
    text: 'client.general.navbar.myOffers'
  },
  {
    icon: null,
    route: ROUTE.MY_CAREGIVERS,
    text: 'client.general.navbar.myCaregivers'
  },
  {
    icon: <SearchIcon
      fill='none'
      height='16'
      stroke='white'
      width='16'
    />,
    route: ROUTE.CREATE_JOB_OFFER,
    text: 'client.general.navbar.findCaregiver'
  }
]

const AppNavbar = ({
  showMobileSidebar,
  user,
  notReadNotifications,
  onLogout,
  changeRoute,
  activeRoute,
  toggleMobileSidebar
}) => {
  const firstname = pathOr('', ['firstname'], user)
  const photo = pathOr(dummyImage, ['photo'], user)

  const desktopDropdownItems = [
    {
      isActive: activeRoute && activeRoute(ROUTE.PROFILE_SETTINGS),
      name: 'client.general.navbar.dropdown.profile',
      onClick: () => changeRoute(ROUTE.PROFILE_SETTINGS)
    },
    {
      isActive: activeRoute && activeRoute(ROUTE.BILLING),
      name: 'client.general.navbar.dropdown.billing',
      onClick: () => changeRoute(ROUTE.BILLING)
    },
    {
      isActive: activeRoute && activeRoute(ROUTE.CREDITS),
      name: 'client.general.navbar.dropdown.credits',
      onClick: () => changeRoute(`${ROUTE.CREDITS}/my-credits`)
    },
    {
      external: useFormatMessage(formatToMatchEnv('app.general.faq.link')),
      isActive: null,
      name: 'client.general.navbar.dropdown.faq',
      onClick: () => {}
    },
    {
      isActive: null,
      name: 'client.general.navbar.dropdown.logout',
      onClick: onLogout
    }
  ]

  return (
    <nav className={`navbar is-fixed-top ${classes.navbar}`} role='navigation'>
      <div className={`level is-mobile full-width ${classes.navWrapper}`}>
        <div className='level-left has-text-centered pointer'>
          <ClientsLogo
            height='32'
            onClick={() => changeRoute(ROUTE.HOME)}
            width='143'
          />
        </div>

        <div className='level-item has-text-centered'>
          <div className='is-hidden-touch'>
            <NavbarTabs
              activeRoute={activeRoute}
              changeRoute={changeRoute}
              items={navTabsItems}
            />
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <NavbarCircleIcon
              badge={notReadNotifications}
              Icon={BellIcon}
              isActive={activeRoute && activeRoute(ROUTE.NOTIFICATIONS)}
              onClick={() => changeRoute(ROUTE.NOTIFICATIONS)}
              tooltip='apps.general.navbar.notifications.tooltip'
            />
          </div>

          <div className='level-item is-hidden-desktop'>
            <NavbarCircleIcon
              badge={null}
              filled
              Icon={BurgerIcon}
              isActive={showMobileSidebar}
              onClick={() => toggleMobileSidebar(!showMobileSidebar)}
            />
          </div>

          <div className='level-item is-hidden-touch'>
            <NavbarAvatar
              dropdownItems={desktopDropdownItems}
              firstname={firstname}
              photo={photo}
            />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default AppNavbar
