import React, { Component, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

class Technical extends Component {
  render() {
    const title = this.props.intl.formatMessage({
      id: 'app.privacy.options.technical_cookies'
    })
    const alwaysActiveText = this.props.intl.formatMessage({
      id: 'app.privacy.options.technical_cookies.always_active'
    })
    const text = this.renderText()

    return (
      <Fragment>
        <div className='level has-margin-b-7'>
          <div className='level-left'>
            <div className='level-item'>
              <h2 className='is-size-5 has-text-weight-semibold has-text-black'>{title}</h2>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <h2 className='is-size-6 has-text-link'>{alwaysActiveText}</h2>
            </div>
          </div>
        </div>
        {text}
      </Fragment>
    )
  }

  renderText() {
    let output = ''
    switch (this.props.locale) {
    // Spanish
    case 'es-es':
    case 'es-us':
      output = (<p className='has-text-justified'>Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Se configuran en respuesta a sus acciones realizadas al solicitar servicios, como establecer sus preferencias de privacidad, iniciar sesión o completar formularios. Puede configurar su navegador para bloquear o alertar sobre estas cookies, pero algunas áreas del sitio no funcionarán. Estas cookies no almacenan ninguna información de identificación personal.</p>)
      break

      // All English
    default:
      output = (<p className='has-text-justified'>These cookies are necessary for the website to function and cannot be disactivated on our systems. They are configured in response to your actions in requesting various services, such as establishing your privacy preferences, starting a session or completing forms. You can configure your browser to block these cookies or set alerts for them, but some areas of the site will no longer function. These cookies do not store any information about your personal identification.</p>)
      break
    }

    return output
  }
}

const mapStateToProps = (state) => ({
  locale: state.intl.locale.toLowerCase()
})

export default injectIntl(connect(mapStateToProps)(Technical))
