import React from 'react'
import { pathOr } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { FORMATION, LANGUAGE_OPTIONS } from 'seniors-first-commons/utils/constants'
import { ArrowDownIcon, ArrowUpIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const FormationCardBody = ({ data: { ...data } }) => {
  const languages = pathOr([], ['languages'], data)

  const getLanguageLevel = (level) => {
    if (data && level) {
      // eslint-disable-next-line
      for (let index in LANGUAGE_OPTIONS) {
        if (parseInt(level) === parseInt(index)) {
          return LANGUAGE_OPTIONS[index]['name']
        }
      }
    }
  }

  return (
    <div className='card-content has-padding-b-5 has-padding-lrt-3'>
      {data && data.formation_type ?
        <div className='columns grey'>
          <div className='column'>
            <h3 className='primary t4-bold has-margin-b-6'>
              <FormattedMessageWithClass id='client.caregiverProfile.educationCard.formation' />
            </h3>
            <div className='has-margin-t-7'>
              <p className='p1-bold'>
                <FormattedMessageWithClass id='client.caregiverProfile.educationCard.formationType' />
              </p>
              <p className='p1-regular'>
                <FormattedMessageWithClass id={FORMATION[data.formation_type]} />
                {data.other_formation_type && `: ${data.other_formation_type}`}
              </p>
            </div>

            {data.other_specific_formation &&
              <div className='has-margin-t-7'>
                <p className='p1-bold'>
                  <FormattedMessageWithClass id='client.caregiverProfile.educationCard.anotherFormationType' />
                </p>
                <p className='p1-regular is-capitalized' style={{ wordBreak: 'break-all' }}>
                  {data.other_specific_formation}
                </p>
              </div>
            }
          </div>

          <div className='column'>
            <h3 className='primary t4-bold has-margin-b-6'>
              <FormattedMessageWithClass id='client.caregiverProfile.educationCard.languages' />
            </h3>
            {languages.length ?
              languages.map((lang, index) => (
                <div
                  className='has-margin-t-7'
                  key={index}
                >
                  <p className='p1-bold'>
                    {lang['language']['name']}
                  </p>
                  <p className='p1-regular'>
                    <FormattedMessageWithClass id={`${getLanguageLevel(lang['level'])}`} />
                  </p>
                </div>
              )) :
              <FormattedMessageWithClass
                className='p1-bold grey'
                id='client.caregiverProfile.educationCard.nolanguages'
              />
            }
          </div>
        </div> :
        <FormattedMessageWithClass
          className='p1-bold grey'
          id='client.caregiverProfile.educationCard.noEducation'
        />
      }
    </div>
  )
}


const CaregiverFormationCard = ({
  isSelected,
  matchesMobile,
  data,
  onToggleCard
}) => {
  const formation = pathOr({}, ['formation'], data)

  const getFormationType = (formationType) => {
    if (formation && formationType !== null && formationType !== undefined) {
      // eslint-disable-next-line
      for (let index in FORMATION) {
        if (parseInt(formationType) === parseInt(index)) {
          return FORMATION[index]
        }
      }
    }
  }

  return (
    <div className={`card has-margin-t-4 ${classes.card}`}>
      <div
        className={`card-header primary-light-background has-padding-l-3 has-padding-bt-5 
        ${matchesMobile ? 'pointer' : ''}`}
        onClick={() => onToggleCard('formation')}
      >
        <div className='columns is-mobile is-vcentered full-width '>
          <div className='column'>
            <p className='t2-bold white'>
              <FormattedMessageWithClass id='client.caregiverProfile.educationCard.title' />
            </p>
          </div>
          {matchesMobile &&
            <div className='column has-text-right pointer center'>
              {isSelected ?
                <ArrowUpIcon  height='24px' stroke='#FFF' width='24px'/> :
                <ArrowDownIcon height='24px' stroke='#FFF' width='24px' />
              }
            </div>
          }
        </div>
      </div>
      {!matchesMobile
        ? <FormationCardBody
          data={{
            formation_type: getFormationType(formation?.formation_type),
            ...formation
          }}
        />
        : matchesMobile && isSelected
          ? <FormationCardBody
            formation_type={getFormationType(formation?.formation_type)}
            {...formation}
          />
          : null
      }
    </div>
  )
}

export default CaregiverFormationCard
