import React from 'react'
import Geosuggest from 'react-geosuggest'

const AddressAutoSuggest = ({
  form,
  field,
  icon,
  ...props
}) => {

  const handleSuggestSelect = (suggest) => {
    if (suggest) {
      form.setFieldValue(`${field.name}.formatted_address`, suggest.label)
      form.setFieldValue(`${field.name}.address_lat`, suggest.location.lat)
      form.setFieldValue(`${field.name}.address_lng`, suggest.location.lng)
    }
  }

  const handleGeoSuggestBlur = () => {
    form.setFieldTouched(field.name)
  }

  return (
    <div className='full-width'>
      <div className={`control ${icon ? 'has-icons-right' : ''}`}>
        <Geosuggest
          autoComplete='off'
          data-lpignore='true'
          initialValue={field.value.formatted_address ? field.value.formatted_address : ''}
          onBlur={handleGeoSuggestBlur}
          onSuggestSelect={handleSuggestSelect}
          {...props}
        />

        {icon ?
          <span className='icon is-small is-right'>
            {icon}
          </span> : null
        }
      </div>
    </div>
  )
}


export default AddressAutoSuggest
