import React, { Fragment, useState } from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import { getMinimumWageInfo } from 'seniors-first-commons/utils/getMinimumWageInfo'
import MinimumWageInfoModal from 'components/templates/CreateJobOfferLayout/Salary/MinimumWageModal'

const Salary = ({ currency, profileCountryId, salaryType }) => {
  const minWageInfo = getMinimumWageInfo(profileCountryId)
  const [wageModalOpen, setWageModalOpen] = useState(false)
  const closeWageModal = () => {
    setWageModalOpen(false)
  }
  const openWageModal = (event) => {
    event.preventDefault()
    setWageModalOpen(true)
  }
  return (
    <Fragment>
      <Radios
        content={[
          {
            name: 'salary_type',
            text: 'client.createOffer.steps.retribution.hourly-rate',
            textValues: { currency },
            value: 'hour'
          },
          {
            name: 'salary_type',
            text: 'client.createOffer.steps.retribution.monthly-rate',
            textValues: { currency },
            value: 'month'
          }
        ]}
        input={salaryType}
      />
      {!!minWageInfo && (
        <>
          <div className='has-text-centered full-width'>
            <button className='button-text' onClick={openWageModal} type='button'>
              <FormattedMessageWithClass
                className='p2-bold'
                id='client.createOffer.steps.retribution.learn-about-min-wage'
              />
            </button>
          </div>
          <MinimumWageInfoModal
            countryCode={minWageInfo.code}
            onClose={closeWageModal}
            open={wageModalOpen}
          />
        </>
      )}
    </Fragment>
  )
}

export default Salary
