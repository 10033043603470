import React, { useState } from 'react'
import Text from 'react-texty'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import { handleServiceType } from 'seniors-first-commons/utils/constants'
import ShiftsModal from 'seniors-first-commons/components/organisms/ShiftsModal'
import { ArrowRightIcon, ArrowUpIcon, ArrowDownIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const OfferDetailsBox = ({
  matchesMobile,
  additionalClasses='',
  data,
  currency,
  secondColumn,
  bottomSection
}) => {
  const [showJobOfferDetails, setShowJobOfferDetails] = useState(false)
  const [showShiftsModal, setShowShiftsModal] = useState(false)
  const translations = {
    hour: useFormatMessage('apps.general.jobOffer.salary.hourlyRate'),
    min: useFormatMessage('apps.general.jobOffer.salary.min'),
    month: useFormatMessage('apps.general.jobOffer.salary.monthlyRate'),
    'undefined': useFormatMessage('apps.general.jobOffer.duration.undefined')
  }

  return (
    <>
      <div className='level full-width is-mobile mb-0'>
        <div className='level-left'>
          <div
            className={'pointer is-flex'}
            onClick={() => setShowJobOfferDetails(!showJobOfferDetails)}
            style={{ alignItems: 'center' }}
          >
            <Text
              className={'has-margin-r-7 primary t3-bold'}
              style={{ maxWidth: matchesMobile ? '100px' : '200px' }}
            >
              <FormattedMessageWithClass
                id='client.general.offerDetails.title.details'
              />
              {' '}
              {data?.name}
            </Text>
            {showJobOfferDetails ?
              <ArrowUpIcon className='button-text-icon' height='24px' width='24px' /> :
              <ArrowDownIcon className='button-text-icon' height='24px' width='24px' />
            }
          </div>
        </div>

        <div className='level-right'>
          {secondColumn}
        </div>
      </div>

      <div className='has-margin-t-7'>
        {showJobOfferDetails &&
          <div className={`box has-margin-t-6 has-padding-bt-3 ${additionalClasses} ${classes.boxcontainer}`}>
            <div className='columns'>
              <div className='column is-one-third'>
                <p className='p1-bold primary'>
                  <FormattedMessageWithClass id='client.general.offerDetails.address.text' />
                </p>
                <Text className='p2-regular grey'>
                  {data.location}
                </Text>

                <p className='has-margin-t-6 p1-bold primary'>
                  <FormattedMessageWithClass id='client.general.offerDetails.services' />
                </p>

                <div className={classes.servicescontainer}>
                  {data['service_categories'].map((service, index) => {
                    return (
                      <div
                        className={`custom-tooltip has-margin-r-7 ${classes.serviceiconwrapper}`}
                        key={index}
                      >
                        {handleServiceType(service['service_category_id'], '20', '20', false)['image']}
                        <FormattedMessageWithClass
                          className='tooltip-content p3-bold white'
                          id={`apps.general.jobOffer.services.${service['service_category_id']}`}
                          placement='top'
                        />
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='column'>
                <p className='p1-bold primary'>
                  <FormattedMessageWithClass id='client.general.offerDetails.modality.text' />
                </p>
                <p className='p2-regular grey is-flex'>
                  <FormattedMessageWithClass
                    className='is-capitalized'
                    id={data.hiring_modality === 'visiting_care'
                      ? 'client.general.offerDetails.modality.visiting-care'
                      : 'client.general.offerDetails.modality.live-in-care'
                    }
                  />
                  <button
                    className={`button-text ${classes.alignButton}`}
                    onClick={() => setShowShiftsModal(!showShiftsModal)}
                  >
                    <div className={classes.alignButton}>
                      <FormattedMessageWithClass
                        className='p2-bold'
                        id='client.general.offerDetails.modality.daysHours'
                      />
                      <ArrowRightIcon
                        className='button-text-icon'
                        height='24px'
                        width='24px'
                      />
                    </div>
                  </button>
                </p>

                <p className='has-margin-t-6 p1-bold primary'>
                  <FormattedMessageWithClass id='client.general.offerDetails.contractType' />
                </p>
                <div className='is-capitalized p2-regular grey'>
                  <FormattedMessageWithClass
                    className='p2-regular grey is-capitalized'
                    id={
                      data?.contract_type === 'autonomous'
                        ? 'client.general.offerDetails.autonomous'
                        : data?.contract_type === 'company'
                          ? 'client.general.offerDetails.company' : ''
                    }
                  />
                </div>
              </div>

              <div className='column'>
                <p className='p1-bold primary'>
                  <FormattedMessageWithClass
                    id='client.general.offerDetails.duration'
                  />
                </p>
                <p className='p2-regular grey is-capitalized ellipsis'>
                  <FormattedMessageWithClass
                    className='p2-bold'
                    id='apps.general.jobOffer.duration.fromTo'
                    values={{ end: <span className='p2-regular grey'>{data.duration === 'temporal'
                      ? data.duration_to
                      : translations.undefined}</span>,
                    start: <span className='p2-regular grey'>{data.duration === 'temporal'
                      ? data.duration_from
                      : data.duration_from}</span>
                    }}
                  />
                </p>

                <p className='has-margin-t-5 p1-bold primary'>
                  <FormattedMessageWithClass id='client.general.offerDetails.retribution' />
                </p>
                <p className='p2-regular grey'>
                  <FormattedMessageWithClass
                    className='p2-bold'
                    id='apps.general.jobOffer.salary.wrapper'
                    values={{
                      currency: data?.retribution !== 'minimum' ? <span className='p2-regular grey'>{currency}</span> : '',
                      paytime: data?.retribution !== 'minimum'
                        ? translations[data?.salary_type]
                        : translations.min,
                      quantity: data?.retribution !== 'minimum' ? <span className='p2-regular grey'>{data?.retribution_amount}</span> : ''
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        }

        <ShiftsModal
          active={showShiftsModal}
          offer={data}
          onClose={setShowShiftsModal}
        />
      </div>

      <div className='mb-5'>{matchesMobile && bottomSection}</div>
    </>
  )
}

export default OfferDetailsBox
