import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import AppContainer from 'components/organisms/AppContainer'
import Pagination from 'seniors-first-commons/components/molecules/Pagination'
import GoBackOrHome from 'seniors-first-commons/components/atoms/GoBackOrHome'
import Checkbox from 'seniors-first-commons/components/atoms/Checkbox'
import RoundedTabs from 'seniors-first-commons/components/atoms/RoundedTabs'
import OfferDetailsBox from 'components/organisms/OfferDetailsBox'
import CandidatesBody from './CandidatesBody'
import ConfirmationModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import VideoModal from 'seniors-first-commons/components/molecules/VideoModal'
import { SearchIcon, InfoIcon, SendIcon } from 'seniors-first-commons/components/atoms/images/icons'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'
import classes from './index.module.css'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import Select from 'seniors-first-commons/components/molecules/Select'
import { ORDER_LIST_STYLES as dropdownStyles } from 'seniors-first-commons/styles/orderListStyles'

const orderOptions = [
  { label: 'client.search.header.sortBy.options.bestRanking', value: 'ranking' },
  { label: 'client.search.header.sortBy.options.nearestFirst', value: 'nearest' }
]

const FilterSentCandidates = ({
  items,
  filters,
  matchesMobile,
  onFilterChange
}) => {
  return (
    <div className={`has-margin-bt-4 ${matchesMobile ? classes.directOffersFilters : ''}`}>
      {items.map((item, index) => (
        <label
          className={`p1-regular grey has-margin-r-4 ${matchesMobile ? 'has-margin-b-7' : ''}`}
          htmlFor={item.value}
          key={index}>
          <Checkbox
            additionalClass={'has-margin-r-7'}
            checked={filters.includes(item.value)}
            id={item.value}
            onChange={onFilterChange}
            rounded={true}
            style={{ verticalAlign: 'middle' }}
          />
          <FormattedMessageWithClass id={item.label} />
        </label>
      ))}
    </div>
  )
}

const HeaderActionButtons = ({
  matchesMobile,
  sheetData,
  loading,
  onPost,
  onSearch
}) => {
  return (
    <div className={`${classes.level}`}>
      {!loading.loadingOfferDetails ?
        <div>
          {sheetData?.posted_until > 2 ?
            <p className={`p1-bold custom-tooltip success ${classes.level}`}>
              <FormattedMessageWithClass
                className='has-margin-r-7'
                id='apps.general.jobOffer.actions.posted'
              />
              {!matchesMobile
                ? (
                  <InfoIcon
                    fill='none'
                    height='24'
                    stroke='#4CC700'
                    width='24'
                  />
                )
                : null
              }
              <span className='p3-bold white tooltip-content' placement='top'>
                <FormattedMessageWithClass
                  id='apps.general.jobOffer.actions.expiresIn'
                />
                <span style={{ marginLeft: '3px', marginRight: '3px' }}>
                  {sheetData?.posted_until}
                </span>
                <FormattedMessageWithClass
                  id='apps.general.jobOffer.actions.days'
                />
              </span>
            </p>
            :
            <button
              className={'filled is-flex'}
              onClick={onPost}
              style={{
                alignItems: 'center',
                height: '40px',
                justifyContent: 'center',
                width: matchesMobile ? '40px' : '120px'
              }}
            >
              {matchesMobile ?
                <SendIcon
                  fill='none'
                  height='16'
                  stroke='#FFF'
                  width='16'
                />
                :
                <FormattedMessageWithClass id={parseInt(sheetData?.posted_until) <= 2
                  ? 'apps.general.jobOffer.actions.postAgain'
                  : 'apps.general.jobOffer.actions.post'
                } />
              }
            </button>
          }
        </div>
        : null
      }

      <button
        className={'button filled has-margin-l-7'}
        onClick={onSearch}
        style={{
          alignItems: 'center',
          height: '40px',
          justifyContent: 'center',
          width: matchesMobile ? '40px' : '120px'
        }}
      >
        {matchesMobile
          ? (
            <SearchIcon
              fill='none'
              height='16'
              stroke='#fff'
              style={{ minWidth: '16px' }}
              width='16'
            />
          )
          : <FormattedMessageWithClass id='client.candidates.header.findSend' />
        }
      </button>
    </div>
  )
}

const tabsItems = [
  { label: 'client.candidates.tabs.fromPost', value: 'post' },
  { label: 'client.candidates.tabs.directOffers', value: 'findsend' },
  { label: 'client.candidates.tabs.connected', value: 'connected' }
]

const find_send_filters = [
  { label: 'client.candidates.directOffers.filters.accepted', value: 'accepted' },
  { label: 'client.candidates.directOffers.filters.interested', value: 'interested' },
  { label: 'client.candidates.directOffers.filters.pending', value: 'pending' },
  { label: 'client.candidates.directOffers.filters.rejected', value: 'rejected' }
]

const CandidatesLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    matchesDesktop,
    showVideoCVModal,
    jobOfferId,
    sheetData,
    selectedTab,
    showConfirmationModal,
    showRenewPostModal,
    profile,
    oneSheetCandidates,
    postCandidates,
    filters,
    loading,
    orderBy,
    onSearch,
    onPost,
    handleFilterChange,
    onPostConfirm,
    onPostModalClose,
    onRenewPostConfirm,
    onCloseRenewPostModal,
    handleTabClick,
    handlePageChange,
    onCloseVideoCVModal,
    onOpenVideoCVModal,
    onChangeOrder
  }
}) => {
  const OrderByDropdown =
    <Select
      addStyles={{...dropdownStyles,
        container: (base) => ({ ...base, width: '130px' }),
        dropdownIndicator: (base) => ({ ...base, height: '20px', padding: 0 }),
        menu: (base) => ({ ...base, zIndex: '99' })
      }}
      hasoptionicon={false}
      isSearchable={false}
      onChange={onChangeOrder}
      options={orderOptions}
      value={orderBy}
    />

  return (
    <AppContainer
      additionalClass={matchesMobile ? 'is-paddingless-lr is-marginless-lr' : 'has-padding-lr-7'}
      name='Candidates'
    >
      <div className='has-padding-b-1'>
        <div className={matchesMobile ? 'has-padding-lr-7' : ''}>
          <GoBackOrHome
            className='has-padding-b-7 is-paddingless-l'
          />

          <OfferDetailsBox
            additionalClasses={matchesMobile ? 'has-padding-lr-5' : 'has-padding-lr-2'}
            currency={profile.index?.currency}
            data={sheetData}
            matchesMobile={matchesMobile}
            secondColumn={(
              <HeaderActionButtons
                loading={loading}
                matchesMobile={matchesMobile}
                onPost={onPost}
                onSearch={onSearch}
                sheetData={sheetData}
              />
            )}
          />

          <div className='has-margin-b-4 level'>
            <div className='level-left'>
              <RoundedTabs
                items={tabsItems}
                matchesMobile={matchesMobile}
                onChangeTab={handleTabClick}
                selected={selectedTab}
              />
            </div>
            <div className='level-right'>
              {OrderByDropdown}
            </div>
          </div>

          {selectedTab === 'findsend' &&
            <FilterSentCandidates
              filters={filters}
              items={find_send_filters}
              matchesMobile={matchesMobile}
              onFilterChange={handleFilterChange}
            />
          }
        </div>

        <CandidatesBody
          jobOfferId={jobOfferId}
          loading={loading}
          matchesDesktop={matchesDesktop}
          matchesMobile={matchesMobile}
          oneSheetCandidates={oneSheetCandidates}
          onOpenVideoCVModal={onOpenVideoCVModal}
          postCandidates={postCandidates}
          selectedTab={selectedTab}
          sheetData={sheetData}
        />
      </div>

      {selectedTab === 'post' && (
        <Pagination
          currentPage={postCandidates.currentPage}
          data={postCandidates.total}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
        />
      )}

      {selectedTab !== 'post' && (
        <Pagination
          currentPage={oneSheetCandidates.currentPage}
          data={oneSheetCandidates.total}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
        />
      )}

      <VideoModal
        isOpen={!!showVideoCVModal}
        onClose={onCloseVideoCVModal}
        title='CV Video'
        videoId={showVideoCVModal}
      />

      <ConfirmationModal
        confirm='client.general.postOfferModal.button'
        details='client.general.postOfferModal.text'
        hascancelButton={false}
        Icon={SendIcon}
        loading={loading.postJobLoading}
        onClose={onPostModalClose}
        onConfirm={onPostConfirm}
        show={showConfirmationModal}
        title='client.general.postOfferModal.title'
      />

      <CustomModal
        hasCloseButton={false}
        onClose={onCloseRenewPostModal}
        open={showRenewPostModal && sheetData?.posted_until <= 2}
        styles={{ maxWidth: '400px', width: '95%' }}
      >
        <div className='has-text-centered'>
          <SendIcon
            className='button-text-icon has-margin-t-7'
            fill='none'
            height='56'
            width='56'
          />
          <p className='t2-bold grey has-margin-t-7'>
            <FormattedMessageWithClass id='client.general.renewPost.title' />
          </p>

          <p className='p1-regular has-margin-bt-7 grey' style={{ wordBreak: 'break-all' }}>
            <FormattedMessageWithClass id='client.general.renewPost.text1' />
            <span className='p1-bold'> {sheetData?.name} </span>
            <FormattedMessageWithClass id='client.general.renewPost.text2' />
          </p>

          <button
            className={`button filled full-width ${loading.postJobLoading ? 'is-loading' : ''}`}
            disabled={loading.postJobLoading}
            onClick={onRenewPostConfirm}
            style={{ maxWidth: '212px' }}
          >
            <FormattedMessageWithClass
              id='client.general.renewPost.button'
            />
          </button>
        </div>
      </CustomModal>
    </AppContainer>
  )
}

export default CandidatesLayout
