import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import services from 'seniors-first-commons/services/api-service'

const { axiosGet, axiosPost, axiosPut } = services


const baseDataToApi = ({ country, address, ...rest }) => {
  const formatData = {}
  formatData['country_id'] = country.value
  formatData['address'] = address.formatted_address

  return { ...formatData, ...rest }
}


export const getCreditsRecords = createAsyncThunk(
  'billing/getCreditsRecords',
  ({ page, per_page }, { rejectWithValue }) => {
    return axiosGet('/clients/credits-use-by-job-offers', { page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCredits = createAsyncThunk(
  'billing/getCredits',
  (_payload, { rejectWithValue }) => {
    return axiosGet('/clients/credits')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const buyCredits = createAsyncThunk(
  'billing/buyCredits',
  ({ amount }, { rejectWithValue }) => {
    return axiosPost('/clients/purchase', { amount })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getBillingData = createAsyncThunk(
  'billing/getBillingData',
  (payload, { rejectWithValue }) => {
    return axiosGet('/clients/billing-info')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateBillingData = createAsyncThunk(
  'billing/updateBillingData',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseDataToApi(payload)

    return axiosPut('/clients/billing-info', requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const sendBillingData = createAsyncThunk(
  'billing/sendBillingData',
  (payload, {rejectWithValue}) => {
    const requestPayload = baseDataToApi(payload)

    return axiosPost('/clients/billing-info', requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const makePayment = createAsyncThunk(
  'billing/makePayment',
  ({ product_id, quantity, success_url, cancel_url,locale,currency }, { rejectWithValue }) => {
    return axiosPost('/clients/stripe/checkout', {
      cancel_url,
      currency,
      locale,
      product_id,
      quantity,
      success_url
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const toggleSuccessStatus = createAction('billing/toggleSuccessStatus')

// new ones
export const onToggleBuyCreditsModal = createAction('billing/onToggleBuyCreditsModal')
