import React from 'react'
import { pathOr } from 'ramda'
import { Field, ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import Select from 'seniors-first-commons/components/molecules/Select'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'
import rawCountries from 'seniors-first-commons/utils/rawCountries'

const Address = ({
  user,
  countries,
  values,
  onChangeStep,
  ...props
}) => {
  const firstname = pathOr('', ['firstname'], user)
  const lastname = pathOr('', ['lastname'], user)

  const onChangeCountry = (e) => {
    //updates the phone input prefix based on the country selected
    const country = rawCountries.find(item => item[2].toLowerCase() === e.code.toLowerCase())
    props.setFieldValue('phone', country[3])
    if (values.country.value !== e.value) {
      props.setFieldValue('address', {
        address_lat: 0,
        address_lng: 0,
        formatted_address: ''
      })
    }
    props.setFieldValue('country', e)
  }

  const onContinue = () => onChangeStep(1)

  return (
    <>
      <h3 className='t2-bold primary has-margin-t-7 has-text-centered is-capitalized ellipsis full-width'>
        <FormattedMessageWithClass id='client.welcome.address.welcome' />, {firstname} {lastname}!
      </h3>

      <p className='l-regular grey has-margin-t-7 has-text-centered'>
        <FormattedMessageWithClass id='client.welcome.address.text' />
      </p>

      <div className='full-width'>
        <FieldLabel
          bottomSpace={0}
          centered={false}
          labelName='client.welcome.address.country'
          labelType='l-bold'
          topSpace={3}
        />
        <Field
          addStyles={{
            container: (base) => ({
              ...base,
              width: '100%'
            })
          }}
          component={Select}
          icon={false}
          name='country'
          onChange={onChangeCountry}
          options={countries}
          placeholder=''
          shouldTranslate={false}
          value={values.country.value ? values.country : null}
        />
        <ErrorMessage
          centered={false}
          component={HandleError}
          name='country'
          spaceTop={0}
        />
      </div>

      <div className='full-width'>
        <FieldLabel
          bottomSpace={0}
          centered={false}
          labelName='client.welcome.address.city'
          labelType='l-bold'
          topSpace={3}
        />
        <Field
          component={AddressAutoSuggest}
          country={values.country.code}
          id='address'
          inputClassName='input'
          name='address'
          placeholder=''
        />
        <ErrorMessage
          centered={false}
          component={HandleError}
          name='address'
          spaceTop={0}
        />
      </div>

      <div className='full-width'>
        <FieldLabel
          centered={false}
          id='phone'
          labelName='client.profileSettings.phone'
          labelType='p1-bold'
          topSpace={1}
        />
        <Field
          component={PhoneInput}
          name='phone'
        />
        <ErrorMessage
          component={HandleError}
          name='phone'
        />

        <div className={`has-margin-t-6 d-flex ${classes.phoneCommentCentered}`}>
          <InfoIcon
            fill='none'
            height='24'
            stroke='#0040AA'
            width='24'
          />
          <FormattedMessageWithClass
            className='p3-regular primary has-margin-l-7 has-text-left'
            id='client.profileSettings.phoneComment'
          />
        </div>
      </div>

      <button
        className='filled has-margin-t-2 full-width'
        onClick={onContinue}
        style={{ height: '45px', maxWidth: '200px' }}
        type='button'
      >
        <FormattedMessageWithClass
          id='client.welcome.address.continue'
        />
      </button>
    </>
  )
}

export default Address
