import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import {
  createJobOffer,
  postJobOffer,
  toggleRegisterModal,
  requestAssistanceForCreatedPublicOffer
} from 'store/jobOffers/actions'
import CreatePublicJobOfferLayout from 'components/templates/CreatePublicJobOfferLayout'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import RegisterModal from 'components/organisms/RegisterModal'
import routingService from 'seniors-first-commons/services/routingService'
import { ROUTE } from 'root/constants'
import { getCountries } from 'seniors-first-commons/shared/store/helpers/actions'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import RequestAsistancePublicOfferModal from 'components/organisms/RequestAsistancePublicOfferModal'
import { initialAddresses } from 'root/constants'
import AppContainer from 'components/organisms/PublicAppContainer'
import RoleConfirmationModal from 'components/organisms/RoleConfirmationModal'


const CreateJobOffer = ({
  user,
  creatingJobOffer,
  createJobOffer,
  toggleRegisterModal,
  getCountries,
  requestAssistanceForCreatedPublicOffer
}) => {
  const { locale } = useIntl()
  const mediaQuery = useMediaQuery()
  const history = useHistory()
  const matchesMobile = mediaQuery[0] <= 768
  const [formValues, setFormValues] = useState({})
  const [chosenCountryId, setChosenCountryId] = useState(null)
  const [countries, setCountries] = useState([])
  const [showRoleConfirmationModal, setShowRoleConfirmationModal] = useState(false)
  const [requestAssistanceModalOpen, setRequestAssistanceModalOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [confirmationHasBeenShownOnce, setConfirmationHasBeenShownOnce] = useState(false)

  useEffect(() => {
    getCountries().unwrap().then(countries => {
      const country = countries.find(country => country.code.toLocaleLowerCase() === chosenCountry())
      setChosenCountryId(country.id)
      setCountries(countries)
    })
  }, [])

  useEffect(() => {
    if (currentStep === 1 && !confirmationHasBeenShownOnce) {
      setShowRoleConfirmationModal(true)
      setConfirmationHasBeenShownOnce(true)
    }
  }, [currentStep])

  const onFormSubmit = (args, { setSubmitting, setStatus }) => {
    setFormValues({...args, country: chosenCountryId})
    setRequestAssistanceModalOpen(true)
  }

  const onCloseRequestAsistance = () => {
    setRequestAssistanceModalOpen(false)
    toggleRegisterModal(true)
  }

  const onConfirmRequestAssistance = ({phone}) => {
    setRequestAssistanceModalOpen(false)
    requestAssistanceForCreatedPublicOffer({
      language: locale,
      phone
    })
    toggleRegisterModal(true)
  }

  const publicOfferWording = useFormatMessage('client.createPublicOffer.publicOffer')

  const onUserRegisteredAndLoggedIn = ({
    user_id
  }) => {
    const offerNameParts = {
      address: formValues.address.formatted_address,
      currentDate: new Date().toLocaleDateString(),
      publicOffer: publicOfferWording
    }
    const offerName = `${offerNameParts.publicOffer} - ${offerNameParts.currentDate} - ${offerNameParts.address}`

    toggleRegisterModal(false)
    const payload = {
      ...formValues,
      client_id: user_id,
      country: chosenCountryId,
      name: offerName
    }

    createJobOffer(payload)
      .unwrap()
      .then((response) => {
        const id = response.id
        routingService.push(history, `${ROUTE.SEARCH}/${id}`)
      })
  }

  const chosenCountry = () => {
    const country_code = locale.split('-')[1].toLocaleLowerCase()
    return country_code
  }

  const currencies = {
    'es': '€',
    'gb': '£',
    'us': '$'
  }

  const profile = {
    country_id: chosenCountryId,
    currency: currencies[chosenCountry()]
  }

  return (
    <AppContainer
      additionalClass={matchesMobile ? 'has-padding-lr-7 is-margineless-lr' : '' }
      name='Create job offer'
      shouldRequestProfile={false}
    >
      <div className='is-flex has-page-height'>
        <CreatePublicJobOfferLayout logicPropsAndMethods={{
          chosenCountry,
          loading: creatingJobOffer,
          matchesMobile,
          onFormSubmit,
          profile,
          setCurrentStep,
          user
        }}
        />
        <RoleConfirmationModal
          onClose={() => setShowRoleConfirmationModal(false)}
          open={showRoleConfirmationModal}
        />
        <RegisterModal
          onUserRegisteredAndLoggedIn={onUserRegisteredAndLoggedIn}
          profileValues={formValues}
          role='client'
          withRegisterLaterOption={true}
        />
        <RequestAsistancePublicOfferModal
          countries={countries}
          onClose={() => onCloseRequestAsistance()}
          onConfirm={onConfirmRequestAssistance}
          open={requestAssistanceModalOpen}
          phone={initialAddresses[chosenCountry()].phone}
        />
      </div>
    </AppContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    creatingJobOffer: state.jobOffers.creatingJobOffer,
    helpers: state.helpers.helpers,
    postJobLoading: state.jobOffers.postJobLoading,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  createJobOffer,
  getCountries,
  postJobOffer,
  requestAssistanceForCreatedPublicOffer,
  toggleRegisterModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobOffer)
