class Auth {
  getToken() {
    return localStorage.getItem('access_token') || ''
  }

  setSession(authResult = {}) {
    if (localStorage.getItem('access_token')) localStorage.removeItem('access_token')
    if (authResult.access_token) {
      localStorage.setItem('access_token', authResult.access_token)
      localStorage.setItem('expires_at', new Date().getTime() - 60 + authResult.expires * 1000)
    }
    if (authResult.refresh_token) localStorage.setItem('refresh_token', authResult.refresh_token)
  }

  saveLocation(location, hash) {
    localStorage.setItem('last_location', location)
    localStorage.setItem('hash_location', hash)
  }

  getLocation() {
    return localStorage.getItem('last_location')
  }

  removeLocation() {
    const location = this.getLocation() || ''
    const hash = localStorage.getItem('hash_location') || ''

    if (location.length > 0) localStorage.removeItem('last_location')
    if (hash.length > 0) localStorage.removeItem('hash_location')
  }

  logout() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('expires_at')
  }

  isAuthenticated() {
    return localStorage.getItem('access_token') !== null
  }
}

const auth = new Auth()
export default auth
