import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getJobOffers } from 'store/jobOffers/actions'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import MyJobOffersLayout from 'components/templates/MyjobOffersLayout'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const MyJobOffers = ({
  profile,
  jobOffers,
  loadingJobOffers,
  postJobLoading,
  getJobOffers
}) => {
  const [showArchived, setShowArchived] = useState(false)
  const [selectedSort, setSelectedSort] = useState({
    label: 'client.myOffers.sortby.mostRecent',
    value: 'updated_at/desc'
  })
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  useEffect(() => {
    getJobOffers({
      archived: showArchived,
      order_by: selectedSort.value.split('/')[0],
      order_type: selectedSort.value.split('/')[1],
      page: 1,
      per_page: ITEMS_PER_PAGE
    })
    // eslint-disable-next-line
  }, [selectedSort.value, showArchived])

  const handlePageChange = (e) => {
    if (e !== jobOffers.currentPage) {
      getJobOffers({
        archived: showArchived,
        order_by: selectedSort.value.split('/')[0],
        order_type: selectedSort.value.split('/')[1],
        page: e,
        per_page: ITEMS_PER_PAGE
      })
    }
  }

  const onToggleArchivedStatus = () => setShowArchived(!showArchived)

  const onChangeOrder = (e) => {
    setSelectedSort(e)
  }

  return (
    <div className='full-content'>
      <MyJobOffersLayout
        logicPropsAndMethods={{
          handlePageChange,
          jobOffers,
          loading: { loadingJobOffers, postJobLoading },
          matchesMobile,
          onChangeOrder,
          onToggleArchivedStatus,
          profile,
          selectedSort,
          showArchived
        }}
      />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    jobOffers: state.jobOffers.index,
    loadingJobOffers: state.jobOffers.loading,
    postJobLoading: state.jobOffers.postJobLoading,
    profile: state.profile.profile.index
  }
}

const mapDispatchToProps = {
  getJobOffers
}

export default connect(mapStateToProps, mapDispatchToProps)(MyJobOffers)
