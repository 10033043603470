import { ROUTE as COMMON_ROUTE } from 'seniors-first-commons/constants'

export const ROUTE = {
  ...COMMON_ROUTE,
  ANAYET_REQUEST: '/anayet-request',
  BILLING: '/billing',
  CANDIDATES: '/candidates',
  CAREGIVER_PROFILE: '/caregiver-profile',
  CAREGIVER_RATING: '/carer-rating',
  CHAT: '/chat',
  CREATE_JOB_OFFER: '/create-job-offer',
  CREATE_PUBLIC_JOB_OFFER: '/create-public-job-offer',
  CREDITS: '/credits',
  HOME: '/',
  MY_CAREGIVERS: '/my-caregivers',
  MY_JOB_OFFERS: '/my-job-offers',
  NOTIFICATIONS: '/notifications',
  PAYMENT_CANCELLED: '/payment-cancelled',
  PAYMENT_SUCCESS: '/payment-success',
  PROFILE_SETTINGS: '/profile-settings',
  SEARCH: '/search',
  WELCOME: '/welcome'
}

export const initialAddresses = {
  //Madrid as starting location for ES
  'es': {
    address_lat: 40.4167754,
    address_lng: -3.7037902,
    phone: '+34'
  },
  //London as starting location for GB
  'gb': {
    address_lat: 51.5072178,
    address_lng: -0.1275862,
    phone: '+44'
  },
  //New York as starting location for US
  'us': {
    address_lat: 40.7127753,
    address_lng: -74.0059728,
    phone: '+1'
  }
}
