import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import classes from './index.module.css'


const NavbarTabs = ({
  items,
  activeRoute,
  changeRoute
}) => {
  return (
    <div className='tabs is-toggle is-toggle-rounded is-centered full-width'>
      <ul className={classes.tabsList}>
        {items.map((item, index) => (
          <li
            className={`${activeRoute(item.route) ? classes.active : classes.item}`}
            key={index}>
            {/* eslint-disable-next-line */ }
            <a href={null} onClick={() => changeRoute(item['route'])}>
              <FormattedMessageWithClass
                className='p1-bold'
                id={item.text}
              />
              {item['icon'] &&
                <span className={`icon is-small circleBorder ${activeRoute(item.route) ? 'primary-background' : 'primary-light-background'}`}>
                  {item['icon']}
                </span>
              }
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavbarTabs
