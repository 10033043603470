import React from 'react'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'


const Retribution = ({ data, currency }) => {
  const translations = {
    hour: useFormatMessage('apps.general.jobOffer.salary.hourlyRate'),
    min: useFormatMessage('apps.general.jobOffer.salary.min'),
    month: useFormatMessage('apps.general.jobOffer.salary.monthlyRate')
  }

  return (
    <div className='grey is-flex' style={{ alignItems: 'center' }}>
      <span
        className='primary-dark is-flex'
        style={{ alignItems: 'center', justifyContent: 'center', width: '18px' }}
      >
        {currency}
      </span>

      <FormattedMessageWithClass
        className='p2-bold has-margin-l-7'
        id='apps.general.jobOffer.salary.wrapper'
        values={{
          currency: data?.retribution !== 'minimum' ? <span className='p2-regular grey'>{currency}</span> : '',
          paytime: data?.retribution !== 'minimum'
            ? translations[data?.salary_type]
            : translations.min,
          quantity: data?.retribution !== 'minimum' ? <span className='p2-regular grey'>{data?.retribution_amount}</span> : ''
        }}
      />
    </div>
  )
}


export default Retribution
