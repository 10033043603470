import React from 'react'
import ConnectedCandidates from '../ConnectedCandidates'
import CandidatesFromSearch from '../CandidatesFromSearch'
import CandidatesFromPost from '../CandidatesFromPost'


const CandidatesBody = ({
  matchesDesktop,
  matchesMobile,
  jobOfferId,
  selectedTab,
  oneSheetCandidates,
  postCandidates,
  sheetData,
  loading,
  onOpenVideoCVModal
}) => {
  switch(selectedTab) {
  case 'post':
    return (
      <CandidatesFromPost
        logicPropsAndMethods={{
          candidates: postCandidates,
          jobOfferId,
          loading: loading.loadingCandidates,
          matchesDesktop,
          matchesMobile,
          onOpenVideoCVModal
        }}
      />
    )
  case 'findsend':
    return (
      <CandidatesFromSearch
        logicPropsAndMethods={{
          candidates: oneSheetCandidates,
          jobOfferId,
          loading: loading.loadingCandidates,
          matchesDesktop,
          matchesMobile,
          onOpenVideoCVModal,
          sheetData
        }}
      />
    )
  case 'connected':
    return (
      <ConnectedCandidates
        logicPropsAndMethods={{
          candidates: oneSheetCandidates,
          jobOfferId,
          loading: loading.loadingCandidates,
          matchesDesktop,
          matchesMobile,
          onOpenVideoCVModal
        }}
      />
    )
  default:
    return null
  }
}

export default CandidatesBody
