import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { GenderIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Gender = ({ data, caregiverStatus }) => {
  const gender = data.gender === 'm'
    ? 'client.general.caregiverCard.gender.male'
    : 'client.general.caregiverCard.gender.female'

  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <GenderIcon
        fill='none'
        height='16'
        stroke={`${caregiverStatus.text === 'Rejected' ? '#A6A6A6' : '#0040AA'}`}
        width='18'
      />
      <FormattedMessageWithClass
        className={`has-margin-l-7 p2-regular ${caregiverStatus.text === 'Rejected' ? 'grey-light' : 'grey'}`}
        id={gender}
      />
    </div>
  )
}

export default Gender
