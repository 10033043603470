import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import AppContainer from 'components/organisms/AppContainer'
import RoundedTabs from 'seniors-first-commons/components/atoms/RoundedTabs'
import CredtisTable from 'components/organisms/CreditsTable'
import CreditsBoard from 'components/organisms/CreditsBoard'
import Select from 'seniors-first-commons/components/molecules/Select'
import Pagination from  'seniors-first-commons/components/molecules/Pagination'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'
import { ORDER_LIST_STYLES as listStyles } from 'seniors-first-commons/styles/orderListStyles'
import classes from './index.module.css'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'

const CreditsBody = ({
  creditRecords,
  tab,
  ...props
}) => {
  switch(tab) {
  case 'records':
    return (
      <>
        <p className='t4-regular primary has-margin-t-6'>
          <FormattedMessageWithClass id='client.credits.records.text1' /> {' '}
          {creditRecords.total} {' '}
          <FormattedMessageWithClass id='client.credits.records.text2' />
        </p>

        {creditRecords.items?.length > 0 ?
          <CredtisTable
            items={creditRecords.items}
            onViewJobOffer={props.onViewJobOffer}
          />
          : <EmptyBox stroke='#525860' />
        }
        <Pagination
          currentPage={creditRecords.page}
          data={creditRecords.total}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={props.onPageChange}
        />
      </>
    )
  case 'my-credits':
    return (
      <CreditsBoard
        matchesMobile={props.matchesMobile}
        withUserInfo={false}
      />
    )
  default:
    return null
  }
}

const CreditsLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    selectedTab,
    tabsItems,
    sortListItems,
    creditRecords,
    onChangeTab,
    onPageChange,
    onViewJobOffer
  }
}) => {
  return (
    <AppContainer
      additionalClass={matchesMobile ? 'has-padding-lr-7 is-marginless' : ''}
      name='Credits'
    >
      <div className={`${classes.header}`}>
        <h1 className='t1-bold primary '>
          <FormattedMessageWithClass id='client.credits.title' />
        </h1>

        <div className={classes.headerRight}>
          {selectedTab === 'record' &&
            <Select
              addStyles={listStyles}
              hasoptionicon={false}
              isSearchable={false}
              onChange={() => {}}
              options={sortListItems}
              placeholder='Sort by'
            />
          }

          <RoundedTabs
            items={tabsItems}
            matchesMobile={matchesMobile}
            onChangeTab={onChangeTab}
            selected={selectedTab}
          />
        </div>
      </div>

      <div className={`has-margin-t-${matchesMobile ? '5' : '7'}`}>
        <CreditsBody
          creditRecords={creditRecords}
          matchesMobile={matchesMobile}
          onPageChange={onPageChange}
          onViewJobOffer={onViewJobOffer}
          tab={selectedTab}
        />
      </div>
    </AppContainer>
  )
}

export default CreditsLayout
