import React, { useEffect, useRef, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Talk from 'talkjs'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import {
  chatWithCaregiver,
  getCaregiverBasicData,
  nullCaregiverBasicData,
  checkConnectionWithCaregiver
} from 'store/caregivers/actions'
import { getProfile } from 'store/profile/actions'
import { toggleSuccessStatus, changeErrorMessageStatus } from 'store/layouts/actions'
import InboxLayout from 'components/templates/InboxLayout'
import { ROUTE } from 'root/constants'


const Inbox = ({
  profile,
  basicData,
  areConnected,
  loading,
  chatWithCaregiver,
  getProfile,
  getCaregiverBasicData,
  nullCaregiverBasicData,
  checkConnectionWithCaregiver,
  toggleSuccessStatus,
  changeErrorMessageStatus,
  ...props
}) => {
  const [conversationSelected, setConversationSelected] = useState(false)
  const [notificationsEnabled, setNotificationsEnabled] = useState(false)
  const [conversationDetails, setConversationsDetails] = useState({})
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const matchesDesktop = mediaQuery[0] <= 1215

  const inboxRef = useRef(null)
  const chatInboxBackButtonRef = useRef(null)

  useEffect(() => {
    const desktopNotifications = localStorage.getItem('talkjs:desktop_notify')
    if (desktopNotifications === 'true') setNotificationsEnabled(true)
    else setNotificationsEnabled(false)
  }, [])

  const caregiverId = props.match.params.id

  const onSelectConversation = (caregiver_id_from_conversation) => {
    getCaregiverBasicData({ caregiver_id: caregiver_id_from_conversation })
  }

  const createSession = (selectedConversation, chatUserId) => {
    if (chatUserId) {
      Talk.ready
        .then(() => {
          if(!window.talkSession) {
            const user = new Talk.User(chatUserId)
            window.talkSession = new Talk.Session({
              appId: process.env.REACT_APP_TALK_ID,
              me: user
            })
          }

          const inbox = window.talkSession.createInbox({
            selected: selectedConversation,
            showChatHeader: false,
            showFeedHeader: false,
            showMobileBackButton: false
          })

          // eslint-disable-next-line no-unused-vars
          inbox.on('conversationSelected', ({ conversation, others }) => {
            if (others && others.length) {
              const carer_id = others[0].custom.sf_user_id
              const image_url = others[0].photoUrl
              if (carer_id) {
                setConversationSelected(true)
                onSelectConversation(carer_id)
                setConversationsDetails({
                  carer_id,
                  carer_name: others.map((o) => o.name).join(', '),
                  image_url
                })
              }
            }
            else {
              if (matchesMobile) {
                setConversationSelected(false)
                nullCaregiverBasicData()
              }
            }
            if (chatInboxBackButtonRef.current) {
              chatInboxBackButtonRef.current.addEventListener('click', () => {
                inbox.select(undefined)
              })
            }
          })
          inbox.mount(inboxRef.current)
        })
        .catch(error => changeErrorMessageStatus(error))
    }
  }

  const startChatting = useCallback(() => {
    if (caregiverId) {
      if (areConnected?.caregiver_id === parseInt(caregiverId) && areConnected?.are_connected) {
        chatWithCaregiver({ caregiver_id: caregiverId })
          .then(({ payload }) => {
            if (!profile?.chat_user_id) {
              getProfile()
                .then((profile) => {
                  setConversationSelected(true)
                  createSession(payload.chat_conversation_id, profile.payload.chat_user_id)
                })
            }
            else {
              setConversationSelected(true)
              createSession(payload.chat_conversation_id, profile.chat_user_id)
            }
          })
      }
      else {
        checkConnectionWithCaregiver({ caregiver_id: caregiverId })
          .unwrap()
          .then((payload) => {
            if (payload.are_connected) {
              chatWithCaregiver({ caregiver_id: caregiverId })
                .then(({ payload }) => {
                  if (!profile?.chat_user_id) {
                    getProfile()
                      .then((profile) => {
                        setConversationSelected(true)
                        createSession(payload.chat_conversation_id, profile.payload.chat_user_id)
                      })
                  }
                  else {
                    setConversationSelected(true)
                    createSession(payload.chat_conversation_id, profile.chat_user_id)
                  }
                })
            }
            else {
              getProfile()
                .then((profile) => {
                  nullCaregiverBasicData()
                  createSession(undefined, profile.payload.chat_user_id)
                })
            }
          })
      }
    }

    else {
      if (profile?.chat_user_id) {
        createSession(undefined, profile.chat_user_id)
      }
      else {
        getProfile()
          .then(profile => {
            createSession(undefined, profile.payload.chat_user_id)
          })
      }
    }
    // eslint-disable-next-line
  }, [getProfile, chatWithCaregiver, getCaregiverBasicData])

  useEffect(() => {
    nullCaregiverBasicData()
    startChatting()
  }, [startChatting, nullCaregiverBasicData])

  const onToggleNotification = () => {
    window.talkSession.setDesktopNotificationEnabled(!notificationsEnabled, { alertOnFailure: true })
      .then(() => {
        setNotificationsEnabled(!notificationsEnabled)
      })
      .catch(() => {
        setNotificationsEnabled(false)
      })
  }

  const onRedirectToCaregiverProfile = (carer_id) => {
    routingService.push(history, `${ROUTE.CAREGIVER_PROFILE}/${carer_id}?from=connected`)
  }

  const onCopyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text.toString())
      toggleSuccessStatus('success.textCopied')
    }
    else {
      changeErrorMessageStatus('errors.httpNothttps')
    }
  }

  return (
    <div className='full-content'>
      {!loading &&
        <InboxLayout
          basicData={basicData}
          chatId={profile?.chat_user_id}
          chatInboxBackButtonRef={chatInboxBackButtonRef}
          conversationDetails={conversationDetails}
          conversationSelected={conversationSelected}
          inboxRef={inboxRef}
          matchesDesktop={matchesDesktop}
          matchesMobile={matchesMobile}
          notificationsEnabled={notificationsEnabled}
          onCopy={onCopyToClipboard}
          onRedirectToCaregiverProfile={onRedirectToCaregiverProfile}
          onToggleNotification={onToggleNotification}
        />
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    areConnected: state.caregivers.chat.areConnected,
    basicData: state.caregivers.caregiverBasicData.index,
    loading: state.user.loading ||
      state.caregivers.chat.loading,
    profile: state.profile.profile.index
  }
}

const mapDispatchToProps = {
  changeErrorMessageStatus,
  chatWithCaregiver,
  checkConnectionWithCaregiver,
  getCaregiverBasicData,
  getProfile,
  nullCaregiverBasicData,
  toggleSuccessStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Inbox)
