import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { ShareIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Contract = ({ data }) => {
  return (
    <div className='is-flex' style={{ alignItems: 'center' }}>
      <ShareIcon
        fill='none'
        height='16'
        stroke='#022C72'
        width='18'
      />
      <FormattedMessageWithClass
        className='has-margin-l-7 p2-regular grey is-capitalized'
        id={
          data?.contract_type === 'autonomous'
            ? 'apps.general.jobOffer.contract.autonomus'
            : data?.contract_type === 'company'
              ? 'apps.general.jobOffer.contract.company' : ''
        }
      />
    </div>
  )
}

export default Contract
