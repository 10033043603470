import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  getUnlockedCaregivers,
  chatWithCaregiver,
  setInboxSelectedConversation
} from '../../store/caregivers/actions'
import { getProfile } from '../../store/profile/actions'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import MyCaregiversLayout from 'components/templates/MyCaregiversLayout'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'


const MyCaregivers = ({
  unlockedCaregivers,
  unlockedCaregiversLoading,
  getUnlockedCaregivers
}) => {
  const [showVideoCVModal, setShowVideoCVModal] = useState('')
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  const fetchUnlockedCaregiver = useCallback(() => {
    getUnlockedCaregivers({
      page: 1,
      per_page: ITEMS_PER_PAGE
    })
  }, [getUnlockedCaregivers])

  useEffect(() => {
    fetchUnlockedCaregiver()
  }, [fetchUnlockedCaregiver])

  const handlePageChange = (e) => {
    if (e !== unlockedCaregivers.currentPage) {
      getUnlockedCaregivers({
        page: e,
        per_page: ITEMS_PER_PAGE
      })
    }
    else return
  }

  const onOpenVideoCVModal = (video_id) => {
    setShowVideoCVModal(video_id)
  }

  const onCloseVideoCVModal = () => {
    setShowVideoCVModal('')
  }

  return (
    <div className='full-content'>
      <MyCaregiversLayout
        logicPropsAndMethods={{
          handlePageChange,
          loading: { unlockedCaregiversLoading },
          matchesMobile,
          onCloseVideoCVModal,
          onOpenVideoCVModal,
          showVideoCVModal,
          unlockedCaregivers
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    chatDetails: state.caregivers.chat,
    profile: state.profile.profile.index,
    unlockedCaregivers: state.caregivers.unlockedCaregiver,
    unlockedCaregiversLoading: state.caregivers.loading
  }
}

const mapDispatchToProps = {
  chatWithCaregiver,
  getProfile,
  getUnlockedCaregivers,
  setInboxSelectedConversation
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCaregivers)
